import { LinkageState } from "./types";
import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { linkageAction } from "./actions";
import { Linkage } from "../../core/api";
import dayjs from "dayjs";
import {
  findLinkageDataType,
  parseLinkageStatus,
  parseLinkageTerm,
} from "../../components/pages/linkages/hooks";

const initialState: LinkageState = {
  linkages: [],
  linkage: undefined,
  filteredLinkages: undefined,
  filter: undefined,
};

export function LinkageReducer(
  state = initialState,
  action: Action
): LinkageState {
  if (isType(action, linkageAction.getLinkages.done)) {
    return {
      ...state,
      linkages: action.payload.result,
    };
  }
  if (isType(action, linkageAction.getLinkage.done)) {
    return {
      ...state,
      linkage: action.payload.result,
    };
  }

  if (isType(action, linkageAction.filterLinkages.done)) {
    const conditionName = action.payload.params.request.name;
    const conditionStatus = action.payload.params.request.status;
    const conditionDataType = action.payload.params.request.dataType;
    const conditionTerm = action.payload.params.request.term;
    const conditionFromYear = action.payload.params.request.fromYear;
    const conditionFromMonth = action.payload.params.request.fromMonth ?? "1";
    const conditionToYear = action.payload.params.request.toYear;
    const conditionToMonth = action.payload.params.request.toMonth ?? "1";
    const conditionFrom = conditionFromYear
      ? dayjs(`${conditionFromYear}-${conditionFromMonth}`)
          .startOf("month")
          .toDate()
      : undefined;
    const conditionTo = conditionToYear
      ? dayjs(`${conditionToYear}-${conditionToMonth}`).endOf("month").toDate()
      : undefined;

    const filteredLinkages = state.linkages.filter((l: Linkage) => {
      const { is_draft, start_at, end_at } = l;
      const status = parseLinkageStatus({ is_draft, start_at, end_at });
      const dataType = findLinkageDataType({
        analysisTypes: action.payload.params.analysisTypes,
        dataTypeId: l.data_type,
      });
      const term = parseLinkageTerm({
        term: l.term,
        analysisTypeId: l.data_type,
        analysisTypes: action.payload.params.analysisTypes,
      });

      if (conditionName && !l.name?.includes(conditionName)) {
        return false;
      }
      if (conditionStatus && status !== conditionStatus) {
        return false;
      }
      if (conditionDataType && dataType?.name !== conditionDataType) {
        return false;
      }
      if (conditionTerm && term !== conditionTerm) {
        return false;
      }
      if (
        conditionFrom &&
        !conditionTo &&
        !(conditionFrom <= dayjs(l.start_at).toDate())
      ) {
        return false;
      } else if (
        !conditionFrom &&
        conditionTo &&
        !(conditionTo >= dayjs(l.start_at).toDate())
      ) {
        return false;
      } else if (
        conditionFrom &&
        conditionTo &&
        !(
          dayjs(l.start_at).toDate() <= conditionTo &&
          dayjs(l.end_at).toDate() >= conditionFrom
        )
      ) {
        return false;
      }

      return true;
    });

    return {
      ...state,
      filteredLinkages,
      filter: action.payload.params.request,
    };
  }
  return state;
}
