import { polygon as turfPolygon } from "@turf/helpers";
import kinks from "@turf/kinks";

export const checkSelfIntersection = (
  coordinates: [number, number][] // longitude, latitude
) => {
  const selfIntersection = kinks(turfPolygon([coordinates]));

  return selfIntersection.features.length > 0;
};
