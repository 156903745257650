import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Tenant, TenantModifyRequestBody, TenantRequestBody } from "../../core/api";

const actionCreator = actionCreatorFactory("@@Tenant");

export const tenantAction = {
  selectTenant: actionCreator.async<{ id: number }, {}>("SELECT_TENANT"),
  setSelectedTenants: actionCreator.async<{ ids: number[] }, {}>("SET_SELECTED_TENANTS"),
  fetchTenantList: actionCreator.async<CommonAsyncType, Tenant[]>("FETCH_TENANT_LIST"),
  deleteTenants: actionCreator.async<{ id: number } & CommonAsyncType, {}>("DELETE_TENANT"),
  createTenant: actionCreator.async<{ body: TenantRequestBody } & CommonAsyncType, {}>("CREATE_TENANT"),
  modifyTenant: actionCreator.async<{ body: TenantModifyRequestBody } & CommonAsyncType, {}>("MODIFY_TENANT"),
  resetSelectedTenant: actionCreator("RESET_SELECTED_TENANT"),
  fetchNextTenant: actionCreator("FETCH_NEXT_TENANT"),
  fetchPrevTenant: actionCreator("FETCH_PREV_TENANT"),
  fetchLatestTenant: actionCreator.async<{ num: number }, {}>("FETCH_LATEST_TENANT"),
  fetchMostPreviousTenant: actionCreator("FETCH_MOST_PREVIOUS_TENANT"),
  setMaxPage: actionCreator.async<{ num: number }, {}>("SET_MAX_PAGE"),
};
