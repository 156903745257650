import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { AppConfig } from "../../AppConfig";
import { PageRoutes } from "../../constants/Routes";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { appActions } from "../../ducks/app";
import { assetAction } from "../../ducks/asset";
import { assetSelectors } from "../../ducks/asset/selector";
import { Header } from "../organisms/Header";
import { SideNavigation } from "../organisms/SideNavigation";

export const MenuTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sideMenus = useSelector(assetSelectors.getMenus);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);

  useEffect(() => {
    const accessToken = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["accessToken"]
    );
    setIsLoggedIn(!!accessToken);
    if (!accessToken) {
      setIsLoading(false);
      dispatch(appActions.hideLoading());
      navigate(PageRoutes.LOGIN);
    } else {
      if (isLoading) {
        dispatch(appActions.showLoading());
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        assetAction.fetchAsset.started({
          onComplete: () => {
            setIsLoading(false);
            dispatch(appActions.hideLoading());
          },
        })
      );
    }
  }, [isLoggedIn, dispatch]);

  if (isLoading) {
    return null;
  }

  return (
    <main className="flex flex-col h-[100svh] bg-gray-background">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <SideNavigation menus={sideMenus} />
        <div className="flex-1 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </main>
  );
};
