import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Asset, Service, Spot, Tenant, User } from "../../core/api";
import { FetchUserListResponse } from "./types";

const actionCreator = actionCreatorFactory("@@Asset");

export const assetAction = {
  fetchAsset: actionCreator.async<CommonAsyncType & { forceRefetch?: boolean }, Asset>("FETCH_ASSET"),
  fetchService: actionCreator.async<CommonAsyncType, Service>("FETCH_SERVICE"),
  fetchSpotList: actionCreator.async<CommonAsyncType & { forceRefetch?: boolean }, Spot[]>("FETCH_SPOT_LIST"),
  fetchUserList: actionCreator.async<CommonAsyncType & { page?: number; forceRefetch?: boolean }, FetchUserListResponse>("FETCH_USER_LIST"),
  fetchTenantList: actionCreator.async<CommonAsyncType & { forceRefetch?: boolean }, Tenant[]>("FETCH_TENANT_LIST"),
  fetchLoggedInUser: actionCreator.async<CommonAsyncType, User | null>("FETCH_LOGGED_IN_USER"),
  reset: actionCreator('RESET'),
};
