import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selectApp = (state: StoreState) => state.linkage;
export const linkageSelectors = {
  getLinkages: createSelector(selectApp, (state) => state.linkages),
  getLinkage: createSelector(selectApp, (state) => state.linkage),
  getFilteredLinkage: createSelector(
    selectApp,
    (state) => state.filteredLinkages
  ),
  getFilter: createSelector(selectApp, (state) => state.filter),
};
