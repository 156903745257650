import actionCreatorFactory from "typescript-fsa";
import { EmptyObject } from "@reduxjs/toolkit";
import { CommonAsyncType } from "../../core/@types";
import { RoadResponseBody } from "./types";

const actionCreator = actionCreatorFactory("@@Road");

export const roadAction = {
  fetchRoadById: actionCreator.async<
    { id: number } & CommonAsyncType,
    RoadResponseBody
  >("FETCH_ROAD_BY_ID"),
  fetchAllRoads: actionCreator.async<CommonAsyncType, RoadResponseBody[]>(
    "FETCH_ALL_ROADS"
  ),
  addRoadMapSelection: actionCreator.async<EmptyObject, {}>(
    "ADD_ROAD_MAP_SELECTION"
  ),
  resetRoadMapSelection: actionCreator("RESET_ROAD_MAP_SELECTION"),
  selectRoadLine: actionCreator.async<RoadResponseBody, {}>("SELECT_ROAD_LINE"),
  activateSecondDraw: actionCreator.async<boolean, {}>("ROAD_CREATION_NUMBER"),
};
