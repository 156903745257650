import { StoreState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";

const selectApp = (state: StoreState) => state.poiConditions;
export const poiConditionSelectors = {
  getConditions: createSelector(selectApp, (state) => state.conditions),
  getPoiByConditions: createSelector(
    selectApp,
    (state) => state.poiByConditions
  ),
};
