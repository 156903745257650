import React, { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { z } from "zod";
import { Spot } from "../../core/api";
import { assetSelectors } from "../../ducks/asset/selector";
import { useCreateSpot } from "../../hooks/useCreateSpot";
import { CustomButton } from "../atoms/CustomButton/CustomButton";
import Input from "../atoms/Input";
import Form from "./Form";

type Props = {
  isOpen: boolean;
  handleNext?: () => void;
  handleClose: () => void;
  handleCallback: () => void;
  selectedSpot?: (Spot & {isNew?: boolean}) | null;
};
export const CreateSpotNameModal: React.FC<Props> = (props) => {
  const { selectedSpot } = props;
  const showDataSet = useSelector(assetSelectors.getDisplayItems);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",

      zIndex: 50,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 140,
    },
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const { handleRegisterSopt, handleModifySpot, handleRegisterPOISpot } = useCreateSpot();

  return (
    <Modal
      style={modalStyle}
      portalClassName="alert-modal"
      isOpen={props.isOpen}
    >
      <div className="w-[640px] min-h-[298px] rounded-lg flex flex-col items-center pb-[32px] bg-white px-[40px]">
        <div className="pt-[17px] items-start w-full font-bold">
          {selectedSpot ? "分析スポットの編集" : "分析スポットの新規追加" }
        </div>
        <div className="w-[416px]  flex flex-col items-center">
          <Form
            schema={z.object({
              spotName: z
                .string()
                .min(1, { message: "スポット名を入力してください" }),
              dataSetName: z
                .string()
                .min(1, { message: "データセット名を入力してください" }),
            })}
            onSubmit={async ({ spotName, dataSetName }) => {
              props.handleCallback();

              if (props.selectedSpot) {
                if (props.selectedSpot.isNew) {
                  handleRegisterPOISpot({
                    ...props.selectedSpot,
                    spot_name: spotName,
                    dataset_name: dataSetName ?? "",
                  });
                } else {
                  handleModifySpot({
                    ...props.selectedSpot,
                    spot_name: spotName,
                    dataset_name: dataSetName ?? "",
                  });
                }
              } else {
                handleRegisterSopt({
                  spot_name: spotName,
                  dataset_name: dataSetName ?? "",
                });
              }
            }}
            className="w-full"
          >
            <div
              className={`mt-[30px] ${
                showDataSet.data_set_name ? "" : "hidden"
              }`}
            >
              <Input
                name="dataSetName"
                required={showDataSet.data_set_name}
                label="データセット名"
                placeholder="入力してください"
                className="w-full bg-white"
                defaultValue={
                  showDataSet.data_set_name
                    ? selectedSpot?.dataset_name ?? ""
                    : " "
                }
              />
            </div>
            <div className="mt-[30px]">
              <Input
                name="spotName"
                required
                label="スポット名"
                placeholder="入力してください"
                className="w-full bg-white"
                defaultValue={selectedSpot?.spot_name ?? ""}
              />
            </div>
            <div className="w-full flex mt-[50px]">
              <CustomButton
                type="button"
                className="w-full mt-[16px] bg-white text-black border-gray-light border"
                onClick={props.handleClose}
                text="キャンセル"
              />
              <CustomButton
                className="w-full mt-[16px] ml-[16px]"
                type="submit"
                text="保存"
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
