import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageRoutes } from "../../../constants/Routes";
import { SpotStatusEnum } from "../../../core/api";
import { analysisExecuteActions } from "../../../ducks/analysis_execute";
import { assetSelectors } from "../../../ducks/asset/selector";
import { getAnalysisIcon } from "../../../utils/icon";
import { AnalysisSpotDisplay } from "../../molecules/AnalysisSpotDisplay";
import { AnlyzeSpotCheckbox } from "../../molecules/AnlyzeSpotCheckbox";
import { DataGenerationStatus } from "../../molecules/DataGenerationStatus";
import { SpotMap } from "../../organisms/SpotMap";
import { useAnalysisExecute } from "../analysis_execution/hooks";

export const SelectSpotForAnalysis = () => {
  const dispatch = useDispatch();
  const spots = useSelector(assetSelectors.getSpots);
  const displayFilter = useSelector(assetSelectors.getDisplayItems);

  const {
    selectedAnalysisItem,
    handleSelectSpotItem,
    selectedAnalysisSpotItems,
  } = useAnalysisExecute();

  useEffect(() => {
    dispatch(analysisExecuteActions.setStep.started(2));

    return () => {
      const hash = window.location.hash.replace("#", "");
      if (hash !== PageRoutes.SELECT_SPOT_ANALYSIS_TYPE) {
        dispatch(analysisExecuteActions.analysisReset());
      }
    };
  }, []);

  const Icon = getAnalysisIcon(selectedAnalysisItem.id);

  return (
    <div className="flex flex-col gap-y-5 h-full  overflow-hidden">
      <AnalysisSpotDisplay
        icon={Icon ? <Icon className="w-6 h-6 text-primary" /> : null}
        title={selectedAnalysisItem?.name ?? ""}
      />

      <div className="text-sm">
        分析するスポットを選択してください。
        {selectedAnalysisItem.min === selectedAnalysisItem.max
          ? `（${selectedAnalysisItem.min}個選択可）`
          : `（${selectedAnalysisItem.min}〜${selectedAnalysisItem.max}個選択可）`}
      </div>

      <div className="flex gap-x-4 h-auto overflow-hidden">
        <div className="overflow-y-auto w-[350px] px-4">
          {spots &&
            [...spots]
              .sort((a, b) =>
                a.spot_name && b.spot_name
                  ? a.spot_name?.localeCompare(b.spot_name, "en")
                  : -1
              )
              .map((spot) => {
                const { id, status, spot_name } = spot;
                return (
                  <div
                    key={id}
                    className="flex border-b py-3 gap-x-4  border-gray-border items-center"
                  >
                    <AnlyzeSpotCheckbox
                      isSelected={
                        !!selectedAnalysisSpotItems.find((x) => x.id === id)
                      }
                      onClick={() => handleSelectSpotItem(spot)}
                      disabled={
                        status !== SpotStatusEnum.Success &&
                        displayFilter.spot_select_always === false
                      }
                      isMax={
                        !!selectedAnalysisItem.max &&
                        selectedAnalysisSpotItems.length >=
                          selectedAnalysisItem.max
                      }
                    />
                    <div className="flex-1 text-[13px] break-all">
                      {spot_name}
                    </div>
                    {displayFilter.data_gen_status === true && (
                      <DataGenerationStatus status={status} />
                    )}
                  </div>
                );
              })}
        </div>
        <SpotMap
          allowMultipleSelection
          spots={spots.map((spot) => ({
            ...spot,
            preventSelection: spot.status !== SpotStatusEnum.Success,
          }))}
        />
      </div>
    </div>
  );
};
