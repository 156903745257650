import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { assetAction } from "../../../ducks/asset";
import { assetSelectors } from "../../../ducks/asset/selector";
import { tenantAction } from "../../../ducks/tenant";
import { formatDate, formatDateTime } from "../../../utils/date";
import Pagination from "../../molecules/Pagination";
import Table from "../../molecules/Table";
import { TenantDeleteModal } from "../../molecules/TenantDeleteModal";
import { TenantModal } from "../../molecules/TenantModal";
import { useTenantManagement } from "./hooks";

export const PageTenantManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isAdmin = useSelector(assetSelectors.isAdminUser);
  const loggedInUser = useSelector(assetSelectors.getLoggedInUser);
  const sideMenus = useSelector(assetSelectors.getMenus);

  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setNew] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    selectedTenantItem,
    deleteTenant,
    page,
    maxPage,
    tenantList,
    numLoad,
    offSet,
    cityState,
    setSelectedTenants,
  } = useTenantManagement();

  const title = useMemo(() => {
    return sideMenus.filter((item) => item.path === location.pathname);
  }, [sideMenus, location.pathname]);

  const enableEdit = useMemo(() => {
    return selectedTenantItem.length === 1;
  }, [selectedTenantItem]);

  const enableDelete = useMemo(() => {
    return selectedTenantItem.length > 0;
  }, [selectedTenantItem]);

  const displayTenant = useMemo(() => {
    return tenantList.slice(offSet, offSet + numLoad);
  }, [tenantList, offSet, numLoad]);

  const handleCloseModal = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenModal = (isCreate?: boolean) => {
    if (isCreate) {
      setNew(true);
    } else {
      setNew(false);
    }
    if (enableEdit || isCreate) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    dispatch(assetAction.fetchTenantList.started({}));
  }, [dispatch]);

  return (
    <>
      <div className="w-full h-full pb-[96px]">
        <div className="bg-white ml-[1px]">
          <p className="ml-[30px] py-[20px] text-[#334A7C] text-[16px] leading-4 font-bold">
            {title?.[0]?.name ?? ""}
          </p>
        </div>
        <div className="mx-5 flex my-4 h-full overflow-hidden flex-col bg-white rounded px-5 py-5">
          <div className="flex justify-between items-center mb-[20px]">
            <div className="flex gap-x-2.5">
              <button
                type="button"
                disabled={!loggedInUser?.wheel}
                className="rounded text-sm w-[96px] h-9 border disabled:opacity-30 border-primary bg-primary text-white disabled:text-[#C0C7D7] disabled:bg-white disabled:border disabled:border-[#C0C7D7] disabled:cursor-not-allowed"
                onClick={() => {
                  handleOpenModal(true);
                }}
              >
                新規追加
              </button>
              <button
                type="button"
                disabled={!enableEdit || !loggedInUser?.wheel}
                className="rounded text-sm w-[72px] h-9 bg-white border flex items-center justify-center text-primary border-primary disabled:text-[#C0C7D7] disabled:border disabled:border-[#C0C7D7] disabled:cursor-not-allowed"
                onClick={() => {
                  handleOpenModal();
                }}
              >
                編集
              </button>
              <button
                type="button"
                disabled={!enableDelete || !loggedInUser?.wheel}
                className="rounded text-sm w-[72px] h-9 bg-white border flex items-center justify-center text-primary border-primary disabled:text-[#C0C7D7] disabled:border disabled:border-[#C0C7D7] disabled:cursor-not-allowed"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                削除
              </button>
            </div>
            <Pagination
              currentPage={page}
              totalPages={maxPage}
              onFirst={() => {
                dispatch(tenantAction.fetchMostPreviousTenant());
              }}
              onPrevious={() => {
                dispatch(tenantAction.fetchPrevTenant());
              }}
              onNext={() => {
                dispatch(tenantAction.fetchNextTenant());
              }}
              onLast={() => {
                dispatch(
                  tenantAction.fetchLatestTenant.started({
                    num: tenantList.length,
                  })
                );
              }}
            />
          </div>
          <Table
            disabled={!loggedInUser?.wheel}
            uniqueIdentifier="id"
            onChange={(tenants) => {
              setSelectedTenants(tenants);
            }}
            items={displayTenant.map((tenant) => {
              if (!tenant.state) {
                return {
                  ...tenant,
                  targetArea: "",
                };
              }

              const state = Object.entries(cityState)
                .map(([stateName, cities]) => {
                  return {
                    name: stateName,
                    city: cities.find((city) => city.id === tenant.state)?.name,
                  };
                })
                .find((x) => x.city);

              return {
                ...tenant,
                targetArea: `${state?.name ?? ""}${state?.city ?? ""}`,
              };
            })}
            headers={[
              { title: "ID", getValue: (tenant) => tenant.id },
              { title: "テナント名", getValue: (tenant) => tenant.name },
              {
                title: "対象エリア",
                getValue: (tenant) => tenant.targetArea,
              },
              {
                title: "データ利用開始日",
                getValue: (tenant) =>
                  tenant.start_date ? formatDate(tenant.start_date) : null,
              },
              {
                title: "登録日時",
                getValue: (tenant) =>
                  tenant.create_date
                    ? formatDateTime(tenant.create_date)
                    : null,
              },
            ]}
          />
        </div>
      </div>
      <TenantModal
        disabled={!isAdmin}
        isEdit={isNew ? false : enableEdit}
        isOpen={isOpen}
        onClose={handleCloseModal}
      />
      <TenantDeleteModal
        isOpen={isDeleteModalOpen}
        title={`テナントを\n削除してよろしいですか？`}
        nextButtonLabel="削除"
        handleNext={() => {
          deleteTenant(() => setIsDeleteModalOpen(false));
        }}
        closeButtonLabel="キャンセル"
        handleClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};
