import axios, { AxiosResponse } from "axios";
import { AppConfig } from "../../AppConfig";
import { NETWORK_ERROR } from "../../constants";
import { PageRoutes } from "../../constants/Routes";
import { appActions } from "../../ducks/app";
import { store } from "../../store";
import {
  AssetsApi,
  LinkagesApi,
  OauthApi,
  PoiConditionsApi,
  PoisApi,
  ReceptionsApi,
  RoadsApi,
  SpotsApi,
  TenantsApi,
  UsersApi,
} from "../api/api";
import { Configuration } from "../api/configuration";
import { MoeEventEmitter } from "../event";
import { LocalStorageManager } from "../storage/LocalStoragetManager";
import { ErrorMessageManager } from "./ErrorMessageManager";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_VERSION}`;

const config = new Configuration({
  basePath: API_BASE_URL,
});

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (config) => requestSuccess(config),
  (config) => requestFailure(config)
);

// リクエスト前の処理
axiosInstance.interceptors.request.use((config) => {
  // set header bearer
  const accessToken = LocalStorageManager.Instance.getObject<string>(
    AppConfig.Instance.LocalStorageKey["accessToken"]
  );
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";

  return config;
});

const requestSuccess = (config: AxiosResponse<any, any>) => {
  // ここでAPIの共通処理を描く
  return config;
};

const requestFailure = async (config: any) => {
  // ここでAPIのエラーの共通処理を描く
  if (config && config.code === NETWORK_ERROR) {
    const errorCode = "0000-0000";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else if (config && config?.response?.status === 503) {
    const errorCode = "9999-9999";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else if (
    (config &&
      config?.response?.status === 401 &&
      config?.config.url.indexOf("api/v1/oauth/logout") === -1) ||
    (config &&
      config?.response?.status === 403 &&
      config?.config.url.indexOf("api/v1/users/register") === -1 &&
      config?.config.url.indexOf("api/v1/tenants/register") === -1 &&
      config?.config.url.indexOf("api/v1/oauth/logout") === -1 &&
      config?.config.url.indexOf("api/v1/oauth/refresh") === -1)
  ) {
    //token refresh attempting to request
    let refreshToken = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["refreshToken"]
    );
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["accessToken"]
    );
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["assets"]
    );
    try {
      if (refreshToken) {
        store.dispatch(appActions.showLoading());
        const refreshTokenResponse = await authApi
          .authupdate({ refresh_token: refreshToken })
          .catch((error) => error);
        store.dispatch(appActions.hideLoading());

        const newAccessToken = refreshTokenResponse?.data?.access_token;
        if (newAccessToken) {
          LocalStorageManager.Instance.saveObject(
            AppConfig.Instance.LocalStorageKey["accessToken"],
            newAccessToken
          );
          return axiosInstance({
            method: config.config.method,
            url: config.config.url,
            data: config.config.data,
            headers: {
              ...config.config.headers,
              Authorization: `Bearer ${newAccessToken}`,
            },
          });
        } else {
          if (
            refreshTokenResponse?.error &&
            !refreshTokenResponse?.errorHandled
          ) {
            const errorCode =
              refreshTokenResponse?.error?.response?.data.error_code;
            const errorObj: { title: string; message: string } =
              ErrorMessageManager.Instance.getErrorMessage(errorCode);
            store.dispatch(
              appActions.showModal.started({
                title: errorObj.title,
                modalType: "alert",
                detailText: errorObj.message,
                errorStatusCode: errorCode,
                closeButtonLabel: "閉じる",
                handleClose: () => {
                  store.dispatch(appActions.closeModal());
                  MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
                },
              })
            );
          }
        }
      } else {
        // 認証エラー
        const errorCode = "0002";
        const errorObj: { title: string; message: string } =
          ErrorMessageManager.Instance.getErrorMessage(errorCode);
        store.dispatch(
          appActions.showModal.started({
            title: errorObj.title,
            modalType: "alert",
            detailText: errorObj.message,
            errorStatusCode: errorCode,
            closeButtonLabel: "閉じる",
            handleClose: () => {
              store.dispatch(appActions.closeModal());
              MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
            },
          })
        );
        return Promise.reject({ error: config, errorHandled: true });
      }
    } catch (e) {
      // 認証エラー
      const errorCode = "0002";
      const errorObj: { title: string; message: string } =
        ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: errorObj.title,
          modalType: "alert",
          detailText: errorObj.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
            MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
          },
        })
      );
      return Promise.reject({ error: config, errorHandled: true });
    }
  } else if (
    config &&
    config?.response?.status === 403 &&
    config?.config.url &&
    config?.config.url.indexOf("api/v1/oauth/refresh") >= 0
  ) {
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["refreshToken"]
    );
    // 認証エラー
    const errorCode = "0002";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
          MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else if (
    config &&
    config?.response?.status === 403 &&
    config?.config.url.indexOf("api/v1/oauth/logout") === 0
  ) {
    // ログアウト時に認証が切れていたらエラーにはせずにそのままログアウトさせる
    return Promise.reject({ error: config, errorHandled: true });
  } else if (config && config?.response?.status === 404) {
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["accessToken"]
    );
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["assets"]
    );
    // 認証エラー
    const errorCode = "9999-9998";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else {
    return Promise.reject({ error: config, errorHandled: false });
  }
};

const spotApi = new SpotsApi(config, "", axiosInstance);
const authApi = new OauthApi(config, "", axiosInstance);
const assetsApi = new AssetsApi(config, "", axiosInstance);
const receptionApi = new ReceptionsApi(config, "", axiosInstance);
const poisApi = new PoisApi(config, "", axiosInstance);
const roadApi = new RoadsApi(config, "", axiosInstance);
const tenantApi = new TenantsApi(config, "", axiosInstance);
const userApi = new UsersApi(config, "", axiosInstance);
const linkageApi = new LinkagesApi(config, "", axiosInstance);
const poiConditionsApi = new PoiConditionsApi(config, "", axiosInstance);

export {
  assetsApi,
  authApi,
  linkageApi,
  poiConditionsApi,
  poisApi,
  receptionApi,
  roadApi,
  spotApi,
  tenantApi,
  userApi,
};
