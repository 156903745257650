/* tslint:disable */
/* eslint-disable */
/**
 * MOE Common API - OpenAPI 3.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.11
 * Contact: tetsuya.kunita@quad.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {Array<Poi>}
     * @memberof Asset
     */
    'pois'?: Array<Poi>;
    /**
     * 
     * @type {Array<Road>}
     * @memberof Asset
     */
    'roads'?: Array<Road>;
    /**
     * 
     * @type {Array<Service>}
     * @memberof Asset
     */
    'services'?: Array<Service>;
    /**
     * 
     * @type {Array<Spot>}
     * @memberof Asset
     */
    'spots'?: Array<Spot>;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof Asset
     */
    'tenants'?: Array<Tenant>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Asset
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<Linkage>}
     * @memberof Asset
     */
    'linkages'?: Array<Linkage>;
}
/**
 * 
 * @export
 * @interface Auth
 */
export interface Auth {
    /**
     * 
     * @type {string}
     * @memberof Auth
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof Auth
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {AuthIam}
     * @memberof Auth
     */
    'iam'?: AuthIam;
}
/**
 * 
 * @export
 * @interface AuthAccess
 */
export interface AuthAccess {
    /**
     * 
     * @type {string}
     * @memberof AuthAccess
     */
    'access_token'?: string;
}
/**
 * 
 * @export
 * @interface AuthIam
 */
export interface AuthIam {
    /**
     * 
     * @type {number}
     * @memberof AuthIam
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthIam
     */
    'tenant_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthIam
     */
    'authority'?: number;
}
/**
 * 
 * @export
 * @interface AuthRefresh
 */
export interface AuthRefresh {
    /**
     * 
     * @type {string}
     * @memberof AuthRefresh
     */
    'refresh_token'?: string;
}
/**
 * 
 * @export
 * @interface GlobalMenu
 */
export interface GlobalMenu {
    /**
     * 
     * @type {number}
     * @memberof GlobalMenu
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalMenu
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalMenu
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface Linkage
 */
export interface Linkage {
    /**
     * 
     * @type {number}
     * @memberof Linkage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Linkage
     */
    'name': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Linkage
     */
    'is_draft': boolean;
    /**
     * 
     * @type {number}
     * @memberof Linkage
     */
    'spot_count': number;
    /**
     * 
     * @type {number}
     * @memberof Linkage
     */
    'data_type': number;
    /**
     * 
     * @type {Array<Spot>}
     * @memberof Linkage
     */
    'data': Array<Spot> | null;
    /**
     * 
     * @type {number}
     * @memberof Linkage
     */
    'term': number;
    /**
     * 
     * @type {string}
     * @memberof Linkage
     */
    'start_at': string;
    /**
     * 
     * @type {string}
     * @memberof Linkage
     */
    'end_at': string | null;
}
/**
 * 
 * @export
 * @interface LinkageCreateRequest
 */
export interface LinkageCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkageCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkageCreateRequest
     */
    'is_draft': boolean;
    /**
     * spot_id array
     * @type {Array<number>}
     * @memberof LinkageCreateRequest
     */
    'spot_ids': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof LinkageCreateRequest
     */
    'data_type': number;
    /**
     * 
     * @type {number}
     * @memberof LinkageCreateRequest
     */
    'term': number;
    /**
     * 
     * @type {string}
     * @memberof LinkageCreateRequest
     */
    'start_at': string;
    /**
     * 
     * @type {string}
     * @memberof LinkageCreateRequest
     */
    'end_at'?: string | null;
}
/**
 * 
 * @export
 * @interface LinkageModifyRequest
 */
export interface LinkageModifyRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkageModifyRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LinkageModifyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkageModifyRequest
     */
    'start_at': string;
    /**
     * 
     * @type {string}
     * @memberof LinkageModifyRequest
     */
    'end_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LinkageModifyRequest
     */
    'is_draft'?: boolean;
}
/**
 * 
 * @export
 * @interface LinkageMutateResponse
 */
export interface LinkageMutateResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkageMutateResponse
     */
    'linkage_id'?: number;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {number}
     * @memberof Login
     */
    'tenant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface Menu
 */
export interface Menu {
    /**
     * 
     * @type {Array<GlobalMenu>}
     * @memberof Menu
     */
    'global_menu'?: Array<GlobalMenu>;
    /**
     * 
     * @type {Array<SpotMenu>}
     * @memberof Menu
     */
    'spot_menu'?: Array<SpotMenu>;
    /**
     * 
     * @type {MenuDisplayItems}
     * @memberof Menu
     */
    'display_items'?: MenuDisplayItems;
}
/**
 * 
 * @export
 * @interface MenuDisplayItems
 */
export interface MenuDisplayItems {
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'spot_create'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'analysis_item_selection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'spot_selection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'period_selection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'data_set_name'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'data_gen_status'?: boolean;
    /**
     * 
     * @type {MenuDisplayItemsSpotSreateMethods}
     * @memberof MenuDisplayItems
     */
    'spot_sreate_methods'?: MenuDisplayItemsSpotSreateMethods;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItems
     */
    'spot_select_always'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuDisplayItemsSpotSreateMethods
 */
export interface MenuDisplayItemsSpotSreateMethods {
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItemsSpotSreateMethods
     */
    'poi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItemsSpotSreateMethods
     */
    'rectangle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItemsSpotSreateMethods
     */
    'circle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDisplayItemsSpotSreateMethods
     */
    'polygon'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'error_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'summary'?: string;
}
/**
 * 
 * @export
 * @interface PasswordReset1
 */
export interface PasswordReset1 {
    /**
     * 
     * @type {number}
     * @memberof PasswordReset1
     */
    'tenant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset1
     */
    'user_name'?: string;
}
/**
 * 
 * @export
 * @interface PasswordReset2
 */
export interface PasswordReset2 {
    /**
     * 
     * @type {string}
     * @memberof PasswordReset2
     */
    'secret_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset2
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface Poi
 */
export interface Poi {
    /**
     * 
     * @type {number}
     * @memberof Poi
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Poi
     */
    'name'?: string;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof Poi
     */
    'polygon'?: Array<Polygon>;
    /**
     * 
     * @type {string}
     * @memberof Poi
     */
    'create_date'?: string;
    /**
     * Create status
     * @type {string}
     * @memberof Poi
     */
    'status'?: PoiStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Poi
     */
    'complete'?: boolean;
}

export const PoiStatusEnum = {
    Approved: 'approved',
    Processing: 'processing',
    Success: 'success',
    Failure: 'failure'
} as const;

export type PoiStatusEnum = typeof PoiStatusEnum[keyof typeof PoiStatusEnum];

/**
 * 
 * @export
 * @interface PoiByConditions
 */
export interface PoiByConditions {
    /**
     * 
     * @type {string}
     * @memberof PoiByConditions
     */
    'chain_name': string;
    /**
     * 
     * @type {string}
     * @memberof PoiByConditions
     */
    'place_id': string;
    /**
     * 
     * @type {string}
     * @memberof PoiByConditions
     */
    'place_name': string;
    /**
     * 
     * @type {string}
     * @memberof PoiByConditions
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof PoiByConditions
     */
    'pl_lat': number;
    /**
     * 
     * @type {number}
     * @memberof PoiByConditions
     */
    'pl_lon': number;
    /**
     * 
     * @type {number}
     * @memberof PoiByConditions
     */
    'radius': number;
}
/**
 * 
 * @export
 * @interface PoiByConditionsRequest
 */
export interface PoiByConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof PoiByConditionsRequest
     */
    'place_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoiByConditionsRequest
     */
    'chain_names'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoiByConditionsRequest
     */
    'cities'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PoiByConditionsResponse
 */
export interface PoiByConditionsResponse {
    /**
     * 
     * @type {Array<PoiByConditions>}
     * @memberof PoiByConditionsResponse
     */
    'poi_by_conditions'?: Array<PoiByConditions>;
}
/**
 * 
 * @export
 * @interface PoiCondition
 */
export interface PoiCondition {
    /**
     * 
     * @type {string}
     * @memberof PoiCondition
     */
    'genre_name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoiCondition
     */
    'chain_name': Array<string>;
}
/**
 * 
 * @export
 * @interface PoiConditionsResponse
 */
export interface PoiConditionsResponse {
    /**
     * 
     * @type {Array<PoiCondition>}
     * @memberof PoiConditionsResponse
     */
    'conditions'?: Array<PoiCondition>;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {number}
     * @memberof Polygon
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Polygon
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Polygon
     */
    'radius'?: number;
}
/**
 * 
 * @export
 * @interface Reception
 */
export interface Reception {
    /**
     * 
     * @type {string}
     * @memberof Reception
     */
    'reception_id'?: string;
}
/**
 * 
 * @export
 * @interface Road
 */
export interface Road {
    /**
     * 
     * @type {number}
     * @memberof Road
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Road
     */
    'name'?: string;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof Road
     */
    'polygon'?: Array<Polygon>;
    /**
     * 
     * @type {string}
     * @memberof Road
     */
    'create_date'?: string;
}
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'pubsub'?: string;
    /**
     * 
     * @type {Menu}
     * @memberof Service
     */
    'menu'?: Menu;
}
/**
 * 
 * @export
 * @interface Spot
 */
export interface Spot {
    /**
     * 
     * @type {number}
     * @memberof Spot
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'spot_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'dataset_name'?: string;
    /**
     * 
     * @type {SpotArea}
     * @memberof Spot
     */
    'area'?: SpotArea;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'create_date'?: string;
    /**
     * Create status
     * @type {string}
     * @memberof Spot
     */
    'status'?: SpotStatusEnum;
}

export const SpotStatusEnum = {
    Approved: 'approved',
    Processing: 'processing',
    Success: 'success',
    Failure: 'failure'
} as const;

export type SpotStatusEnum = typeof SpotStatusEnum[keyof typeof SpotStatusEnum];

/**
 * 
 * @export
 * @interface SpotArea
 */
export interface SpotArea {
    /**
     * polygon, circle, rectangle, etc...
     * @type {string}
     * @memberof SpotArea
     */
    'type'?: SpotAreaTypeEnum;
    /**
     * Each data is entered according to type.
     * @type {Array<Polygon>}
     * @memberof SpotArea
     */
    'data'?: Array<Polygon>;
    /**
     * sub data
     * @type {Array<Polygon>}
     * @memberof SpotArea
     */
    'sub_data'?: Array<Polygon>;
    /**
     * Circle radius
     * @type {Array<number>}
     * @memberof SpotArea
     */
    'radius'?: Array<number>;
    /**
     * road_id array
     * @type {Array<number>}
     * @memberof SpotArea
     */
    'road_id'?: Array<number>;
}

export const SpotAreaTypeEnum = {
    Polygon: 'polygon',
    Circle: 'circle',
    Rectangle: 'rectangle',
    Road: 'road',
    Poi: 'poi'
} as const;

export type SpotAreaTypeEnum = typeof SpotAreaTypeEnum[keyof typeof SpotAreaTypeEnum];

/**
 * 
 * @export
 * @interface SpotCreateRequest
 */
export interface SpotCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SpotCreateRequest
     */
    'spot_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotCreateRequest
     */
    'dataset_name'?: string;
    /**
     * 
     * @type {SpotArea}
     * @memberof SpotCreateRequest
     */
    'area'?: SpotArea;
}
/**
 * 
 * @export
 * @interface SpotMenu
 */
export interface SpotMenu {
    /**
     * 
     * @type {number}
     * @memberof SpotMenu
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotMenu
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotMenu
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotMenu
     */
    'usecase_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotMenu
     */
    'image_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof SpotMenu
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotMenu
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotMenu
     */
    'retroactiveDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotMenu
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface SpotModifyRequest
 */
export interface SpotModifyRequest {
    /**
     * 
     * @type {number}
     * @memberof SpotModifyRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotModifyRequest
     */
    'spot_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotModifyRequest
     */
    'dataset_name'?: string;
    /**
     * 
     * @type {SpotArea}
     * @memberof SpotModifyRequest
     */
    'area'?: SpotArea;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'create_date'?: string;
    /**
     * 
     * @type {TenantSetting}
     * @memberof Tenant
     */
    'setting'?: TenantSetting;
}
/**
 * 
 * @export
 * @interface TenantModifyRequestBody
 */
export interface TenantModifyRequestBody {
    /**
     * 
     * @type {number}
     * @memberof TenantModifyRequestBody
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantModifyRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TenantModifyRequestBody
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof TenantModifyRequestBody
     */
    'start_at': string;
    /**
     * 
     * @type {TenantSetting}
     * @memberof TenantModifyRequestBody
     */
    'setting': TenantSetting;
}
/**
 * 
 * @export
 * @interface TenantRequestBody
 */
export interface TenantRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestBody
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestBody
     */
    'start_at': string;
    /**
     * 
     * @type {TenantSetting}
     * @memberof TenantRequestBody
     */
    'setting': TenantSetting;
}
/**
 * 
 * @export
 * @interface TenantResponse
 */
export interface TenantResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TenantSetting
 */
export interface TenantSetting {
    /**
     * 
     * @type {Polygon}
     * @memberof TenantSetting
     */
    'default_location'?: Polygon;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'wheel'?: boolean;
    /**
     * 
     * @type {Array<UserTenant>}
     * @memberof User
     */
    'tenants'?: Array<UserTenant>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'create_date'?: string;
}
/**
 * 
 * @export
 * @interface UserModifyRequestBody
 */
export interface UserModifyRequestBody {
    /**
     * 
     * @type {number}
     * @memberof UserModifyRequestBody
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserModifyRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserModifyRequestBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserModifyRequestBody
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModifyRequestBody
     */
    'wheel': boolean;
    /**
     * 
     * @type {Array<UserTenant>}
     * @memberof UserModifyRequestBody
     */
    'tenants': Array<UserTenant>;
}
/**
 * 
 * @export
 * @interface UserRegistRequestBody
 */
export interface UserRegistRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UserRegistRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistRequestBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistRequestBody
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegistRequestBody
     */
    'wheel': boolean;
    /**
     * 
     * @type {Array<UserTenant>}
     * @memberof UserRegistRequestBody
     */
    'tenants': Array<UserTenant>;
}
/**
 * 
 * @export
 * @interface UserTenant
 */
export interface UserTenant {
    /**
     * 
     * @type {number}
     * @memberof UserTenant
     */
    'tenant_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserTenant
     */
    'authorization'?: number;
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Access to asset information
         * @summary Access to asset information API
         * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
         * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asset: async (schema?: AssetSchemaEnum, id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (schema !== undefined) {
                localVarQueryParameter['schema'] = schema;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Access to asset information
         * @summary Access to asset information API
         * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
         * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asset(schema?: AssetSchemaEnum, id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asset(schema, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * Access to asset information
         * @summary Access to asset information API
         * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
         * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asset(schema?: AssetSchemaEnum, id?: number, options?: any): AxiosPromise<Asset> {
            return localVarFp.asset(schema, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * Access to asset information
     * @summary Access to asset information API
     * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
     * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public asset(schema?: AssetSchemaEnum, id?: number, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).asset(schema, id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AssetSchemaEnum = {
    Pois: 'pois',
    Roads: 'roads',
    Services: 'services',
    Spots: 'spots',
    Tenants: 'tenants',
    Users: 'users',
    Linkages: 'linkages'
} as const;
export type AssetSchemaEnum = typeof AssetSchemaEnum[keyof typeof AssetSchemaEnum];


/**
 * LinkagesApi - axios parameter creator
 * @export
 */
export const LinkagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Modify linkage
         * @summary Modify linkage
         * @param {LinkageModifyRequest} linkageModifyRequest Modify linkage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkagemodify: async (linkageModifyRequest: LinkageModifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkageModifyRequest' is not null or undefined
            assertParamExists('linkagemodify', 'linkageModifyRequest', linkageModifyRequest)
            const localVarPath = `/linkage/modify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkageModifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add linkage
         * @summary Add linkage
         * @param {LinkageCreateRequest} linkageCreateRequest Add linkage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkageregist: async (linkageCreateRequest: LinkageCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkageCreateRequest' is not null or undefined
            assertParamExists('linkageregist', 'linkageCreateRequest', linkageCreateRequest)
            const localVarPath = `/linkage/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkageCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkagesApi - functional programming interface
 * @export
 */
export const LinkagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Modify linkage
         * @summary Modify linkage
         * @param {LinkageModifyRequest} linkageModifyRequest Modify linkage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkagemodify(linkageModifyRequest: LinkageModifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkageMutateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkagemodify(linkageModifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add linkage
         * @summary Add linkage
         * @param {LinkageCreateRequest} linkageCreateRequest Add linkage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkageregist(linkageCreateRequest: LinkageCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkageMutateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkageregist(linkageCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LinkagesApi - factory interface
 * @export
 */
export const LinkagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkagesApiFp(configuration)
    return {
        /**
         * Modify linkage
         * @summary Modify linkage
         * @param {LinkageModifyRequest} linkageModifyRequest Modify linkage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkagemodify(linkageModifyRequest: LinkageModifyRequest, options?: any): AxiosPromise<LinkageMutateResponse> {
            return localVarFp.linkagemodify(linkageModifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add linkage
         * @summary Add linkage
         * @param {LinkageCreateRequest} linkageCreateRequest Add linkage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkageregist(linkageCreateRequest: LinkageCreateRequest, options?: any): AxiosPromise<LinkageMutateResponse> {
            return localVarFp.linkageregist(linkageCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkagesApi - object-oriented interface
 * @export
 * @class LinkagesApi
 * @extends {BaseAPI}
 */
export class LinkagesApi extends BaseAPI {
    /**
     * Modify linkage
     * @summary Modify linkage
     * @param {LinkageModifyRequest} linkageModifyRequest Modify linkage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkagesApi
     */
    public linkagemodify(linkageModifyRequest: LinkageModifyRequest, options?: AxiosRequestConfig) {
        return LinkagesApiFp(this.configuration).linkagemodify(linkageModifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add linkage
     * @summary Add linkage
     * @param {LinkageCreateRequest} linkageCreateRequest Add linkage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkagesApi
     */
    public linkageregist(linkageCreateRequest: LinkageCreateRequest, options?: AxiosRequestConfig) {
        return LinkagesApiFp(this.configuration).linkageregist(linkageCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Refrash authorization API
         * @summary Refrash authorization API
         * @param {AuthRefresh} authRefresh Token for access token refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authupdate: async (authRefresh: AuthRefresh, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRefresh' is not null or undefined
            assertParamExists('authupdate', 'authRefresh', authRefresh)
            const localVarPath = `/oauth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authentication based on tenant ID, user ID, and password
         * @summary Authentication API
         * @param {Login} login Authentication based on tenant ID, user ID, and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/oauth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Log out and destroy the current token
         * @summary Logout API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset Password 
         * @summary Password reset
         * @param {PasswordReset2} [passwordReset2] secret_key, new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordreset: async (passwordReset2?: PasswordReset2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordReset2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Email sending API for password reset.  The email contains a temporary URL, and the temporary URL contains a secret key.  URL example: https://ebpm-dashboard.com/password_reset/{secret_key} 
         * @summary Send password reset mail
         * @param {PasswordReset1} [passwordReset1] tenant ID, user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendresetmail: async (passwordReset1?: PasswordReset1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordReset1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * Refrash authorization API
         * @summary Refrash authorization API
         * @param {AuthRefresh} authRefresh Token for access token refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authupdate(authRefresh: AuthRefresh, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authupdate(authRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authentication based on tenant ID, user ID, and password
         * @summary Authentication API
         * @param {Login} login Authentication based on tenant ID, user ID, and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Log out and destroy the current token
         * @summary Logout API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reset Password 
         * @summary Password reset
         * @param {PasswordReset2} [passwordReset2] secret_key, new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordreset(passwordReset2?: PasswordReset2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordreset(passwordReset2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Email sending API for password reset.  The email contains a temporary URL, and the temporary URL contains a secret key.  URL example: https://ebpm-dashboard.com/password_reset/{secret_key} 
         * @summary Send password reset mail
         * @param {PasswordReset1} [passwordReset1] tenant ID, user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendresetmail(passwordReset1?: PasswordReset1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendresetmail(passwordReset1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * Refrash authorization API
         * @summary Refrash authorization API
         * @param {AuthRefresh} authRefresh Token for access token refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authupdate(authRefresh: AuthRefresh, options?: any): AxiosPromise<AuthAccess> {
            return localVarFp.authupdate(authRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * Authentication based on tenant ID, user ID, and password
         * @summary Authentication API
         * @param {Login} login Authentication based on tenant ID, user ID, and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): AxiosPromise<Auth> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Log out and destroy the current token
         * @summary Logout API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Reset Password 
         * @summary Password reset
         * @param {PasswordReset2} [passwordReset2] secret_key, new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordreset(passwordReset2?: PasswordReset2, options?: any): AxiosPromise<void> {
            return localVarFp.passwordreset(passwordReset2, options).then((request) => request(axios, basePath));
        },
        /**
         * Email sending API for password reset.  The email contains a temporary URL, and the temporary URL contains a secret key.  URL example: https://ebpm-dashboard.com/password_reset/{secret_key} 
         * @summary Send password reset mail
         * @param {PasswordReset1} [passwordReset1] tenant ID, user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendresetmail(passwordReset1?: PasswordReset1, options?: any): AxiosPromise<void> {
            return localVarFp.sendresetmail(passwordReset1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * Refrash authorization API
     * @summary Refrash authorization API
     * @param {AuthRefresh} authRefresh Token for access token refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public authupdate(authRefresh: AuthRefresh, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).authupdate(authRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authentication based on tenant ID, user ID, and password
     * @summary Authentication API
     * @param {Login} login Authentication based on tenant ID, user ID, and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public login(login: Login, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Log out and destroy the current token
     * @summary Logout API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset Password 
     * @summary Password reset
     * @param {PasswordReset2} [passwordReset2] secret_key, new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public passwordreset(passwordReset2?: PasswordReset2, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).passwordreset(passwordReset2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Email sending API for password reset.  The email contains a temporary URL, and the temporary URL contains a secret key.  URL example: https://ebpm-dashboard.com/password_reset/{secret_key} 
     * @summary Send password reset mail
     * @param {PasswordReset1} [passwordReset1] tenant ID, user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public sendresetmail(passwordReset1?: PasswordReset1, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).sendresetmail(passwordReset1, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoiConditionsApi - axios parameter creator
 * @export
 */
export const PoiConditionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get poi by conditions
         * @summary Get poi by conditions
         * @param {PoiByConditionsRequest} poiByConditionsRequest Get poi by conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poibyconditions: async (poiByConditionsRequest: PoiByConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'poiByConditionsRequest' is not null or undefined
            assertParamExists('poibyconditions', 'poiByConditionsRequest', poiByConditionsRequest)
            const localVarPath = `/poi/byconditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poiByConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get poi conditions
         * @summary Get poi conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poiconditions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/poi/conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoiConditionsApi - functional programming interface
 * @export
 */
export const PoiConditionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoiConditionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get poi by conditions
         * @summary Get poi by conditions
         * @param {PoiByConditionsRequest} poiByConditionsRequest Get poi by conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poibyconditions(poiByConditionsRequest: PoiByConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoiByConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poibyconditions(poiByConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get poi conditions
         * @summary Get poi conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poiconditions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkageMutateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poiconditions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoiConditionsApi - factory interface
 * @export
 */
export const PoiConditionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoiConditionsApiFp(configuration)
    return {
        /**
         * Get poi by conditions
         * @summary Get poi by conditions
         * @param {PoiByConditionsRequest} poiByConditionsRequest Get poi by conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poibyconditions(poiByConditionsRequest: PoiByConditionsRequest, options?: any): AxiosPromise<PoiByConditionsResponse> {
            return localVarFp.poibyconditions(poiByConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get poi conditions
         * @summary Get poi conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poiconditions(options?: any): AxiosPromise<LinkageMutateResponse> {
            return localVarFp.poiconditions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoiConditionsApi - object-oriented interface
 * @export
 * @class PoiConditionsApi
 * @extends {BaseAPI}
 */
export class PoiConditionsApi extends BaseAPI {
    /**
     * Get poi by conditions
     * @summary Get poi by conditions
     * @param {PoiByConditionsRequest} poiByConditionsRequest Get poi by conditions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoiConditionsApi
     */
    public poibyconditions(poiByConditionsRequest: PoiByConditionsRequest, options?: AxiosRequestConfig) {
        return PoiConditionsApiFp(this.configuration).poibyconditions(poiByConditionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get poi conditions
     * @summary Get poi conditions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoiConditionsApi
     */
    public poiconditions(options?: AxiosRequestConfig) {
        return PoiConditionsApiFp(this.configuration).poiconditions(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoisApi - axios parameter creator
 * @export
 */
export const PoisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get information poi
         * @summary Get information on poi
         * @param {number} id Poi id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poiinfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('poiinfo', 'id', id)
            const localVarPath = `/pois/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list on pois
         * @summary Get list on pois
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poilist: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pois/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoisApi - functional programming interface
 * @export
 */
export const PoisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoisApiAxiosParamCreator(configuration)
    return {
        /**
         * Get information poi
         * @summary Get information on poi
         * @param {number} id Poi id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poiinfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Poi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poiinfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list on pois
         * @summary Get list on pois
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poilist(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Poi>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poilist(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoisApi - factory interface
 * @export
 */
export const PoisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoisApiFp(configuration)
    return {
        /**
         * Get information poi
         * @summary Get information on poi
         * @param {number} id Poi id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poiinfo(id: number, options?: any): AxiosPromise<Poi> {
            return localVarFp.poiinfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list on pois
         * @summary Get list on pois
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poilist(options?: any): AxiosPromise<Array<Poi>> {
            return localVarFp.poilist(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoisApi - object-oriented interface
 * @export
 * @class PoisApi
 * @extends {BaseAPI}
 */
export class PoisApi extends BaseAPI {
    /**
     * Get information poi
     * @summary Get information on poi
     * @param {number} id Poi id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoisApi
     */
    public poiinfo(id: number, options?: AxiosRequestConfig) {
        return PoisApiFp(this.configuration).poiinfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list on pois
     * @summary Get list on pois
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoisApi
     */
    public poilist(options?: AxiosRequestConfig) {
        return PoisApiFp(this.configuration).poilist(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReceptionsApi - axios parameter creator
 * @export
 */
export const ReceptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtaining reception results
         * @summary Obtaining reception results
         * @param {string} id Reception id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        result: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('result', 'id', id)
            const localVarPath = `/receptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionsApi - functional programming interface
 * @export
 */
export const ReceptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtaining reception results
         * @summary Obtaining reception results
         * @param {string} id Reception id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async result(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Spot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.result(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionsApi - factory interface
 * @export
 */
export const ReceptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionsApiFp(configuration)
    return {
        /**
         * Obtaining reception results
         * @summary Obtaining reception results
         * @param {string} id Reception id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        result(id: string, options?: any): AxiosPromise<Spot> {
            return localVarFp.result(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionsApi - object-oriented interface
 * @export
 * @class ReceptionsApi
 * @extends {BaseAPI}
 */
export class ReceptionsApi extends BaseAPI {
    /**
     * Obtaining reception results
     * @summary Obtaining reception results
     * @param {string} id Reception id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionsApi
     */
    public result(id: string, options?: AxiosRequestConfig) {
        return ReceptionsApiFp(this.configuration).result(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoadsApi - axios parameter creator
 * @export
 */
export const RoadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get information road
         * @summary Get information on road
         * @param {number} id road id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadinfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roadinfo', 'id', id)
            const localVarPath = `/roads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list on roads
         * @summary Get list on roads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadlist: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roads/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadsApi - functional programming interface
 * @export
 */
export const RoadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get information road
         * @summary Get information on road
         * @param {number} id road id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roadinfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Road>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roadinfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list on roads
         * @summary Get list on roads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roadlist(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Road>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roadlist(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadsApi - factory interface
 * @export
 */
export const RoadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadsApiFp(configuration)
    return {
        /**
         * Get information road
         * @summary Get information on road
         * @param {number} id road id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadinfo(id: number, options?: any): AxiosPromise<Road> {
            return localVarFp.roadinfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list on roads
         * @summary Get list on roads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roadlist(options?: any): AxiosPromise<Array<Road>> {
            return localVarFp.roadlist(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoadsApi - object-oriented interface
 * @export
 * @class RoadsApi
 * @extends {BaseAPI}
 */
export class RoadsApi extends BaseAPI {
    /**
     * Get information road
     * @summary Get information on road
     * @param {number} id road id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadsApi
     */
    public roadinfo(id: number, options?: AxiosRequestConfig) {
        return RoadsApiFp(this.configuration).roadinfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list on roads
     * @summary Get list on roads
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadsApi
     */
    public roadlist(options?: AxiosRequestConfig) {
        return RoadsApiFp(this.configuration).roadlist(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpotsApi - axios parameter creator
 * @export
 */
export const SpotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Spot Analysis
         * @summary Spot Analysis
         * @param {Spot} spot Analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotanalyze: async (spot: Spot, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spot' is not null or undefined
            assertParamExists('spotanalyze', 'spot', spot)
            const localVarPath = `/spots/analyze`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spot, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete spot
         * @summary Delete spot
         * @param {number} id Spot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotdelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('spotdelete', 'id', id)
            const localVarPath = `/spots/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information spot
         * @summary Get information on spot
         * @param {number} id Spot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotinfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('spotinfo', 'id', id)
            const localVarPath = `/spots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list on spots
         * @summary Get list on spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlist: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spots/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify spots
         * @summary Modify spots
         * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotmodify: async (spotModifyRequest: SpotModifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotModifyRequest' is not null or undefined
            assertParamExists('spotmodify', 'spotModifyRequest', spotModifyRequest)
            const localVarPath = `/spots/modify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotModifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add spots
         * @summary Add spots
         * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotregist: async (spotCreateRequest: SpotCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotCreateRequest' is not null or undefined
            assertParamExists('spotregist', 'spotCreateRequest', spotCreateRequest)
            const localVarPath = `/spots/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotsApi - functional programming interface
 * @export
 */
export const SpotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotsApiAxiosParamCreator(configuration)
    return {
        /**
         * Spot Analysis
         * @summary Spot Analysis
         * @param {Spot} spot Analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotanalyze(spot: Spot, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotanalyze(spot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete spot
         * @summary Delete spot
         * @param {number} id Spot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotdelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotdelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information spot
         * @summary Get information on spot
         * @param {number} id Spot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotinfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Spot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotinfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list on spots
         * @summary Get list on spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotlist(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Spot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotlist(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify spots
         * @summary Modify spots
         * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotmodify(spotModifyRequest: SpotModifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotmodify(spotModifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add spots
         * @summary Add spots
         * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotregist(spotCreateRequest: SpotCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotregist(spotCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpotsApi - factory interface
 * @export
 */
export const SpotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotsApiFp(configuration)
    return {
        /**
         * Spot Analysis
         * @summary Spot Analysis
         * @param {Spot} spot Analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotanalyze(spot: Spot, options?: any): AxiosPromise<Reception> {
            return localVarFp.spotanalyze(spot, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete spot
         * @summary Delete spot
         * @param {number} id Spot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotdelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.spotdelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information spot
         * @summary Get information on spot
         * @param {number} id Spot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotinfo(id: number, options?: any): AxiosPromise<Spot> {
            return localVarFp.spotinfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list on spots
         * @summary Get list on spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlist(options?: any): AxiosPromise<Array<Spot>> {
            return localVarFp.spotlist(options).then((request) => request(axios, basePath));
        },
        /**
         * Modify spots
         * @summary Modify spots
         * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotmodify(spotModifyRequest: SpotModifyRequest, options?: any): AxiosPromise<Reception> {
            return localVarFp.spotmodify(spotModifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add spots
         * @summary Add spots
         * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotregist(spotCreateRequest: SpotCreateRequest, options?: any): AxiosPromise<Reception> {
            return localVarFp.spotregist(spotCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpotsApi - object-oriented interface
 * @export
 * @class SpotsApi
 * @extends {BaseAPI}
 */
export class SpotsApi extends BaseAPI {
    /**
     * Spot Analysis
     * @summary Spot Analysis
     * @param {Spot} spot Analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public spotanalyze(spot: Spot, options?: AxiosRequestConfig) {
        return SpotsApiFp(this.configuration).spotanalyze(spot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete spot
     * @summary Delete spot
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public spotdelete(id: number, options?: AxiosRequestConfig) {
        return SpotsApiFp(this.configuration).spotdelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information spot
     * @summary Get information on spot
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public spotinfo(id: number, options?: AxiosRequestConfig) {
        return SpotsApiFp(this.configuration).spotinfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list on spots
     * @summary Get list on spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public spotlist(options?: AxiosRequestConfig) {
        return SpotsApiFp(this.configuration).spotlist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify spots
     * @summary Modify spots
     * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public spotmodify(spotModifyRequest: SpotModifyRequest, options?: AxiosRequestConfig) {
        return SpotsApiFp(this.configuration).spotmodify(spotModifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add spots
     * @summary Add spots
     * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public spotregist(spotCreateRequest: SpotCreateRequest, options?: AxiosRequestConfig) {
        return SpotsApiFp(this.configuration).spotregist(spotCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete tenant
         * @summary Delete tenant
         * @param {number} id tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantdelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantdelete', 'id', id)
            const localVarPath = `/tenants/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify tenant
         * @summary Modify tenant
         * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantmodify: async (tenantModifyRequestBody: TenantModifyRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantModifyRequestBody' is not null or undefined
            assertParamExists('tenantmodify', 'tenantModifyRequestBody', tenantModifyRequestBody)
            const localVarPath = `/tenants/modify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantModifyRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add tenant
         * @summary Add tenant
         * @param {TenantRequestBody} tenantRequestBody Add tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantregist: async (tenantRequestBody: TenantRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantRequestBody' is not null or undefined
            assertParamExists('tenantregist', 'tenantRequestBody', tenantRequestBody)
            const localVarPath = `/tenants/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete tenant
         * @summary Delete tenant
         * @param {number} id tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantdelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantdelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify tenant
         * @summary Modify tenant
         * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantmodify(tenantModifyRequestBody: TenantModifyRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantmodify(tenantModifyRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add tenant
         * @summary Add tenant
         * @param {TenantRequestBody} tenantRequestBody Add tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantregist(tenantRequestBody: TenantRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantregist(tenantRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * Delete tenant
         * @summary Delete tenant
         * @param {number} id tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantdelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.tenantdelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify tenant
         * @summary Modify tenant
         * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantmodify(tenantModifyRequestBody: TenantModifyRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.tenantmodify(tenantModifyRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Add tenant
         * @summary Add tenant
         * @param {TenantRequestBody} tenantRequestBody Add tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantregist(tenantRequestBody: TenantRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.tenantregist(tenantRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * Delete tenant
     * @summary Delete tenant
     * @param {number} id tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantdelete(id: number, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantdelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify tenant
     * @summary Modify tenant
     * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantmodify(tenantModifyRequestBody: TenantModifyRequestBody, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantmodify(tenantModifyRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add tenant
     * @summary Add tenant
     * @param {TenantRequestBody} tenantRequestBody Add tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantregist(tenantRequestBody: TenantRequestBody, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantregist(tenantRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete user
         * @summary Delete user
         * @param {number} id user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userdelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userdelete', 'id', id)
            const localVarPath = `/users/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify user
         * @summary Modify user
         * @param {UserModifyRequestBody} userModifyRequestBody Modify users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usermodify: async (userModifyRequestBody: UserModifyRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userModifyRequestBody' is not null or undefined
            assertParamExists('usermodify', 'userModifyRequestBody', userModifyRequestBody)
            const localVarPath = `/users/modify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userModifyRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add user
         * @summary Add user
         * @param {UserRegistRequestBody} userRegistRequestBody Add user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userregist: async (userRegistRequestBody: UserRegistRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegistRequestBody' is not null or undefined
            assertParamExists('userregist', 'userRegistRequestBody', userRegistRequestBody)
            const localVarPath = `/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegistRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete user
         * @summary Delete user
         * @param {number} id user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userdelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userdelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify user
         * @summary Modify user
         * @param {UserModifyRequestBody} userModifyRequestBody Modify users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usermodify(userModifyRequestBody: UserModifyRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usermodify(userModifyRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add user
         * @summary Add user
         * @param {UserRegistRequestBody} userRegistRequestBody Add user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userregist(userRegistRequestBody: UserRegistRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userregist(userRegistRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Delete user
         * @summary Delete user
         * @param {number} id user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userdelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.userdelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify user
         * @summary Modify user
         * @param {UserModifyRequestBody} userModifyRequestBody Modify users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usermodify(userModifyRequestBody: UserModifyRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.usermodify(userModifyRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Add user
         * @summary Add user
         * @param {UserRegistRequestBody} userRegistRequestBody Add user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userregist(userRegistRequestBody: UserRegistRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.userregist(userRegistRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Delete user
     * @summary Delete user
     * @param {number} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userdelete(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userdelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify user
     * @summary Modify user
     * @param {UserModifyRequestBody} userModifyRequestBody Modify users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usermodify(userModifyRequestBody: UserModifyRequestBody, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usermodify(userModifyRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add user
     * @summary Add user
     * @param {UserRegistRequestBody} userRegistRequestBody Add user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userregist(userRegistRequestBody: UserRegistRequestBody, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userregist(userRegistRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



