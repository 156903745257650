import { call, put } from "typed-redux-saga";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { tenantApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { store } from "../../store";
import { appActions } from "../app/actions";
import { takeEveryFsa } from "../operations";
import { tenantAction } from "./actions";

export function* tenantSaga() {
  yield takeEveryFsa(tenantAction.selectTenant.started, function* (actions) {
    yield* put(
      tenantAction.selectTenant.done({
        params: actions.payload,
        result: {},
      })
    );
  });

  yield takeEveryFsa(tenantAction.setSelectedTenants.started, function* (actions) {
    yield* put(
      tenantAction.setSelectedTenants.done({
        params: actions.payload,
        result: {},
      })
    );
  });

  /*yield takeEveryFsa(tenantAction.fetchTenantList.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response: any = yield* call(async (): Promise<any> => {
        return await tenantApi.tenantslist();
      });
      if (response && response.error && onError) {
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        tenantAction.fetchTenantList.done({
          params: {},
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });*/

  yield takeEveryFsa(tenantAction.createTenant.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response: any = yield* call(async (): Promise<any> => {
        return await tenantApi.tenantregist(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } = ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      yield* put(
        tenantAction.createTenant.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (response && onSuccess) {
        onSuccess(response);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(tenantAction.modifyTenant.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await tenantApi.tenantmodify(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());

        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } = ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      yield* put(
        tenantAction.modifyTenant.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess(response);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(tenantAction.deleteTenants.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await tenantApi.tenantdelete(actions.payload.id).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());

        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } = ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      yield* put(
        tenantAction.deleteTenants.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess(response);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(tenantAction.setMaxPage.started, function* (actions) {
    yield* put(
      tenantAction.setMaxPage.done({
        params: actions.payload,
        result: {},
      })
    );
  });
  yield takeEveryFsa(tenantAction.fetchLatestTenant.started, function* (actions) {
    yield* put(
      tenantAction.fetchLatestTenant.done({
        params: actions.payload,
        result: {},
      })
    );
  });
}
