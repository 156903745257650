import IconCheckboxCheck from "../../icons/Checkbox/IconCheckboxCheck";
import IconCheckboxDash from "../../icons/Checkbox/IconCheckboxDash";
import { mergeClassNames } from "../../utils/style";

type Props = {
  selected: boolean | "some";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export default function Checkbox({
  selected,
  disabled,
  className,
  onClick,
}: Props) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={mergeClassNames(
        "w-6 h-6 border rounded-sm focus:outline-none grid place-items-center",
        selected ? "bg-primary border-primary" : "bg-white border-gray-light",
        disabled ? "cursor-not-allowed bg-gray-50" : "",
        className
      )}
      onClick={onClick}
    >
      {selected &&
        (selected === "some" ? <IconCheckboxDash /> : <IconCheckboxCheck />)}
    </button>
  );
}
