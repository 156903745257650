import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { z } from "zod";
import { PageRoutes } from "../../../constants/Routes";
import { LOGIN_MESSAGE } from "../../../constants/schema";
import { appActions } from "../../../ducks/app";
import { LoginAction } from "../../../ducks/login";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import Input from "../../atoms/Input";
import Form from "../../organisms/Form";

export const ForgotPassword: React.FC<{}> = () => {
  const dispatch = useDispatch();

  return (
    <div className="grid place-items-center w-full">
      <Form
        schema={z.object({
          emailAddress: z.string().email({ message: LOGIN_MESSAGE.EMAIL }),
          tenantId: z.string().min(1, { message: LOGIN_MESSAGE.TENANT_ID }),
        })}
        className="flex flex-col w-full max-w-100 gap-y-14"
        onSubmit={async ({ emailAddress, tenantId }) => {
          dispatch(appActions.showLoading());
          dispatch(
            LoginAction.forgetPassword.started({
              body: {
                tenant_id: parseInt(tenantId),
                user_name: emailAddress,
              },
              onSuccess: () => {
                dispatch(appActions.hideLoading());
                dispatch(
                  appActions.showModal.started({
                    title: "メールを送信しました。",
                    showXIcon: true,
                    closeButtonLabel: "閉じる",
                    handleClose: () => {
                      dispatch(appActions.closeModal());
                    },
                  })
                );
              },
            })
          );
          // await authApi.forgotPassword({ emailAddress });
        }}
      >
        <span className="text-3xl text-gray-dark text-center">
          パスワードをお忘れの方
        </span>
        <div className="flex flex-col gap-y-2.5">
          <div className="mb-[22px]">
            <Input
                name="tenantId"
                type="number"
                placeholder="テナントID"
                className="w-full"
              />
          </div>
          <div className="mb-[22px]">
              <Input
                name="emailAddress"
                placeholder="Eメール"
                className="w-full"
              />
          </div>
          <span className="text-gray-light text-xs">
            本人確認用URLをお送りします。Eメールを入力していただき、
            「送信」をタップしてください。
          </span>
        </div>
        <div className="flex flex-col gap-y-2.5 mt-[30px]">
          <CustomButton type="submit" text="送信" />
          <Link
            to={PageRoutes.LOGIN}
            className="mt-[20px] mx-auto hover:underline text-primary text-sm"
          >
            ログインページへ戻る
          </Link>
        </div>
      </Form>
    </div>
  );
};
