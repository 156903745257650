import React from "react";
import { SpotStatusEnum } from "../../core/api";
import { getStatus } from "../../utils/spotstatus";

type Props = {
  status: SpotStatusEnum | undefined;
};

export const DataGenerationStatus: React.FC<Props> = ({ status }) => {
  if (!status) {
    return null;
  }
  return (
    <>
      <div
        className={`${
          getStatus(status).color
        } text-white flex items-center justify-center w-[60px] text-[11px] h-[20px] rounded-[30px] text-center`}
      >
        {getStatus(status).name}
      </div>
    </>
  );
};
