import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PageRoutes } from "../../constants/Routes";
import { GlobalMenu } from "../../core/api";
import { assetSelectors } from "../../ducks/asset/selector";
import IconResizeNav from "../../icons/SideNav/IconResizeNav";
import IconResizeNavCollaspe from "../../icons/SideNav/IconResizeNavCollaspe";
import { changeIdToIcon } from "../../utils/sidenavigation";
import { mergeClassNames } from "../../utils/style";
import { SideNavItem } from "../molecules/SideNavItem";

type SideNavigateProps = {
  menus: GlobalMenu[];
};

export const SideNavigation: React.FC<SideNavigateProps> = ({ menus }) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(true);
  const isAdmin = useSelector(assetSelectors.isAdminUser);
  return (
    <aside
      aria-label="Sidebar"
      className={mergeClassNames(
        "flex flex-col pb-3 bg-white",
        expanded ? "w-60" : "w-auto"
      )}
    >
      {menus.map((menu) => {
        if (
          (menu.path === PageRoutes.USER_MANAGEMENT ||
            menu.path === PageRoutes.TENANT_MANAGEMENT) &&
          !isAdmin
        ) {
          return;
        }
        return (
          <SideNavItem
            key={menu.id}
            shrink={!expanded}
            icon={menu.id ? changeIdToIcon(menu.id) : null}
            path={menu.path ?? ""}
            title={menu.name ?? ""}
            current={!!menu.path && location.pathname.indexOf(menu.path) >= 0}
          />
        );
      })}
      <div
        className={mergeClassNames(
          "flex-1 flex flex-col justify-end",
          expanded ? "items-end" : "items-center"
        )}
      >
        <button
          type="button"
          className="px-6 py-4.5"
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
        >
          {expanded ? (
            <IconResizeNav className="h-3" />
          ) : (
            <IconResizeNavCollaspe className="h-3" />
          )}
        </button>
      </div>
    </aside>
  );
};
