import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tenant,
  TenantModifyRequestBody,
  TenantRequestBody,
} from "../../../core/api";
import { appActions } from "../../../ducks/app";
import { appSelectors } from "../../../ducks/app/selector";
import { assetAction } from "../../../ducks/asset";
import { assetSelectors } from "../../../ducks/asset/selector";
import { tenantAction } from "../../../ducks/tenant";
import { tenantSelectors } from "../../../ducks/tenant/selector";
import { isTrue } from "../../../utils/array";

export const useTenantManagement = () => {
  const selectedTenantItem = useSelector(tenantSelectors.getSelectedID);
  const numLoad = useSelector(tenantSelectors.getNumLoad);
  const offSet = useSelector(tenantSelectors.getOffsets);
  const page = useSelector(tenantSelectors.getPage);
  const maxPage = useSelector(tenantSelectors.getMaxPage);
  const cityState = useSelector(appSelectors.getState);
  const tenantList = useSelector(assetSelectors.getTenants);
  const dispatch = useDispatch();

  const addTenant = (id: number) => {
    dispatch(tenantAction.selectTenant.started({ id: id }));
  };

  const addAllTenants = (data: Tenant[]) => {
    if (selectedTenantItem.length > 0) {
      dispatch(tenantAction.resetSelectedTenant());
    } else {
      data.forEach((target) => {
        dispatch(tenantAction.selectTenant.started({ id: target.id ?? 0 }));
      });
    }
  };

  const setSelectedTenants = (tenants: Tenant[]) => {
    dispatch(
      tenantAction.setSelectedTenants.started({
        ids: tenants.map((tenant) => tenant.id).filter(isTrue),
      })
    );
  };

  const fetchTenantList = () => {
    appActions.showLoading();
    dispatch(
      assetAction.fetchTenantList.started({
        onSuccess: () => {
          appActions.hideLoading();
        },
      })
    );
  };

  useEffect(() => {
    if (tenantList.length < numLoad) {
      dispatch(tenantAction.setMaxPage.started({ num: 1 }));
    } else
      dispatch(
        tenantAction.setMaxPage.started({
          num: Math.ceil(tenantList.length / numLoad),
        })
      );
  }, []);

  const createTenant = (
    data: TenantRequestBody & { cityName: string },
    callback: () => void
  ) => {
    appActions.showLoading();
    dispatch(
      appActions.fetchGeoJson.started({
        name: data.cityName,
        access_token: process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "",
        onSuccess: (response: any) => {
          if (response) {
            if (response.data.features.length > 0) {
              dispatch(
                tenantAction.createTenant.started({
                  body: {
                    name: data.name,
                    state: data.state,
                    start_at: new Date(data.start_at ?? "").toISOString(),
                    setting: {
                      default_location: {
                        latitude:
                          response.data.features[0].geometry.coordinates[1],
                        longitude:
                          response.data.features[0].geometry.coordinates[0],
                        radius:
                          response.data.features[0].geometry.coordinates[0],
                      },
                    },
                  },
                  onSuccess: () => {
                    appActions.hideLoading();
                    callback();
                    dispatch(appActions.showNotification());
                    dispatch(
                      appActions.displayNotification.started({
                        type: "success",
                        text: "テナント情報を保存しました",
                      })
                    );
                    dispatch(
                      assetAction.fetchTenantList.started({
                        forceRefetch: true,
                      })
                    );
                    dispatch(tenantAction.resetSelectedTenant());
                  },
                })
              );
            }
          }
        },
      })
    );
  };
  const modifyTenant = (
    data: TenantModifyRequestBody & { cityName: string },
    callback: () => void
  ) => {
    appActions.showLoading();
    if (data.cityName !== "") {
      dispatch(
        appActions.fetchGeoJson.started({
          name: data.cityName,
          access_token: process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "",
          onSuccess: (response: any) => {
            if (response) {
              if (response.data.features.length > 0) {
                dispatch(
                  tenantAction.modifyTenant.started({
                    body: {
                      ...data,
                      name: data.name,
                      state: data.state,
                      start_at: new Date(data.start_at ?? "").toISOString(),
                      setting: {
                        default_location: {
                          latitude:
                            response.data.features[0].geometry.coordinates[1],
                          longitude:
                            response.data.features[0].geometry.coordinates[0],
                          radius:
                            response.data.features[0].geometry.coordinates[0],
                        },
                      },
                    },
                    onSuccess: () => {
                      appActions.hideLoading();
                      callback();
                      dispatch(appActions.showNotification());

                      dispatch(
                        appActions.displayNotification.started({
                          type: "success",
                          text: "テナント情報を保存しました",
                        })
                      );
                      dispatch(
                        assetAction.fetchTenantList.started({
                          forceRefetch: true,
                        })
                      );
                      dispatch(tenantAction.resetSelectedTenant());
                    },
                  })
                );
              }
            }
          },
        })
      );
    } else {
      dispatch(
        tenantAction.modifyTenant.started({
          body: {
            ...data,
            name: data.name,
            state: data.state,
            start_at: new Date(data.start_at ?? "").toISOString(),
          },
          onSuccess: () => {
            appActions.hideLoading();
            callback();
            dispatch(appActions.showNotification());

            dispatch(
              appActions.displayNotification.started({
                type: "success",
                text: "テナント情報を保存しました",
              })
            );
            dispatch(
              assetAction.fetchTenantList.started({
                forceRefetch: true,
              })
            );
            dispatch(tenantAction.resetSelectedTenant());
          },
        })
      );
    }
  };

  const deleteTenant = (callback: () => void) => {
    appActions.showLoading();
    selectedTenantItem.forEach((id) => {
      dispatch(
        tenantAction.deleteTenants.started({
          id: id,
          onSuccess: () => {
            appActions.hideLoading();
            callback();
            dispatch(appActions.showNotification());
            dispatch(
              appActions.displayNotification.started({
                type: "success",
                text: "テナント情報を削除しました",
              })
            );
            dispatch(
              assetAction.fetchTenantList.started({
                forceRefetch: true,
              })
            );
            dispatch(tenantAction.resetSelectedTenant());
          },
        })
      );
    });
  };
  return {
    selectedTenantItem,
    addTenant,
    addAllTenants,
    setSelectedTenants,
    cityState,
    fetchTenantList,
    tenantList,
    createTenant,
    modifyTenant,
    deleteTenant,
    numLoad,
    offSet,
    page,
    maxPage,
  };
};
