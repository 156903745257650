import actionCreatorFactory from "typescript-fsa";
import { EmptyObject } from "@reduxjs/toolkit";
import { CommonAsyncType } from "../../core/@types";
import { POIResponseBody } from "./types";

const actionCreator = actionCreatorFactory("@@POI");

export const poiAction = {
  fetchPOIById: actionCreator.async<
    { id: number } & CommonAsyncType,
    POIResponseBody
  >("FETCH_POI_BY_ID"),
  fetchAllPOIs: actionCreator.async<CommonAsyncType, POIResponseBody[]>(
    "FETCH_ALL_POIS"
  ),
};
