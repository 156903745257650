import React, { Dispatch } from "react";
import { AnyAction } from "redux";
import { AppConfig } from "../../../AppConfig";
import { Spot, SpotStatusEnum } from "../../../core/api";
import { LocalStorageManager } from "../../../core/storage/LocalStoragetManager";
import { appActions } from "../../../ducks/app";
import IconX from "../../../icons/IconX";
import { formatDate } from "../../../utils/date";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import { SpotStatusLabel } from "../../atoms/SpotStatusLabel";

type Props = {
  item: Spot;
  dispatch: Dispatch<AnyAction>;
  onDelete: (item: Spot) => void;
  onAnalyze: (item: Spot) => void;
  onEdit: () => void;
  onClose: () => void;
  displayDatSetFlg: boolean;
  dataGenStatus: boolean;
  alwaysDisplayFlg?: boolean;
};

export const SpotCard: React.FC<Props> = ({
  item,
  dispatch,
  onAnalyze,
  onEdit,
  onDelete,
  onClose,
  displayDatSetFlg,
  dataGenStatus,
  alwaysDisplayFlg,
}) => {
  const asset: any = LocalStorageManager.Instance.getObject(
    AppConfig.Instance.LocalStorageKey["assets"]
  );
  const targetItem = asset.spots.value.find((it: any) => it.id === item.id);
  return (
    <div className="p-5 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
      <div className="flex flex-col gap-y-2">
        {item.dataset_name && displayDatSetFlg && (
          <div className="flex gap-x-1.25 items-start">
            <span className="text-[13px] whitespace-nowrap text-[#666666]">
              データセット名：
            </span>
            <span className="text-sm">{targetItem.dataset_name}</span>
          </div>
        )}
        <div className="flex gap-x-1.25 items-start">
          <span className="text-[13px] whitespace-nowrap text-[#666666]">
            スポット名：
          </span>
          <span className="text-sm">{targetItem.spot_name}</span>
        </div>
        {dataGenStatus && (
          <div className="flex gap-x-1.25 items-center">
            <span className="text-[13px] whitespace-nowrap text-[#666666]">
              データ生成ステータス：
            </span>
            <SpotStatusLabel status={targetItem.status} />
          </div>
        )}
        {item.create_date && (
          <div className="flex gap-x-1.25 items-start">
            <span className="text-[13px] whitespace-nowrap text-[#666666]">
              登録日：
            </span>
            <span className="text-sm">
              {formatDate(targetItem.create_date)}
            </span>
          </div>
        )}
      </div>
      <div className="flex gap-x-2.5">
        <CustomButton
          text="分析実行"
          disabled={item.status !== SpotStatusEnum.Success && !alwaysDisplayFlg}
          className={`px-5 py-2 text-sm border ${
            item.status === SpotStatusEnum.Success
              ? `border-primary`
              : `border-[#cccccc]`
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onAnalyze(item);
          }}
        />
        <CustomButton
          text="スポット名編集"
          className="px-5 py-2 text-sm bg-white text-primary border border-primary"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        />
        <CustomButton
          text="削除"
          className="px-5 py-2 text-sm bg-white text-primary border border-primary"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              appActions.showModal.started({
                showXIcon: true,
                title: `スポットを\n削除して良いですか？`,
                nextButtonLabel: "削除",
                handleNext: () => {
                  onDelete(item);
                  dispatch(appActions.closeModal());
                },
                closeButtonLabel: "キャンセル",
                handleClose: () => {
                  dispatch(appActions.closeModal());
                },
              })
            );
          }}
        />
      </div>
      <button
        type="button"
        className="absolute p-2.5 top-0 right-0"
        onClick={onClose}
      >
        <IconX />
      </button>
    </div>
  );
};
