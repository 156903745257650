import axios from "axios";
import { call, put } from "typed-redux-saga";
import { PageRoutes } from "../../constants/Routes";
import { deleteLoginLocalStorageData } from "../../constants/utils";
import { MoeEventEmitter } from "../../core/event";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { IHttpError } from "../../core/http/httpClient";
import { mapBoxAPIInstance } from "../../core/http/mapboxAPIClient";
import { authApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { store } from "../../store";
import { takeEveryFsa } from "../operations";
import { appActions } from "./actions";

export function* appSaga() {
  yield takeEveryFsa(appActions.showModal.started, function* (actions) {
    try {
      yield* put(
        appActions.showModal.done({
          params: actions.payload,
          result: {},
        })
      );
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  /**
   * set messages from server
   */
  yield takeEveryFsa(appActions.fetchMessageJson.started, function* () {
    const {
      response,
    }: { response: Record<string, string>; error: IHttpError } = yield call(
      () => {
        return axios
          .get("/data/messages.json")
          .then((response) => ({ response }))
          .catch((error) => ({ error }));
      }
    );
    if (response && response.data) {
      ErrorMessageManager.Instance.setErrorMessage(response.data as any);
    }
  });

  yield takeEveryFsa(
    appActions.displayNotification.started,
    function* (actions) {
      yield* put(
        appActions.displayNotification.done({
          params: actions.payload,
          result: {},
        })
      );
    }
  );
  /**
   * logout
   */
  yield takeEveryFsa(appActions.logout.started, function* (actions) {
    const { onComplete, onSuccess, onError } = actions.payload;
    const response: any = yield* call(async (): Promise<unknown> => {
      return await authApi.logout().catch((error) => error);
    });

    if (response && response.error) {
      if (!response.errorHandled && onError) {
        onError(response.error);
      } else if (!onError) {
        const errorStatus = response?.error?.response?.status;
        if (errorStatus === 401 || errorStatus === 403) {
            // 認証切れはそのままログアウト
            store.dispatch(appActions.closeModal());
            deleteLoginLocalStorageData();
            MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
        } else {
            const errorCode = response?.error?.response?.data.error_code;
            const errorObj: { title: string; message: string } =
                ErrorMessageManager.Instance.getErrorMessage(errorCode);
            store.dispatch(
                appActions.showModal.started({
                    title: errorObj.title,
                    modalType: "alert",
                    detailText: errorObj.message,
                    errorStatusCode: errorCode,
                    closeButtonLabel: "閉じる",
                    handleClose: () => {
                        store.dispatch(appActions.closeModal());
                        deleteLoginLocalStorageData();
                        MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
                    },
                })
            );
        }
      }
      return;
    }

    yield* put(
      appActions.logout.done({
        params: actions.payload,
        result: {},
      })
    );

    if (onSuccess) {
      onSuccess();
    }
  });

  /**
   * refreshToken
   */
  yield takeEveryFsa(appActions.refreshToken.started, function* (actions) {
    const { onComplete, onSuccess, onError } = actions.payload;
    const response: any = yield* call(async (): Promise<unknown> => {
      return await authApi
        .authupdate({ refresh_token: actions.payload.refreshToken })
        .catch((error) => error);
    });

    if (response && response.error) {
      if (!response.errorHandled && onError) {
        onError(response.error);
      } else if (!response.errorHandled && !onError) {
        const errorCode = response?.error?.response?.data.error_code;
        const errorObj: { title: string; message: string } =
          ErrorMessageManager.Instance.getErrorMessage(errorCode);
        store.dispatch(
          appActions.showModal.started({
            title: errorObj.title,
            modalType: "alert",
            detailText: errorObj.message,
            errorStatusCode: errorCode,
            closeButtonLabel: "閉じる",
            handleClose: () => {
              store.dispatch(appActions.closeModal());
              MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
            },
          })
        );
      }
      return;
    }

    yield* put(
      appActions.refreshToken.done({
        params: actions.payload,
        result: response.data,
      })
    );
    if (onSuccess) {
      onSuccess(response);
    }
  });

  yield takeEveryFsa(appActions.fetchStateJson.started, function* () {
    const {
      response,
    }: { response: Record<string, string>; error: IHttpError } = yield call(
      () => {
        return axios
          .get("/data/state.json")
          .then((response) => ({ response }))
          .catch((error) => ({ error }));
      }
    );
    if (response && response.data) {
      yield* put(
        appActions.fetchStateJson.done({
          params: {},
          result: response.data,
        })
      );
    }
  });
  yield takeEveryFsa(appActions.fetchGeoJson.started, function* (action) {
    const { onComplete, onError, onSuccess } = action.payload;
    const {
      response,
    }: { response: Record<string, string>; error: IHttpError } = yield call(
      () => {
        return mapBoxAPIInstance
          .get(
            `${action.payload.name}.json?type=poi&access_token=${action.payload.access_token}`
          )
          .then((response) => ({ response }))
          .catch((error) => ({ error }));
      }
    );
    if (response && response.data) {
      yield* put(
        appActions.fetchGeoJson.done({
          params: action.payload,
          result: {},
        })
      );
    }
    if (onSuccess) {
      onSuccess(response);
    }
    if (response && response.error) {
      if (!response.errorHandled && onError) {
        onError(response.error);
      } else if (!response.errorHandled && !onError) {
        const errorCode = response?.error;
        const errorObj: { title: string; message: string } =
          ErrorMessageManager.Instance.getErrorMessage(errorCode);
        store.dispatch(
          appActions.showModal.started({
            title: errorObj.title,
            modalType: "alert",
            detailText: errorObj.message,
            errorStatusCode: errorCode,
            closeButtonLabel: "閉じる",
            handleClose: () => {
              store.dispatch(appActions.closeModal());
            },
          })
        );
      }
      return;
    }
  });
}
