import { PoiConditionState } from "./types";
import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { poiConditionAction } from "./actions";

const initialState: PoiConditionState = {
  conditions: [],
  poiByConditions: [],
};

export function PoiCondiotionReducer(
  state = initialState,
  action: Action
): PoiConditionState {
  if (isType(action, poiConditionAction.getPoiConditions.done)) {
    return {
      ...state,
      conditions: action.payload.result,
    };
  }

  if (isType(action, poiConditionAction.getPoiByConditions.done)) {
    return {
      ...state,
      poiByConditions: action.payload.result.poi_by_conditions ?? [],
    };
  }

  return state;
}
