import React, { useCallback } from "react";
import { CheckboxActive } from "../atoms/CheckboxActive";
import { CheckboxInactive } from "../atoms/CheckboxInactive";
type Props = {
  onClick?: () => void;
  isSelected?: boolean;
};

export const MoeCheckbox: React.FC<Props> = (props) => {
  const { onClick, isSelected } = props;
  const handleClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [isSelected, onClick]);

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {isSelected && <CheckboxActive size="small" />}
      {!isSelected && <CheckboxInactive size="small" />}
    </div>
  );
};
