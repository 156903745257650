import { ReactNode, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useController, useFormContext } from "react-hook-form";
import IconDropdown from "../../icons/IconDropdown";
import { mergeClassNames } from "../../utils/style";
import Label from "./Label";

type Props<T> = {
  name: string;
  options: T[];
  displayKey?: keyof T;
  placeholder?: string;
  label?: string;
  required?: boolean;
  onChange?: (value: any) => any;
  disabled?: boolean;
  enableScrollIntoView?: boolean
};

export default function Select<T>({
  name,
  options,
  displayKey,
  placeholder,
  label,
  required,
  onChange,
  disabled,
  enableScrollIntoView = true
}: Props<T>) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { field, formState } = useController({ name });
  const { register } = useFormContext();
  const [showDropdown, setShowDropdown] = useState(false);

  const error = formState.errors[name];
  const errorMessage = error
    ? typeof error.message === "string"
      ? error.message
      : ""
    : "";

  return (
    <div className="flex flex-col">
      <Label required={required} text={label} />
      <button
        type="button"
        disabled={disabled || !options.length}
        className={mergeClassNames(
          "flex items-center justify-between disabled:cursor-not-allowed border rounded",
          showDropdown && options.length > 0
            ? "border-gray-light"
            : "border-gray-border"
        )}
        onClick={() => {
          flushSync(() => {
            setShowDropdown((previousValue) => !previousValue);
          });
          if (dropdownRef.current && enableScrollIntoView) {
            dropdownRef.current.lastElementChild?.scrollIntoView();
          }
        }}
      >
        <span
          className={mergeClassNames(
            "py-4.5 px-3.75 flex-1 text-left truncate select-none text-sm min-h-[3.625rem]",
            !field.value && "text-gray-light"
          )}
        >
          {(displayKey ? (field.value ?? {})[displayKey] : field.value) ??
            placeholder}
        </span>
        {options.length > 0 && (
          <div className="px-5 h-full grid place-items-center">
            <IconDropdown direction={showDropdown ? "up" : "down"} />
          </div>
        )}
      </button>

      <div
        ref={dropdownRef}
        className={mergeClassNames(
          "absolute z-10 w-[232px]  max-h-[256px] overflow-y-auto  bg-white text-sm",
          showDropdown && "mt-2.5 mb-1 py-2.5"
        )}
        style={{ boxShadow: "0 0 8px rgb(0 0 0 / 0.1)" }}
      >
        {showDropdown &&
          options.map((option, i) => {
            const value = (
              displayKey ? option[displayKey] : option
            ) as ReactNode;

            return (
              <div
                key={i}
                className="px-3.75 py-2.5 select-none cursor-pointer hover:bg-primary-light min-h-[2rem]"
                onClick={() => {
                  field.onChange(option);
                  if (onChange) {
                    onChange(option);
                  }
                  setShowDropdown(false);
                }}
              >
                {value}
              </div>
            );
          })}
      </div>

      {errorMessage && (
        <span className="text-sm text-rose-500">{errorMessage}</span>
      )}
    </div>
  );
}
