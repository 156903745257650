import { applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "typed-redux-saga";
import { AdminReducer, adminSaga } from "./admin";
import { AnalysisExecuteReducer, analysisExcuteSaga } from "./analysis_execute";
import { AppReducer, appSaga } from "./app";
import { AssetReducer, assetSaga } from "./asset";
import { LoginReducer, LoginSaga } from "./login";
import { POIReducer, poiSaga } from "./pois";
import { ReceptionReducer, receptionSaga } from "./reception.ts";
import { RoadReducer, roadSaga } from "./roads";
import { SpotReducer, spotSaga } from "./spot";
import { TenantReducer, tenantSaga } from "./tenant";
import { LinkageReducer, linkageSaga } from "./linkage";
import { PoiCondiotionReducer } from "./poi_conditions/reducer";
import {poiConditionSaga} from "./poi_conditions/operator";

const sagaMiddleware = createSagaMiddleware({});
export function runSaga() {
  sagaMiddleware.run(function* () {
    yield all([
      fork(appSaga),
      fork(assetSaga),
      fork(adminSaga),
      fork(spotSaga),
      fork(LoginSaga),
      fork(analysisExcuteSaga),
      fork(receptionSaga),
      fork(poiSaga),
      fork(roadSaga),
      fork(tenantSaga),
      fork(linkageSaga),
      fork(poiConditionSaga),
    ]);
  });
}
export const middlewares = applyMiddleware(sagaMiddleware);

export const reducers = combineReducers({
  app: AppReducer,
  asset: AssetReducer,
  admin: AdminReducer,
  analysisExcute: AnalysisExecuteReducer,
  spot: SpotReducer,
  login: LoginReducer,
  reception: ReceptionReducer,
  poi: POIReducer,
  road: RoadReducer,
  tenant: TenantReducer,
  linkage: LinkageReducer,
  poiConditions: PoiCondiotionReducer,
});
