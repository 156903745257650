export const PrefCitiesWithCode = [
  {
    pref: '北海道',
    sort: '1',
    cities: [
      {
        name: '網走郡美幌町',
        code: '1543',
      },
      {
        name: '斜里郡斜里町',
        code: '1545',
      },
      {
        name: '斜里郡清里町',
        code: '1546',
      },
      {
        name: '斜里郡小清水町',
        code: '1547',
      },
      {
        name: '常呂郡訓子府町',
        code: '1549',
      },
      {
        name: '常呂郡置戸町',
        code: '1550',
      },
      {
        name: '常呂郡佐呂間町',
        code: '1552',
      },
      {
        name: '石狩郡当別町',
        code: '1303',
      },
      {
        name: '紋別郡湧別町',
        code: '1559',
      },
      {
        name: '紋別郡滝上町',
        code: '1560',
      },
      {
        name: '紋別郡興部町',
        code: '1561',
      },
      {
        name: '紋別郡西興部村',
        code: '1562',
      },
      {
        name: '紋別郡雄武町',
        code: '1563',
      },
      {
        name: '虻田郡豊浦町',
        code: '1571',
      },
      {
        name: '白老郡白老町',
        code: '1578',
      },
      {
        name: '勇払郡厚真町',
        code: '1581',
      },
      {
        name: '虻田郡洞爺湖町',
        code: '1584',
      },
      {
        name: '勇払郡安平町',
        code: '1585',
      },
      {
        name: '勇払郡むかわ町',
        code: '1586',
      },
      {
        name: '松前郡松前町',
        code: '1331',
      },
      {
        name: '松前郡福島町',
        code: '1332',
      },
      {
        name: '上磯郡知内町',
        code: '1333',
      },
      {
        name: '上磯郡木古内町',
        code: '1334',
      },
      {
        name: '茅部郡鹿部町',
        code: '1343',
      },
      {
        name: '茅部郡森町',
        code: '1345',
      },
      {
        name: '沙流郡日高町',
        code: '1601',
      },
      {
        name: '二海郡八雲町',
        code: '1346',
      },
      {
        name: '山越郡長万部町',
        code: '1347',
      },
      {
        name: '新冠郡新冠町',
        code: '1604',
      },
      {
        name: '浦河郡浦河町',
        code: '1607',
      },
      {
        name: '様似郡様似町',
        code: '1608',
      },
      {
        name: '幌泉郡えりも町',
        code: '1609',
      },
      {
        name: '日高郡新ひだか町',
        code: '1610',
      },
      {
        name: '札幌市中央区',
        code: '1101',
      },
      {
        name: '札幌市北区',
        code: '1102',
      },
      {
        name: '札幌市東区',
        code: '1103',
      },
      {
        name: '檜山郡江差町',
        code: '1361',
      },
      {
        name: '檜山郡上ノ国町',
        code: '1362',
      },
      {
        name: '札幌市西区',
        code: '1107',
      },
      {
        name: '檜山郡厚沢部町',
        code: '1363',
      },
      {
        name: '爾志郡乙部町',
        code: '1364',
      },
      {
        name: '奥尻郡奥尻町',
        code: '1367',
      },
      {
        name: '瀬棚郡今金町',
        code: '1370',
      },
      {
        name: '久遠郡せたな町',
        code: '1371',
      },
      {
        name: '河東郡音更町',
        code: '1631',
      },
      {
        name: '河東郡士幌町',
        code: '1632',
      },
      {
        name: '上川郡清水町',
        code: '1636',
      },
      {
        name: '河西郡芽室町',
        code: '1637',
      },
      {
        name: '河西郡中札内村',
        code: '1638',
      },
      {
        name: '網走郡津別町',
        code: '1544',
      },
      {
        name: '紋別郡遠軽町',
        code: '1555',
      },
      {
        name: '石狩郡新篠津村',
        code: '1304',
      },
      {
        name: '網走郡大空町',
        code: '1564',
      },
      {
        name: '有珠郡壮瞥町',
        code: '1575',
      },
      {
        name: '亀田郡七飯町',
        code: '1337',
      },
      {
        name: '沙流郡平取町',
        code: '1602',
      },
      {
        name: '札幌市白石区',
        code: '1104',
      },
      {
        name: '札幌市豊平区',
        code: '1105',
      },
      {
        name: '札幌市南区',
        code: '1106',
      },
      {
        name: '札幌市厚別区',
        code: '1108',
      },
      {
        name: '札幌市手稲区',
        code: '1109',
      },
      {
        name: '札幌市清田区',
        code: '1110',
      },
      {
        name: '河東郡上士幌町',
        code: '1633',
      },
      {
        name: '河東郡鹿追町',
        code: '1634',
      },
      {
        name: '上川郡新得町',
        code: '1635',
      },
      {
        name: '広尾郡広尾町',
        code: '1642',
      },
      {
        name: '中川郡池田町',
        code: '1644',
      },
      {
        name: '島牧郡島牧村',
        code: '1391',
      },
      {
        name: '足寄郡足寄町',
        code: '1647',
      },
      {
        name: '寿都郡寿都町',
        code: '1392',
      },
      {
        name: '足寄郡陸別町',
        code: '1648',
      },
      {
        name: '寿都郡黒松内町',
        code: '1393',
      },
      {
        name: '十勝郡浦幌町',
        code: '1649',
      },
      {
        name: '虻田郡ニセコ町',
        code: '1395',
      },
      {
        name: '虻田郡留寿都村',
        code: '1397',
      },
      {
        name: '虻田郡喜茂別町',
        code: '1398',
      },
      {
        name: '虻田郡京極町',
        code: '1399',
      },
      {
        name: '岩内郡共和町',
        code: '1401',
      },
      {
        name: '岩内郡岩内町',
        code: '1402',
      },
      {
        name: '古宇郡泊村',
        code: '1403',
      },
      {
        name: '古宇郡神恵内村',
        code: '1404',
      },
      {
        name: '積丹郡積丹町',
        code: '1405',
      },
      {
        name: '釧路郡釧路町',
        code: '1661',
      },
      {
        name: '古平郡古平町',
        code: '1406',
      },
      {
        name: '厚岸郡厚岸町',
        code: '1662',
      },
      {
        name: '余市郡仁木町',
        code: '1407',
      },
      {
        name: '厚岸郡浜中町',
        code: '1663',
      },
      {
        name: '余市郡余市町',
        code: '1408',
      },
      {
        name: '余市郡赤井川村',
        code: '1409',
      },
      {
        name: '阿寒郡鶴居村',
        code: '1667',
      },
      {
        name: '白糠郡白糠町',
        code: '1668',
      },
      {
        name: '空知郡南幌町',
        code: '1423',
      },
      {
        name: '空知郡上砂川町',
        code: '1425',
      },
      {
        name: '夕張郡栗山町',
        code: '1429',
      },
      {
        name: '樺戸郡月形町',
        code: '1430',
      },
      {
        name: '樺戸郡浦臼町',
        code: '1431',
      },
      {
        name: '樺戸郡新十津川町',
        code: '1432',
      },
      {
        name: '雨竜郡妹背牛町',
        code: '1433',
      },
      {
        name: '雨竜郡秩父別町',
        code: '1434',
      },
      {
        name: '野付郡別海町',
        code: '1691',
      },
      {
        name: '標津郡中標津町',
        code: '1692',
      },
      {
        name: '雨竜郡沼田町',
        code: '1438',
      },
      {
        name: '目梨郡羅臼町',
        code: '1694',
      },
      {
        name: '色丹郡色丹村',
        code: '1695',
      },
      {
        name: '国後郡泊村',
        code: '1696',
      },
      {
        name: '国後郡留夜別村',
        code: '1697',
      },
      {
        name: '択捉郡留別村',
        code: '1698',
      },
      {
        name: '紗那郡紗那村',
        code: '1699',
      },
      {
        name: '蘂取郡蘂取村',
        code: '1700',
      },
      {
        name: '上川郡当麻町',
        code: '1454',
      },
      {
        name: '函館市',
        code: '1202',
      },
      {
        name: '上川郡東川町',
        code: '1458',
      },
      {
        name: '小樽市',
        code: '1203',
      },
      {
        name: '河西郡更別村',
        code: '1639',
      },
      {
        name: '広尾郡大樹町',
        code: '1641',
      },
      {
        name: '中川郡幕別町',
        code: '1643',
      },
      {
        name: '中川郡豊頃町',
        code: '1645',
      },
      {
        name: '中川郡本別町',
        code: '1646',
      },
      {
        name: '磯谷郡蘭越町',
        code: '1394',
      },
      {
        name: '虻田郡真狩村',
        code: '1396',
      },
      {
        name: '虻田郡倶知安町',
        code: '1400',
      },
      {
        name: '川上郡標茶町',
        code: '1664',
      },
      {
        name: '川上郡弟子屈町',
        code: '1665',
      },
      {
        name: '空知郡奈井江町',
        code: '1424',
      },
      {
        name: '夕張郡由仁町',
        code: '1427',
      },
      {
        name: '夕張郡長沼町',
        code: '1428',
      },
      {
        name: '雨竜郡雨竜町',
        code: '1436',
      },
      {
        name: '雨竜郡北竜町',
        code: '1437',
      },
      {
        name: '標津郡標津町',
        code: '1693',
      },
      {
        name: '上川郡鷹栖町',
        code: '1452',
      },
      {
        name: '上川郡東神楽町',
        code: '1453',
      },
      {
        name: '上川郡比布町',
        code: '1455',
      },
      {
        name: '上川郡愛別町',
        code: '1456',
      },
      {
        name: '上川郡上川町',
        code: '1457',
      },
      {
        name: '上川郡美瑛町',
        code: '1459',
      },
      {
        name: '旭川市',
        code: '1204',
      },
      {
        name: '空知郡上富良野町',
        code: '1460',
      },
      {
        name: '室蘭市',
        code: '1205',
      },
      {
        name: '空知郡中富良野町',
        code: '1461',
      },
      {
        name: '釧路市',
        code: '1206',
      },
      {
        name: '空知郡南富良野町',
        code: '1462',
      },
      {
        name: '勇払郡占冠村',
        code: '1463',
      },
      {
        name: '北見市',
        code: '1208',
      },
      {
        name: '上川郡和寒町',
        code: '1464',
      },
      {
        name: '夕張市',
        code: '1209',
      },
      {
        name: '岩見沢市',
        code: '1210',
      },
      {
        name: '網走市',
        code: '1211',
      },
      {
        name: '留萌市',
        code: '1212',
      },
      {
        name: '上川郡下川町',
        code: '1468',
      },
      {
        name: '苫小牧市',
        code: '1213',
      },
      {
        name: '中川郡美深町',
        code: '1469',
      },
      {
        name: '稚内市',
        code: '1214',
      },
      {
        name: '美唄市',
        code: '1215',
      },
      {
        name: '中川郡中川町',
        code: '1471',
      },
      {
        name: '芦別市',
        code: '1216',
      },
      {
        name: '雨竜郡幌加内町',
        code: '1472',
      },
      {
        name: '江別市',
        code: '1217',
      },
      {
        name: '赤平市',
        code: '1218',
      },
      {
        name: '紋別市',
        code: '1219',
      },
      {
        name: '士別市',
        code: '1220',
      },
      {
        name: '名寄市',
        code: '1221',
      },
      {
        name: '三笠市',
        code: '1222',
      },
      {
        name: '根室市',
        code: '1223',
      },
      {
        name: '滝川市',
        code: '1225',
      },
      {
        name: '増毛郡増毛町',
        code: '1481',
      },
      {
        name: '砂川市',
        code: '1226',
      },
      {
        name: '留萌郡小平町',
        code: '1482',
      },
      {
        name: '歌志内市',
        code: '1227',
      },
      {
        name: '苫前郡苫前町',
        code: '1483',
      },
      {
        name: '苫前郡羽幌町',
        code: '1484',
      },
      {
        name: '天塩郡遠別町',
        code: '1486',
      },
      {
        name: '恵庭市',
        code: '1231',
      },
      {
        name: '伊達市',
        code: '1233',
      },
      {
        name: '北広島市',
        code: '1234',
      },
      {
        name: '石狩市',
        code: '1235',
      },
      {
        name: '北斗市',
        code: '1236',
      },
      {
        name: '宗谷郡猿払村',
        code: '1511',
      },
      {
        name: '枝幸郡浜頓別町',
        code: '1512',
      },
      {
        name: '枝幸郡枝幸町',
        code: '1514',
      },
      {
        name: '天塩郡豊富町',
        code: '1516',
      },
      {
        name: '礼文郡礼文町',
        code: '1517',
      },
      {
        name: '利尻郡利尻町',
        code: '1518',
      },
      {
        name: '利尻郡利尻富士町',
        code: '1519',
      },
      {
        name: '天塩郡幌延町',
        code: '1520',
      },
      {
        name: '帯広市',
        code: '1207',
      },
      {
        name: '上川郡剣淵町',
        code: '1465',
      },
      {
        name: '中川郡音威子府村',
        code: '1470',
      },
      {
        name: '千歳市',
        code: '1224',
      },
      {
        name: '深川市',
        code: '1228',
      },
      {
        name: '富良野市',
        code: '1229',
      },
      {
        name: '苫前郡初山別村',
        code: '1485',
      },
      {
        name: '登別市',
        code: '1230',
      },
      {
        name: '天塩郡天塩町',
        code: '1487',
      },
      {
        name: '枝幸郡中頓別町',
        code: '1513',
      },
    ],
  },
  {
    pref: '青森県',
    sort: '2',
    cities: [
      {
        name: '東津軽郡外ヶ浜町',
        code: '2307',
      },
      {
        name: '西津軽郡深浦町',
        code: '2323',
      },
      {
        name: '南津軽郡藤崎町',
        code: '2361',
      },
      {
        name: '南津軽郡大鰐町',
        code: '2362',
      },
      {
        name: '南津軽郡田舎館村',
        code: '2367',
      },
      {
        name: '北津軽郡板柳町',
        code: '2381',
      },
      {
        name: '北津軽郡鶴田町',
        code: '2384',
      },
      {
        name: '北津軽郡中泊町',
        code: '2387',
      },
      {
        name: '上北郡野辺地町',
        code: '2401',
      },
      {
        name: '上北郡七戸町',
        code: '2402',
      },
      {
        name: '上北郡六戸町',
        code: '2405',
      },
      {
        name: '上北郡横浜町',
        code: '2406',
      },
      {
        name: '上北郡東北町',
        code: '2408',
      },
      {
        name: '上北郡六ヶ所村',
        code: '2411',
      },
      {
        name: '下北郡大間町',
        code: '2423',
      },
      {
        name: '下北郡東通村',
        code: '2424',
      },
      {
        name: '下北郡風間浦村',
        code: '2425',
      },
      {
        name: '下北郡佐井村',
        code: '2426',
      },
      {
        name: '三戸郡階上町',
        code: '2446',
      },
      {
        name: '三戸郡新郷村',
        code: '2450',
      },
      {
        name: '青森市',
        code: '2201',
      },
      {
        name: '八戸市',
        code: '2203',
      },
      {
        name: '黒石市',
        code: '2204',
      },
      {
        name: '五所川原市',
        code: '2205',
      },
      {
        name: 'むつ市',
        code: '2208',
      },
      {
        name: 'つがる市',
        code: '2209',
      },
      {
        name: '東津軽郡平内町',
        code: '2301',
      },
      {
        name: '東津軽郡今別町',
        code: '2303',
      },
      {
        name: '東津軽郡蓬田村',
        code: '2304',
      },
      {
        name: '西津軽郡鰺ヶ沢町',
        code: '2321',
      },
      {
        name: '中津軽郡西目屋村',
        code: '2343',
      },
      {
        name: '上北郡おいらせ町',
        code: '2412',
      },
      {
        name: '三戸郡三戸町',
        code: '2441',
      },
      {
        name: '三戸郡五戸町',
        code: '2442',
      },
      {
        name: '三戸郡田子町',
        code: '2443',
      },
      {
        name: '三戸郡南部町',
        code: '2445',
      },
      {
        name: '弘前市',
        code: '2202',
      },
      {
        name: '十和田市',
        code: '2206',
      },
      {
        name: '三沢市',
        code: '2207',
      },
      {
        name: '平川市',
        code: '2210',
      },
    ],
  },
  {
    pref: '岩手県',
    sort: '3',
    cities: [
      {
        name: '胆沢郡金ケ崎町',
        code: '3381',
      },
      {
        name: '気仙郡住田町',
        code: '3441',
      },
      {
        name: '宮古市',
        code: '3202',
      },
      {
        name: '和賀郡西和賀町',
        code: '3366',
      },
      {
        name: '西磐井郡平泉町',
        code: '3402',
      },
      {
        name: '盛岡市',
        code: '3201',
      },
      {
        name: '大船渡市',
        code: '3203',
      },
      {
        name: '上閉伊郡大槌町',
        code: '3461',
      },
      {
        name: '北上市',
        code: '3206',
      },
      {
        name: '久慈市',
        code: '3207',
      },
      {
        name: '遠野市',
        code: '3208',
      },
      {
        name: '陸前高田市',
        code: '3210',
      },
      {
        name: '釜石市',
        code: '3211',
      },
      {
        name: '八幡平市',
        code: '3214',
      },
      {
        name: '奥州市',
        code: '3215',
      },
      {
        name: '滝沢市',
        code: '3216',
      },
      {
        name: '下閉伊郡山田町',
        code: '3482',
      },
      {
        name: '花巻市',
        code: '3205',
      },
      {
        name: '一関市',
        code: '3209',
      },
      {
        name: '二戸市',
        code: '3213',
      },
      {
        name: '下閉伊郡岩泉町',
        code: '3483',
      },
      {
        name: '下閉伊郡田野畑村',
        code: '3484',
      },
      {
        name: '下閉伊郡普代村',
        code: '3485',
      },
      {
        name: '九戸郡軽米町',
        code: '3501',
      },
      {
        name: '九戸郡野田村',
        code: '3503',
      },
      {
        name: '九戸郡洋野町',
        code: '3507',
      },
      {
        name: '岩手郡雫石町',
        code: '3301',
      },
      {
        name: '紫波郡矢巾町',
        code: '3322',
      },
      {
        name: '九戸郡九戸村',
        code: '3506',
      },
      {
        name: '二戸郡一戸町',
        code: '3524',
      },
      {
        name: '岩手郡葛巻町',
        code: '3302',
      },
      {
        name: '岩手郡岩手町',
        code: '3303',
      },
      {
        name: '紫波郡紫波町',
        code: '3321',
      },
    ],
  },
  {
    pref: '宮城県',
    sort: '4',
    cities: [
      {
        name: '仙台市宮城野区',
        code: '4102',
      },
      {
        name: '仙台市若林区',
        code: '4103',
      },
      {
        name: '仙台市青葉区',
        code: '4101',
      },
      {
        name: '亘理郡亘理町',
        code: '4361',
      },
      {
        name: '宮城郡松島町',
        code: '4401',
      },
      {
        name: '宮城郡七ヶ浜町',
        code: '4404',
      },
      {
        name: '宮城郡利府町',
        code: '4406',
      },
      {
        name: '黒川郡大和町',
        code: '4421',
      },
      {
        name: '黒川郡大衡村',
        code: '4424',
      },
      {
        name: '加美郡色麻町',
        code: '4444',
      },
      {
        name: '石巻市',
        code: '4202',
      },
      {
        name: '塩竈市',
        code: '4203',
      },
      {
        name: '気仙沼市',
        code: '4205',
      },
      {
        name: '東松島市',
        code: '4214',
      },
      {
        name: '大崎市',
        code: '4215',
      },
      {
        name: '遠田郡涌谷町',
        code: '4501',
      },
      {
        name: '柴田郡大河原町',
        code: '4321',
      },
      {
        name: '柴田郡村田町',
        code: '4322',
      },
      {
        name: '牡鹿郡女川町',
        code: '4581',
      },
      {
        name: '伊具郡丸森町',
        code: '4341',
      },
      {
        name: '本吉郡南三陸町',
        code: '4606',
      },
      {
        name: '仙台市太白区',
        code: '4104',
      },
      {
        name: '仙台市泉区',
        code: '4105',
      },
      {
        name: '亘理郡山元町',
        code: '4362',
      },
      {
        name: '黒川郡大郷町',
        code: '4422',
      },
      {
        name: '加美郡加美町',
        code: '4445',
      },
      {
        name: '白石市',
        code: '4206',
      },
      {
        name: '名取市',
        code: '4207',
      },
      {
        name: '角田市',
        code: '4208',
      },
      {
        name: '多賀城市',
        code: '4209',
      },
      {
        name: '岩沼市',
        code: '4211',
      },
      {
        name: '登米市',
        code: '4212',
      },
      {
        name: '栗原市',
        code: '4213',
      },
      {
        name: '富谷市',
        code: '4216',
      },
      {
        name: '遠田郡美里町',
        code: '4505',
      },
      {
        name: '刈田郡蔵王町',
        code: '4301',
      },
      {
        name: '刈田郡七ヶ宿町',
        code: '4302',
      },
      {
        name: '柴田郡柴田町',
        code: '4323',
      },
      {
        name: '柴田郡川崎町',
        code: '4324',
      },
    ],
  },
  {
    pref: '秋田県',
    sort: '5',
    cities: [
      {
        name: '仙北郡美郷町',
        code: '5434',
      },
      {
        name: '秋田市',
        code: '5201',
      },
      {
        name: '大館市',
        code: '5204',
      },
      {
        name: '男鹿市',
        code: '5206',
      },
      {
        name: '雄勝郡羽後町',
        code: '5463',
      },
      {
        name: '由利本荘市',
        code: '5210',
      },
      {
        name: '潟上市',
        code: '5211',
      },
      {
        name: 'にかほ市',
        code: '5214',
      },
      {
        name: '仙北市',
        code: '5215',
      },
      {
        name: '鹿角郡小坂町',
        code: '5303',
      },
      {
        name: '山本郡三種町',
        code: '5348',
      },
      {
        name: '山本郡八峰町',
        code: '5349',
      },
      {
        name: '南秋田郡井川町',
        code: '5366',
      },
      {
        name: '南秋田郡大潟村',
        code: '5368',
      },
      {
        name: '能代市',
        code: '5202',
      },
      {
        name: '横手市',
        code: '5203',
      },
      {
        name: '湯沢市',
        code: '5207',
      },
      {
        name: '雄勝郡東成瀬村',
        code: '5464',
      },
      {
        name: '鹿角市',
        code: '5209',
      },
      {
        name: '大仙市',
        code: '5212',
      },
      {
        name: '北秋田市',
        code: '5213',
      },
      {
        name: '北秋田郡上小阿仁村',
        code: '5327',
      },
      {
        name: '山本郡藤里町',
        code: '5346',
      },
      {
        name: '南秋田郡五城目町',
        code: '5361',
      },
      {
        name: '南秋田郡八郎潟町',
        code: '5363',
      },
    ],
  },
  {
    pref: '山形県',
    sort: '6',
    cities: [
      {
        name: '西置賜郡白鷹町',
        code: '6402',
      },
      {
        name: '米沢市',
        code: '6202',
      },
      {
        name: '鶴岡市',
        code: '6203',
      },
      {
        name: '酒田市',
        code: '6204',
      },
      {
        name: '新庄市',
        code: '6205',
      },
      {
        name: '飽海郡遊佐町',
        code: '6461',
      },
      {
        name: '寒河江市',
        code: '6206',
      },
      {
        name: '上山市',
        code: '6207',
      },
      {
        name: '天童市',
        code: '6210',
      },
      {
        name: '東根市',
        code: '6211',
      },
      {
        name: '南陽市',
        code: '6213',
      },
      {
        name: '東村山郡山辺町',
        code: '6301',
      },
      {
        name: '東村山郡中山町',
        code: '6302',
      },
      {
        name: '西村山郡西川町',
        code: '6322',
      },
      {
        name: '西村山郡大江町',
        code: '6324',
      },
      {
        name: '北村山郡大石田町',
        code: '6341',
      },
      {
        name: '最上郡金山町',
        code: '6361',
      },
      {
        name: '最上郡最上町',
        code: '6362',
      },
      {
        name: '最上郡舟形町',
        code: '6363',
      },
      {
        name: '最上郡真室川町',
        code: '6364',
      },
      {
        name: '最上郡大蔵村',
        code: '6365',
      },
      {
        name: '最上郡鮭川村',
        code: '6366',
      },
      {
        name: '東置賜郡高畠町',
        code: '6381',
      },
      {
        name: '東置賜郡川西町',
        code: '6382',
      },
      {
        name: '西置賜郡小国町',
        code: '6401',
      },
      {
        name: '西置賜郡飯豊町',
        code: '6403',
      },
      {
        name: '東田川郡三川町',
        code: '6426',
      },
      {
        name: '東田川郡庄内町',
        code: '6428',
      },
      {
        name: '山形市',
        code: '6201',
      },
      {
        name: '村山市',
        code: '6208',
      },
      {
        name: '長井市',
        code: '6209',
      },
      {
        name: '尾花沢市',
        code: '6212',
      },
      {
        name: '西村山郡河北町',
        code: '6321',
      },
      {
        name: '西村山郡朝日町',
        code: '6323',
      },
      {
        name: '最上郡戸沢村',
        code: '6367',
      },
    ],
  },
  {
    pref: '福島県',
    sort: '7',
    cities: [
      {
        name: '大沼郡三島町',
        code: '7444',
      },
      {
        name: '大沼郡金山町',
        code: '7445',
      },
      {
        name: 'いわき市',
        code: '7204',
      },
      {
        name: '白河市',
        code: '7205',
      },
      {
        name: '西白河郡西郷村',
        code: '7461',
      },
      {
        name: '須賀川市',
        code: '7207',
      },
      {
        name: '喜多方市',
        code: '7208',
      },
      {
        name: '相馬市',
        code: '7209',
      },
      {
        name: '西白河郡中島村',
        code: '7465',
      },
      {
        name: '西白河郡矢吹町',
        code: '7466',
      },
      {
        name: '南相馬市',
        code: '7212',
      },
      {
        name: '伊達市',
        code: '7213',
      },
      {
        name: '東白川郡塙町',
        code: '7483',
      },
      {
        name: '石川郡玉川村',
        code: '7502',
      },
      {
        name: '田村郡三春町',
        code: '7521',
      },
      {
        name: '双葉郡楢葉町',
        code: '7542',
      },
      {
        name: '双葉郡富岡町',
        code: '7543',
      },
      {
        name: '双葉郡川内村',
        code: '7544',
      },
      {
        name: '双葉郡大熊町',
        code: '7545',
      },
      {
        name: '双葉郡浪江町',
        code: '7547',
      },
      {
        name: '伊達郡桑折町',
        code: '7301',
      },
      {
        name: '伊達郡国見町',
        code: '7303',
      },
      {
        name: '相馬郡新地町',
        code: '7561',
      },
      {
        name: '相馬郡飯舘村',
        code: '7564',
      },
      {
        name: '岩瀬郡鏡石町',
        code: '7342',
      },
      {
        name: '岩瀬郡天栄村',
        code: '7344',
      },
      {
        name: '南会津郡檜枝岐村',
        code: '7364',
      },
      {
        name: '南会津郡只見町',
        code: '7367',
      },
      {
        name: '南会津郡南会津町',
        code: '7368',
      },
      {
        name: '耶麻郡北塩原村',
        code: '7402',
      },
      {
        name: '耶麻郡西会津町',
        code: '7405',
      },
      {
        name: '耶麻郡磐梯町',
        code: '7407',
      },
      {
        name: '耶麻郡猪苗代町',
        code: '7408',
      },
      {
        name: '河沼郡柳津町',
        code: '7423',
      },
      {
        name: '大沼郡昭和村',
        code: '7446',
      },
      {
        name: '大沼郡会津美里町',
        code: '7447',
      },
      {
        name: '福島市',
        code: '7201',
      },
      {
        name: '会津若松市',
        code: '7202',
      },
      {
        name: '郡山市',
        code: '7203',
      },
      {
        name: '西白河郡泉崎村',
        code: '7464',
      },
      {
        name: '二本松市',
        code: '7210',
      },
      {
        name: '田村市',
        code: '7211',
      },
      {
        name: '本宮市',
        code: '7214',
      },
      {
        name: '東白川郡棚倉町',
        code: '7481',
      },
      {
        name: '東白川郡矢祭町',
        code: '7482',
      },
      {
        name: '東白川郡鮫川村',
        code: '7484',
      },
      {
        name: '石川郡石川町',
        code: '7501',
      },
      {
        name: '石川郡平田村',
        code: '7503',
      },
      {
        name: '石川郡浅川町',
        code: '7504',
      },
      {
        name: '石川郡古殿町',
        code: '7505',
      },
      {
        name: '田村郡小野町',
        code: '7522',
      },
      {
        name: '双葉郡広野町',
        code: '7541',
      },
      {
        name: '双葉郡双葉町',
        code: '7546',
      },
      {
        name: '双葉郡葛尾村',
        code: '7548',
      },
      {
        name: '伊達郡川俣町',
        code: '7308',
      },
      {
        name: '安達郡大玉村',
        code: '7322',
      },
      {
        name: '南会津郡下郷町',
        code: '7362',
      },
      {
        name: '河沼郡会津坂下町',
        code: '7421',
      },
      {
        name: '河沼郡湯川村',
        code: '7422',
      },
    ],
  },
  {
    pref: '茨城県',
    sort: '8',
    cities: [
      {
        name: '水戸市',
        code: '8201',
      },
      {
        name: '日立市',
        code: '8202',
      },
      {
        name: '古河市',
        code: '8204',
      },
      {
        name: '龍ケ崎市',
        code: '8208',
      },
      {
        name: '常総市',
        code: '8211',
      },
      {
        name: '常陸太田市',
        code: '8212',
      },
      {
        name: '高萩市',
        code: '8214',
      },
      {
        name: '北茨城市',
        code: '8215',
      },
      {
        name: '笠間市',
        code: '8216',
      },
      {
        name: '牛久市',
        code: '8219',
      },
      {
        name: 'ひたちなか市',
        code: '8221',
      },
      {
        name: '鹿嶋市',
        code: '8222',
      },
      {
        name: '那珂市',
        code: '8226',
      },
      {
        name: '筑西市',
        code: '8227',
      },
      {
        name: '坂東市',
        code: '8228',
      },
      {
        name: '神栖市',
        code: '8232',
      },
      {
        name: '行方市',
        code: '8233',
      },
      {
        name: 'つくばみらい市',
        code: '8235',
      },
      {
        name: '小美玉市',
        code: '8236',
      },
      {
        name: '東茨城郡大洗町',
        code: '8309',
      },
      {
        name: '那珂郡東海村',
        code: '8341',
      },
      {
        name: '久慈郡大子町',
        code: '8364',
      },
      {
        name: '稲敷郡阿見町',
        code: '8443',
      },
      {
        name: '稲敷郡河内町',
        code: '8447',
      },
      {
        name: '土浦市',
        code: '8203',
      },
      {
        name: '石岡市',
        code: '8205',
      },
      {
        name: '結城市',
        code: '8207',
      },
      {
        name: '下妻市',
        code: '8210',
      },
      {
        name: '取手市',
        code: '8217',
      },
      {
        name: 'つくば市',
        code: '8220',
      },
      {
        name: '潮来市',
        code: '8223',
      },
      {
        name: '守谷市',
        code: '8224',
      },
      {
        name: '常陸大宮市',
        code: '8225',
      },
      {
        name: '稲敷市',
        code: '8229',
      },
      {
        name: 'かすみがうら市',
        code: '8230',
      },
      {
        name: '桜川市',
        code: '8231',
      },
      {
        name: '鉾田市',
        code: '8234',
      },
      {
        name: '結城郡八千代町',
        code: '8521',
      },
      {
        name: '猿島郡五霞町',
        code: '8542',
      },
      {
        name: '猿島郡境町',
        code: '8546',
      },
      {
        name: '東茨城郡茨城町',
        code: '8302',
      },
      {
        name: '北相馬郡利根町',
        code: '8564',
      },
      {
        name: '東茨城郡城里町',
        code: '8310',
      },
      {
        name: '稲敷郡美浦村',
        code: '8442',
      },
    ],
  },
  {
    pref: '栃木県',
    sort: '9',
    cities: [
      {
        name: '河内郡上三川町',
        code: '9301',
      },
      {
        name: '芳賀郡益子町',
        code: '9342',
      },
      {
        name: '芳賀郡市貝町',
        code: '9344',
      },
      {
        name: '下都賀郡壬生町',
        code: '9361',
      },
      {
        name: '下都賀郡野木町',
        code: '9364',
      },
      {
        name: '塩谷郡高根沢町',
        code: '9386',
      },
      {
        name: '足利市',
        code: '9202',
      },
      {
        name: '佐野市',
        code: '9204',
      },
      {
        name: '鹿沼市',
        code: '9205',
      },
      {
        name: '真岡市',
        code: '9209',
      },
      {
        name: '大田原市',
        code: '9210',
      },
      {
        name: '矢板市',
        code: '9211',
      },
      {
        name: '那須塩原市',
        code: '9213',
      },
      {
        name: 'さくら市',
        code: '9214',
      },
      {
        name: '那須烏山市',
        code: '9215',
      },
      {
        name: '下野市',
        code: '9216',
      },
      {
        name: '芳賀郡茂木町',
        code: '9343',
      },
      {
        name: '芳賀郡芳賀町',
        code: '9345',
      },
      {
        name: '塩谷郡塩谷町',
        code: '9384',
      },
      {
        name: '那須郡那須町',
        code: '9407',
      },
      {
        name: '那須郡那珂川町',
        code: '9411',
      },
      {
        name: '宇都宮市',
        code: '9201',
      },
      {
        name: '栃木市',
        code: '9203',
      },
      {
        name: '日光市',
        code: '9206',
      },
      {
        name: '小山市',
        code: '9208',
      },
    ],
  },
  {
    pref: '群馬県',
    sort: '10',
    cities: [
      {
        name: '邑楽郡明和町',
        code: '10522',
      },
      {
        name: '邑楽郡邑楽町',
        code: '10525',
      },
      {
        name: '北群馬郡榛東村',
        code: '10344',
      },
      {
        name: '北群馬郡吉岡町',
        code: '10345',
      },
      {
        name: '多野郡上野村',
        code: '10366',
      },
      {
        name: '甘楽郡甘楽町',
        code: '10384',
      },
      {
        name: '利根郡片品村',
        code: '10443',
      },
      {
        name: '利根郡川場村',
        code: '10444',
      },
      {
        name: '利根郡昭和村',
        code: '10448',
      },
      {
        name: '利根郡みなかみ町',
        code: '10449',
      },
      {
        name: '館林市',
        code: '10207',
      },
      {
        name: '渋川市',
        code: '10208',
      },
      {
        name: '佐波郡玉村町',
        code: '10464',
      },
      {
        name: '富岡市',
        code: '10210',
      },
      {
        name: 'みどり市',
        code: '10212',
      },
      {
        name: '邑楽郡板倉町',
        code: '10521',
      },
      {
        name: '邑楽郡千代田町',
        code: '10523',
      },
      {
        name: '邑楽郡大泉町',
        code: '10524',
      },
      {
        name: '多野郡神流町',
        code: '10367',
      },
      {
        name: '甘楽郡下仁田町',
        code: '10382',
      },
      {
        name: '甘楽郡南牧村',
        code: '10383',
      },
      {
        name: '吾妻郡中之条町',
        code: '10421',
      },
      {
        name: '吾妻郡長野原町',
        code: '10424',
      },
      {
        name: '吾妻郡嬬恋村',
        code: '10425',
      },
      {
        name: '吾妻郡草津町',
        code: '10426',
      },
      {
        name: '吾妻郡高山村',
        code: '10428',
      },
      {
        name: '吾妻郡東吾妻町',
        code: '10429',
      },
      {
        name: '前橋市',
        code: '10201',
      },
      {
        name: '高崎市',
        code: '10202',
      },
      {
        name: '桐生市',
        code: '10203',
      },
      {
        name: '伊勢崎市',
        code: '10204',
      },
      {
        name: '太田市',
        code: '10205',
      },
      {
        name: '沼田市',
        code: '10206',
      },
      {
        name: '藤岡市',
        code: '10209',
      },
      {
        name: '安中市',
        code: '10211',
      },
    ],
  },
  {
    pref: '埼玉県',
    sort: '11',
    cities: [
      {
        name: '北足立郡伊奈町',
        code: '11301',
      },
      {
        name: '比企郡川島町',
        code: '11346',
      },
      {
        name: '比企郡吉見町',
        code: '11347',
      },
      {
        name: '比企郡鳩山町',
        code: '11348',
      },
      {
        name: 'さいたま市西区',
        code: '11101',
      },
      {
        name: 'さいたま市北区',
        code: '11102',
      },
      {
        name: 'さいたま市大宮区',
        code: '11103',
      },
      {
        name: 'さいたま市中央区',
        code: '11105',
      },
      {
        name: '秩父郡皆野町',
        code: '11362',
      },
      {
        name: 'さいたま市浦和区',
        code: '11107',
      },
      {
        name: '秩父郡長瀞町',
        code: '11363',
      },
      {
        name: 'さいたま市南区',
        code: '11108',
      },
      {
        name: 'さいたま市緑区',
        code: '11109',
      },
      {
        name: 'さいたま市岩槻区',
        code: '11110',
      },
      {
        name: '秩父郡東秩父村',
        code: '11369',
      },
      {
        name: '児玉郡美里町',
        code: '11381',
      },
      {
        name: '川越市',
        code: '11201',
      },
      {
        name: '熊谷市',
        code: '11202',
      },
      {
        name: '行田市',
        code: '11206',
      },
      {
        name: '加須市',
        code: '11210',
      },
      {
        name: '本庄市',
        code: '11211',
      },
      {
        name: '東松山市',
        code: '11212',
      },
      {
        name: '深谷市',
        code: '11218',
      },
      {
        name: '草加市',
        code: '11221',
      },
      {
        name: '戸田市',
        code: '11224',
      },
      {
        name: '入間市',
        code: '11225',
      },
      {
        name: '桶川市',
        code: '11231',
      },
      {
        name: '久喜市',
        code: '11232',
      },
      {
        name: '八潮市',
        code: '11234',
      },
      {
        name: '富士見市',
        code: '11235',
      },
      {
        name: '三郷市',
        code: '11237',
      },
      {
        name: '蓮田市',
        code: '11238',
      },
      {
        name: '幸手市',
        code: '11240',
      },
      {
        name: '日高市',
        code: '11242',
      },
      {
        name: '吉川市',
        code: '11243',
      },
      {
        name: '白岡市',
        code: '11246',
      },
      {
        name: '入間郡三芳町',
        code: '11324',
      },
      {
        name: '入間郡毛呂山町',
        code: '11326',
      },
      {
        name: '入間郡越生町',
        code: '11327',
      },
      {
        name: '比企郡滑川町',
        code: '11341',
      },
      {
        name: '比企郡嵐山町',
        code: '11342',
      },
      {
        name: '比企郡小川町',
        code: '11343',
      },
      {
        name: '比企郡ときがわ町',
        code: '11349',
      },
      {
        name: 'さいたま市見沼区',
        code: '11104',
      },
      {
        name: '秩父郡横瀬町',
        code: '11361',
      },
      {
        name: 'さいたま市桜区',
        code: '11106',
      },
      {
        name: '秩父郡小鹿野町',
        code: '11365',
      },
      {
        name: '児玉郡神川町',
        code: '11383',
      },
      {
        name: '児玉郡上里町',
        code: '11385',
      },
      {
        name: '大里郡寄居町',
        code: '11408',
      },
      {
        name: '南埼玉郡宮代町',
        code: '11442',
      },
      {
        name: '川口市',
        code: '11203',
      },
      {
        name: '秩父市',
        code: '11207',
      },
      {
        name: '所沢市',
        code: '11208',
      },
      {
        name: '北葛飾郡杉戸町',
        code: '11464',
      },
      {
        name: '飯能市',
        code: '11209',
      },
      {
        name: '北葛飾郡松伏町',
        code: '11465',
      },
      {
        name: '春日部市',
        code: '11214',
      },
      {
        name: '狭山市',
        code: '11215',
      },
      {
        name: '羽生市',
        code: '11216',
      },
      {
        name: '鴻巣市',
        code: '11217',
      },
      {
        name: '上尾市',
        code: '11219',
      },
      {
        name: '越谷市',
        code: '11222',
      },
      {
        name: '蕨市',
        code: '11223',
      },
      {
        name: '朝霞市',
        code: '11227',
      },
      {
        name: '志木市',
        code: '11228',
      },
      {
        name: '和光市',
        code: '11229',
      },
      {
        name: '新座市',
        code: '11230',
      },
      {
        name: '北本市',
        code: '11233',
      },
      {
        name: '坂戸市',
        code: '11239',
      },
      {
        name: '鶴ヶ島市',
        code: '11241',
      },
      {
        name: 'ふじみ野市',
        code: '11245',
      },
    ],
  },
  {
    pref: '千葉県',
    sort: '12',
    cities: [
      {
        name: '印旛郡栄町',
        code: '12329',
      },
      {
        name: '香取郡多古町',
        code: '12347',
      },
      {
        name: '香取郡東庄町',
        code: '12349',
      },
      {
        name: '千葉市中央区',
        code: '12101',
      },
      {
        name: '千葉市花見川区',
        code: '12102',
      },
      {
        name: '千葉市稲毛区',
        code: '12103',
      },
      {
        name: '千葉市緑区',
        code: '12105',
      },
      {
        name: '千葉市美浜区',
        code: '12106',
      },
      {
        name: '山武郡九十九里町',
        code: '12403',
      },
      {
        name: '山武郡芝山町',
        code: '12409',
      },
      {
        name: '長生郡一宮町',
        code: '12421',
      },
      {
        name: '長生郡長生村',
        code: '12423',
      },
      {
        name: '夷隅郡御宿町',
        code: '12443',
      },
      {
        name: '銚子市',
        code: '12202',
      },
      {
        name: '船橋市',
        code: '12204',
      },
      {
        name: '館山市',
        code: '12205',
      },
      {
        name: '木更津市',
        code: '12206',
      },
      {
        name: '松戸市',
        code: '12207',
      },
      {
        name: '安房郡鋸南町',
        code: '12463',
      },
      {
        name: '茂原市',
        code: '12210',
      },
      {
        name: '成田市',
        code: '12211',
      },
      {
        name: '佐倉市',
        code: '12212',
      },
      {
        name: '東金市',
        code: '12213',
      },
      {
        name: '旭市',
        code: '12215',
      },
      {
        name: '習志野市',
        code: '12216',
      },
      {
        name: '勝浦市',
        code: '12218',
      },
      {
        name: '市原市',
        code: '12219',
      },
      {
        name: '八千代市',
        code: '12221',
      },
      {
        name: '我孫子市',
        code: '12222',
      },
      {
        name: '鴨川市',
        code: '12223',
      },
      {
        name: '君津市',
        code: '12225',
      },
      {
        name: '富津市',
        code: '12226',
      },
      {
        name: '浦安市',
        code: '12227',
      },
      {
        name: '袖ケ浦市',
        code: '12229',
      },
      {
        name: '八街市',
        code: '12230',
      },
      {
        name: '南房総市',
        code: '12234',
      },
      {
        name: '匝瑳市',
        code: '12235',
      },
      {
        name: '香取市',
        code: '12236',
      },
      {
        name: '山武市',
        code: '12237',
      },
      {
        name: 'いすみ市',
        code: '12238',
      },
      {
        name: '印旛郡酒々井町',
        code: '12322',
      },
      {
        name: '香取郡神崎町',
        code: '12342',
      },
      {
        name: '千葉市若葉区',
        code: '12104',
      },
      {
        name: '山武郡横芝光町',
        code: '12410',
      },
      {
        name: '長生郡睦沢町',
        code: '12422',
      },
      {
        name: '長生郡白子町',
        code: '12424',
      },
      {
        name: '長生郡長柄町',
        code: '12426',
      },
      {
        name: '長生郡長南町',
        code: '12427',
      },
      {
        name: '夷隅郡大多喜町',
        code: '12441',
      },
      {
        name: '市川市',
        code: '12203',
      },
      {
        name: '野田市',
        code: '12208',
      },
      {
        name: '柏市',
        code: '12217',
      },
      {
        name: '流山市',
        code: '12220',
      },
      {
        name: '鎌ケ谷市',
        code: '12224',
      },
      {
        name: '四街道市',
        code: '12228',
      },
      {
        name: '印西市',
        code: '12231',
      },
      {
        name: '白井市',
        code: '12232',
      },
      {
        name: '富里市',
        code: '12233',
      },
      {
        name: '大網白里市',
        code: '12239',
      },
    ],
  },
  {
    pref: '東京都',
    sort: '13',
    cities: [
      {
        name: '千代田区',
        code: '13101',
      },
      {
        name: '港区',
        code: '13103',
      },
      {
        name: '新宿区',
        code: '13104',
      },
      {
        name: '文京区',
        code: '13105',
      },
      {
        name: '大島支庁大島町',
        code: '13361',
      },
      {
        name: '大島支庁利島村',
        code: '13362',
      },
      {
        name: '墨田区',
        code: '13107',
      },
      {
        name: '大島支庁新島村',
        code: '13363',
      },
      {
        name: '江東区',
        code: '13108',
      },
      {
        name: '大島支庁神津島村',
        code: '13364',
      },
      {
        name: '品川区',
        code: '13109',
      },
      {
        name: '目黒区',
        code: '13110',
      },
      {
        name: '大田区',
        code: '13111',
      },
      {
        name: '世田谷区',
        code: '13112',
      },
      {
        name: '渋谷区',
        code: '13113',
      },
      {
        name: '杉並区',
        code: '13115',
      },
      {
        name: '豊島区',
        code: '13116',
      },
      {
        name: '北区',
        code: '13117',
      },
      {
        name: '荒川区',
        code: '13118',
      },
      {
        name: '中央区',
        code: '13102',
      },
      {
        name: '台東区',
        code: '13106',
      },
      {
        name: '中野区',
        code: '13114',
      },
      {
        name: '足立区',
        code: '13121',
      },
      {
        name: '葛飾区',
        code: '13122',
      },
      {
        name: '三宅支庁三宅村',
        code: '13381',
      },
      {
        name: '三宅支庁御蔵島村',
        code: '13382',
      },
      {
        name: '八丈支庁八丈町',
        code: '13401',
      },
      {
        name: '八丈支庁青ヶ島村',
        code: '13402',
      },
      {
        name: '小笠原支庁小笠原村',
        code: '13421',
      },
      {
        name: '八王子市',
        code: '13201',
      },
      {
        name: '武蔵野市',
        code: '13203',
      },
      {
        name: '青梅市',
        code: '13205',
      },
      {
        name: '府中市',
        code: '13206',
      },
      {
        name: '昭島市',
        code: '13207',
      },
      {
        name: '町田市',
        code: '13209',
      },
      {
        name: '小平市',
        code: '13211',
      },
      {
        name: '日野市',
        code: '13212',
      },
      {
        name: '東村山市',
        code: '13213',
      },
      {
        name: '東大和市',
        code: '13220',
      },
      {
        name: '稲城市',
        code: '13225',
      },
      {
        name: 'あきる野市',
        code: '13228',
      },
      {
        name: '西多摩郡檜原村',
        code: '13307',
      },
      {
        name: '西多摩郡奥多摩町',
        code: '13308',
      },
      {
        name: '板橋区',
        code: '13119',
      },
      {
        name: '練馬区',
        code: '13120',
      },
      {
        name: '江戸川区',
        code: '13123',
      },
      {
        name: '立川市',
        code: '13202',
      },
      {
        name: '三鷹市',
        code: '13204',
      },
      {
        name: '調布市',
        code: '13208',
      },
      {
        name: '小金井市',
        code: '13210',
      },
      {
        name: '国分寺市',
        code: '13214',
      },
      {
        name: '国立市',
        code: '13215',
      },
      {
        name: '福生市',
        code: '13218',
      },
      {
        name: '狛江市',
        code: '13219',
      },
      {
        name: '清瀬市',
        code: '13221',
      },
      {
        name: '東久留米市',
        code: '13222',
      },
      {
        name: '武蔵村山市',
        code: '13223',
      },
      {
        name: '多摩市',
        code: '13224',
      },
      {
        name: '羽村市',
        code: '13227',
      },
      {
        name: '西東京市',
        code: '13229',
      },
      {
        name: '西多摩郡瑞穂町',
        code: '13303',
      },
      {
        name: '西多摩郡日の出町',
        code: '13305',
      },
    ],
  },
  {
    pref: '神奈川県',
    sort: '14',
    cities: [
      {
        name: '中郡二宮町',
        code: '14342',
      },
      {
        name: '横浜市鶴見区',
        code: '14101',
      },
      {
        name: '横浜市神奈川区',
        code: '14102',
      },
      {
        name: '横浜市中区',
        code: '14104',
      },
      {
        name: '足柄上郡中井町',
        code: '14361',
      },
      {
        name: '横浜市磯子区',
        code: '14107',
      },
      {
        name: '横浜市金沢区',
        code: '14108',
      },
      {
        name: '足柄上郡山北町',
        code: '14364',
      },
      {
        name: '横浜市戸塚区',
        code: '14110',
      },
      {
        name: '足柄上郡開成町',
        code: '14366',
      },
      {
        name: '横浜市緑区',
        code: '14113',
      },
      {
        name: '横浜市栄区',
        code: '14115',
      },
      {
        name: '横浜市都筑区',
        code: '14118',
      },
      {
        name: '足柄下郡箱根町',
        code: '14382',
      },
      {
        name: '足柄下郡真鶴町',
        code: '14383',
      },
      {
        name: '川崎市川崎区',
        code: '14131',
      },
      {
        name: '川崎市高津区',
        code: '14134',
      },
      {
        name: '川崎市多摩区',
        code: '14135',
      },
      {
        name: '川崎市麻生区',
        code: '14137',
      },
      {
        name: '相模原市中央区',
        code: '14152',
      },
      {
        name: '相模原市南区',
        code: '14153',
      },
      {
        name: '横須賀市',
        code: '14201',
      },
      {
        name: '鎌倉市',
        code: '14204',
      },
      {
        name: '藤沢市',
        code: '14205',
      },
      {
        name: '小田原市',
        code: '14206',
      },
      {
        name: '茅ヶ崎市',
        code: '14207',
      },
      {
        name: '逗子市',
        code: '14208',
      },
      {
        name: '三浦市',
        code: '14210',
      },
      {
        name: '秦野市',
        code: '14211',
      },
      {
        name: '大和市',
        code: '14213',
      },
      {
        name: '座間市',
        code: '14216',
      },
      {
        name: '三浦郡葉山町',
        code: '14301',
      },
      {
        name: '高座郡寒川町',
        code: '14321',
      },
      {
        name: '中郡大磯町',
        code: '14341',
      },
      {
        name: '横浜市西区',
        code: '14103',
      },
      {
        name: '横浜市南区',
        code: '14105',
      },
      {
        name: '横浜市保土ケ谷区',
        code: '14106',
      },
      {
        name: '足柄上郡大井町',
        code: '14362',
      },
      {
        name: '足柄上郡松田町',
        code: '14363',
      },
      {
        name: '横浜市港北区',
        code: '14109',
      },
      {
        name: '横浜市港南区',
        code: '14111',
      },
      {
        name: '横浜市旭区',
        code: '14112',
      },
      {
        name: '横浜市瀬谷区',
        code: '14114',
      },
      {
        name: '横浜市泉区',
        code: '14116',
      },
      {
        name: '横浜市青葉区',
        code: '14117',
      },
      {
        name: '足柄下郡湯河原町',
        code: '14384',
      },
      {
        name: '川崎市幸区',
        code: '14132',
      },
      {
        name: '川崎市中原区',
        code: '14133',
      },
      {
        name: '川崎市宮前区',
        code: '14136',
      },
      {
        name: '愛甲郡愛川町',
        code: '14401',
      },
      {
        name: '愛甲郡清川村',
        code: '14402',
      },
      {
        name: '相模原市緑区',
        code: '14151',
      },
      {
        name: '平塚市',
        code: '14203',
      },
      {
        name: '厚木市',
        code: '14212',
      },
      {
        name: '伊勢原市',
        code: '14214',
      },
      {
        name: '海老名市',
        code: '14215',
      },
      {
        name: '南足柄市',
        code: '14217',
      },
      {
        name: '綾瀬市',
        code: '14218',
      },
    ],
  },
  {
    pref: '新潟県',
    sort: '15',
    cities: [
      {
        name: '新潟市江南区',
        code: '15104',
      },
      {
        name: '新潟市秋葉区',
        code: '15105',
      },
      {
        name: '新潟市南区',
        code: '15106',
      },
      {
        name: '新潟市西蒲区',
        code: '15108',
      },
      {
        name: '三島郡出雲崎町',
        code: '15405',
      },
      {
        name: '長岡市',
        code: '15202',
      },
      {
        name: '三条市',
        code: '15204',
      },
      {
        name: '柏崎市',
        code: '15205',
      },
      {
        name: '小千谷市',
        code: '15208',
      },
      {
        name: '見附市',
        code: '15211',
      },
      {
        name: '村上市',
        code: '15212',
      },
      {
        name: '燕市',
        code: '15213',
      },
      {
        name: '糸魚川市',
        code: '15216',
      },
      {
        name: '上越市',
        code: '15222',
      },
      {
        name: '阿賀野市',
        code: '15223',
      },
      {
        name: '佐渡市',
        code: '15224',
      },
      {
        name: '胎内市',
        code: '15227',
      },
      {
        name: '刈羽郡刈羽村',
        code: '15504',
      },
      {
        name: '北蒲原郡聖籠町',
        code: '15307',
      },
      {
        name: '岩船郡関川村',
        code: '15581',
      },
      {
        name: '岩船郡粟島浦村',
        code: '15586',
      },
      {
        name: '新潟市東区',
        code: '15102',
      },
      {
        name: '新潟市中央区',
        code: '15103',
      },
      {
        name: '南蒲原郡田上町',
        code: '15361',
      },
      {
        name: '新潟市西区',
        code: '15107',
      },
      {
        name: '東蒲原郡阿賀町',
        code: '15385',
      },
      {
        name: '南魚沼郡湯沢町',
        code: '15461',
      },
      {
        name: '新発田市',
        code: '15206',
      },
      {
        name: '加茂市',
        code: '15209',
      },
      {
        name: '十日町市',
        code: '15210',
      },
      {
        name: '妙高市',
        code: '15217',
      },
      {
        name: '五泉市',
        code: '15218',
      },
      {
        name: '魚沼市',
        code: '15225',
      },
      {
        name: '南魚沼市',
        code: '15226',
      },
      {
        name: '中魚沼郡津南町',
        code: '15482',
      },
      {
        name: '西蒲原郡弥彦村',
        code: '15342',
      },
      {
        name: '新潟市北区',
        code: '15101',
      },
    ],
  },
  {
    pref: '富山県',
    sort: '16',
    cities: [
      {
        name: '富山市',
        code: '16201',
      },
      {
        name: '高岡市',
        code: '16202',
      },
      {
        name: '魚津市',
        code: '16204',
      },
      {
        name: '氷見市',
        code: '16205',
      },
      {
        name: '黒部市',
        code: '16207',
      },
      {
        name: '小矢部市',
        code: '16209',
      },
      {
        name: '射水市',
        code: '16211',
      },
      {
        name: '中新川郡舟橋村',
        code: '16321',
      },
      {
        name: '下新川郡入善町',
        code: '16342',
      },
      {
        name: '下新川郡朝日町',
        code: '16343',
      },
      {
        name: '滑川市',
        code: '16206',
      },
      {
        name: '砺波市',
        code: '16208',
      },
      {
        name: '南砺市',
        code: '16210',
      },
      {
        name: '中新川郡上市町',
        code: '16322',
      },
      {
        name: '中新川郡立山町',
        code: '16323',
      },
    ],
  },
  {
    pref: '石川県',
    sort: '17',
    cities: [
      {
        name: '金沢市',
        code: '17201',
      },
      {
        name: '七尾市',
        code: '17202',
      },
      {
        name: '小松市',
        code: '17203',
      },
      {
        name: '輪島市',
        code: '17204',
      },
      {
        name: '珠洲市',
        code: '17205',
      },
      {
        name: '鳳珠郡穴水町',
        code: '17461',
      },
      {
        name: '加賀市',
        code: '17206',
      },
      {
        name: '羽咋市',
        code: '17207',
      },
      {
        name: '鳳珠郡能登町',
        code: '17463',
      },
      {
        name: '白山市',
        code: '17210',
      },
      {
        name: '能美市',
        code: '17211',
      },
      {
        name: '河北郡内灘町',
        code: '17365',
      },
      {
        name: '羽咋郡志賀町',
        code: '17384',
      },
      {
        name: '羽咋郡宝達志水町',
        code: '17386',
      },
      {
        name: '鹿島郡中能登町',
        code: '17407',
      },
      {
        name: 'かほく市',
        code: '17209',
      },
      {
        name: '野々市市',
        code: '17212',
      },
      {
        name: '能美郡川北町',
        code: '17324',
      },
      {
        name: '河北郡津幡町',
        code: '17361',
      },
    ],
  },
  {
    pref: '福井県',
    sort: '18',
    cities: [
      {
        name: '三方郡美浜町',
        code: '18442',
      },
      {
        name: '福井市',
        code: '18201',
      },
      {
        name: '敦賀市',
        code: '18202',
      },
      {
        name: '小浜市',
        code: '18204',
      },
      {
        name: '大野市',
        code: '18205',
      },
      {
        name: '勝山市',
        code: '18206',
      },
      {
        name: 'あわら市',
        code: '18208',
      },
      {
        name: '坂井市',
        code: '18210',
      },
      {
        name: '大飯郡高浜町',
        code: '18481',
      },
      {
        name: '大飯郡おおい町',
        code: '18483',
      },
      {
        name: '三方上中郡若狭町',
        code: '18501',
      },
      {
        name: '今立郡池田町',
        code: '18382',
      },
      {
        name: '南条郡南越前町',
        code: '18404',
      },
      {
        name: '丹生郡越前町',
        code: '18423',
      },
      {
        name: '鯖江市',
        code: '18207',
      },
      {
        name: '越前市',
        code: '18209',
      },
      {
        name: '吉田郡永平寺町',
        code: '18322',
      },
    ],
  },
  {
    pref: '山梨県',
    sort: '19',
    cities: [
      {
        name: '甲府市',
        code: '19201',
      },
      {
        name: '富士吉田市',
        code: '19202',
      },
      {
        name: '都留市',
        code: '19204',
      },
      {
        name: '大月市',
        code: '19206',
      },
      {
        name: '甲州市',
        code: '19213',
      },
      {
        name: '南巨摩郡早川町',
        code: '19364',
      },
      {
        name: '南巨摩郡身延町',
        code: '19365',
      },
      {
        name: '南巨摩郡南部町',
        code: '19366',
      },
      {
        name: '中巨摩郡昭和町',
        code: '19384',
      },
      {
        name: '南都留郡西桂町',
        code: '19423',
      },
      {
        name: '南都留郡忍野村',
        code: '19424',
      },
      {
        name: '南都留郡山中湖村',
        code: '19425',
      },
      {
        name: '南都留郡鳴沢村',
        code: '19429',
      },
      {
        name: '南都留郡富士河口湖町',
        code: '19430',
      },
      {
        name: '北都留郡小菅村',
        code: '19442',
      },
      {
        name: '山梨市',
        code: '19205',
      },
      {
        name: '韮崎市',
        code: '19207',
      },
      {
        name: '南アルプス市',
        code: '19208',
      },
      {
        name: '北杜市',
        code: '19209',
      },
      {
        name: '甲斐市',
        code: '19210',
      },
      {
        name: '笛吹市',
        code: '19211',
      },
      {
        name: '上野原市',
        code: '19212',
      },
      {
        name: '中央市',
        code: '19214',
      },
      {
        name: '西八代郡市川三郷町',
        code: '19346',
      },
      {
        name: '南巨摩郡富士川町',
        code: '19368',
      },
      {
        name: '南都留郡道志村',
        code: '19422',
      },
      {
        name: '北都留郡丹波山村',
        code: '19443',
      },
    ],
  },
  {
    pref: '長野県',
    sort: '20',
    cities: [
      {
        name: '北安曇郡池田町',
        code: '20481',
      },
      {
        name: '北安曇郡小谷村',
        code: '20486',
      },
      {
        name: '上高井郡小布施町',
        code: '20541',
      },
      {
        name: '上高井郡高山村',
        code: '20543',
      },
      {
        name: '南佐久郡小海町',
        code: '20303',
      },
      {
        name: '下高井郡木島平村',
        code: '20562',
      },
      {
        name: '南佐久郡北相木村',
        code: '20307',
      },
      {
        name: '北佐久郡御代田町',
        code: '20323',
      },
      {
        name: '上水内郡飯綱町',
        code: '20590',
      },
      {
        name: '下水内郡栄村',
        code: '20602',
      },
      {
        name: '諏訪郡富士見町',
        code: '20362',
      },
      {
        name: '諏訪郡原村',
        code: '20363',
      },
      {
        name: '上伊那郡箕輪町',
        code: '20383',
      },
      {
        name: '上伊那郡飯島町',
        code: '20384',
      },
      {
        name: '上伊那郡南箕輪村',
        code: '20385',
      },
      {
        name: '下伊那郡高森町',
        code: '20403',
      },
      {
        name: '下伊那郡平谷村',
        code: '20409',
      },
      {
        name: '下伊那郡根羽村',
        code: '20410',
      },
      {
        name: '下伊那郡売木村',
        code: '20412',
      },
      {
        name: '下伊那郡天龍村',
        code: '20413',
      },
      {
        name: '下伊那郡喬木村',
        code: '20415',
      },
      {
        name: '木曽郡大桑村',
        code: '20430',
      },
      {
        name: '木曽郡木曽町',
        code: '20432',
      },
      {
        name: '東筑摩郡生坂村',
        code: '20448',
      },
      {
        name: '東筑摩郡朝日村',
        code: '20451',
      },
      {
        name: '東筑摩郡筑北村',
        code: '20452',
      },
      {
        name: '飯田市',
        code: '20205',
      },
      {
        name: '須坂市',
        code: '20207',
      },
      {
        name: '伊那市',
        code: '20209',
      },
      {
        name: '駒ヶ根市',
        code: '20210',
      },
      {
        name: '中野市',
        code: '20211',
      },
      {
        name: '大町市',
        code: '20212',
      },
      {
        name: '佐久市',
        code: '20217',
      },
      {
        name: '東御市',
        code: '20219',
      },
      {
        name: '北安曇郡松川村',
        code: '20482',
      },
      {
        name: '北安曇郡白馬村',
        code: '20485',
      },
      {
        name: '埴科郡坂城町',
        code: '20521',
      },
      {
        name: '南佐久郡川上村',
        code: '20304',
      },
      {
        name: '南佐久郡南牧村',
        code: '20305',
      },
      {
        name: '下高井郡山ノ内町',
        code: '20561',
      },
      {
        name: '南佐久郡南相木村',
        code: '20306',
      },
      {
        name: '下高井郡野沢温泉村',
        code: '20563',
      },
      {
        name: '南佐久郡佐久穂町',
        code: '20309',
      },
      {
        name: '北佐久郡軽井沢町',
        code: '20321',
      },
      {
        name: '北佐久郡立科町',
        code: '20324',
      },
      {
        name: '上水内郡信濃町',
        code: '20583',
      },
      {
        name: '上水内郡小川村',
        code: '20588',
      },
      {
        name: '小県郡青木村',
        code: '20349',
      },
      {
        name: '小県郡長和町',
        code: '20350',
      },
      {
        name: '諏訪郡下諏訪町',
        code: '20361',
      },
      {
        name: '上伊那郡辰野町',
        code: '20382',
      },
      {
        name: '上伊那郡中川村',
        code: '20386',
      },
      {
        name: '上伊那郡宮田村',
        code: '20388',
      },
      {
        name: '下伊那郡松川町',
        code: '20402',
      },
      {
        name: '下伊那郡阿南町',
        code: '20404',
      },
      {
        name: '下伊那郡阿智村',
        code: '20407',
      },
      {
        name: '下伊那郡下條村',
        code: '20411',
      },
      {
        name: '下伊那郡泰阜村',
        code: '20414',
      },
      {
        name: '下伊那郡豊丘村',
        code: '20416',
      },
      {
        name: '下伊那郡大鹿村',
        code: '20417',
      },
      {
        name: '木曽郡上松町',
        code: '20422',
      },
      {
        name: '木曽郡南木曽町',
        code: '20423',
      },
      {
        name: '木曽郡木祖村',
        code: '20425',
      },
      {
        name: '木曽郡王滝村',
        code: '20429',
      },
      {
        name: '東筑摩郡麻績村',
        code: '20446',
      },
      {
        name: '東筑摩郡山形村',
        code: '20450',
      },
      {
        name: '長野市',
        code: '20201',
      },
      {
        name: '松本市',
        code: '20202',
      },
      {
        name: '上田市',
        code: '20203',
      },
      {
        name: '岡谷市',
        code: '20204',
      },
      {
        name: '諏訪市',
        code: '20206',
      },
      {
        name: '小諸市',
        code: '20208',
      },
      {
        name: '飯山市',
        code: '20213',
      },
      {
        name: '茅野市',
        code: '20214',
      },
      {
        name: '塩尻市',
        code: '20215',
      },
      {
        name: '千曲市',
        code: '20218',
      },
      {
        name: '安曇野市',
        code: '20220',
      },
    ],
  },
  {
    pref: '岐阜県',
    sort: '21',
    cities: [
      {
        name: '加茂郡七宗町',
        code: '21504',
      },
      {
        name: '加茂郡八百津町',
        code: '21505',
      },
      {
        name: '加茂郡白川町',
        code: '21506',
      },
      {
        name: '可児郡御嵩町',
        code: '21521',
      },
      {
        name: '羽島郡岐南町',
        code: '21302',
      },
      {
        name: '羽島郡笠松町',
        code: '21303',
      },
      {
        name: '養老郡養老町',
        code: '21341',
      },
      {
        name: '不破郡垂井町',
        code: '21361',
      },
      {
        name: '不破郡関ケ原町',
        code: '21362',
      },
      {
        name: '安八郡安八町',
        code: '21383',
      },
      {
        name: '揖斐郡揖斐川町',
        code: '21401',
      },
      {
        name: '揖斐郡大野町',
        code: '21403',
      },
      {
        name: '大垣市',
        code: '21202',
      },
      {
        name: '高山市',
        code: '21203',
      },
      {
        name: '関市',
        code: '21205',
      },
      {
        name: '中津川市',
        code: '21206',
      },
      {
        name: '瑞浪市',
        code: '21208',
      },
      {
        name: '恵那市',
        code: '21210',
      },
      {
        name: '土岐市',
        code: '21212',
      },
      {
        name: '各務原市',
        code: '21213',
      },
      {
        name: '可児市',
        code: '21214',
      },
      {
        name: '山県市',
        code: '21215',
      },
      {
        name: '瑞穂市',
        code: '21216',
      },
      {
        name: '飛騨市',
        code: '21217',
      },
      {
        name: '本巣市',
        code: '21218',
      },
      {
        name: '郡上市',
        code: '21219',
      },
      {
        name: '加茂郡川辺町',
        code: '21503',
      },
      {
        name: '加茂郡東白川村',
        code: '21507',
      },
      {
        name: '大野郡白川村',
        code: '21604',
      },
      {
        name: '安八郡神戸町',
        code: '21381',
      },
      {
        name: '安八郡輪之内町',
        code: '21382',
      },
      {
        name: '揖斐郡池田町',
        code: '21404',
      },
      {
        name: '本巣郡北方町',
        code: '21421',
      },
      {
        name: '岐阜市',
        code: '21201',
      },
      {
        name: '多治見市',
        code: '21204',
      },
      {
        name: '美濃市',
        code: '21207',
      },
      {
        name: '羽島市',
        code: '21209',
      },
      {
        name: '美濃加茂市',
        code: '21211',
      },
      {
        name: '下呂市',
        code: '21220',
      },
      {
        name: '海津市',
        code: '21221',
      },
      {
        name: '加茂郡坂祝町',
        code: '21501',
      },
      {
        name: '加茂郡富加町',
        code: '21502',
      },
    ],
  },
  {
    pref: '静岡県',
    sort: '22',
    cities: [
      {
        name: '賀茂郡東伊豆町',
        code: '22301',
      },
      {
        name: '賀茂郡河津町',
        code: '22302',
      },
      {
        name: '賀茂郡南伊豆町',
        code: '22304',
      },
      {
        name: '賀茂郡松崎町',
        code: '22305',
      },
      {
        name: '賀茂郡西伊豆町',
        code: '22306',
      },
      {
        name: '駿東郡清水町',
        code: '22341',
      },
      {
        name: '駿東郡長泉町',
        code: '22342',
      },
      {
        name: '駿東郡小山町',
        code: '22344',
      },
      {
        name: '静岡市葵区',
        code: '22101',
      },
      {
        name: '静岡市駿河区',
        code: '22102',
      },
      {
        name: '静岡市清水区',
        code: '22103',
      },
      {
        name: '浜松市中区',
        code: '22131',
      },
      {
        name: '浜松市東区',
        code: '22132',
      },
      {
        name: '浜松市天竜区',
        code: '22137',
      },
      {
        name: '榛原郡川根本町',
        code: '22429',
      },
      {
        name: '沼津市',
        code: '22203',
      },
      {
        name: '熱海市',
        code: '22205',
      },
      {
        name: '富士宮市',
        code: '22207',
      },
      {
        name: '伊東市',
        code: '22208',
      },
      {
        name: '島田市',
        code: '22209',
      },
      {
        name: '富士市',
        code: '22210',
      },
      {
        name: '磐田市',
        code: '22211',
      },
      {
        name: '焼津市',
        code: '22212',
      },
      {
        name: '掛川市',
        code: '22213',
      },
      {
        name: '下田市',
        code: '22219',
      },
      {
        name: '裾野市',
        code: '22220',
      },
      {
        name: '湖西市',
        code: '22221',
      },
      {
        name: '伊豆市',
        code: '22222',
      },
      {
        name: '御前崎市',
        code: '22223',
      },
      {
        name: '菊川市',
        code: '22224',
      },
      {
        name: '伊豆の国市',
        code: '22225',
      },
      {
        name: '牧之原市',
        code: '22226',
      },
      {
        name: '田方郡函南町',
        code: '22325',
      },
      {
        name: '浜松市西区',
        code: '22133',
      },
      {
        name: '浜松市南区',
        code: '22134',
      },
      {
        name: '浜松市北区',
        code: '22135',
      },
      {
        name: '浜松市浜北区',
        code: '22136',
      },
      {
        name: '榛原郡吉田町',
        code: '22424',
      },
      {
        name: '周智郡森町',
        code: '22461',
      },
      {
        name: '三島市',
        code: '22206',
      },
      {
        name: '藤枝市',
        code: '22214',
      },
      {
        name: '御殿場市',
        code: '22215',
      },
      {
        name: '袋井市',
        code: '22216',
      },
    ],
  },
  {
    pref: '愛知県',
    sort: '23',
    cities: [
      {
        name: '北設楽郡豊根村',
        code: '23563',
      },
      {
        name: '名古屋市東区',
        code: '23102',
      },
      {
        name: '名古屋市北区',
        code: '23103',
      },
      {
        name: '名古屋市中村区',
        code: '23105',
      },
      {
        name: '丹羽郡扶桑町',
        code: '23362',
      },
      {
        name: '名古屋市昭和区',
        code: '23107',
      },
      {
        name: '名古屋市瑞穂区',
        code: '23108',
      },
      {
        name: '名古屋市中川区',
        code: '23110',
      },
      {
        name: '名古屋市港区',
        code: '23111',
      },
      {
        name: '名古屋市名東区',
        code: '23115',
      },
      {
        name: '海部郡大治町',
        code: '23424',
      },
      {
        name: '海部郡飛島村',
        code: '23427',
      },
      {
        name: '知多郡南知多町',
        code: '23445',
      },
      {
        name: '豊橋市',
        code: '23201',
      },
      {
        name: '春日井市',
        code: '23206',
      },
      {
        name: '豊川市',
        code: '23207',
      },
      {
        name: '豊田市',
        code: '23211',
      },
      {
        name: '安城市',
        code: '23212',
      },
      {
        name: '西尾市',
        code: '23213',
      },
      {
        name: '蒲郡市',
        code: '23214',
      },
      {
        name: '犬山市',
        code: '23215',
      },
      {
        name: '常滑市',
        code: '23216',
      },
      {
        name: '小牧市',
        code: '23219',
      },
      {
        name: '稲沢市',
        code: '23220',
      },
      {
        name: '東海市',
        code: '23222',
      },
      {
        name: '知多市',
        code: '23224',
      },
      {
        name: '知立市',
        code: '23225',
      },
      {
        name: '高浜市',
        code: '23227',
      },
      {
        name: '岩倉市',
        code: '23228',
      },
      {
        name: '日進市',
        code: '23230',
      },
      {
        name: '田原市',
        code: '23231',
      },
      {
        name: '北名古屋市',
        code: '23234',
      },
      {
        name: '弥富市',
        code: '23235',
      },
      {
        name: 'あま市',
        code: '23237',
      },
      {
        name: '額田郡幸田町',
        code: '23501',
      },
      {
        name: '愛知郡東郷町',
        code: '23302',
      },
      {
        name: '北設楽郡設楽町',
        code: '23561',
      },
      {
        name: '北設楽郡東栄町',
        code: '23562',
      },
      {
        name: '西春日井郡豊山町',
        code: '23342',
      },
      {
        name: '名古屋市千種区',
        code: '23101',
      },
      {
        name: '名古屋市西区',
        code: '23104',
      },
      {
        name: '丹羽郡大口町',
        code: '23361',
      },
      {
        name: '名古屋市中区',
        code: '23106',
      },
      {
        name: '名古屋市熱田区',
        code: '23109',
      },
      {
        name: '名古屋市南区',
        code: '23112',
      },
      {
        name: '名古屋市守山区',
        code: '23113',
      },
      {
        name: '名古屋市緑区',
        code: '23114',
      },
      {
        name: '名古屋市天白区',
        code: '23116',
      },
      {
        name: '海部郡蟹江町',
        code: '23425',
      },
      {
        name: '知多郡阿久比町',
        code: '23441',
      },
      {
        name: '知多郡東浦町',
        code: '23442',
      },
      {
        name: '知多郡美浜町',
        code: '23446',
      },
      {
        name: '知多郡武豊町',
        code: '23447',
      },
      {
        name: '岡崎市',
        code: '23202',
      },
      {
        name: '一宮市',
        code: '23203',
      },
      {
        name: '瀬戸市',
        code: '23204',
      },
      {
        name: '半田市',
        code: '23205',
      },
      {
        name: '津島市',
        code: '23208',
      },
      {
        name: '碧南市',
        code: '23209',
      },
      {
        name: '刈谷市',
        code: '23210',
      },
      {
        name: '江南市',
        code: '23217',
      },
      {
        name: '新城市',
        code: '23221',
      },
      {
        name: '大府市',
        code: '23223',
      },
      {
        name: '尾張旭市',
        code: '23226',
      },
      {
        name: '豊明市',
        code: '23229',
      },
      {
        name: '愛西市',
        code: '23232',
      },
      {
        name: '清須市',
        code: '23233',
      },
      {
        name: 'みよし市',
        code: '23236',
      },
      {
        name: '長久手市',
        code: '23238',
      },
    ],
  },
  {
    pref: '三重県',
    sort: '24',
    cities: [
      {
        name: '員弁郡東員町',
        code: '24324',
      },
      {
        name: '三重郡朝日町',
        code: '24343',
      },
      {
        name: '三重郡川越町',
        code: '24344',
      },
      {
        name: '多気郡明和町',
        code: '24442',
      },
      {
        name: '四日市市',
        code: '24202',
      },
      {
        name: '伊勢市',
        code: '24203',
      },
      {
        name: '松阪市',
        code: '24204',
      },
      {
        name: '桑名市',
        code: '24205',
      },
      {
        name: '鈴鹿市',
        code: '24207',
      },
      {
        name: '尾鷲市',
        code: '24209',
      },
      {
        name: '鳥羽市',
        code: '24211',
      },
      {
        name: '熊野市',
        code: '24212',
      },
      {
        name: 'いなべ市',
        code: '24214',
      },
      {
        name: '度会郡度会町',
        code: '24470',
      },
      {
        name: '志摩市',
        code: '24215',
      },
      {
        name: '度会郡大紀町',
        code: '24471',
      },
      {
        name: '伊賀市',
        code: '24216',
      },
      {
        name: '度会郡南伊勢町',
        code: '24472',
      },
      {
        name: '北牟婁郡紀北町',
        code: '24543',
      },
      {
        name: '南牟婁郡御浜町',
        code: '24561',
      },
      {
        name: '南牟婁郡紀宝町',
        code: '24562',
      },
      {
        name: '三重郡菰野町',
        code: '24341',
      },
      {
        name: '多気郡多気町',
        code: '24441',
      },
      {
        name: '多気郡大台町',
        code: '24443',
      },
      {
        name: '津市',
        code: '24201',
      },
      {
        name: '度会郡玉城町',
        code: '24461',
      },
      {
        name: '名張市',
        code: '24208',
      },
      {
        name: '亀山市',
        code: '24210',
      },
      {
        name: '桑名郡木曽岬町',
        code: '24303',
      },
    ],
  },
  {
    pref: '滋賀県',
    sort: '25',
    cities: [
      {
        name: '蒲生郡日野町',
        code: '25383',
      },
      {
        name: '愛知郡愛荘町',
        code: '25425',
      },
      {
        name: '犬上郡豊郷町',
        code: '25441',
      },
      {
        name: '犬上郡多賀町',
        code: '25443',
      },
      {
        name: '彦根市',
        code: '25202',
      },
      {
        name: '近江八幡市',
        code: '25204',
      },
      {
        name: '草津市',
        code: '25206',
      },
      {
        name: '甲賀市',
        code: '25209',
      },
      {
        name: '野洲市',
        code: '25210',
      },
      {
        name: '湖南市',
        code: '25211',
      },
      {
        name: '蒲生郡竜王町',
        code: '25384',
      },
      {
        name: '犬上郡甲良町',
        code: '25442',
      },
      {
        name: '大津市',
        code: '25201',
      },
      {
        name: '長浜市',
        code: '25203',
      },
      {
        name: '守山市',
        code: '25207',
      },
      {
        name: '栗東市',
        code: '25208',
      },
      {
        name: '高島市',
        code: '25212',
      },
      {
        name: '東近江市',
        code: '25213',
      },
      {
        name: '米原市',
        code: '25214',
      },
    ],
  },
  {
    pref: '京都府',
    sort: '26',
    cities: [
      {
        name: '舞鶴市',
        code: '26202',
      },
      {
        name: '宮津市',
        code: '26205',
      },
      {
        name: '亀岡市',
        code: '26206',
      },
      {
        name: '与謝郡伊根町',
        code: '26463',
      },
      {
        name: '向日市',
        code: '26208',
      },
      {
        name: '与謝郡与謝野町',
        code: '26465',
      },
      {
        name: '八幡市',
        code: '26210',
      },
      {
        name: '京丹後市',
        code: '26212',
      },
      {
        name: '乙訓郡大山崎町',
        code: '26303',
      },
      {
        name: '久世郡久御山町',
        code: '26322',
      },
      {
        name: '綴喜郡宇治田原町',
        code: '26344',
      },
      {
        name: '京都市北区',
        code: '26101',
      },
      {
        name: '京都市下京区',
        code: '26106',
      },
      {
        name: '京都市南区',
        code: '26107',
      },
      {
        name: '京都市右京区',
        code: '26108',
      },
      {
        name: '相楽郡笠置町',
        code: '26364',
      },
      {
        name: '相楽郡和束町',
        code: '26365',
      },
      {
        name: '相楽郡南山城村',
        code: '26367',
      },
      {
        name: '船井郡京丹波町',
        code: '26407',
      },
      {
        name: '福知山市',
        code: '26201',
      },
      {
        name: '綾部市',
        code: '26203',
      },
      {
        name: '宇治市',
        code: '26204',
      },
      {
        name: '城陽市',
        code: '26207',
      },
      {
        name: '長岡京市',
        code: '26209',
      },
      {
        name: '京田辺市',
        code: '26211',
      },
      {
        name: '南丹市',
        code: '26213',
      },
      {
        name: '木津川市',
        code: '26214',
      },
      {
        name: '綴喜郡井手町',
        code: '26343',
      },
      {
        name: '京都市上京区',
        code: '26102',
      },
      {
        name: '京都市左京区',
        code: '26103',
      },
      {
        name: '京都市中京区',
        code: '26104',
      },
      {
        name: '京都市東山区',
        code: '26105',
      },
      {
        name: '京都市伏見区',
        code: '26109',
      },
      {
        name: '京都市山科区',
        code: '26110',
      },
      {
        name: '相楽郡精華町',
        code: '26366',
      },
      {
        name: '京都市西京区',
        code: '26111',
      },
    ],
  },
  {
    pref: '大阪府',
    sort: '27',
    cities: [
      {
        name: '堺市堺区',
        code: '27141',
      },
      {
        name: '堺市西区',
        code: '27144',
      },
      {
        name: '堺市南区',
        code: '27145',
      },
      {
        name: '堺市北区',
        code: '27146',
      },
      {
        name: '岸和田市',
        code: '27202',
      },
      {
        name: '豊中市',
        code: '27203',
      },
      {
        name: '吹田市',
        code: '27205',
      },
      {
        name: '貝塚市',
        code: '27208',
      },
      {
        name: '枚方市',
        code: '27210',
      },
      {
        name: '茨木市',
        code: '27211',
      },
      {
        name: '泉佐野市',
        code: '27213',
      },
      {
        name: '寝屋川市',
        code: '27215',
      },
      {
        name: '河内長野市',
        code: '27216',
      },
      {
        name: '大東市',
        code: '27218',
      },
      {
        name: '門真市',
        code: '27223',
      },
      {
        name: '摂津市',
        code: '27224',
      },
      {
        name: '高石市',
        code: '27225',
      },
      {
        name: '東大阪市',
        code: '27227',
      },
      {
        name: '泉南市',
        code: '27228',
      },
      {
        name: '四條畷市',
        code: '27229',
      },
      {
        name: '阪南市',
        code: '27232',
      },
      {
        name: '豊能郡豊能町',
        code: '27321',
      },
      {
        name: '豊能郡能勢町',
        code: '27322',
      },
      {
        name: '大阪市福島区',
        code: '27103',
      },
      {
        name: '大阪市此花区',
        code: '27104',
      },
      {
        name: '泉南郡田尻町',
        code: '27362',
      },
      {
        name: '大阪市天王寺区',
        code: '27109',
      },
      {
        name: '泉南郡岬町',
        code: '27366',
      },
      {
        name: '大阪市東淀川区',
        code: '27114',
      },
      {
        name: '大阪市城東区',
        code: '27118',
      },
      {
        name: '大阪市東住吉区',
        code: '27121',
      },
      {
        name: '大阪市西成区',
        code: '27122',
      },
      {
        name: '大阪市鶴見区',
        code: '27124',
      },
      {
        name: '大阪市住之江区',
        code: '27125',
      },
      {
        name: '南河内郡太子町',
        code: '27381',
      },
      {
        name: '大阪市平野区',
        code: '27126',
      },
      {
        name: '南河内郡河南町',
        code: '27382',
      },
      {
        name: '大阪市北区',
        code: '27127',
      },
      {
        name: '南河内郡千早赤阪村',
        code: '27383',
      },
      {
        name: '堺市中区',
        code: '27142',
      },
      {
        name: '堺市東区',
        code: '27143',
      },
      {
        name: '堺市美原区',
        code: '27147',
      },
      {
        name: '池田市',
        code: '27204',
      },
      {
        name: '泉大津市',
        code: '27206',
      },
      {
        name: '高槻市',
        code: '27207',
      },
      {
        name: '守口市',
        code: '27209',
      },
      {
        name: '八尾市',
        code: '27212',
      },
      {
        name: '富田林市',
        code: '27214',
      },
      {
        name: '松原市',
        code: '27217',
      },
      {
        name: '和泉市',
        code: '27219',
      },
      {
        name: '箕面市',
        code: '27220',
      },
      {
        name: '柏原市',
        code: '27221',
      },
      {
        name: '羽曳野市',
        code: '27222',
      },
      {
        name: '藤井寺市',
        code: '27226',
      },
      {
        name: '交野市',
        code: '27230',
      },
      {
        name: '大阪狭山市',
        code: '27231',
      },
      {
        name: '三島郡島本町',
        code: '27301',
      },
      {
        name: '泉北郡忠岡町',
        code: '27341',
      },
      {
        name: '大阪市都島区',
        code: '27102',
      },
      {
        name: '泉南郡熊取町',
        code: '27361',
      },
      {
        name: '大阪市西区',
        code: '27106',
      },
      {
        name: '大阪市港区',
        code: '27107',
      },
      {
        name: '大阪市大正区',
        code: '27108',
      },
      {
        name: '大阪市浪速区',
        code: '27111',
      },
      {
        name: '大阪市西淀川区',
        code: '27113',
      },
      {
        name: '大阪市東成区',
        code: '27115',
      },
      {
        name: '大阪市生野区',
        code: '27116',
      },
      {
        name: '大阪市旭区',
        code: '27117',
      },
      {
        name: '大阪市阿倍野区',
        code: '27119',
      },
      {
        name: '大阪市住吉区',
        code: '27120',
      },
      {
        name: '大阪市淀川区',
        code: '27123',
      },
      {
        name: '大阪市中央区',
        code: '27128',
      },
    ],
  },
  {
    pref: '兵庫県',
    sort: '28',
    cities: [
      {
        name: '神崎郡福崎町',
        code: '28443',
      },
      {
        name: '姫路市',
        code: '28201',
      },
      {
        name: '尼崎市',
        code: '28202',
      },
      {
        name: '明石市',
        code: '28203',
      },
      {
        name: '西宮市',
        code: '28204',
      },
      {
        name: '洲本市',
        code: '28205',
      },
      {
        name: '芦屋市',
        code: '28206',
      },
      {
        name: '伊丹市',
        code: '28207',
      },
      {
        name: '相生市',
        code: '28208',
      },
      {
        name: '揖保郡太子町',
        code: '28464',
      },
      {
        name: '豊岡市',
        code: '28209',
      },
      {
        name: '加古川市',
        code: '28210',
      },
      {
        name: '赤穂市',
        code: '28212',
      },
      {
        name: '宝塚市',
        code: '28214',
      },
      {
        name: '川西市',
        code: '28217',
      },
      {
        name: '三田市',
        code: '28219',
      },
      {
        name: '丹波市',
        code: '28223',
      },
      {
        name: '南あわじ市',
        code: '28224',
      },
      {
        name: '朝来市',
        code: '28225',
      },
      {
        name: '赤穂郡上郡町',
        code: '28481',
      },
      {
        name: '淡路市',
        code: '28226',
      },
      {
        name: '加東市',
        code: '28228',
      },
      {
        name: 'たつの市',
        code: '28229',
      },
      {
        name: '佐用郡佐用町',
        code: '28501',
      },
      {
        name: '川辺郡猪名川町',
        code: '28301',
      },
      {
        name: '美方郡香美町',
        code: '28585',
      },
      {
        name: '美方郡新温泉町',
        code: '28586',
      },
      {
        name: '神戸市東灘区',
        code: '28101',
      },
      {
        name: '神戸市灘区',
        code: '28102',
      },
      {
        name: '神戸市長田区',
        code: '28106',
      },
      {
        name: '神戸市須磨区',
        code: '28107',
      },
      {
        name: '神戸市垂水区',
        code: '28108',
      },
      {
        name: '神戸市北区',
        code: '28109',
      },
      {
        name: '神戸市中央区',
        code: '28110',
      },
      {
        name: '加古郡稲美町',
        code: '28381',
      },
      {
        name: '加古郡播磨町',
        code: '28382',
      },
      {
        name: '神崎郡市川町',
        code: '28442',
      },
      {
        name: '神崎郡神河町',
        code: '28446',
      },
      {
        name: '西脇市',
        code: '28213',
      },
      {
        name: '三木市',
        code: '28215',
      },
      {
        name: '高砂市',
        code: '28216',
      },
      {
        name: '小野市',
        code: '28218',
      },
      {
        name: '加西市',
        code: '28220',
      },
      {
        name: '篠山市',
        code: '28221',
      },
      {
        name: '養父市',
        code: '28222',
      },
      {
        name: '宍粟市',
        code: '28227',
      },
      {
        name: '神戸市兵庫区',
        code: '28105',
      },
      {
        name: '多可郡多可町',
        code: '28365',
      },
      {
        name: '神戸市西区',
        code: '28111',
      },
    ],
  },
  {
    pref: '奈良県',
    sort: '29',
    cities: [
      {
        name: '吉野郡吉野町',
        code: '29441',
      },
      {
        name: '吉野郡大淀町',
        code: '29442',
      },
      {
        name: '吉野郡野迫川村',
        code: '29447',
      },
      {
        name: '吉野郡十津川村',
        code: '29449',
      },
      {
        name: '吉野郡下北山村',
        code: '29450',
      },
      {
        name: '吉野郡上北山村',
        code: '29451',
      },
      {
        name: '吉野郡川上村',
        code: '29452',
      },
      {
        name: '吉野郡東吉野村',
        code: '29453',
      },
      {
        name: '橿原市',
        code: '29205',
      },
      {
        name: '桜井市',
        code: '29206',
      },
      {
        name: '五條市',
        code: '29207',
      },
      {
        name: '御所市',
        code: '29208',
      },
      {
        name: '生駒市',
        code: '29209',
      },
      {
        name: '山辺郡山添村',
        code: '29322',
      },
      {
        name: '生駒郡三郷町',
        code: '29343',
      },
      {
        name: '生駒郡安堵町',
        code: '29345',
      },
      {
        name: '磯城郡三宅町',
        code: '29362',
      },
      {
        name: '磯城郡田原本町',
        code: '29363',
      },
      {
        name: '宇陀郡曽爾村',
        code: '29385',
      },
      {
        name: '宇陀郡御杖村',
        code: '29386',
      },
      {
        name: '北葛城郡上牧町',
        code: '29424',
      },
      {
        name: '北葛城郡河合町',
        code: '29427',
      },
      {
        name: '吉野郡下市町',
        code: '29443',
      },
      {
        name: '吉野郡黒滝村',
        code: '29444',
      },
      {
        name: '吉野郡天川村',
        code: '29446',
      },
      {
        name: '奈良市',
        code: '29201',
      },
      {
        name: '大和高田市',
        code: '29202',
      },
      {
        name: '大和郡山市',
        code: '29203',
      },
      {
        name: '天理市',
        code: '29204',
      },
      {
        name: '香芝市',
        code: '29210',
      },
      {
        name: '葛城市',
        code: '29211',
      },
      {
        name: '宇陀市',
        code: '29212',
      },
      {
        name: '生駒郡平群町',
        code: '29342',
      },
      {
        name: '生駒郡斑鳩町',
        code: '29344',
      },
      {
        name: '磯城郡川西町',
        code: '29361',
      },
      {
        name: '高市郡高取町',
        code: '29401',
      },
      {
        name: '高市郡明日香村',
        code: '29402',
      },
      {
        name: '北葛城郡王寺町',
        code: '29425',
      },
      {
        name: '北葛城郡広陵町',
        code: '29426',
      },
    ],
  },
  {
    pref: '和歌山県',
    sort: '30',
    cities: [
      {
        name: '岩出市',
        code: '30209',
      },
      {
        name: '伊都郡かつらぎ町',
        code: '30341',
      },
      {
        name: '伊都郡高野町',
        code: '30344',
      },
      {
        name: '有田郡湯浅町',
        code: '30361',
      },
      {
        name: '有田郡広川町',
        code: '30362',
      },
      {
        name: '日高郡美浜町',
        code: '30381',
      },
      {
        name: '日高郡日高町',
        code: '30382',
      },
      {
        name: '日高郡由良町',
        code: '30383',
      },
      {
        name: '日高郡印南町',
        code: '30390',
      },
      {
        name: '日高郡みなべ町',
        code: '30391',
      },
      {
        name: '西牟婁郡白浜町',
        code: '30401',
      },
      {
        name: '西牟婁郡上富田町',
        code: '30404',
      },
      {
        name: '西牟婁郡すさみ町',
        code: '30406',
      },
      {
        name: '東牟婁郡那智勝浦町',
        code: '30421',
      },
      {
        name: '東牟婁郡太地町',
        code: '30422',
      },
      {
        name: '東牟婁郡北山村',
        code: '30427',
      },
      {
        name: '東牟婁郡串本町',
        code: '30428',
      },
      {
        name: '和歌山市',
        code: '30201',
      },
      {
        name: '海南市',
        code: '30202',
      },
      {
        name: '橋本市',
        code: '30203',
      },
      {
        name: '有田市',
        code: '30204',
      },
      {
        name: '御坊市',
        code: '30205',
      },
      {
        name: '田辺市',
        code: '30206',
      },
      {
        name: '新宮市',
        code: '30207',
      },
      {
        name: '紀の川市',
        code: '30208',
      },
      {
        name: '海草郡紀美野町',
        code: '30304',
      },
      {
        name: '伊都郡九度山町',
        code: '30343',
      },
      {
        name: '有田郡有田川町',
        code: '30366',
      },
      {
        name: '日高郡日高川町',
        code: '30392',
      },
      {
        name: '東牟婁郡古座川町',
        code: '30424',
      },
    ],
  },
  {
    pref: '鳥取県',
    sort: '31',
    cities: [
      {
        name: '岩美郡岩美町',
        code: '31302',
      },
      {
        name: '八頭郡智頭町',
        code: '31328',
      },
      {
        name: '東伯郡湯梨浜町',
        code: '31370',
      },
      {
        name: '東伯郡琴浦町',
        code: '31371',
      },
      {
        name: '東伯郡北栄町',
        code: '31372',
      },
      {
        name: '西伯郡大山町',
        code: '31386',
      },
      {
        name: '日野郡江府町',
        code: '31403',
      },
      {
        name: '鳥取市',
        code: '31201',
      },
      {
        name: '米子市',
        code: '31202',
      },
      {
        name: '境港市',
        code: '31204',
      },
      {
        name: '八頭郡若桜町',
        code: '31325',
      },
      {
        name: '八頭郡八頭町',
        code: '31329',
      },
      {
        name: '東伯郡三朝町',
        code: '31364',
      },
      {
        name: '西伯郡日吉津村',
        code: '31384',
      },
      {
        name: '西伯郡南部町',
        code: '31389',
      },
      {
        name: '西伯郡伯耆町',
        code: '31390',
      },
      {
        name: '日野郡日南町',
        code: '31401',
      },
      {
        name: '日野郡日野町',
        code: '31402',
      },
      {
        name: '倉吉市',
        code: '31203',
      },
    ],
  },
  {
    pref: '島根県',
    sort: '32',
    cities: [
      {
        name: '隠岐郡海士町',
        code: '32525',
      },
      {
        name: '隠岐郡西ノ島町',
        code: '32526',
      },
      {
        name: '隠岐郡知夫村',
        code: '32527',
      },
      {
        name: '隠岐郡隠岐の島町',
        code: '32528',
      },
      {
        name: '邑智郡川本町',
        code: '32441',
      },
      {
        name: '邑智郡美郷町',
        code: '32448',
      },
      {
        name: '邑智郡邑南町',
        code: '32449',
      },
      {
        name: '松江市',
        code: '32201',
      },
      {
        name: '浜田市',
        code: '32202',
      },
      {
        name: '出雲市',
        code: '32203',
      },
      {
        name: '益田市',
        code: '32204',
      },
      {
        name: '大田市',
        code: '32205',
      },
      {
        name: '安来市',
        code: '32206',
      },
      {
        name: '江津市',
        code: '32207',
      },
      {
        name: '雲南市',
        code: '32209',
      },
      {
        name: '鹿足郡津和野町',
        code: '32501',
      },
      {
        name: '仁多郡奥出雲町',
        code: '32343',
      },
      {
        name: '飯石郡飯南町',
        code: '32386',
      },
      {
        name: '鹿足郡吉賀町',
        code: '32505',
      },
    ],
  },
  {
    pref: '岡山県',
    sort: '33',
    cities: [
      {
        name: '苫田郡鏡野町',
        code: '33606',
      },
      {
        name: '岡山市中区',
        code: '33102',
      },
      {
        name: '岡山市東区',
        code: '33103',
      },
      {
        name: '岡山市南区',
        code: '33104',
      },
      {
        name: '勝田郡勝央町',
        code: '33622',
      },
      {
        name: '勝田郡奈義町',
        code: '33623',
      },
      {
        name: '久米郡久米南町',
        code: '33663',
      },
      {
        name: '久米郡美咲町',
        code: '33666',
      },
      {
        name: '浅口郡里庄町',
        code: '33445',
      },
      {
        name: '倉敷市',
        code: '33202',
      },
      {
        name: '玉野市',
        code: '33204',
      },
      {
        name: '笠岡市',
        code: '33205',
      },
      {
        name: '小田郡矢掛町',
        code: '33461',
      },
      {
        name: '新見市',
        code: '33210',
      },
      {
        name: '備前市',
        code: '33211',
      },
      {
        name: '瀬戸内市',
        code: '33212',
      },
      {
        name: '浅口市',
        code: '33216',
      },
      {
        name: '真庭郡新庄村',
        code: '33586',
      },
      {
        name: '和気郡和気町',
        code: '33346',
      },
      {
        name: '岡山市北区',
        code: '33101',
      },
      {
        name: '英田郡西粟倉村',
        code: '33643',
      },
      {
        name: '都窪郡早島町',
        code: '33423',
      },
      {
        name: '加賀郡吉備中央町',
        code: '33681',
      },
      {
        name: '津山市',
        code: '33203',
      },
      {
        name: '井原市',
        code: '33207',
      },
      {
        name: '総社市',
        code: '33208',
      },
      {
        name: '高梁市',
        code: '33209',
      },
      {
        name: '赤磐市',
        code: '33213',
      },
      {
        name: '真庭市',
        code: '33214',
      },
      {
        name: '美作市',
        code: '33215',
      },
    ],
  },
  {
    pref: '広島県',
    sort: '34',
    cities: [
      {
        name: '安芸郡海田町',
        code: '34304',
      },
      {
        name: '安芸郡坂町',
        code: '34309',
      },
      {
        name: '広島市中区',
        code: '34101',
      },
      {
        name: '広島市南区',
        code: '34103',
      },
      {
        name: '広島市西区',
        code: '34104',
      },
      {
        name: '広島市安佐南区',
        code: '34105',
      },
      {
        name: '広島市安佐北区',
        code: '34106',
      },
      {
        name: '広島市安芸区',
        code: '34107',
      },
      {
        name: '広島市佐伯区',
        code: '34108',
      },
      {
        name: '山県郡安芸太田町',
        code: '34368',
      },
      {
        name: '豊田郡大崎上島町',
        code: '34431',
      },
      {
        name: '呉市',
        code: '34202',
      },
      {
        name: '竹原市',
        code: '34203',
      },
      {
        name: '三原市',
        code: '34204',
      },
      {
        name: '尾道市',
        code: '34205',
      },
      {
        name: '世羅郡世羅町',
        code: '34462',
      },
      {
        name: '福山市',
        code: '34207',
      },
      {
        name: '府中市',
        code: '34208',
      },
      {
        name: '庄原市',
        code: '34210',
      },
      {
        name: '大竹市',
        code: '34211',
      },
      {
        name: '東広島市',
        code: '34212',
      },
      {
        name: '廿日市市',
        code: '34213',
      },
      {
        name: '安芸高田市',
        code: '34214',
      },
      {
        name: '江田島市',
        code: '34215',
      },
      {
        name: '安芸郡府中町',
        code: '34302',
      },
      {
        name: '安芸郡熊野町',
        code: '34307',
      },
      {
        name: '広島市東区',
        code: '34102',
      },
      {
        name: '山県郡北広島町',
        code: '34369',
      },
      {
        name: '三次市',
        code: '34209',
      },
      {
        name: '神石郡神石高原町',
        code: '34545',
      },
    ],
  },
  {
    pref: '山口県',
    sort: '35',
    cities: [
      {
        name: '熊毛郡上関町',
        code: '35341',
      },
      {
        name: '熊毛郡田布施町',
        code: '35343',
      },
      {
        name: '熊毛郡平生町',
        code: '35344',
      },
      {
        name: '下関市',
        code: '35201',
      },
      {
        name: '宇部市',
        code: '35202',
      },
      {
        name: '山口市',
        code: '35203',
      },
      {
        name: '萩市',
        code: '35204',
      },
      {
        name: '防府市',
        code: '35206',
      },
      {
        name: '下松市',
        code: '35207',
      },
      {
        name: '岩国市',
        code: '35208',
      },
      {
        name: '光市',
        code: '35210',
      },
      {
        name: '長門市',
        code: '35211',
      },
      {
        name: '柳井市',
        code: '35212',
      },
      {
        name: '周南市',
        code: '35215',
      },
      {
        name: '山陽小野田市',
        code: '35216',
      },
      {
        name: '阿武郡阿武町',
        code: '35502',
      },
      {
        name: '大島郡周防大島町',
        code: '35305',
      },
      {
        name: '玖珂郡和木町',
        code: '35321',
      },
      {
        name: '美祢市',
        code: '35213',
      },
    ],
  },
  {
    pref: '徳島県',
    sort: '36',
    cities: [
      {
        name: '那賀郡那賀町',
        code: '36368',
      },
      {
        name: '海部郡牟岐町',
        code: '36383',
      },
      {
        name: '海部郡美波町',
        code: '36387',
      },
      {
        name: '海部郡海陽町',
        code: '36388',
      },
      {
        name: '板野郡松茂町',
        code: '36401',
      },
      {
        name: '板野郡北島町',
        code: '36402',
      },
      {
        name: '板野郡上板町',
        code: '36405',
      },
      {
        name: '徳島市',
        code: '36201',
      },
      {
        name: '鳴門市',
        code: '36202',
      },
      {
        name: '小松島市',
        code: '36203',
      },
      {
        name: '阿南市',
        code: '36204',
      },
      {
        name: '吉野川市',
        code: '36205',
      },
      {
        name: '阿波市',
        code: '36206',
      },
      {
        name: '美馬市',
        code: '36207',
      },
      {
        name: '三好市',
        code: '36208',
      },
      {
        name: '三好郡東みよし町',
        code: '36489',
      },
      {
        name: '勝浦郡勝浦町',
        code: '36301',
      },
      {
        name: '勝浦郡上勝町',
        code: '36302',
      },
      {
        name: '板野郡藍住町',
        code: '36403',
      },
      {
        name: '板野郡板野町',
        code: '36404',
      },
      {
        name: '美馬郡つるぎ町',
        code: '36468',
      },
      {
        name: '名東郡佐那河内村',
        code: '36321',
      },
      {
        name: '名西郡石井町',
        code: '36341',
      },
      {
        name: '名西郡神山町',
        code: '36342',
      },
    ],
  },
  {
    pref: '香川県',
    sort: '37',
    cities: [
      {
        name: '綾歌郡綾川町',
        code: '37387',
      },
      {
        name: '仲多度郡琴平町',
        code: '37403',
      },
      {
        name: '仲多度郡多度津町',
        code: '37404',
      },
      {
        name: '仲多度郡まんのう町',
        code: '37406',
      },
      {
        name: '高松市',
        code: '37201',
      },
      {
        name: '丸亀市',
        code: '37202',
      },
      {
        name: '坂出市',
        code: '37203',
      },
      {
        name: '観音寺市',
        code: '37205',
      },
      {
        name: 'さぬき市',
        code: '37206',
      },
      {
        name: '東かがわ市',
        code: '37207',
      },
      {
        name: '三豊市',
        code: '37208',
      },
      {
        name: '小豆郡土庄町',
        code: '37322',
      },
      {
        name: '小豆郡小豆島町',
        code: '37324',
      },
      {
        name: '香川郡直島町',
        code: '37364',
      },
      {
        name: '綾歌郡宇多津町',
        code: '37386',
      },
      {
        name: '善通寺市',
        code: '37204',
      },
      {
        name: '木田郡三木町',
        code: '37341',
      },
    ],
  },
  {
    pref: '愛媛県',
    sort: '38',
    cities: [
      {
        name: '伊予郡砥部町',
        code: '38402',
      },
      {
        name: '西宇和郡伊方町',
        code: '38442',
      },
      {
        name: '松山市',
        code: '38201',
      },
      {
        name: '今治市',
        code: '38202',
      },
      {
        name: '宇和島市',
        code: '38203',
      },
      {
        name: '八幡浜市',
        code: '38204',
      },
      {
        name: '伊予郡松前町',
        code: '38401',
      },
      {
        name: '喜多郡内子町',
        code: '38422',
      },
      {
        name: '新居浜市',
        code: '38205',
      },
      {
        name: '西条市',
        code: '38206',
      },
      {
        name: '大洲市',
        code: '38207',
      },
      {
        name: '伊予市',
        code: '38210',
      },
      {
        name: '四国中央市',
        code: '38213',
      },
      {
        name: '西予市',
        code: '38214',
      },
      {
        name: '南宇和郡愛南町',
        code: '38506',
      },
      {
        name: '越智郡上島町',
        code: '38356',
      },
      {
        name: '上浮穴郡久万高原町',
        code: '38386',
      },
      {
        name: '東温市',
        code: '38215',
      },
      {
        name: '北宇和郡松野町',
        code: '38484',
      },
      {
        name: '北宇和郡鬼北町',
        code: '38488',
      },
    ],
  },
  {
    pref: '高知県',
    sort: '39',
    cities: [
      {
        name: '幡多郡大月町',
        code: '39424',
      },
      {
        name: '幡多郡黒潮町',
        code: '39428',
      },
      {
        name: '高知市',
        code: '39201',
      },
      {
        name: '室戸市',
        code: '39202',
      },
      {
        name: '安芸市',
        code: '39203',
      },
      {
        name: '土佐市',
        code: '39205',
      },
      {
        name: '須崎市',
        code: '39206',
      },
      {
        name: '宿毛市',
        code: '39208',
      },
      {
        name: '土佐清水市',
        code: '39209',
      },
      {
        name: '幡多郡三原村',
        code: '39427',
      },
      {
        name: '南国市',
        code: '39204',
      },
      {
        name: '四万十市',
        code: '39210',
      },
      {
        name: '香南市',
        code: '39211',
      },
      {
        name: '香美市',
        code: '39212',
      },
      {
        name: '安芸郡東洋町',
        code: '39301',
      },
      {
        name: '安芸郡奈半利町',
        code: '39302',
      },
      {
        name: '安芸郡田野町',
        code: '39303',
      },
      {
        name: '安芸郡安田町',
        code: '39304',
      },
      {
        name: '安芸郡北川村',
        code: '39305',
      },
      {
        name: '安芸郡馬路村',
        code: '39306',
      },
      {
        name: '安芸郡芸西村',
        code: '39307',
      },
      {
        name: '長岡郡本山町',
        code: '39341',
      },
      {
        name: '土佐郡土佐町',
        code: '39363',
      },
      {
        name: '土佐郡大川村',
        code: '39364',
      },
      {
        name: '吾川郡仁淀川町',
        code: '39387',
      },
      {
        name: '高岡郡中土佐町',
        code: '39401',
      },
      {
        name: '高岡郡佐川町',
        code: '39402',
      },
      {
        name: '高岡郡梼原町',
        code: '39405',
      },
      {
        name: '長岡郡大豊町',
        code: '39344',
      },
      {
        name: '吾川郡いの町',
        code: '39386',
      },
      {
        name: '高岡郡越知町',
        code: '39403',
      },
      {
        name: '高岡郡日高村',
        code: '39410',
      },
      {
        name: '高岡郡四万十町',
        code: '39412',
      },
      {
        name: '高岡郡津野町',
        code: '39411',
      },
    ],
  },
  {
    pref: '福岡県',
    sort: '40',
    cities: [
      {
        name: '朝倉郡東峰村',
        code: '40448',
      },
      {
        name: '大牟田市',
        code: '40202',
      },
      {
        name: '久留米市',
        code: '40203',
      },
      {
        name: '直方市',
        code: '40204',
      },
      {
        name: '飯塚市',
        code: '40205',
      },
      {
        name: '柳川市',
        code: '40207',
      },
      {
        name: '八女市',
        code: '40210',
      },
      {
        name: '筑後市',
        code: '40211',
      },
      {
        name: '行橋市',
        code: '40213',
      },
      {
        name: '豊前市',
        code: '40214',
      },
      {
        name: '中間市',
        code: '40215',
      },
      {
        name: '小郡市',
        code: '40216',
      },
      {
        name: '筑紫野市',
        code: '40217',
      },
      {
        name: '大野城市',
        code: '40219',
      },
      {
        name: '宗像市',
        code: '40220',
      },
      {
        name: '太宰府市',
        code: '40221',
      },
      {
        name: '福津市',
        code: '40224',
      },
      {
        name: 'うきは市',
        code: '40225',
      },
      {
        name: '宮若市',
        code: '40226',
      },
      {
        name: '嘉麻市',
        code: '40227',
      },
      {
        name: '朝倉市',
        code: '40228',
      },
      {
        name: '糸島市',
        code: '40230',
      },
      {
        name: '八女郡広川町',
        code: '40544',
      },
      {
        name: '筑紫郡那珂川町',
        code: '40305',
      },
      {
        name: '糟屋郡宇美町',
        code: '40341',
      },
      {
        name: '糟屋郡新宮町',
        code: '40345',
      },
      {
        name: '糟屋郡久山町',
        code: '40348',
      },
      {
        name: '田川郡糸田町',
        code: '40604',
      },
      {
        name: '田川郡川崎町',
        code: '40605',
      },
      {
        name: '田川郡大任町',
        code: '40608',
      },
      {
        name: '田川郡赤村',
        code: '40609',
      },
      {
        name: '田川郡福智町',
        code: '40610',
      },
      {
        name: '北九州市門司区',
        code: '40101',
      },
      {
        name: '北九州市若松区',
        code: '40103',
      },
      {
        name: '北九州市戸畑区',
        code: '40105',
      },
      {
        name: '北九州市小倉北区',
        code: '40106',
      },
      {
        name: '北九州市小倉南区',
        code: '40107',
      },
      {
        name: '北九州市八幡東区',
        code: '40108',
      },
      {
        name: '北九州市八幡西区',
        code: '40109',
      },
      {
        name: '京都郡苅田町',
        code: '40621',
      },
      {
        name: '田川市',
        code: '40206',
      },
      {
        name: '大川市',
        code: '40212',
      },
      {
        name: '春日市',
        code: '40218',
      },
      {
        name: '古賀市',
        code: '40223',
      },
      {
        name: 'みやま市',
        code: '40229',
      },
      {
        name: '三井郡大刀洗町',
        code: '40503',
      },
      {
        name: '三潴郡大木町',
        code: '40522',
      },
      {
        name: '糟屋郡篠栗町',
        code: '40342',
      },
      {
        name: '糟屋郡志免町',
        code: '40343',
      },
      {
        name: '糟屋郡須恵町',
        code: '40344',
      },
      {
        name: '田川郡香春町',
        code: '40601',
      },
      {
        name: '田川郡添田町',
        code: '40602',
      },
      {
        name: '糟屋郡粕屋町',
        code: '40349',
      },
      {
        name: '遠賀郡芦屋町',
        code: '40381',
      },
      {
        name: '遠賀郡水巻町',
        code: '40382',
      },
      {
        name: '遠賀郡岡垣町',
        code: '40383',
      },
      {
        name: '築上郡吉富町',
        code: '40642',
      },
      {
        name: '福岡市東区',
        code: '40131',
      },
      {
        name: '福岡市中央区',
        code: '40133',
      },
      {
        name: '福岡市南区',
        code: '40134',
      },
      {
        name: '築上郡上毛町',
        code: '40646',
      },
      {
        name: '福岡市西区',
        code: '40135',
      },
      {
        name: '築上郡築上町',
        code: '40647',
      },
      {
        name: '福岡市城南区',
        code: '40136',
      },
      {
        name: '福岡市早良区',
        code: '40137',
      },
      {
        name: '嘉穂郡桂川町',
        code: '40421',
      },
      {
        name: '朝倉郡筑前町',
        code: '40447',
      },
      {
        name: '京都郡みやこ町',
        code: '40625',
      },
      {
        name: '遠賀郡遠賀町',
        code: '40384',
      },
      {
        name: '福岡市博多区',
        code: '40132',
      },
      {
        name: '鞍手郡小竹町',
        code: '40401',
      },
      {
        name: '鞍手郡鞍手町',
        code: '40402',
      },
    ],
  },
  {
    pref: '佐賀県',
    sort: '41',
    cities: [
      {
        name: '神埼郡吉野ヶ里町',
        code: '41327',
      },
      {
        name: '三養基郡上峰町',
        code: '41345',
      },
      {
        name: '三養基郡みやき町',
        code: '41346',
      },
      {
        name: '東松浦郡玄海町',
        code: '41387',
      },
      {
        name: '杵島郡大町町',
        code: '41423',
      },
      {
        name: '藤津郡太良町',
        code: '41441',
      },
      {
        name: '佐賀市',
        code: '41201',
      },
      {
        name: '唐津市',
        code: '41202',
      },
      {
        name: '鳥栖市',
        code: '41203',
      },
      {
        name: '多久市',
        code: '41204',
      },
      {
        name: '伊万里市',
        code: '41205',
      },
      {
        name: '鹿島市',
        code: '41207',
      },
      {
        name: '小城市',
        code: '41208',
      },
      {
        name: '嬉野市',
        code: '41209',
      },
      {
        name: '神埼市',
        code: '41210',
      },
      {
        name: '三養基郡基山町',
        code: '41341',
      },
      {
        name: '西松浦郡有田町',
        code: '41401',
      },
      {
        name: '杵島郡江北町',
        code: '41424',
      },
      {
        name: '杵島郡白石町',
        code: '41425',
      },
      {
        name: '武雄市',
        code: '41206',
      },
    ],
  },
  {
    pref: '長崎県',
    sort: '42',
    cities: [
      {
        name: '西彼杵郡長与町',
        code: '42307',
      },
      {
        name: '西彼杵郡時津町',
        code: '42308',
      },
      {
        name: '東彼杵郡東彼杵町',
        code: '42321',
      },
      {
        name: '東彼杵郡川棚町',
        code: '42322',
      },
      {
        name: '東彼杵郡波佐見町',
        code: '42323',
      },
      {
        name: '北松浦郡小値賀町',
        code: '42383',
      },
      {
        name: '南松浦郡新上五島町',
        code: '42411',
      },
      {
        name: '長崎市',
        code: '42201',
      },
      {
        name: '北松浦郡佐々町',
        code: '42391',
      },
      {
        name: '佐世保市',
        code: '42202',
      },
      {
        name: '島原市',
        code: '42203',
      },
      {
        name: '諫早市',
        code: '42204',
      },
      {
        name: '大村市',
        code: '42205',
      },
      {
        name: '平戸市',
        code: '42207',
      },
      {
        name: '松浦市',
        code: '42208',
      },
      {
        name: '対馬市',
        code: '42209',
      },
      {
        name: '壱岐市',
        code: '42210',
      },
      {
        name: '五島市',
        code: '42211',
      },
      {
        name: '西海市',
        code: '42212',
      },
      {
        name: '雲仙市',
        code: '42213',
      },
      {
        name: '南島原市',
        code: '42214',
      },
    ],
  },
  {
    pref: '熊本県',
    sort: '43',
    cities: [
      {
        name: '天草郡苓北町',
        code: '43531',
      },
      {
        name: '熊本市東区',
        code: '43102',
      },
      {
        name: '熊本市西区',
        code: '43103',
      },
      {
        name: '玉名郡玉東町',
        code: '43364',
      },
      {
        name: '玉名郡南関町',
        code: '43367',
      },
      {
        name: '阿蘇郡南小国町',
        code: '43423',
      },
      {
        name: '阿蘇郡小国町',
        code: '43424',
      },
      {
        name: '阿蘇郡西原村',
        code: '43432',
      },
      {
        name: '阿蘇郡南阿蘇村',
        code: '43433',
      },
      {
        name: '上益城郡御船町',
        code: '43441',
      },
      {
        name: '上益城郡甲佐町',
        code: '43444',
      },
      {
        name: '八代市',
        code: '43202',
      },
      {
        name: '荒尾市',
        code: '43204',
      },
      {
        name: '水俣市',
        code: '43205',
      },
      {
        name: '玉名市',
        code: '43206',
      },
      {
        name: '菊池市',
        code: '43210',
      },
      {
        name: '宇土市',
        code: '43211',
      },
      {
        name: '上天草市',
        code: '43212',
      },
      {
        name: '八代郡氷川町',
        code: '43468',
      },
      {
        name: '宇城市',
        code: '43213',
      },
      {
        name: '阿蘇市',
        code: '43214',
      },
      {
        name: '天草市',
        code: '43215',
      },
      {
        name: '合志市',
        code: '43216',
      },
      {
        name: '葦北郡芦北町',
        code: '43482',
      },
      {
        name: '葦北郡津奈木町',
        code: '43484',
      },
      {
        name: '球磨郡錦町',
        code: '43501',
      },
      {
        name: '球磨郡多良木町',
        code: '43505',
      },
      {
        name: '球磨郡湯前町',
        code: '43506',
      },
      {
        name: '球磨郡山江村',
        code: '43512',
      },
      {
        name: '球磨郡あさぎり町',
        code: '43514',
      },
      {
        name: '下益城郡美里町',
        code: '43348',
      },
      {
        name: '熊本市中央区',
        code: '43101',
      },
      {
        name: '熊本市南区',
        code: '43104',
      },
      {
        name: '熊本市北区',
        code: '43105',
      },
      {
        name: '玉名郡長洲町',
        code: '43368',
      },
      {
        name: '玉名郡和水町',
        code: '43369',
      },
      {
        name: '菊池郡大津町',
        code: '43403',
      },
      {
        name: '菊池郡菊陽町',
        code: '43404',
      },
      {
        name: '阿蘇郡産山村',
        code: '43425',
      },
      {
        name: '阿蘇郡高森町',
        code: '43428',
      },
      {
        name: '上益城郡嘉島町',
        code: '43442',
      },
      {
        name: '上益城郡益城町',
        code: '43443',
      },
      {
        name: '上益城郡山都町',
        code: '43447',
      },
      {
        name: '人吉市',
        code: '43203',
      },
      {
        name: '山鹿市',
        code: '43208',
      },
      {
        name: '球磨郡水上村',
        code: '43507',
      },
      {
        name: '球磨郡相良村',
        code: '43510',
      },
      {
        name: '球磨郡五木村',
        code: '43511',
      },
      {
        name: '球磨郡球磨村',
        code: '43513',
      },
    ],
  },
  {
    pref: '大分県',
    sort: '44',
    cities: [
      {
        name: '東国東郡姫島村',
        code: '44322',
      },
      {
        name: '速見郡日出町',
        code: '44341',
      },
      {
        name: '大分市',
        code: '44201',
      },
      {
        name: '別府市',
        code: '44202',
      },
      {
        name: '佐伯市',
        code: '44205',
      },
      {
        name: '玖珠郡九重町',
        code: '44461',
      },
      {
        name: '臼杵市',
        code: '44206',
      },
      {
        name: '津久見市',
        code: '44207',
      },
      {
        name: '豊後高田市',
        code: '44209',
      },
      {
        name: '杵築市',
        code: '44210',
      },
      {
        name: '宇佐市',
        code: '44211',
      },
      {
        name: '豊後大野市',
        code: '44212',
      },
      {
        name: '由布市',
        code: '44213',
      },
      {
        name: '国東市',
        code: '44214',
      },
      {
        name: '中津市',
        code: '44203',
      },
      {
        name: '日田市',
        code: '44204',
      },
      {
        name: '玖珠郡玖珠町',
        code: '44462',
      },
      {
        name: '竹田市',
        code: '44208',
      },
    ],
  },
  {
    pref: '宮崎県',
    sort: '45',
    cities: [
      {
        name: '児湯郡高鍋町',
        code: '45401',
      },
      {
        name: '児湯郡川南町',
        code: '45405',
      },
      {
        name: '児湯郡都農町',
        code: '45406',
      },
      {
        name: '東臼杵郡門川町',
        code: '45421',
      },
      {
        name: '西臼杵郡高千穂町',
        code: '45441',
      },
      {
        name: '西臼杵郡日之影町',
        code: '45442',
      },
      {
        name: '宮崎市',
        code: '45201',
      },
      {
        name: '延岡市',
        code: '45203',
      },
      {
        name: '日南市',
        code: '45204',
      },
      {
        name: '小林市',
        code: '45205',
      },
      {
        name: '日向市',
        code: '45206',
      },
      {
        name: '串間市',
        code: '45207',
      },
      {
        name: '西都市',
        code: '45208',
      },
      {
        name: '北諸県郡三股町',
        code: '45341',
      },
      {
        name: '西諸県郡高原町',
        code: '45361',
      },
      {
        name: '東諸県郡国富町',
        code: '45382',
      },
      {
        name: '東諸県郡綾町',
        code: '45383',
      },
      {
        name: '児湯郡新富町',
        code: '45402',
      },
      {
        name: '児湯郡西米良村',
        code: '45403',
      },
      {
        name: '児湯郡木城町',
        code: '45404',
      },
      {
        name: '東臼杵郡諸塚村',
        code: '45429',
      },
      {
        name: '東臼杵郡椎葉村',
        code: '45430',
      },
      {
        name: '東臼杵郡美郷町',
        code: '45431',
      },
      {
        name: '西臼杵郡五ヶ瀬町',
        code: '45443',
      },
      {
        name: '都城市',
        code: '45202',
      },
      {
        name: 'えびの市',
        code: '45209',
      },
    ],
  },
  {
    pref: '鹿児島県',
    sort: '46',
    cities: [
      {
        name: '出水郡長島町',
        code: '46404',
      },
      {
        name: '姶良郡湧水町',
        code: '46452',
      },
      {
        name: '鹿児島市',
        code: '46201',
      },
      {
        name: '鹿屋市',
        code: '46203',
      },
      {
        name: '枕崎市',
        code: '46204',
      },
      {
        name: '阿久根市',
        code: '46206',
      },
      {
        name: '出水市',
        code: '46208',
      },
      {
        name: '指宿市',
        code: '46210',
      },
      {
        name: '西之表市',
        code: '46213',
      },
      {
        name: '垂水市',
        code: '46214',
      },
      {
        name: '薩摩川内市',
        code: '46215',
      },
      {
        name: '日置市',
        code: '46216',
      },
      {
        name: '霧島市',
        code: '46218',
      },
      {
        name: 'いちき串木野市',
        code: '46219',
      },
      {
        name: '南さつま市',
        code: '46220',
      },
      {
        name: '志布志市',
        code: '46221',
      },
      {
        name: '奄美市',
        code: '46222',
      },
      {
        name: '南九州市',
        code: '46223',
      },
      {
        name: '姶良市',
        code: '46225',
      },
      {
        name: '肝属郡東串良町',
        code: '46482',
      },
      {
        name: '肝属郡錦江町',
        code: '46490',
      },
      {
        name: '肝属郡南大隅町',
        code: '46491',
      },
      {
        name: '肝属郡肝付町',
        code: '46492',
      },
      {
        name: '熊毛郡中種子町',
        code: '46501',
      },
      {
        name: '熊毛郡南種子町',
        code: '46502',
      },
      {
        name: '熊毛郡屋久島町',
        code: '46505',
      },
      {
        name: '大島郡大和村',
        code: '46523',
      },
      {
        name: '大島郡宇検村',
        code: '46524',
      },
      {
        name: '大島郡瀬戸内町',
        code: '46525',
      },
      {
        name: '大島郡龍郷町',
        code: '46527',
      },
      {
        name: '大島郡喜界町',
        code: '46529',
      },
      {
        name: '大島郡徳之島町',
        code: '46530',
      },
      {
        name: '大島郡天城町',
        code: '46531',
      },
      {
        name: '大島郡伊仙町',
        code: '46532',
      },
      {
        name: '大島郡和泊町',
        code: '46533',
      },
      {
        name: '大島郡知名町',
        code: '46534',
      },
      {
        name: '大島郡与論町',
        code: '46535',
      },
      {
        name: '鹿児島郡三島村',
        code: '46303',
      },
      {
        name: '鹿児島郡十島村',
        code: '46304',
      },
      {
        name: '薩摩郡さつま町',
        code: '46392',
      },
      {
        name: '曽於郡大崎町',
        code: '46468',
      },
      {
        name: '曽於市',
        code: '46217',
      },
      {
        name: '伊佐市',
        code: '46224',
      },
    ],
  },
  {
    pref: '沖縄県',
    sort: '47',
    cities: [
      {
        name: '島尻郡伊是名村',
        code: '47360',
      },
      {
        name: '島尻郡久米島町',
        code: '47361',
      },
      {
        name: '島尻郡八重瀬町',
        code: '47362',
      },
      {
        name: '宮古郡多良間村',
        code: '47375',
      },
      {
        name: '八重山郡竹富町',
        code: '47381',
      },
      {
        name: '八重山郡与那国町',
        code: '47382',
      },
      {
        name: '那覇市',
        code: '47201',
      },
      {
        name: '石垣市',
        code: '47207',
      },
      {
        name: '浦添市',
        code: '47208',
      },
      {
        name: '名護市',
        code: '47209',
      },
      {
        name: '糸満市',
        code: '47210',
      },
      {
        name: '沖縄市',
        code: '47211',
      },
      {
        name: '豊見城市',
        code: '47212',
      },
      {
        name: 'うるま市',
        code: '47213',
      },
      {
        name: '宮古島市',
        code: '47214',
      },
      {
        name: '南城市',
        code: '47215',
      },
      {
        name: '国頭郡国頭村',
        code: '47301',
      },
      {
        name: '国頭郡大宜味村',
        code: '47302',
      },
      {
        name: '国頭郡東村',
        code: '47303',
      },
      {
        name: '国頭郡今帰仁村',
        code: '47306',
      },
      {
        name: '国頭郡本部町',
        code: '47308',
      },
      {
        name: '国頭郡恩納村',
        code: '47311',
      },
      {
        name: '国頭郡宜野座村',
        code: '47313',
      },
      {
        name: '国頭郡金武町',
        code: '47314',
      },
      {
        name: '国頭郡伊江村',
        code: '47315',
      },
      {
        name: '中頭郡読谷村',
        code: '47324',
      },
      {
        name: '中頭郡嘉手納町',
        code: '47325',
      },
      {
        name: '中頭郡北谷町',
        code: '47326',
      },
      {
        name: '中頭郡北中城村',
        code: '47327',
      },
      {
        name: '中頭郡中城村',
        code: '47328',
      },
      {
        name: '中頭郡西原町',
        code: '47329',
      },
      {
        name: '島尻郡与那原町',
        code: '47348',
      },
      {
        name: '島尻郡渡嘉敷村',
        code: '47353',
      },
      {
        name: '島尻郡座間味村',
        code: '47354',
      },
      {
        name: '宜野湾市',
        code: '47205',
      },
      {
        name: '島尻郡南風原町',
        code: '47350',
      },
      {
        name: '島尻郡粟国村',
        code: '47355',
      },
      {
        name: '島尻郡渡名喜村',
        code: '47356',
      },
      {
        name: '島尻郡南大東村',
        code: '47357',
      },
      {
        name: '島尻郡北大東村',
        code: '47358',
      },
      {
        name: '島尻郡伊平屋村',
        code: '47359',
      },
    ],
  },
].map((p) => {
  return { ...p, cities: p.cities.sort((a, b) => (a.name < b.name ? -1 : 1)) }
})
