import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { RoadState } from "./types";
import { roadAction } from "./actions";

const initialState: RoadState = {
  activateSecondDraw: false,
};

export function RoadReducer(state = initialState, action: Action): RoadState {
  if (isType(action, roadAction.fetchAllRoads.done)) {
    return {
      ...state,
      roads: action.payload.result,
    };
  }

  if (isType(action, roadAction.addRoadMapSelection.done)) {
    let target = [];

    target.push(action.payload.result);
    return {
      ...state,
      roadMapSelection: [...(state.roadMapSelection ?? []), target],
    };
  }
  if (isType(action, roadAction.resetRoadMapSelection)) {
    return {
      ...state,
      roadMapSelection: [],
      selectedRoadLine: [],
    };
  }
  if (isType(action, roadAction.selectRoadLine.done)) {
    const target: any = {
      ...action.payload.params,
      area: {
        type: "polygon",
        data: action.payload.params.area.map(([longitude, latitude]: any) => ({
          longitude,
          latitude,
        })),
      },
    };

    return {
      ...state,
      selectedRoadLine: state.selectedRoadLine
        ? [...state.selectedRoadLine, target]
        : [target],
    };
  }
  if (isType(action, roadAction.activateSecondDraw.done)) {
    return {
      ...state,
      activateSecondDraw: action.payload.params,
    };
  }
  return state;
}
