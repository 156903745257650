import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { linkageAction } from "../../../ducks/linkage";
import { linkageSelectors } from "../../../ducks/linkage/selector";
import { LinkageTable } from "../../organisms/LinkageTable";
import { PageRoutes } from "../../../constants/Routes";

export const AddLinkageComplete = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id = parseInt(params.id ?? "0");

  const dispatch = useDispatch();

  const linkage = useSelector(linkageSelectors.getLinkage);

  const goToList = useCallback(() => {
    navigate(PageRoutes.LINKAGE_HISTORY);
  }, [navigate]);

  useEffect(() => {
    dispatch(linkageAction.getLinkage.started({ id }));
    // 初回表示時のみ Linkages 取得
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full flex flex-col">
      <div className="ml-[1px] py-[18px] bg-white leading-4 text-primary font-bold flex items-center pl-7.5">
        <p className="mr-[40px] text-[16px]">新規データ作成</p>
      </div>
      <div className="mx-5 flex my-4 max-h-full overflow-hidden flex-col bg-white rounded px-7.5 pt-6 pb-24">
        <div className="rounded-[40px]  flex items-center ">
          <div className="ml-[5px] text-[16px] font-bold text-primary">
            結果確認
          </div>
        </div>
        <div className="mt-6">
          <p className="text-sm mb-6">下記の通り、設定が完了しました。</p>
          <LinkageTable linkages={linkage ? [linkage] : []} />
        </div>
      </div>
      <div className="py-5.5 bg-[#EEEEEE] flex gap-x-2.5 justify-end items-center px-5">
        <button
          onClick={() => {
            goToList();
          }}
          className="text-white bg-primary rounded h-10 w-[200px] disabled:bg-gray-border disabled:cursor-not-allowed"
        >
          一覧へ
        </button>
      </div>
    </div>
  );
};
