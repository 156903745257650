import CommercialComplexActiveIcon from "../assets/icons/commercial-complex-active.svg";
import CommercialComplexIcon from "../assets/icons/commercial-complex.svg";
import ConvenienceStoreIcon from "../assets/icons/convenience-store.svg";
import ConvenienceStoreActiveIcon from "../assets/icons/convenience_store_active.svg";
import CulturalFacilityActiveIcon from "../assets/icons/cultural-activity-active.svg";
import CulturalFacilityIcon from "../assets/icons/cultural-activity.svg";
import DepartmentStoresActiveIcon from "../assets/icons/departmentstore-active.svg";
import DepartmentStoresIcon from "../assets/icons/departmentstore.svg";
import FamilyRestaurantsActiveIcon from "../assets/icons/family-restaurant-active.svg";
import FamilyRestaurantsIcon from "../assets/icons/family-restaurant.svg";
import HotelActiveIcon from "../assets/icons/hotel-active.svg";
import HotelIcon from "../assets/icons/hotel.svg";
import MedicalFacilityActiveIcon from "../assets/icons/medical-active.svg";
import MedicalFacilityIcon from "../assets/icons/medical.svg";
import SupermarketActiveIcon from "../assets/icons/supermarket-active.svg";
import SupermarketIcon from "../assets/icons/supermarket.svg";

import { FacilityMasterItemsType } from ".";
import { AppConfig } from "../AppConfig";
import { LocalStorageManager } from "../core/storage/LocalStoragetManager";

export const loadMarkerImage = (type: FacilityMasterItemsType) => {
  switch (type) {
    case FacilityMasterItemsType.HOTEL:
      return `url(${HotelIcon})`;
    case FacilityMasterItemsType.CONVENIENCE_STORE:
      return `url(${ConvenienceStoreIcon})`;
    case FacilityMasterItemsType.COMMERCIAL_COMPLEX:
      return `url(${CommercialComplexIcon})`;
    case FacilityMasterItemsType.FAMILTY_RESTAURANTS:
      return `url(${FamilyRestaurantsIcon})`;
    case FacilityMasterItemsType.DEPARTMENT_STORES:
      return `url(${DepartmentStoresIcon})`;
    case FacilityMasterItemsType.SUPERMARKET:
      return `url(${SupermarketIcon})`;
    case FacilityMasterItemsType.MEDICAL_FACILITY:
      return `url(${MedicalFacilityIcon})`;
    case FacilityMasterItemsType.CULTURAL_FACILITY:
      return `url(${CulturalFacilityIcon})`;

    default:
      return "";
  }
};
export const loadActiveMarkerImage = (type: FacilityMasterItemsType) => {
  switch (type) {
    case FacilityMasterItemsType.HOTEL:
      return `url(${HotelActiveIcon})`;
    case FacilityMasterItemsType.CONVENIENCE_STORE:
      return `url(${ConvenienceStoreActiveIcon})`;
    case FacilityMasterItemsType.COMMERCIAL_COMPLEX:
      return `url(${CommercialComplexActiveIcon})`;
    case FacilityMasterItemsType.FAMILTY_RESTAURANTS:
      return `url(${FamilyRestaurantsActiveIcon})`;
    case FacilityMasterItemsType.DEPARTMENT_STORES:
      return `url(${DepartmentStoresActiveIcon})`;
    case FacilityMasterItemsType.SUPERMARKET:
      return `url(${SupermarketActiveIcon})`;
    case FacilityMasterItemsType.MEDICAL_FACILITY:
      return `url(${MedicalFacilityActiveIcon})`;
    case FacilityMasterItemsType.CULTURAL_FACILITY:
      return `url(${CulturalFacilityActiveIcon})`;

    default:
      return `url(${HotelActiveIcon})`;
  }
};

/**
 * delete login information in local storage data.
 * @constructor
 */
export const deleteLoginLocalStorageData = () => {
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["accessToken"]
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["refreshToken"]
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["userId"]
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["tenantId"]
  );
  LocalStorageManager.Instance.removeObject(
    AppConfig.Instance.LocalStorageKey["authority"]
  );
  LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["assets"]
  );
};
