import React, { useCallback, useEffect, useMemo, useRef } from "react";

export const PoiSelectorAllChecker = ({
  set,
  reset,
  maxLength,
  checkedLength,
}: {
  set: () => void;
  reset: () => void;
  maxLength: number;
  checkedLength: number;
}) => {
  const allSelectRef = useRef<HTMLInputElement>(null!);

  const isAllChecked = useMemo(() => {
    return maxLength === checkedLength;
  }, [checkedLength, maxLength]);

  const execCheckAll = useCallback(() => {
    if (isAllChecked || (0 < checkedLength && !isAllChecked)) {
      reset();
    } else {
      set();
    }
  }, [checkedLength, isAllChecked, reset, set]);

  useEffect(() => {
    if (allSelectRef.current) {
      allSelectRef.current.checked = isAllChecked;
      allSelectRef.current.indeterminate =
        0 < checkedLength && !isAllChecked && !isAllChecked;
    }
  }, [checkedLength, isAllChecked]);

  return (
    <label className="flex w-fit items-center cursor-pointer">
      <input
        type="checkbox"
        onChange={execCheckAll}
        ref={allSelectRef}
        className="h-3.5 w-3.5 accent-primary"
      />
      <div className="ml-2 text-xs">すべて選択</div>
    </label>
  );
};
