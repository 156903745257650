import { OutlineIconProps } from "../types";

export default function IconPlaceOfResidenceAnalysis({
    className,
}: OutlineIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className ?? "w-6 h-6 text-primary"}
        >
            <path
                fill="currentColor"
                d="M12.073 18.44H6.19v-6.374h12.963a.833.833 0 0 0 .646-.3.782.782 0 0 0 .161-.679l-1.278-5.462A.821.821 0 0 0 17.876 5H5.1a.822.822 0 0 0-.8.625l-1.28 5.46a.788.788 0 0 0 .161.679.837.837 0 0 0 .646.3h.711v7.177a.814.814 0 0 0 .826.8h6.71a.803.803 0 1 0 0-1.606M5.763 6.605h11.453l.9 3.855H4.861l.902-3.855Zm11.766 6.555a3.4 3.4 0 0 0-3.471 3.456c0 1.4 1.157 3.058 3.471 4.974C19.843 19.676 21 18.018 21 16.616a3.4 3.4 0 0 0-3.471-3.456Zm0 4.612a1.3 1.3 0 1 1 1.337-1.3 1.32 1.32 0 0 1-1.337 1.3Z"
            />
        </svg>
    );
}
