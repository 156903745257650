import { call, put } from "typed-redux-saga";
import { AppConfig } from "../../AppConfig";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { authApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { store } from "../../store";
import { appActions } from "../app";
import { takeEveryFsa } from "../operations";
import { LoginAction } from "./actions";

export function* LoginSaga() {
  yield takeEveryFsa(LoginAction.login.started, function* (actions) {
    try {
      const {
        tenantName,
        emailAddress,
        password,
        onComplete,
        onSuccess,
        onError,
      } = actions.payload;
      const response: any = yield* call(async (): Promise<unknown> => {
        return await authApi
          .login({
            tenant_id: Number(tenantName),
            user_name: emailAddress,
            password,
          })
          .catch((error) => error);
      });

      if (response && response.error && onError) {
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }

      if (response && response.data && response.data.access_token) {
        LocalStorageManager.Instance.saveObject(
          AppConfig.Instance.LocalStorageKey["accessToken"],
          response.data.access_token
        );
        LocalStorageManager.Instance.saveObject(
          AppConfig.Instance.LocalStorageKey["refreshToken"],
          response.data.refresh_token
        );
        if (response.data.iam) {
          LocalStorageManager.Instance.saveObject(
            AppConfig.Instance.LocalStorageKey["userId"],
            response.data.iam.user_id
          );
          LocalStorageManager.Instance.saveObject(
            AppConfig.Instance.LocalStorageKey["tenantId"],
            response.data.iam.tenant_id
          );
          LocalStorageManager.Instance.saveObject(
            AppConfig.Instance.LocalStorageKey["authority"],
            response.data.iam.authority
          );
        }
      } else {
        // トークンがない場合
        const errorCode = "9999-9998";
        const errorObj: { title: string; message: string } =
          ErrorMessageManager.Instance.getErrorMessage(errorCode);
        store.dispatch(
          appActions.showModal.started({
            title: errorObj.title,
            modalType: "alert",
            detailText: errorObj.message,
            errorStatusCode: errorCode,
            closeButtonLabel: "閉じる",
            handleClose: () => {
              store.dispatch(appActions.closeModal());
            },
          })
        );
        return;
      }

      yield* put(
        LoginAction.login.done({
          params: actions.payload,
          result: response.data,
        })
      );

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(LoginAction.forgetPassword.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess, body } = actions.payload;
      const response: any = yield* call(async (): Promise<any> => {
        return await authApi.sendresetmail(body).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      if (response && response.status === 200) {
        yield* put(
          LoginAction.forgetPassword.done({
            params: actions.payload,
            result: response.data,
          })
        );
      }

      if (onSuccess) {
        onSuccess(response);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(LoginAction.resetPassword.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess, body } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await authApi.passwordreset(body).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      if (response && response.status === 200) {
        yield* put(
          LoginAction.resetPassword.done({
            params: actions.payload,
            result: response.data,
          })
        );
      }

      if (onSuccess) {
        onSuccess(response);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });
}
