import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import {
  Controller,
  ValidationValueMessage,
  useFormContext,
} from "react-hook-form";
import "./DatePicker.css";
import RequiredBadge from "./RequiredBadge";

type Props = {
  fieldName?: string;
  name: string;
  label: string;
  required: boolean;
  placeholder?: string;
  disabled?: boolean;
  showClearButton?: boolean;
  value?: Value;
  onChange: (value: string) => void;
  can_be_edited?: number;
  rules?: {
    required?: ValidationValueMessage<boolean>;
    maxLength?: ValidationValueMessage<number>;
  };
  defaultValue?: string;
  minDate?: Date;
  maxDate?: Date;
};

export const DatePicker: React.FC<Props> = (props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState<Value>(null);
  const { setValue } = useFormContext();
  const showPicker = () => {
    if (props.disabled) return;
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    if (props.defaultValue) {
      setDate(new Date(props.defaultValue));
      setValue(props.fieldName ?? "", props.defaultValue);
    }
  }, [props.defaultValue]);
  const handleCalendarChange = (value: Value, field: any) => {
    // Handle calendar date change
    setDate(value);
    const date = value
      ? value.toLocaleString("ja", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      : "";

    field.onChange(date);

    setValue(props.fieldName ?? "", date, { shouldValidate: true });

    props.onChange(date);

    setShowCalendar(!showCalendar);
  };

  return (
    <div className="relative min-w-[300px] max-w-full w-full">
      <div className="flex items-center mb-3 gap-x-2.5">
        <p className="text-black text-sm">{props.label}</p>
        {props.required && <RequiredBadge />}
      </div>

      <Controller
        name={props.fieldName ?? ""}
        defaultValue={props.defaultValue}
        render={({ field }) => {
          return (
            <>
              <div className="flex gap-x-2">
                <div className="flex-1 relative">
                  <input
                    readOnly
                    type="text"
                    className={`border border-gray disabled:border-gray/80 w-full disabled:cursor-not-allowed disabled:bg-gray-soft/70 outline-none h-12 rounded-md p-2.5 text-black placeholder-shown:bg-gray-soft placeholder-shown:text-[13px] bg-transparent`}
                    placeholder={props.placeholder}
                    value={
                      date
                        ? dayjs(date.toLocaleString()).format("YYYY/MM/DD")
                        : ""
                    }
                    onClick={showPicker}
                    disabled={props.disabled}
                  />
                  {showCalendar && (
                    <div className="absolute z-20 bg-white -mt-[36px] w-[300px] px-[30px] py-[20px] shadow-2xl ">
                      <Calendar
                        locale="ja"
                        value={date}
                        onClickDay={(e) => {
                          handleCalendarChange(e, field);
                        }}
                        showNeighboringMonth={false}
                        prevLabel={
                          <div className="flex justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="10"
                              viewBox="0 0 5 10"
                            >
                              <path
                                id="Path_640"
                                data-name="Path 640"
                                d="M4.166,4.677.172.932A.542.542,0,0,1,.583,0H9.417a.542.542,0,0,1,.411.932L5.835,4.677a1.24,1.24,0,0,1-1.669,0"
                                transform="translate(5) rotate(90)"
                                fill="#555"
                              />
                            </svg>
                          </div>
                        }
                        nextLabel={
                          <div className="flex justify-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="10"
                              viewBox="0 0 5 10"
                            >
                              <path
                                id="Path_681"
                                data-name="Path 681"
                                d="M4.166.323.172,4.068A.542.542,0,0,0,.583,5H9.417a.542.542,0,0,0,.411-.932L5.835.323a1.24,1.24,0,0,0-1.669,0"
                                transform="translate(5) rotate(90)"
                                fill="#555"
                              />
                            </svg>
                          </div>
                        }
                        formatDay={(locale, date) => {
                          return dayjs(date).format("D");
                        }}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
