import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selectApp = (state: StoreState) => state.spot;

export const spotSelectors = {
  getPolygonPostion: createSelector(selectApp, (app) => app.polygonPositions),
  getRectanglePostion: createSelector(
    selectApp,
    (app) => app.rectanglePositions
  ),
  getCirclePostion: createSelector(selectApp, (app) => app.circlePositions),
  getFacilityMasterItems: createSelector(
    selectApp,
    (app) => app.facilityMasterItems
  ),
  getFacilityMasterFilterTypes: createSelector(
    selectApp,
    (app) => app.facilityMasterFilterTypes
  ),
  getTargetFacilityMasterItem: createSelector(
    selectApp,
    (app) => app.targetFacilityMasterItem
  ),
};
