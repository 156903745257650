import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { PageRoutes } from "../../../constants/Routes";
import { Spot } from "../../../core/api";
import { analysisExecuteActions } from "../../../ducks/analysis_execute";
import { assetAction } from "../../../ducks/asset";
import { assetSelectors } from "../../../ducks/asset/selector";
import { useCreateSpot } from "../../../hooks/useCreateSpot";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import { CreateSpotModal } from "../../organisms/CreateSpotModal";
import { CreateSpotNameModal } from "../../organisms/CreateSpotNameModal";
import { PoiSelector } from "../../organisms/PoiSelector";
import { SpotMap } from "../../organisms/SpotMap";
import { SpotCard } from "../../organisms/SpotlistDisplayMap/SpotCard";
import { useAnalysisExecute } from "../analysis_execution/hooks";

export const CreateSpotPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const spots = useSelector(assetSelectors.getSpots);
  const displayFilter = useSelector(assetSelectors.getDisplayItems);
  const { deleteSpot } = useCreateSpot();
  const { selectedAnalysisSpotItems } = useAnalysisExecute();

  const [openModal, setIsOpenModal] = useState(false);
  const [openNameModal, setIsOpenNameModal] = useState(false);
  const [openPoiSelector, setOpenPoiSelector] = useState(false);

  useEffect(() => {
    dispatch(assetAction.fetchSpotList.started({}));
  }, []);

  const _renderSpotcard = (item: Spot) => {
    return (
      <SpotCard
        item={item}
        dispatch={dispatch}
        onAnalyze={() => {
          navigate(PageRoutes.EXECUTE_SPOT_ANALYSIS);
        }}
        onEdit={() => {
          setIsOpenNameModal(true);
          const closeButton = document.querySelector<HTMLButtonElement>(
            ".mapboxgl-popup-close-button"
          );
          closeButton?.click();
        }}
        onDelete={(item) => {
          if (item.id) {
            dispatch(analysisExecuteActions.setDeleteSpotId(item));

            deleteSpot(item.id);
            const closeButton = document.querySelector<HTMLButtonElement>(
              ".mapboxgl-popup-close-button"
            );
            closeButton?.click();
          }
        }}
        onClose={() => {
          const closeButton = document.querySelector<HTMLButtonElement>(
            ".mapboxgl-popup-close-button"
          );
          closeButton?.click();
          dispatch(analysisExecuteActions.analysisReset());
        }}
        displayDatSetFlg={!!displayFilter.data_set_name}
        dataGenStatus={displayFilter.data_gen_status ?? false}
      />
    );
  };

  return (
    <div className="pb-2.5 w-full h-full">
      <div className="bg-white ml-[1px]">
        <p className="ml-[30px] py-[20px] text-[#334A7C] text-[16px] leading-4 font-bold">
          分析スポット一覧
        </p>
      </div>
      <div className="relative border-[#FFFFFF] mx-5 my-4 overflow-hidden  border-8 h-[calc(100%_-_80px)] rounded-[4px]">
        <SpotMap spots={spots} renderPopup={_renderSpotcard} />
        <div className="absolute p-2.5 top-0">
          {displayFilter.spot_create && (
            <CustomButton
              text="新規追加"
              className="px-6.25 py-2.5"
              onClick={() => {
                dispatch(analysisExecuteActions.analysisReset());
                setIsOpenModal(true);
              }}
            />
          )}
        </div>
        <CreateSpotModal
          spots={spots}
          isOpen={openModal}
          handleNext={() => {
            setIsOpenNameModal(true);
          }}
          handleClose={() => {
            setIsOpenModal(false);
          }}
          openPoiSelector={() => {
            setOpenPoiSelector(true);
          }}
        />
        <CreateSpotNameModal
          isOpen={openNameModal}
          selectedSpot={selectedAnalysisSpotItems[0]}
          handleCallback={() => {
            setIsOpenNameModal(false);
            setIsOpenModal(false);
          }}
          handleClose={() => {
            setIsOpenNameModal(false);
          }}
        />
        <PoiSelector
          isOpen={openPoiSelector}
          closeButton={
            <button onClick={() => setOpenPoiSelector(false)}>
              <CloseIcon />
            </button>
          }
          closeSelector={() => {
            setOpenPoiSelector(false);
          }}
        />
      </div>
    </div>
  );
};
