import { ReactNode } from "react";
import { Header } from "../organisms/Header";
import { Outlet } from "react-router-dom";

type Props = {
  children?: ReactNode;
  bgColor?: string;
};

export const MainTemplate = ({ children, bgColor }: Props) => {
  return (
    <main className={`flex flex-col h-[100svh] ${bgColor ? bgColor : 'bg-gray-background'}`}>
      <Header empty />
      <div className="flex-1 overflow-y-auto flex flex-col justify-center items-center">
        <Outlet />
      </div>
    </main>
  );
};
