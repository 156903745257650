import { Provider } from "react-redux";
import { store } from "./store";
import { MessageProvider } from "./components/pages/MessageProvider";
import { RouteConfig } from "./components/templates/RouteConfig";

export default function App() {
  return (
    <Provider store={store}>
      <MessageProvider>
        <RouteConfig />
      </MessageProvider>
    </Provider>
  );
}
