import EventEmitter from "events";

export interface IMoeEventEmitter {}

export class MoeEventEmitter implements IMoeEventEmitter {
	private static _instance: EventEmitter;
	public static get Instance() {
		return this._instance || (this._instance = new EventEmitter());
	}
}
