import React from "react";
import { ReactComponent as CheckboxInactiveBlackIcon } from "../../assets/icons/inactive-checkbox-black.svg";
import { ReactComponent as CheckboxInactiveIcon } from "../../assets/icons/inactive-checkbox.svg";
import { mergeClassNames } from "../../utils/style";

type Props = {
  size: "small" | "normal";
  isMax?: boolean;
};

export const CheckboxInactive: React.FC<Props> = ({ size, isMax = false }) => {
  return (
    <div
      className={mergeClassNames(
        "mr-px pb-1",
        size === "small" ? "w-4 h-3.75" : "w-6 h-5.75"
      )}
    >
      <div className="flex justify-center">
        {isMax ? <CheckboxInactiveBlackIcon /> : <CheckboxInactiveIcon />}{" "}
      </div>
    </div>
  );
};
