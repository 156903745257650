import { IconProps } from "../types";

export default function IconResizeNavCollaspe({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.281"
      height="12"
      viewBox="0 0 12.281 12"
    >
      <g
        id="Group_5560"
        data-name="Group 5560"
        transform="translate(-136.719 -699)"
      >
        <path
          id="線_576"
          data-name="線 576"
          d="M1,12H-1V0H1Z"
          transform="translate(137.719 711) rotate(180)"
        />
        <path
          id="パス_310"
          data-name="パス 310"
          d="M28.174,168.2l-4.5-5.625,4.5-5.625,1.562,1.249-3.5,4.375,3.5,4.375Z"
          transform="translate(172.674 867.574) rotate(180)"
        />
      </g>
    </svg>
  );
}
