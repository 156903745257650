import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PageRoutes } from "../../constants/Routes";
import { MoeEventEmitter } from "../../core/event";
import { appSelectors } from "../../ducks/app/selector";
import { assetSelectors } from "../../ducks/asset/selector";
import { LoadingIndicator } from "../molecules/LoadingIndicator";
import { NotificationStatus } from "../molecules/NotificationStatus";
import { CustomModal } from "../organisms/CustomModal";
import { SelectAnalysis } from "../organisms/select_analysis";
import { PageTenantManagement } from "../pages/TenantManagement";
import { AnalysisExcution } from "../pages/analysis_execution";
import { CreateSpotPage } from "../pages/create_spot";
import { ForgotPassword } from "../pages/forgot_password";
import { LinkageHistory } from "../pages/linkages";
import { AddLinkageComplete } from "../pages/linkages/AddLinkageComplete";
import { AddLinkageConfirm } from "../pages/linkages/AddLinkageConfirm";
import { EditLinkage } from "../pages/linkages/EditLinkage";
import { Login } from "../pages/login";
import { ResetPassword } from "../pages/reset_password";
import { SelectSpotAnalysisType } from "../pages/select_spot_analysis_type";
import { SelectSpotForAnalysis } from "../pages/select_spot_for_analysis";
import PageUserManagement from "../pages/user_management";
import AuthTemplate from "./AuthTemplate";
import { MainTemplate } from "./MainTemplate";
import { MenuTemplate } from "./MenuTemplate";

export const RouteConfig = () => {
  const modalObj = useSelector(appSelectors.getModalObject);
  const isLoading = useSelector(appSelectors.getLoading);
  const notification = useSelector(appSelectors.getNotification);
  const showNotification = useSelector(appSelectors.showNotification);
  const isAdmin = useSelector(assetSelectors.isAdminUser);
  const navigater = useNavigate();

  const location = useLocation();
  useEffect(() => {
    MoeEventEmitter.Instance.on("navigate", (path: string) => {
      navigater(path);
    });
  }, []);

  return (
    <>
      <Routes>
        <Route
          path={PageRoutes.LOGIN}
          element={<MainTemplate bgColor={`bg-white`} />}
        >
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <Login />
              </AuthTemplate>
            }
          />
        </Route>
        <Route
          path={PageRoutes.FORGOT_PASSWORD}
          element={<MainTemplate bgColor={`bg-white`} />}
        >
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <ForgotPassword />
              </AuthTemplate>
            }
          />
        </Route>
        <Route
          path={PageRoutes.RESET_PASSWORD}
          element={<MainTemplate bgColor={`bg-white`} />}
        >
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <ResetPassword />
              </AuthTemplate>
            }
          />
        </Route>
        <Route
          path={PageRoutes.RESET_PASSWORD_DEFAULT}
          element={<MainTemplate bgColor={`bg-white`} />}
        >
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <ResetPassword />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={PageRoutes.INDEX} element={<MenuTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogin>
                <CreateSpotPage />
              </AuthTemplate>
            }
          />
          <Route
            path={PageRoutes.CREATE_SPOT}
            element={
              <AuthTemplate requiredLogin>
                <CreateSpotPage />
              </AuthTemplate>
            }
          />
          <Route
            path={PageRoutes.EXECUTE_SPOT_ANALYSIS}
            element={
              <AuthTemplate requiredLogin>
                <AnalysisExcution />
              </AuthTemplate>
            }
          >
            <Route index element={<SelectAnalysis />} />
            <Route
              path={PageRoutes.CHOOSE_SPOT_ANALYSIS}
              element={<SelectSpotForAnalysis />}
            />
            <Route
              path={PageRoutes.SELECT_SPOT_ANALYSIS_TYPE}
              element={<SelectSpotAnalysisType />}
            />
          </Route>
          <Route
            path={PageRoutes.TENANT_MANAGEMENT}
            element={<PageTenantManagement />}
          />

          <Route
            path={PageRoutes.USER_MANAGEMENT}
            element={<PageUserManagement />}
          />

          <Route
            path={PageRoutes.LINKAGE_HISTORY}
            element={<LinkageHistory />}
          />
          <Route
            path={PageRoutes.ADD_LINKAGE_CONFIRM}
            element={<AddLinkageConfirm />}
          />
          <Route
            path={`${PageRoutes.ADD_LINKAGE_COMPLETE}/:id`}
            element={<AddLinkageComplete />}
          />
          <Route
            path={`${PageRoutes.EDIT_LINKAGE}/:id`}
            element={<EditLinkage />}
          />
        </Route>
      </Routes>

      <CustomModal isOpen={modalObj.isOpen} {...modalObj.modalItem} />
      <LoadingIndicator isShown={isLoading} />
      {notification && (
        <NotificationStatus
          type={notification.type}
          text={notification.text}
          visible={showNotification}
        />
      )}
    </>
  );
};
