import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { linkageAction } from "../../../ducks/linkage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../atoms/Input";
import { z } from "zod";
import Form from "../../organisms/Form";
import { appActions } from "../../../ducks/app";
import { Linkage, LinkageModifyRequest } from "../../../core/api";
import { assetSelectors } from "../../../ducks/asset/selector";
import { DatePicker } from "../../atoms/DatePicker";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

export const EditLinkage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id ?? "0");

  const dispatch = useDispatch();

  const [linkage, setLinkage] = useState<Linkage | undefined>();

  const formSchema = z.object({
    name: z.string().optional(),
  });
  type FormSchema = z.infer<typeof formSchema>;

  const isAdmin = useSelector(assetSelectors.isAdminUser);

  const minDate = useMemo(() => {
    return dayjs().add(1, "day").toDate();
  }, []);

  const updateLinkage = useCallback(
    ({
       dateString,
       key,
     }: {
      dateString: string;
      key: "start_at" | "end_at";
    }) => {
      setLinkage(linkage ? { ...linkage, [key]: dateString } : undefined);
    },
    [linkage]
  );

  const back = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSubmit = useCallback(
    ({ form, isDraft }: { form: FormSchema; isDraft?: boolean }) => {
      console.log("handleSubmit");
      console.log(form);
      const body: LinkageModifyRequest = {
        id,
        name: form.name,
        start_at: dayjs(linkage?.start_at).startOf("day").toISOString(),
        end_at: linkage?.end_at
          ? dayjs(linkage?.end_at).endOf("day").toISOString()
          : undefined,
      };

      if (!isDraft) {
        body.is_draft = isDraft;
      }

      dispatch(
        linkageAction.modifyLinkage.started({
          body,
          onSuccess: () => {
            dispatch(appActions.showNotification());
            dispatch(
              appActions.displayNotification.started({
                type: "success",
                text: "連携データを保存しました",
              })
            );
            back();
          },
        })
      );
    },
    [back, dispatch, id, linkage?.end_at, linkage?.start_at]
  );

  const spotNames = useMemo(() => {
    return linkage?.data?.map((d: any) => {
      return d["name"];
    });
  }, [linkage?.data]);

  useEffect(() => {
    console.log("useEffect!");
    console.log(id);
    dispatch(
      linkageAction.getLinkage.started({
        id,
        onSuccess: (res: Linkage) => {
          setLinkage(res);
        },
      })
    );
    // 初回表示時のみ Linkages 取得
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const SaveButton = ({ forPublish = false }: { forPublish?: boolean }) => {
    const formContext = useFormContext();

    const label = useMemo(() => {
      return forPublish ? "出力開始" : "保存";
    }, [forPublish]);

    const disabledSaveButton = useMemo(() => {
      return (
        !formContext?.formState.isValid ||
        formContext.formState.isSubmitting ||
        dayjs(linkage?.end_at) < dayjs(linkage?.start_at)
      );
    }, [formContext.formState.isSubmitting, formContext.formState.isValid]);

    return !forPublish || (forPublish && isAdmin && linkage?.is_draft) ? (
      <button
        disabled={disabledSaveButton}
        type={"button"}
        onClick={() =>
          handleSubmit({
            form: formContext.getValues() as FormSchema,
            isDraft: forPublish && isAdmin ? false : undefined,
          })
        }
        className="text-[14px] w-[200px] px-10 py-3 disabled:bg-gray-border whitespace-nowrap bg-primary text-white disabled:cursor-not-allowed rounded"
      >
        {label}
      </button>
    ) : null;
  };

  return linkage ? (
    <div className="h-full flex flex-col">
      <div className="ml-[1px] py-4 bg-white leading-4 text-primary font-bold flex items-center pl-7.5">
        <p className="mr-[40px] text-[16px]">履歴一覧</p>
      </div>
      <Form schema={formSchema} onSubmit={() => {}}>
        <div className="mx-5 flex my-4 flex-col bg-white rounded px-7.5 py-6">
          <div className="rounded-[40px]  flex items-center ">
            <div className="text-[16px] font-bold text-primary">
              連携データを編集する
            </div>
          </div>
          <div className="mt-6">
            <div className="w-1/2">
              <Input
                label="連携データ名"
                name="name"
                className="w-full bg-white"
                placeholder="連携データ名を入力してください"
                defaultValue={linkage.name ?? ""}
              />
            </div>
          </div>
          <div className="mt-[35px]">
            <div className="text-[14px] ">対象スポット</div>
            <div className="flex mt-3 gap-2">
              {spotNames?.map((s) => {
                return (
                  <div className="bg-[#E8F0FE] px-6 h-[28px] rounded-[40px]  border border-[#E8F0FE] flex items-center justify-center text-primary font-bold text-[13px]">
                    {s}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex w-1/2 mt-[35px] mb-6">
            <DatePicker
              name="start_at"
              label={"データ連携開始日"}
              defaultValue={linkage.start_at}
              onChange={(value) => {
                updateLinkage({ key: "start_at", dateString: value });
              }}
              required
              placeholder="データ連携開始日を入力してください"
              disabled={!isAdmin}
              minDate={minDate}
            />
            <div className="w-5 mx-[20px] mt-[54px] h-[1px] border border-[#707070]" />
            <DatePicker
              name="end_at"
              label={"データ連携終了日"}
              defaultValue={linkage.end_at ?? undefined}
              onChange={(value) => {
                updateLinkage({ key: "end_at", dateString: value });
              }}
              required={false}
              placeholder="データ連携終了日を入力してください"
              disabled={!isAdmin}
              minDate={minDate}
            />
          </div>
        </div>
        <div className="py-5.5 bg-[#EEEEEE] flex gap-x-2.5 justify-end items-center px-5">
          <button
            type="button"
            className="border border-gray-light bg-white rounded w-[200px] h-11"
            onClick={() => {
              back();
            }}
          >
            戻る
          </button>
          <SaveButton />
          <SaveButton forPublish={true} />
        </div>
      </Form>
    </div>
  ) : null;
};
