import React from "react";
import { ReactComponent as CheckboxDashedIcon } from "../../assets/icons/dashed-checkbox.svg";

export const CheckboxDash: React.FC = () => {
  return (
    <div className="block mr-[1px] pb-[4px] w-[24px] h-[23px]">
      <div className="flex justify-center">
        <CheckboxDashedIcon />
      </div>
    </div>
  );
};
