import React from "react";
import { ReactComponent as CheckboxActiveIcon } from "../../assets/icons/active-checkbox.svg";
import { mergeClassNames } from "../../utils/style";

type Props = {
  size: "small" | "normal";
};

export const CheckboxActive: React.FC<Props> = ({ size }) => {
  return (
    <div
      className={mergeClassNames(
        "mr-px pb-1",
        size === "small" ? "w-4 h-3.75" : "w-6 h-5.75"
      )}
    >
      <div className="flex justify-center">
        <CheckboxActiveIcon />
      </div>
    </div>
  );
};
