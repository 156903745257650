import { IconProps } from "../types";

export default function IconNumberOfVisitorsAnalysis({ className }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className ?? "w-6 h-6 text-primary"}
        >
            <path
                fill="currentColor"
                d="M9.892 13.237c-2.329 0-6.893 1.152-6.893 3.814v1.54a.733.733 0 0 0 .733.733h12.32a.733.733 0 0 0 .733-.733v-1.54c0-2.662-4.564-3.814-6.893-3.814Zm5.426 4.62H4.466v-.807c0-1.3 3.283-2.347 5.426-2.347 2.143 0 5.427 1.052 5.427 2.347l-.001.807Zm-5.426-5.432A4.211 4.211 0 1 0 5.68 8.209a4.218 4.218 0 0 0 4.212 4.213m0-6.958a2.746 2.746 0 1 1-2.745 2.745 2.748 2.748 0 0 1 2.745-2.742m3.556-1.411a4.213 4.213 0 1 1 0 8.312 5.458 5.458 0 0 0 0-8.312Zm7.554 12.994v1.54a.734.734 0 0 1-.733.733h-2.457a1.9 1.9 0 0 0 .147-.733v-1.54c0-1.758-1.234-2.961-2.8-3.747 2.429.276 5.838 1.446 5.838 3.747"
            />
        </svg>
    );
}
