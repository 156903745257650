import actionCreatorFactory from "typescript-fsa";
import { PoiByConditionsRequest, PoiByConditionsResponse, PoiCondition } from "../../core/api";
import { CommonAsyncType } from "../../core/@types";

const actionCreator = actionCreatorFactory("@@PoiCondition");

export const poiConditionAction = {
  getPoiConditions: actionCreator.async<{}, PoiCondition[]>("GET_POI_CONDITIONS"),
  getPoiByConditions: actionCreator.async<{ body: PoiByConditionsRequest } & CommonAsyncType, PoiByConditionsResponse>("GET_POI_BY_CONDITIONS"),
};
