import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { analysisExecuteSelectors } from "../../../ducks/analysis_execute/selector";
import { assetSelectors } from "../../../ducks/asset/selector";
import { useAnalysisExecute } from "./hooks";

export const AnalysisExcution = () => {
  const step = useSelector(analysisExecuteSelectors.getStep);
  const displayList = useSelector(assetSelectors.getDisplayItems);
  const stepList = [
    displayList.analysis_item_selection,
    displayList.spot_selection,
    displayList.period_selection,
  ];
  const location = useLocation();
  const analysis = 1;
  const spotSel = stepList[0] === true ? 2 : 1;
  const periodSel =
    stepList[0] && stepList[1]
      ? 3
      : !stepList[0] && stepList[1]
      ? 2
      : stepList[0] && !stepList[1]
      ? 2
      : 1;
  const sideMenus = useSelector(assetSelectors.getMenus);

  const title = useMemo(() => {
    return sideMenus.filter((item) => item.path === location.pathname);
  }, []);
  const { handleNextStep, handleBackStep, isSaveButtonDisabled } =
    useAnalysisExecute();

  return (
    <div className="h-full flex flex-col">
      <div className="ml-[1px] py-[18px] bg-white leading-4 text-primary font-bold flex items-center pl-7.5">
        <p className="mr-[40px] text-[16px]">{title && title[0] ? title[0].name : ''}</p>

        {stepList[0] && (
          <p className="border-l-[1px] border-[#999999] pl-[16px] pr-[18px] py-[2px]">
            <span
              className={`mr-[6px] align-top inline-block text-[10px] text-center rounded-full font-bold w-[16px] h-[16px] text-[#ffffff] 
                  ${step === analysis ? "bg-black" : "bg-[#999999]"}`}
            >
              {analysis}
            </span>
            <span
              className={`text-[14px] align-top ${
                step === analysis ? "text-black" : "text-[#999999]"
              }`}
            >
              分析項目の選択
            </span>
          </p>
        )}
        {stepList[0] && stepList[1] && (
          <span className="align-top border-t-[1px] w-[15px] mr-[15px] border-[#999999] inline-block"></span>
        )}
        {stepList[1] && (
          <p className="py-[2px] pr-[18px]">
            <span
              className={`mr-[6px] align-top inline-block text-[10px] text-center rounded-full font-bold w-[16px] h-[16px] text-[#ffffff] ${
                step === spotSel ? "bg-black" : "bg-[#999999]"
              }`}
            >
              {spotSel}
            </span>
            <span
              className={`text-[14px] align-top ${
                step === spotSel ? "text-black" : "text-[#999999]"
              }`}
            >
              分析スポットの選択
            </span>
          </p>
        )}
        {((stepList[1] && stepList[2]) ||
          (stepList[0] && !stepList[1] && stepList[2])) && (
          <span className="align-top border-t-[1px] w-[15px] mr-[15px] border-[#999999] inline-block"></span>
        )}
        {stepList[2] && (
          <>
            <p className="py-[2px]">
              <span
                className={`mr-[6px] align-top inline-block text-[10px] text-center rounded-full font-bold w-[16px] h-[16px] text-[#ffffff] ${
                  step === periodSel ? "bg-black" : "bg-[#999999]"
                }`}
              >
                {periodSel}
              </span>
              <span
                className={`text-[14px] align-top ${
                  step === periodSel ? "text-black" : "text-[#999999]"
                }`}
              >
                分析期間の選択
              </span>
            </p>
          </>
        )}
      </div>
      <div className="mx-5 flex my-4 min-h-[577px] max-h-full overflow-hidden flex-col bg-white rounded px-7.5 py-6">
        <Outlet />
      </div>
      <div className="pb-5.5 pt-1.5 flex gap-x-2.5 justify-end items-center px-5">
        {step !== 1 && (
          <button
            className="border border-gray-light bg-white rounded w-[200px] h-10"
            onClick={handleBackStep}
          >
            戻る
          </button>
        )}
        <button
          disabled={isSaveButtonDisabled}
          className="text-white bg-primary disabled:bg-gray-border disabled:cursor-not-allowed rounded h-10 w-[200px]"
          onClick={handleNextStep}
        >
          次へ
        </button>
      </div>
    </div>
  );
};
