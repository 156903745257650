import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { v4 as uuidv4 } from "uuid";
import {
  FacilityMasterItemsType,
  MapStyle,
  defaultLngLat,
} from "../../../constants";
import {
  loadActiveMarkerImage,
  loadMarkerImage,
} from "../../../constants/utils";
import { FacilityMasterItems } from "../../../ducks/spot";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

type Props = {
  targetPinnedItem: FacilityMasterItems[];
  callback(selectedSpot?: FacilityMasterItems | null): void;
};

/*
  *** Developer Note ***
  Since mapbox do not support custom icon over their marker.
  In order to create a new marker base on the filter input (e.g. hotel, convenience store, etc),
  we need to create a custom icon for each filter type.
  1. Create SVG for inactive marker
  2. Create SVG for active marker
  after that map it to the loadMarkerImage and loadActiveMarkerImage in utils.ts
*/

export const FacilityMasterMap: React.FC<Props> = (props) => {
  const mapRef = useRef(null);
  const mapContainer = useRef(null);
  const [shownMarker, setShownMarker] = useState<
    { id: string; type: FacilityMasterItemsType }[]
  >([]);

  const [selectedSpot, setSelectedSpot] = useState<FacilityMasterItems>();

  //initial loading of map and its controller
  useEffect(() => {
    mapContainer.current = new mapboxgl.Map({
      container: mapRef.current,
      style: MapStyle,
      center: defaultLngLat,
      zoom: 14,
    })
      .addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: '地図を検索'
        })
      )
      .addControl(
        new MapboxLanguage({
          defaultLanguage: "ja",
        })
      )
      .addControl(new mapboxgl.NavigationControl(), "bottom-right")
      .addControl(
        new mapboxgl.GeolocateControl({ maxWidth: 300 }),
        "bottom-right"
      );
  }, []);

  useEffect(() => {
    //Remove previous market after changing the filtering options
    if (shownMarker.length > 0) {
      shownMarker.forEach((marker) => {
        document.getElementById(marker.id)?.remove();
      });
    }
    if (props.targetPinnedItem.length > 0) {
      //storing marketClass for purpose of remove and change styling
      let markersClass: { id: string; type: FacilityMasterItemsType }[] = [];

      //Create a market position base on filter options
      for (let i = 0; i < props.targetPinnedItem.length; i++) {
        const ID = uuidv4();
        markersClass.push({
          id: ID,
          type: props.targetPinnedItem[i].facility_type,
        });

        //create marker element and load its image from utils.ts
        const el = document.createElement("div");
        el.id = ID;
        el.className = `marker`;
        el.style.width = "26px";
        el.style.height = "36px";
        el.style.borderRadius = "100%";
        el.style.backgroundSize = "100%";
        el.style.backgroundPosition = "center";
        el.style.cursor = "pointer";
        el.style.backgroundImage = loadMarkerImage(
          props.targetPinnedItem[i].facility_type
        );

        //handling click events when user select on marker
        el.addEventListener("click", () => {
          el.style.backgroundImage = loadActiveMarkerImage(
            props.targetPinnedItem[i].facility_type
          );
          setSelectedSpot(props.targetPinnedItem[i]);
          props.callback(props.targetPinnedItem[i]);
          markersClass.forEach((item) => {
            if (item.id !== ID) {
              const unTargetEl = document.getElementById(
                item.id
              ) as HTMLElement;
              unTargetEl.style.backgroundImage = loadMarkerImage(item.type);
            }
          });
        });

        new mapboxgl.Marker(el)
          .setLngLat([
            props.targetPinnedItem[i].cordorinate[0],
            props.targetPinnedItem[i].cordorinate[1],
          ])
          .addTo(mapContainer.current);
      }
      setShownMarker(markersClass);
    } else {
      setShownMarker([]);
    }
  }, [props.targetPinnedItem]);

  const handleResetSelected = () => {
    shownMarker.forEach((item) => {
      const el = document.getElementById(item.id) as HTMLElement;
      el.style.backgroundImage = loadMarkerImage(item.type);
    });
    props.callback(null);
  };

  return (
    <div className="flex flex-1 h-full  w-full  ">
      <div ref={mapRef} className="w-full h-full rounded-[4px] ">
        <button
          id="remove-circle"
          className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
          onClick={handleResetSelected}
        >
          リセット
        </button>
      </div>
    </div>
  );
};
