//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import CommonActive from "../../assets/icons/common-active.png";
import CommonInActive from "../../assets/icons/common-inactive.png";
import { MapStyle } from "../../constants";
import { BLUE, RED } from "../../constants/color";
import { Spot } from "../../core/api";
import { analysisExecuteActions } from "../../ducks/analysis_execute";
import { analysisExecuteSelectors } from "../../ducks/analysis_execute/selector";
import { assetAction } from "../../ducks/asset";
import { assetSelectors } from "../../ducks/asset/selector";
import { isTrue } from "../../utils/array";
import { mergeClassNames } from "../../utils/style";
import { useAnalysisExecute } from "../pages/analysis_execution/hooks";

const initialHeight = window.innerHeight - 328;
type Props = {
  spots: (Spot & { preventSelection?: boolean })[];
  allowMultipleSelection?: boolean;
  renderPopup?: (spot: Spot) => JSX.Element;
};

const ZOOM = 13;
const LAYER_OPACITY = 0.15;

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "";

type Point = [number, number];

function calculatePolygonArea(points: Point[]): number {
  const numPoints = points.length;

  if (numPoints < 3) {
    return 0;
  }

  let area = 0;

  for (let i = 0; i < numPoints; i++) {
    const [currentPointLng, currentPointLat] = points[i];
    const [nextPointLng, nextPointLat] = points[(i + 1) % numPoints];
    area += (nextPointLng - currentPointLng) * (nextPointLat + currentPointLat);
  }

  area = Math.abs(area) / 2;
  return area;
}

let lastClick = 0;
export const SpotMap: React.FC<Props> = ({
  spots,
  allowMultipleSelection,
  renderPopup,
}) => {
  const dispatch = useDispatch();

  const { longitude: centerLongitude, latitude: centerLatitude } = useSelector(
    assetSelectors.getDefaultLocation
  );

  const { selectedAnalysisSpotItems } = useAnalysisExecute();
  const mapBound = useSelector(analysisExecuteSelectors.getMapBound);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const deleteSpotID = useSelector(analysisExecuteSelectors.getDeleteSpotId);

  useEffect(() => {
    dispatch(assetAction.fetchAsset.started({ forceRefetch: true }));
  }, []);

  useEffect(() => {
    // Draw the initial map only if it doesn't exists yet.
    if (!map.current && mapContainer.current) {
      mapContainer.current.style.minHeight = initialHeight + "px";
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: MapStyle,
        center: [centerLongitude, centerLatitude],
        zoom: ZOOM,
        tap: false,
      });
    }

    if (map.current) {
      map.current
        .addControl(
          new MapboxGeocoder({
            mapboxgl,
            accessToken: mapboxgl.accessToken,
            placeholder: "地図を検索",
          })
        )
        .addControl(new mapboxgl.NavigationControl(), "bottom-right")
        .addControl(new mapboxgl.GeolocateControl(), "bottom-right")
        .addControl(new MapboxLanguage({ defaultLanguage: "ja" }));

      map.current?.loadImage(
        CommonActive,
        (error: Error, image: HTMLImageElement) => {
          if (image) {
            map.current?.addImage("common-active-marker", image);
          }
        }
      );
      map.current?.loadImage(
        CommonInActive,
        (error: Error, image: HTMLImageElement) => {
          if (image) {
            map.current?.addImage("common-inactive-marker", image);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    const sortedSpots = [...spots].sort((a, b) => {
      const areaA =
        a.area?.data
          ?.map(({ longitude, latitude }) => {
            if (!longitude || !latitude) return null;
            return [longitude, latitude] as [number, number];
          })
          .filter<[number, number]>(isTrue) ?? [];

      const areaB =
        b.area?.data
          ?.map(({ longitude, latitude }) => {
            if (!longitude || !latitude) return null;
            return [longitude, latitude] as [number, number];
          })
          .filter<[number, number]>(isTrue) ?? [];

      return calculatePolygonArea(areaA) - calculatePolygonArea(areaB);
    });
    if (map.current) {
      if (Object.keys(deleteSpotID).length > 0) {
        const sourceId = `source-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
        const fillLayerId = `layer-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
        const lineLayerId = `line-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
        const circleLayerId = `circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
        const innerCircleLayerId = `inner-circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
        if (map.current.getLayer(fillLayerId))
          map.current.removeLayer(fillLayerId);
        if (map.current.getLayer(lineLayerId))
          map.current.removeLayer(lineLayerId);
        if (map.current.getLayer(circleLayerId))
          map.current.removeLayer(circleLayerId);
        if (map.current.getLayer(innerCircleLayerId))
          map.current.removeLayer(innerCircleLayerId);
        if (map.current.getSource(sourceId)) {
          map.current.removeSource(sourceId);
        }
      }
    }
    sortedSpots.forEach((spot) => {
      const { id, area, spot_name, preventSelection } = spot;
      const isCircle = area?.type === "circle";
      const isPoi = area?.type === "poi";

      const sourceId = `source-spots-${id}-${spot_name}`;
      const fillLayerId = `layer-spots-${id}-${spot_name}`;
      const lineLayerId = `line-spots-${id}-${spot_name}`;
      const circleLayerId = `circle-spots-${spot.id}-${spot.spot_name}`;
      const innerCircleLayerId = `inner-circle-spots-${id}-${spot_name}`;
      const coordinates =
        area?.data
          ?.map(({ longitude, latitude }) => {
            if (!longitude || !latitude) return null;
            return [longitude, latitude] as [number, number];
          })
          .filter<[number, number]>(isTrue) ?? [];
      if (map.current) {
        if (Object.keys(deleteSpotID).length > 0) {
          const sourceId = `source-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const fillLayerId = `layer-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const lineLayerId = `line-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const circleLayerId = `circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const innerCircleLayerId = `inner-circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          if (map.current.getLayer(fillLayerId))
            map.current.removeLayer(fillLayerId);
          if (map.current.getLayer(lineLayerId))
            map.current.removeLayer(lineLayerId);
          if (map.current.getLayer(circleLayerId))
            map.current.removeLayer(circleLayerId);
          if (map.current.getLayer(innerCircleLayerId))
            map.current.removeLayer(innerCircleLayerId);
          if (map.current.getSource(sourceId)) {
            map.current.removeSource(sourceId);
          }
        }
        // When all map resources are loaded, draw 2 layers on top - "fillLayerId" and "lineLayerId"
        const draw = () => {
          if (!map.current?.getSource(sourceId)) {
            if (isCircle || isPoi) {
              map.current?.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: { type: "Point", coordinates: coordinates[0] },
                  properties: {},
                },
              });
            } else {
              map.current?.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: { type: "Polygon", coordinates: [coordinates] },
                  properties: {},
                },
              });
            }
          }

          if (isCircle) {
            if (!map.current?.getLayer(fillLayerId)) {
              map.current?.addLayer({
                id: fillLayerId,
                type: "circle",
                source: sourceId,
                paint: {
                  "circle-radius": area.data?.[0]?.radius ?? 0,
                  "circle-color": BLUE,
                  "circle-opacity": LAYER_OPACITY,
                  "circle-stroke-width": 2,
                  "circle-stroke-color": BLUE,
                },
              });
            }
          } else if (isPoi) {
            if (!map.current?.getLayer(fillLayerId)) {
              map.current?.addLayer({
                id: fillLayerId,
                type: "symbol",
                source: sourceId,
                layout: {
                  "icon-image": "common-inactive-marker",
                  "icon-size": 0.25,
                },
              });
            }
          } else {
            if (!map.current?.getLayer(fillLayerId)) {
              map.current?.addLayer({
                id: fillLayerId,
                source: sourceId,
                type: "fill",
                paint: { "fill-color": BLUE, "fill-opacity": LAYER_OPACITY },
              });
            }
            if (!map.current?.getLayer(lineLayerId)) {
              map.current?.addLayer({
                id: lineLayerId,
                type: "line",
                source: sourceId,
                paint: { "line-color": BLUE, "line-width": 2 },
              });
            }
            if (!map.current?.getLayer(circleLayerId)) {
              map.current?.addLayer({
                id: circleLayerId,
                type: "circle",
                source: sourceId,
                paint: { "circle-radius": 4, "circle-color": BLUE },
              });
            }
            if (!map.current?.getLayer(innerCircleLayerId)) {
              map.current?.addLayer({
                id: innerCircleLayerId,
                type: "circle",
                source: sourceId,
                paint: { "circle-radius": 2, "circle-color": "#fff" },
              });
            }
          }
        };

        if (map.current.isStyleLoaded()) {
          draw();
        } else {
          map.current.on("load", () => {
            draw();
          });
        }
        // Display popup when user click on "fillLayerId"
        map.current.on("click", fillLayerId, (e: any) => {
          if (lastClick < Date.now() - 200) {
            lastClick = Date.now();
            if (!preventSelection) {
              dispatch(
                analysisExecuteActions.selectSpot.started({
                  ...spot,
                  multiple: !!allowMultipleSelection,
                })
              );
            }
            if (map.current && renderPopup && sortedSpots) {
              const popup = document.createElement("div");
              popup.id = fillLayerId;
              const root = createRoot(popup);
              root.render(
                renderPopup(sortedSpots.filter((sp) => sp.id === spot.id)[0])
              );
              new mapboxgl.Popup()
                .setLngLat({
                  lng: Math.max(...coordinates.map((x) => x[0])),
                  lat: Math.max(...coordinates.map((x) => x[1])),
                })
                .setDOMContent(popup)
                .addTo(map.current);
            }
          }
        });

        // Change cursor when user hover on "fillLayerId"
        map.current.on("mouseenter", fillLayerId, () => {
          if (map.current) {
            map.current.getCanvas().style.cursor = preventSelection
              ? "not-allowed"
              : "pointer";
            if (!isPoi) {
              map.current.setPaintProperty(
                fillLayerId,
                isCircle ? "circle-opacity" : "fill-opacity",
                LAYER_OPACITY + (preventSelection ? 0 : 0.1)
              );
            }
          }
        });
        map.current.on("mouseleave", fillLayerId, () => {
          if (map.current) {
            map.current.getCanvas().style.cursor = "";
            if (!isPoi) {
              map.current.setPaintProperty(
                fillLayerId,
                isCircle ? "circle-opacity" : "fill-opacity",
                LAYER_OPACITY - (preventSelection ? 0 : 0.1)
              );
            }
          }
        });
      }
    });
    if (Object.keys(mapBound).length > 0) {
      map.current.fitBounds?.(mapBound, {
        duration: 0.25,
      });
    } else {
      if (spots.length > 0) {
        let allLatitudes: number[] = [];
        let allLongitude: number[] = [];
        spots.forEach((spot) => {
          //if (spot.status === 'success') {
          if (spot.area?.data?.length === 0) return;
          const laltLng = spot.area?.data;
          const latitudeArray = laltLng
            ?.map((x) => x.latitude)
            ?.filter((lat) => lat !== undefined) as number[];
          const longitudeArray = laltLng
            ?.map((x) => x.longitude)
            ?.filter((lon) => lon !== undefined) as number[];

          allLatitudes.push(...latitudeArray);
          allLongitude.push(...longitudeArray);
        });
        const minLat: number = Math.min(...allLatitudes);
        const minLon: number = Math.min(...allLongitude);
        const maxLat: number = Math.max(...allLatitudes);
        const maxLon: number = Math.max(...allLongitude);
        const marginVal = 0.01;
        const bounds = new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(minLon - marginVal, minLat - marginVal),
          new mapboxgl.LngLat(maxLon + marginVal, maxLat + marginVal)
        );
        map.current.fitBounds?.(bounds, {
          duration: 0.25,
        });
      }
    }
  }, [spots, deleteSpotID]);

  useEffect(() => {
    const paint = () => {
      spots.forEach((spot) => {
        const isCirlce = spot.area?.type === "circle";
        const isPoi = spot.area?.type === "poi";
        const isSelected = selectedAnalysisSpotItems.find(
          (x) => x.id === spot.id
        );

        const fillLayerId = `layer-spots-${spot.id}-${spot.spot_name}`;
        const lineLayerId = `line-spots-${spot.id}-${spot.spot_name}`;
        const circleLayerId = `circle-spots-${spot.id}-${spot.spot_name}`;

        if (Object.keys(deleteSpotID).length > 0) {
          const sourceId = `source-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const fillLayerId = `layer-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const lineLayerId = `line-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const circleLayerId = `circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          const innerCircleLayerId = `inner-circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`;
          if (map.current.getLayer(fillLayerId))
            map.current.removeLayer(fillLayerId);
          if (map.current.getLayer(lineLayerId))
            map.current.removeLayer(lineLayerId);
          if (map.current.getLayer(circleLayerId))
            map.current.removeLayer(circleLayerId);
          if (map.current.getLayer(innerCircleLayerId))
            map.current.removeLayer(innerCircleLayerId);
          if (map.current.getSource(sourceId)) {
            map.current.removeSource(sourceId);
          }
        }
        if (
          !isPoi &&
          fillLayerId !==
            `layer-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`
        ) {
          // Paint the layer
          map.current?.setPaintProperty(
            fillLayerId,
            isCirlce ? "circle-color" : "fill-color",
            isSelected ? RED : BLUE
          );
        }

        if (isCirlce) {
          map.current?.setPaintProperty(
            fillLayerId,
            "circle-stroke-color",
            isSelected ? RED : BLUE
          );
        } else if (!isPoi) {
          if (
            lineLayerId !==
              `line-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}` &&
            circleLayerId !==
              `circle-spots-${deleteSpotID.id}-${deleteSpotID.spot_name}`
          )
            map.current
              ?.setPaintProperty(
                lineLayerId,
                "line-color",
                isSelected ? RED : BLUE
              )
              .setPaintProperty(
                circleLayerId,
                "circle-color",
                isSelected ? RED : BLUE
              );
        }

        if (isPoi) {
          map.current?.setLayoutProperty(
            fillLayerId,
            "icon-image",
            isSelected ? "common-active-marker" : "common-inactive-marker"
          );
        }
      });
    };
    map.current.on("drag", () => {
      if (map.current) {
        const bounds = map.current.getBounds();
        dispatch(analysisExecuteActions.setMapbound(bounds));
      }
    });
    map.current.on("wheel", () => {
      if (map.current) {
        const bounds = map.current.getBounds();
        dispatch(analysisExecuteActions.setMapbound(bounds));
      }
    });
    if (map.current?.isStyleLoaded()) {
      paint();
    } else {
      map.current?.on("load", () => {
        paint();
      });
    }
  }, [spots, selectedAnalysisSpotItems, deleteSpotID]);

  useEffect(() => {
    if (selectedAnalysisSpotItems.length === 0) {
      const closeButton = document.querySelector<HTMLButtonElement>(
        ".mapboxgl-popup-close-button"
      );
      closeButton?.click();
    }
  }, [selectedAnalysisSpotItems]);
  return (
    <div ref={mapContainer} className={mergeClassNames(`w-full h-full`)} />
  );
};
