import React, { useCallback, useMemo, useState } from "react";
import { z } from "zod";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { CustomButton } from "../atoms/CustomButton/CustomButton";
import { DatePicker } from "../atoms/DatePicker";
import Input from "../atoms/Input";
import Form from "../organisms/Form";

import { useTenantManagement } from "../pages/TenantManagement/hooks";
import { TenantSelection } from "./TenantSelection";

type Props = {
  isOpen: boolean;
  isEdit?: boolean;
  onClose: () => void;
  disabled: boolean;
};
export const TenantModal: React.FC<Props> = ({
  isOpen,
  isEdit,
  onClose,
  disabled,
}) => {
  const {
    cityState,
    createTenant,
    selectedTenantItem,
    tenantList,
    modifyTenant,
  } = useTenantManagement();
  const [selectedCityState, setSelectedCityState] = useState("");
  const [selectCityName, setSelectedCityName] = useState("");
  const [isResetDefaultCity, setIsResetDefaultCity] = useState(false);

  const handleChangeState = useCallback(
    (value: string) => {
      setSelectedCityState(value);
      setIsResetDefaultCity(true);
    },
    [selectedCityState]
  );
  const defaultItem = useMemo(() => {
    if (isEdit) {
      return {
        ...tenantList.find((item) => item.id === selectedTenantItem[0]),
      };
    } else {
      return {};
    }
  }, [isEdit]);

  const cityName = useMemo(() => {
    let cityDictionary: any = {};
    for (const key in cityState) {
      if (Array.isArray(cityState[key])) {
        for (const city of cityState[key]) {
          cityDictionary[city.id] = city.name;
        }
      }
    }
    return cityDictionary[defaultItem.state ?? 0];
  }, [defaultItem]);

  const defaultStateName = useMemo(() => {
    for (const key in cityState) {
      const cities = cityState[key];
      for (const city of cities) {
        if (city.name === cityName) {
          return key;
        }
      }
    }
    return "";
  }, [cityName]);

  const handleClickClose = () => {
    setIsResetDefaultCity(false);
    onClose();
  };

  const city = useMemo(() => {
    if (cityState) {
      if (!isResetDefaultCity && defaultStateName) {
        return cityState[defaultStateName];
      }
      return cityState[selectedCityState] ?? [];
    }
    return [];
  }, [selectedCityState, defaultStateName]);

  if (!isOpen || disabled) {
    return null;
  }
  return (
    <div className="fixed inset-0 z-30 bg-black/25 grid place-content-center">
      <div className="w-[800px] h-[540px] relative pt-[29px] rounded-lg bg-white px-[30px] flex  flex-col ">
        <div className="flex justify-between">
          <span className="font-[600]">
            {isEdit ? "テナント情報の編集" : "テナントの新規作成"}
          </span>
          <div
            onClick={handleClickClose}
            className="w-[32px] h-[32px] flex justify-end items-center cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
        <div className="w-full px-[50px] mt-[29px]">
          <Form
            schema={z.object({
              name: z
                .string()
                .min(1, { message: "テナント名を入力してください" }),
              state: z.string().min(1),
              start_at: z.string().min(1),
            })}
            onSubmit={async ({ ...field }) => {
              if (isEdit) {
                modifyTenant(
                  {
                    id: defaultItem.id ?? 0,
                    ...defaultItem,
                    ...field,
                    cityName: selectCityName,
                    setting: {
                      default_location: {
                        latitude:
                          defaultItem.setting?.default_location?.latitude,
                        longitude:
                          defaultItem.setting?.default_location?.longitude,
                      },
                    },
                  },
                  handleClickClose
                );
              } else {
                createTenant(
                  {
                    ...field,
                    cityName: selectCityName,
                    setting: {},
                  },
                  handleClickClose
                );
              }
            }}
          >
            <Input
              label="テナント名"
              name="name"
              className="w-full bg-white"
              placeholder="テナント名を入力してください"
              defaultValue={defaultItem && defaultItem.name}
              required
            />
            <div className="text-[14px] mt-[36px] mb-[11px]">対象エリア</div>
            <div className="flex mb-[36px]">
              <TenantSelection
                name="city"
                onChange={handleChangeState}
                defaltValue={defaultStateName}
                options={cityState}
              />
              <div className="ml-[10px]">
                <TenantSelection
                  name="state"
                  defaltValue={isResetDefaultCity ? "" : cityName}
                  defaultId={isResetDefaultCity ? "" : defaultItem.state}
                  isCity
                  onChange={(e) => {
                    setSelectedCityName(e);
                  }}
                  options={city}
                />
              </div>
            </div>

            <DatePicker
              fieldName="start_at"
              name="データ利用開始日"
              placeholder="データ利用開始日を入力してください"
              onChange={() => {}}
              label="データ利用開始日"
              required={false}
              defaultValue={defaultItem && defaultItem.start_date}
            />
            <div className="flex justify-center mt-[49px]">
              <CustomButton
                type={"button"}
                onClick={handleClickClose}
                text="キャンセル"
                className="bg-white text-black text-[14px] w-[200px] border"
              />
              <CustomButton
                type={"submit"}
                text="保存"
                className="text-[14px] w-[200px] ml-4"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
