import { call, getContext, put } from "typed-redux-saga";
import { takeEveryFsa } from "../operations";
import { Logger } from "../../core/logger";
import { poisApi, roadApi } from "../../core/http/openAPIClient";
import { roadAction } from "./actions";
import { RoadMapMockData } from "../../constants";

export function* roadSaga() {
  yield takeEveryFsa(roadAction.fetchRoadById.started, function* (actions) {
    const { onComplete, onSuccess, onError } = actions.payload;
    const response: any = yield* call(async (): Promise<unknown> => {
      return await roadApi.roadinfo(actions.payload.id).catch((error) => error);
    });

    if (response && response.error && onError) {
      if (!response.errorHandled) {
        onError(response.error);
      }
      return;
    }

    yield* put(
      roadAction.fetchRoadById.done({
        params: actions.payload,
        result: response.data,
      })
    );
    if (onSuccess) {
      onSuccess();
    }
  });

  yield takeEveryFsa(roadAction.fetchAllRoads.started, function* (actions) {
    const { onComplete, onSuccess, onError } = actions.payload;
    const response: any = yield* call(async (): Promise<unknown> => {
      return await poisApi.poilist().catch((error) => error);
    });
    if (response && response.error && onError) {
      if (!response.errorHandled) {
        onError(response.error);
      }
      return;
    }

    yield* put(
      roadAction.fetchAllRoads.done({
        params: actions.payload,
        result: response.data,
      })
    );
    if (onSuccess) {
      onSuccess();
    }
  });

  yield takeEveryFsa(
    roadAction.addRoadMapSelection.started,
    function* (actions) {
      yield* put(
        roadAction.addRoadMapSelection.done({
          params: actions.payload,
          result: RoadMapMockData,
        })
      );
    }
  );
  yield takeEveryFsa(roadAction.selectRoadLine.started, function* (actions) {
    yield* put(
      roadAction.selectRoadLine.done({
        params: actions.payload,
        result: actions.payload,
      })
    );
  });
  yield takeEveryFsa(
    roadAction.activateSecondDraw.started,
    function* (actions) {
      yield* put(
        roadAction.activateSecondDraw.done({
          params: actions.payload,
          result: {},
        })
      );
    }
  );
}
