import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selectApp = (state: StoreState) => state.tenant;

export const tenantSelectors = {
  getSelectedID: createSelector(selectApp, (state) => state.selectedID),
  getTenantList: createSelector(selectApp, (state) => state.tenantList),
  getNumLoad: createSelector(selectApp, (state) => state.numLoad),
  getOffsets: createSelector(selectApp, (state) => state.offSet),
  getPage: createSelector(selectApp, (state) => state.page),
  getMaxPage: createSelector(selectApp, (state) => state.maxPage),
};
