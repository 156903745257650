import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spot } from "../core/api";
import { ErrorMessageManager } from "../core/http/ErrorMessageManager";
import { analysisExecuteActions } from "../ducks/analysis_execute";
import { appActions } from "../ducks/app";
import { assetAction } from "../ducks/asset";
import { assetSelectors } from "../ducks/asset/selector";
import { roadAction } from "../ducks/roads";
import { roadSelectors } from "../ducks/roads/selector";
import { CirclePosition, FacilityMasterItems, spotAction } from "../ducks/spot";
import { spotSelectors } from "../ducks/spot/selector";
import { store } from "../store";

export const useCreateSpot = () => {
  const dispatch = useDispatch();
  const roadMapSelectionItem = useSelector(
    roadSelectors.getRoadMapOptionSelection
  );
  const activateSecondDraw = useSelector(roadSelectors.getActivateSecondDraw);
  const facilityMasterFilterTypes = useSelector(
    spotSelectors.getFacilityMasterFilterTypes
  );

  const selectedRoadLine = useSelector(roadSelectors.getSelectedRoadLine);

  //W-007 Target Facility Master Item to display coordinate on the map
  const targetFacilityMasterItem = useSelector(
    spotSelectors.getTargetFacilityMasterItem
  );

  const polygonPosition = useSelector(spotSelectors.getPolygonPostion);
  const circlePosition = useSelector(spotSelectors.getCirclePostion);
  const rectPosition = useSelector(spotSelectors.getRectanglePostion);
  const displayItems = useSelector(assetSelectors.getDisplayItems);
  const addPolygonPosition = (positions: number[]) => {
    dispatch(spotAction.addPolygonPosition.started(positions));
  };

  const resetPolygonPosition = () => {
    dispatch(spotAction.resetPolygonPosition());
  };

  const addRectanglePosition = (positions: number[]) => {
    dispatch(spotAction.addRectanglePosition.started(positions));
  };
  const resetRectanglePosition = () => {
    dispatch(spotAction.resetRectanglePosition());
  };
  const addCirclePosition = (position: CirclePosition) => {
    dispatch(spotAction.addCirclePosition.started(position));
  };
  const resetCirclePosition = () => {
    dispatch(spotAction.resetCirclePosition());
  };

  const setFacilityMasterItems = (
    facilityMasterItems: FacilityMasterItems[]
  ) => {
    dispatch(spotAction.setFacilityMasterItems.started(facilityMasterItems));
  };

  const handleSelectFacilityMasterType = useCallback(
    (key: string) => {
      if (facilityMasterFilterTypes.includes(key)) {
        dispatch(
          spotAction.removeSelectedFacilityMasterFilterType.started(key)
        );
      } else {
        dispatch(spotAction.addSelectedFacilityMasterFilterType.started(key));
      }
    },
    [facilityMasterFilterTypes]
  );

  const handleAddRoadMapSelection = (roadMap: any) => {
    dispatch(roadAction.addRoadMapSelection.started(roadMap));
  };
  const resetRoadMapSelection = () => {
    dispatch(roadAction.resetRoadMapSelection());
  };

  useEffect(() => {
    dispatch(assetAction.fetchService.started({}));
  }, []);

  const handleRegisterSopt = (item: Spot) => {
    let target = {};

    if (selectedRoadLine && selectedRoadLine.length > 0) {
      target = {
        spot_name: item.spot_name,
        dataset_name: item.dataset_name,
        area: {
          type: "polygon",
          data: selectedRoadLine[0].area,
          sub_data: selectedRoadLine[0].area,
          road_id: selectedRoadLine.map((item) => item.id),
        },
      };
    } else {
      target = {
        spot_name: item.spot_name,
        dataset_name: item.dataset_name,

        area: {
          type:
            polygonPosition && polygonPosition.length > 0
              ? "polygon"
              : circlePosition && circlePosition.radius > 0
              ? "circle"
              : "rectangle",
          data:
            polygonPosition && polygonPosition.length > 0
              ? polygonPosition
              : circlePosition && circlePosition.radius > 0
              ? [
                  {
                    longitude: circlePosition.lng,
                    latitude: circlePosition.lat,
                    radius: circlePosition.radius,
                  },
                ]
              : rectPosition,
        },
      };
    }

    dispatch(appActions.showLoading());
    dispatch(
      spotAction.registerSpot.started({
        body: target,
        onComplete: () => {
          resetCirclePosition();
          resetPolygonPosition();
          resetRectanglePosition();
          dispatch(appActions.hideLoading());
          store.dispatch(
            assetAction.fetchAsset.started({ forceRefetch: true })
          );
        },
        onError: (err: any) => {
          dispatch(appActions.hideLoading());
          const errorCode = err?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        },
        onSuccess: (response: any) => {
          dispatch(appActions.hideLoading());
          dispatch(appActions.showNotification());
          dispatch(
            appActions.displayNotification.started({
              type: "success",
              text: "スポットを保存しました",
            })
          );
          dispatch(assetAction.fetchSpotList.started({ forceRefetch: true }));
        },
      })
    );
  };

  const handleModifySpot = (item: Spot) => {
    dispatch(appActions.showLoading());
    dispatch(
      spotAction.modifySpot.started({
        body: item,
        onComplete: () => {
          dispatch(appActions.hideLoading());
          resetCirclePosition();
          resetPolygonPosition();
          resetRectanglePosition();
          store.dispatch(
            assetAction.fetchAsset.started({ forceRefetch: true })
          );
          dispatch(analysisExecuteActions.analysisReset());
        },
        onError: (err: any) => {
          const errorCode = err?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        },
        onSuccess: () => {
          dispatch(appActions.hideLoading());
          dispatch(appActions.showNotification());
          dispatch(
            appActions.displayNotification.started({
              type: "success",
              text: "スポットを保存しました",
            })
          );
          dispatch(analysisExecuteActions.analysisReset());

          dispatch(assetAction.fetchSpotList.started({ forceRefetch: true }));
        },
      })
    );
  };

  const handleRegisterPOISpot = (item: Spot) => {
    dispatch(appActions.showLoading());
    dispatch(
      spotAction.registerSpot.started({
        body: item,
        onComplete: () => {
          dispatch(appActions.hideLoading());
        },
        onError: (err: any) => {
          const errorCode = err?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        },
        onSuccess: () => {
          store.dispatch(
            assetAction.fetchAsset.started({
              forceRefetch: true,
              onComplete: () => {
                window.location.reload();
              },
            })
          );
        },
      })
    );
  };

  const deleteSpot = (id: number) => {
    dispatch(appActions.showLoading());
    dispatch(
      spotAction.deleteSpot.started({
        id: id,
        onError: (err: any) => {
          dispatch(appActions.hideLoading());
          const errorCode = err?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        },
        onSuccess: () => {
          dispatch(appActions.hideLoading());
          dispatch(appActions.showNotification());
          dispatch(
            appActions.displayNotification.started({
              type: "success",
              text: "スポットを削除しました",
            })
          );
          store.dispatch(
            assetAction.fetchAsset.started({
              forceRefetch: true,
            })
          );
        },
      })
    );
  };

  return {
    addPolygonPosition,
    resetPolygonPosition,
    addRectanglePosition,
    resetRectanglePosition,
    addCirclePosition,
    resetCirclePosition,
    setFacilityMasterItems,
    handleSelectFacilityMasterType,
    facilityMasterFilterTypes,
    targetFacilityMasterItem,
    handleAddRoadMapSelection,
    roadMapSelectionItem,
    resetRoadMapSelection,
    selectedRoadLine,
    handleRegisterPOISpot,
    handleRegisterSopt,
    activateSecondDraw,
    handleModifySpot,
    deleteSpot,
    displayItems,
  };
};
