import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { AppConfig } from "../../AppConfig";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { assetAction } from "./actions";
import { AssetState } from "./types";

const initialDefaultLocation = { longitude: 0, latitude: 0 };

const initialDisplayItems = {
  spot_create: false,
  analysis_item_selection: false,
  spot_selection: false,
  period_selection: false,
  spot_select_always: false,
  data_set_name: false,
  spot_sreate_methods: {
    poi: false,
    rectangle: false,
    circle: false,
    polygon: false,
  },
  data_gen_status: false,
};

const initialState: AssetState = {
  title: "",
  url: "",
  displayItems: initialDisplayItems,
  defaultLocation: initialDefaultLocation,
  menus: [],
  analysisTypes: [],
  spots: [],
  users: {
    value: [],
    totalPages: 1,
    currentPage: 1,
  },
  tenants: [],
  loggedInUser: null,
  isAdmin: false,
};

export function AssetReducer(state = initialState, action: Action): AssetState {
  if (isType(action, assetAction.fetchAsset.done)) {
    const { services, tenants } = action.payload.result;

    return {
      ...state,
      title: services?.[0].name ?? "",
      url: services?.[0].url ?? "",
      menus: services?.[0].menu?.global_menu ?? [],
      displayItems: services?.[0].menu?.display_items ?? initialDisplayItems,
      defaultLocation: {
        longitude: tenants?.[0].setting?.default_location?.longitude ?? 0,
        latitude: tenants?.[0].setting?.default_location?.latitude ?? 0,
      },
    };
  }

  if (isType(action, assetAction.fetchService.done)) {
    return {
      ...state,
      analysisTypes: action.payload.result.menu?.spot_menu ?? [],
      displayItems:
        action.payload.result.menu?.display_items ?? initialDisplayItems,
    };
  }

  if (isType(action, assetAction.fetchSpotList.done)) {
    return {
      ...state,
      spots: action.payload.result,
    };
  }

  if (isType(action, assetAction.fetchUserList.done)) {
    return {
      ...state,
      users: action.payload.result,
    };
  }

  if (isType(action, assetAction.fetchTenantList.done)) {
    return {
      ...state,
      tenants: action.payload.result,
    };
  }

  if (isType(action, assetAction.fetchLoggedInUser.done)) {
    const tenandId = LocalStorageManager.Instance.getObject(
      AppConfig.Instance.LocalStorageKey["tenantId"]
    );
    const targetTenant = action.payload.result?.tenants?.find(
      (item) => item.tenant_id === tenandId
    );
    return {
      ...state,
      loggedInUser: action.payload.result,
      isAdmin:
        targetTenant?.authorization === 0 ||
        action.payload.result?.wheel === true,
    };
  }

  if (isType(action, assetAction.reset)) {
    return {
      ...state,
      title: "",
      url: "",
      displayItems: initialDisplayItems,
      defaultLocation: initialDefaultLocation,
      menus: [],
      analysisTypes: [],
      spots: [],
      users: {
        value: [],
        totalPages: 1,
        currentPage: 1,
      },
      tenants: [],
      loggedInUser: null,
      isAdmin: false,
    };
  }

  return state;
}
