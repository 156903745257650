import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as SmallRightArrow } from "../../../assets/icons/small-right-arrow.svg";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { PoiFilterSchema } from "./Body";

export const PoiSelectorGroup = ({
  title,
  rows,
  selectedRows,
  setValue,
  setValueKey,
  filterText,
  prefix,
}: {
  title: string;
  rows: string[];
  selectedRows: string[];
  setValue: UseFormSetValue<PoiFilterSchema>;
  setValueKey: keyof PoiFilterSchema;
  filterText?: string | null;
  prefix?: string;
}) => {
  const [open, setOpen] = useState(false);
  const ulRef = useRef<HTMLUListElement>(null);
  const allSelectRef = useRef<HTMLInputElement>(null);
  const checkAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (selected) {
      setValue(
        setValueKey,
        selectedRows.filter((s) => !rows.includes(s))
      );
    } else {
      setValue(setValueKey, [...selectedRows, ...rows]);
    }
  };

  const allSelected = useMemo(() => {
    return rows?.every((n) => selectedRows.includes(n));
  }, [rows, selectedRows]);

  /**
   * メンバーどれか一つでも選択された状態ならtrue
   */
  const activeRows = useMemo(() => {
    return rows?.filter((n) => selectedRows.includes(n));
  }, [rows, selectedRows]);

  const selected = useMemo(() => {
    return activeRows.length > 0;
  }, [activeRows]);

  const checked = useCallback(
    (row: string) => {
      return -1 < selectedRows.findIndex((s) => s === row);
    },
    [selectedRows]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSelected = event.target.checked
        ? [...selectedRows, event.target.value]
        : selectedRows.filter((r) => {
            return r !== event.target.value;
          });
      setValue(setValueKey, newSelected);
    },
    [selectedRows, setValue, setValueKey]
  );

  /**
   * メンバーがチェック状態のときは すべて選択をindeterminateにする
   */
  useEffect(() => {
    if (allSelectRef.current) {
      // 一個でも選択されていれば、すべて選択はチェック状態（次にクリックで全部外す）
      allSelectRef.current.checked = selected;

      // 全チェック状態でなければindeterminateにする
      allSelectRef.current.indeterminate = selected && !allSelected;
    }
  }, [selected, filterText, open, allSelected]);

  return (
    <div className="w-full text-sm">
      {filterText ? (
        // テキストフィルタが入力されている場合はジャンルで区切らず全体からフィルタ
        <>
          {rows.length > 0 && (
            <>
              <div
                className={`cursor-pointer text-gray-500 py-3 flex px-4 items-center border-b ${
                  selected && "font-bold italic !text-primary"
                }`}
              >
                <div className="mr-2 transition-all origin-center rotate-90">
                  <SmallRightArrow />
                </div>
                {title}
                {selected && (
                  <span className="ml-4 text-sm font-normal">{`${activeRows.length}件 選択中`}</span>
                )}
              </div>

              <div className="bg-gray-50">
                <ul ref={ulRef} className="py-1.5 border-b">
                  {rows.map((i) => {
                    return (
                      <li key={i} className="hover:bg-gray-200 px-3 pl-8">
                        <label
                          className={`flex items-center py-1 cursor-pointer`}
                        >
                          <input
                            type="checkbox"
                            className="h-4.5 w-4.5 accent-primary"
                            value={i}
                            onChange={(e) => handleChange(e)}
                            checked={checked(i)}
                          />
                          <div className={`ml-3`}>
                            {prefix}
                            {i}
                          </div>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div
            className={`cursor-pointer text-gray-500 py-3 flex px-4 items-center border-b ${
              selected && "font-bold italic text-primary"
            }`}
            onClick={() => setOpen((i) => !i)}
          >
            <div
              className={`mr-2 transition-all origin-center ${
                open && "rotate-90"
              }`}
            >
              <SmallRightArrow />
            </div>
            {title}
            {selected && (
              <span className="ml-4 text-sm font-normal">{`${activeRows.length}件 選択中`}</span>
            )}
          </div>
          <div className={`bg-gray-50 ${!open && "hidden"}`}>
            <label className="flex w-fit items-center text-gray-400 pt-3 px-3 pl-8 cursor-pointer">
              <input
                type="checkbox"
                onChange={checkAll}
                className="h-4.5 w-4.5 accent-primary"
                ref={allSelectRef}
              />
              <div className="ml-3 text-sm">すべて選択</div>
            </label>
            <ul ref={ulRef} className="py-1.5 border-b">
              {rows.map((i) => {
                return (
                  <li key={i} className="hover:bg-gray-200 px-3 pl-8">
                    <label className={`flex items-center py-1 cursor-pointer`}>
                      <input
                        type="checkbox"
                        className="h-4.5 w-4.5 accent-primary"
                        value={i}
                        onChange={(e) => handleChange(e)}
                        checked={checked(i)}
                      />
                      <div className={`ml-3`}>
                        {prefix}
                        {i}
                      </div>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
