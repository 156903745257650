import React, { useEffect, useMemo } from "react";
import { parseLinkageTerm, useLinkage } from "./hooks";
import { useDispatch } from "react-redux";
import { appActions } from "../../../ducks/app";
import { useAnalysisExecute } from "../analysis_execution/hooks";

export const AddLinkageConfirm = () => {
  const dispatch = useDispatch();

  const {
    areaSettingType,
    isValidSpotIds,
    isValidSpotMenuId,
    spot_ids,
    registerLinkage,
  } = useLinkage();

  const { selectedAnalysisItem, getDate, analysisItem } = useAnalysisExecute();

  const termForDisplay = useMemo(() => {
    return parseLinkageTerm({
      term: getDate,
      analysisTypeId: selectedAnalysisItem.id ?? 0,
      analysisTypes: analysisItem,
    });
  }, [analysisItem, getDate, selectedAnalysisItem.id]);

  useEffect(() => {
    dispatch(appActions.hideLoading());
  }, [dispatch]);

  return (
    <div className="h-full flex flex-col">
      <div className="ml-[1px] py-[18px] bg-white leading-4 text-primary font-bold flex items-center pl-7.5">
        <p className="mr-[40px] text-[16px]">新規データ作成</p>
      </div>
      <div className="mx-5 flex my-4 max-h-full overflow-hidden flex-col bg-white rounded px-7.5 pt-6 pb-24">
        <div className="rounded-[40px]  flex items-center ">
          <div className="text-[16px] font-bold text-primary">設定確認</div>
        </div>
        <div className="mt-6 px-[1rem]">
          <div className="mb-3">【設定条件】</div>
          <div className="grid grid-cols-[120px_1fr] gap-y-2 items-center ml-3">
            <div className="text-sm text-gray-400">場所の設定方法:</div>
            <div>{areaSettingType}</div>
            <div className="text-sm text-gray-400">データ種別:</div>
            <div>{selectedAnalysisItem.name}</div>
            <div className="text-sm text-gray-400">期間:</div>
            <div>{termForDisplay}</div>
          </div>
          <div className="mt-10 mb-3">【概算見積り】</div>
          <div className="grid grid-cols-[120px_1fr] gap-y-2 items-center ml-3">
            <div className="text-sm text-gray-400">初期費用:</div>
            <div>〇〇万円</div>
            <div className="text-sm text-gray-400">月額費用:</div>
            <div>△△万円</div>
          </div>
          <div className="mt-3 text-sm ml-3">※詳細なお見積りが別途必要となりますので、システム管理者までお問い合わせください。</div>
        </div>
      </div>
      <div className="py-5.5 bg-[#EEEEEE] flex gap-x-2.5 justify-end items-center px-5">
        <button
          disabled={
            !isValidSpotIds(spot_ids) ||
            !isValidSpotMenuId(selectedAnalysisItem.id)
          }
          onClick={() => registerLinkage({ is_draft: true })}
          className="border border-gray-light bg-white rounded w-[200px] h-10 disabled:bg-gray-border disabled:text-white disabled:cursor-not-allowed"
        >
          下書き保存
        </button>
        <button
          disabled={
            !isValidSpotIds(spot_ids) ||
            !isValidSpotMenuId(selectedAnalysisItem.id)
          }
          onClick={() => registerLinkage()}
          className="text-white bg-primary rounded h-10 w-[200px] disabled:bg-gray-border disabled:cursor-not-allowed"
        >
          出力開始
        </button>
      </div>
    </div>
  );
};
