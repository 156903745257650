import { Linkage } from "../../core/api";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import {
  findLinkageDataType,
  parseLinkageStatus,
  parseLinkageTerm,
} from "../pages/linkages/hooks";
import Checkbox from "../atoms/Checkbox";
import { useAnalysisExecute } from "../pages/analysis_execution/hooks";

export const LinkageTable = ({
  linkages,
  needsCheckBox = false,
  emitSelected = undefined,
}: {
  linkages: Linkage[];
  needsCheckBox?: boolean;
  emitSelected?: ({ id }: { id: number | undefined }) => void;
}) => {
  const { analysisItem } = useAnalysisExecute();

  const [selected, setSelected] = useState<number | undefined>();

  const updateSelected = useCallback(
    ({ id }: { id: number }) => {
      const sel = selected === id ? undefined : id;
      setSelected(sel);
      if (emitSelected) {
        emitSelected({ id: sel });
      }
    },
    [emitSelected, selected]
  );

  const items = useMemo(() => {
    return linkages
      .map((h, idx) => {
        const {
          id,
          name,
          is_draft,
          start_at,
          end_at,
          spot_count,
          data_type,
          term,
        } = h;

        const status = parseLinkageStatus({ is_draft, start_at, end_at });
        const termForDisplay = parseLinkageTerm({
          term,
          analysisTypeId: data_type,
          analysisTypes: analysisItem,
        });

        const startAtForDisplay = start_at
          ? dayjs(start_at).format("YYYY/MM/DD")
          : null;

        const endAtForDisplay = end_at
          ? dayjs(end_at).format("YYYY/MM/DD")
          : null;

        const dataType = findLinkageDataType({
          analysisTypes: analysisItem,
          dataTypeId: data_type,
        });

        const headers = {
          checkBox: "" as string | undefined,
          id: "ID",
          name: "連携データ名",
          status: "連携ステータス",
          spotCount: "スポット数",
          dataType: "データ種別",
          terms: "期間",
          exportType: "出力形式",
          startAt: "連携開始日",
          endAt: "連携終了日",
        };

        const values = {
          checkBox: "" as string | undefined,
          id,
          name,
          status,
          spot_count,
          dataTypeName: dataType ? dataType.name : "",
          termForDisplay,
          exportType: "JSON",
          startAtForDisplay,
          endAtForDisplay,
        };

        if (!needsCheckBox) {
          delete headers.checkBox;
          delete values.checkBox;
        }

        return idx === 0 ? [headers, values] : values;
      })
      .flat();
  }, [analysisItem, linkages, needsCheckBox]);

  return 0 < items.length ? (
    <>
      {items.map((item, idx) => {
        const values = Object.values(item);
        const gridFirstColumnStyle = !needsCheckBox ? "" : "60px ";
        const repeat = !needsCheckBox ? values.length : values.length - 1;
        const gridStyle = {
          gridTemplateColumns: `${gridFirstColumnStyle}repeat(${repeat}, minmax(0, 1fr))`,
        };
        return (
          <div
            key={idx}
            className={`grid ${
              idx === 0 && "font-bold bg-gray-light text-white text-[11px]"
            }`}
            style={gridStyle}
          >
            {values.map((v, vIdx) => {
              const borderLeft = vIdx === 0 && "border-l";
              const showCheckBox = needsCheckBox && 0 < idx && vIdx === 0;
              return (
                <div
                  key={vIdx}
                  className={`${borderLeft} border-r border-b p-2 flex justify-center items-center text-sm`}
                >
                  {showCheckBox ? (
                    <Checkbox
                      selected={selected === item.id}
                      onClick={() => updateSelected({ id: item.id as number })}
                    />
                  ) : (
                    v
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  ) : null;
};
