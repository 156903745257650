import IconChevron from "../../icons/IconChevron";
import IconDoubleChevron from "../../icons/IconDoubleChevron";

type Props = {
  currentPage: number;
  totalPages: number;
  onPrevious: () => void;
  onNext: () => void;
  onFirst: () => void;
  onLast: () => void;
};

export default function Pagination({
  currentPage,
  totalPages,
  onFirst,
  onPrevious,
  onNext,
  onLast,
}: Props) {
  return (
    <div className="flex border border-[#B5B5B5] divide-x divide-[#B5B5B5] rounded">
      <button
        type="button"
        disabled={currentPage <= 1}
        className="disabled:opacity-30 px-2"
        onClick={onFirst}
      >
        <IconDoubleChevron />
      </button>
      <button
        type="button"
        disabled={currentPage <= 1}
        className="disabled:opacity-30 px-2.5"
        onClick={onPrevious}
      >
        <IconChevron />
      </button>

      <div className="text-xs px-2.5 py-1 select-none bg-gray-light text-white">
        {currentPage}
      </div>

      <button
        type="button"
        disabled={currentPage >= totalPages}
        className="disabled:opacity-30 px-2.5"
        onClick={onNext}
      >
        <IconChevron direction="right" />
      </button>
      <button
        type="button"
        disabled={currentPage >= totalPages}
        className="disabled:opacity-30 px-2"
        onClick={onLast}
      >
        <IconDoubleChevron direction="right" />
      </button>
    </div>
  );
}
