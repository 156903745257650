export interface IAppConfig {
  LocalStorageKey: Record<string, string>;
}

export class AppConfig implements IAppConfig {
  private static _instance: IAppConfig;
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  readonly LocalStorageKey = {
    accessToken: "t__moe_access_token",
    refreshToken: "t__moe_refresh_token",
    receptionIDList: "t__moe_reception_id_list",
    userId: "t__moe_user_id",
    tenantId: "t__moe_tenant_id",
    authority: "t__moe_authority",
    assets: "t__moe_assets",
  };
}
