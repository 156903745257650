import { call, getContext, put } from "typed-redux-saga";
import { takeEveryFsa } from "../operations";
import { Logger } from "../../core/logger";
import { poisApi } from "../../core/http/openAPIClient";
import { poiAction } from "./actions";

export function* poiSaga() {
  yield takeEveryFsa(poiAction.fetchPOIById.started, function* (actions) {
    const { onComplete, onSuccess, onError } = actions.payload;
    const response: any = yield* call(async (): Promise<unknown> => {
      return await poisApi.poiinfo(actions.payload.id).catch((error) => error);
    });

    if (response && response.error && onError) {
      if (!response.errorHandled) {
        onError(response.error);
      }
      return;
    }

    yield* put(
      poiAction.fetchPOIById.done({
        params: actions.payload,
        result: response.data,
      })
    );
    if (onSuccess) {
      onSuccess();
    }
  });

  yield takeEveryFsa(poiAction.fetchAllPOIs.started, function* (actions) {
    const { onComplete, onSuccess, onError } = actions.payload;
    const response: any = yield* call(async (): Promise<unknown> => {
      return await poisApi.poilist().catch((error) => error);
    });
    if (response && response.error && onError) {
      if (!response.errorHandled) {
        onError(response.error);
      }
      return;
    }

    yield* put(
      poiAction.fetchAllPOIs.done({
        params: actions.payload,
        result: response.data,
      })
    );
    if (onSuccess) {
      onSuccess();
    }
  });
}
