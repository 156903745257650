import IconAnalysisExecution from "../icons/SideNav/IconAnalysisExcecution";
import IconAnalysisSpot from "../icons/SideNav/IconAnalysisSpot";
import IconHistory from "../icons/SideNav/IconHistory";
import IconTenantManagement from "../icons/SideNav/IconTenantManagement";
import IconUserManagement from "../icons/SideNav/IconUserManagement";
import { IconComponent } from "../icons/types";

export const changeIdToIcon = (id: number | undefined) => {
  let icon: IconComponent | null = null;
  switch (id) {
    case 1:
      icon = IconAnalysisSpot;
      break;
    case 2:
      icon = IconAnalysisExecution;
      break;
    case 3:
      icon = IconHistory;
      break;
    case 4:
      icon = IconTenantManagement;
      break;
    case 5:
      icon = IconUserManagement;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};
