import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as VisitorIcon } from "../../../assets/icons/visitor-primary.svg";
import { analysisExecuteActions } from "../../../ducks/analysis_execute";
import { analysisExecuteSelectors } from "../../../ducks/analysis_execute/selector";
import { AnalysicAreaText } from "../../atoms/AnalysicAreaText";
import { DatePicker } from "../../atoms/DatePicker";
import Input from "../../atoms/Input";
import Form from "../../organisms/Form";
import { useAnalysisExecute } from "../analysis_execution/hooks";

export const SelectSpotAnalysisType = () => {
  const dispatch = useDispatch();
  const { selectedAnalysisItem, selectedAnalysisSpotItems } =
    useAnalysisExecute();

  const getDate = useSelector(analysisExecuteSelectors.getGetDate);
  const selectedAnalysis = useSelector(
    analysisExecuteSelectors.getSelectedItem
  );
  const retroactiveDate = selectedAnalysis.retroactiveDate
    ? selectedAnalysis.retroactiveDate
    : 30;
  const type = selectedAnalysis.type ? selectedAnalysis.type : "actual";

  const minDate = useMemo(() => {
    return dayjs().add(1, "day").toDate();
  }, []);

  useEffect(() => {
    dispatch(analysisExecuteActions.setStep.started(3));
    dispatch(analysisExecuteActions.setGetDate(retroactiveDate));
  }, []);

  return (
    <div className="mx-[30px] my-[25px] flex flex-col min-h-[540px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center ">
          <VisitorIcon />
          <div className="ml-[5px] text-[14px] font-bold text-primary">
            {selectedAnalysisItem.name}
          </div>
        </div>
      </div>

      <div className="relative w-full overflow-x-auto overflow-hidden">
        <div className="flex justify-left items-center mt-[23px] overflow-x-auto overflow-hidden">
          {selectedAnalysisSpotItems?.map((spot: any) => {
            return (
              <AnalysicAreaText
                key={spot.id}
                text={spot.spot_name || spot.name}
              />
            );
          })}
        </div>
      </div>
      <Form onSubmit={() => {}}>
        <div className="max-w-[840px] mx-[auto] mt-[20px] flex w-full items-center bg-white">
          {type === "actual" && (
            <p className="text-black text-sm mt-[8px] mr-[8px]">過去</p>
          )}
          <Input
            type={"number"}
            name="numberOfDate"
            placeholder=""
            value={getDate}
            onInput={(value) => {
              const target = value.target as any;
              let val = target.value;
              val = val === "" ? 0 : val;
              val = val > retroactiveDate ? retroactiveDate : val;
              val = val < 0 ? 0 : val;
              dispatch(analysisExecuteActions.setGetDate(val));
            }}
            className={`border-none text-center w-[50px] h-[46px] text-[100%] disabled:cursor-not-allowed disabled:bg-gray-soft/70 outline-none rounded-md p-1.5 text-black placeholder-shown:bg-gray-soft placeholder-shown:text-[13px] bg-white`}
          />
          <p className="text-black text-sm mt-[7px] ml-[8px]">
            {type === "actual" ? "日分取得する" : "日分先まで取得する"}
          </p>
        </div>
        <div className="max-w-[840px] mx-[auto] flex w-full items-start justify-center mt-[35px]">
          <DatePicker
            name="startDate"
            label={"データ連携開始日"}
            onChange={(value) =>
              dispatch(
                analysisExecuteActions.setDate.started({
                  type: "startDate",
                  date: value,
                })
              )
            }
            required
            placeholder="データ連携開始日を入力してください"
            minDate={minDate}
          />
          <div className="w-5 mx-[20px] mt-[52px] border border-[#707070]" />
          <DatePicker
            name="endDate"
            label={"データ連携終了日"}
            onChange={(value) =>
              dispatch(
                analysisExecuteActions.setDate.started({
                  type: "endDate",
                  date: value,
                })
              )
            }
            required={false}
            placeholder="データ連携終了日を入力してください"
            minDate={minDate}
          />
        </div>
      </Form>
    </div>
  );
};
