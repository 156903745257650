import { mergeClassNames } from "../utils/style";
import { IconProps } from "./types";

type Props = IconProps & {
  direction?: "up" | "down";
};

export default function IconDropdown({ className, direction }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 8"
      className={mergeClassNames(
        className ?? "h-2",
        direction === "up" && "rotate-180"
      )}
    >
      <path d="m0 0 8 8 8-8Z" />
    </svg>
  );
}
