import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { AppConfig } from "../../AppConfig";
import { Spot } from "../../core/api";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { analysisExecuteActions } from "./actions";
import { AnalysisExecuteState } from "./types";

const initialState: AnalysisExecuteState = {
  selectedSpotItems: [],
  selectedItem: {
    name: "",
    id: 0,
    description: "",
    usecase_description: "",
    image_url: "",
    min: 0,
    max: 0,
  },
  step: 1,
  date: {
    startDate: "",
    endDate: "",
  },
  getDateNum: 30,
  type: "actual",
  mapBound: {},
  deleteSpotID: {},
};

export function AnalysisExecuteReducer(
  state = initialState,
  action: Action
): AnalysisExecuteState {
  if (isType(action, analysisExecuteActions.addSelectedItem.done)) {
    return {
      ...state,
      selectedItem: action.payload.params,
    };
  }

  if (isType(action, analysisExecuteActions.removeSelectedItem.done)) {
    return {
      ...state,
      selectedItem: {
        name: "",
        id: 0,
        description: "",
        usecase_description: "",
        image_url: "",
        min: 0,
        max: 0,
      },
    };
  }

  if (isType(action, analysisExecuteActions.addSelectedSpotItem.done)) {
    return {
      ...state,
      selectedSpotItems: [...state.selectedSpotItems, action.payload.params],
    };
  }

  if (isType(action, analysisExecuteActions.selectSpot.done)) {
    const { multiple, ...selectedSpot } = action.payload.params;

    const asset: any = LocalStorageManager.Instance.getObject(
      AppConfig.Instance.LocalStorageKey["assets"]
    );
    const targetSelectSpot: Spot = asset.spots.value.find(
      (it: Spot) => it.id === selectedSpot.id
    );

    const isMax = state.selectedSpotItems.length === state.selectedItem.max;
    const alreadySelected = state.selectedSpotItems.find(
      (x: Spot) => x.id === targetSelectSpot.id
    );

    if (
      isMax &&
      !alreadySelected &&
      state.selectedItem.max &&
      state.selectedItem.max > 0
    ) {
      return state;
    }
    return {
      ...state,
      selectedSpotItems: alreadySelected
        ? state.selectedSpotItems.filter(
            (x: Spot) => x.id !== targetSelectSpot.id
          )
        : multiple
        ? [...state.selectedSpotItems, targetSelectSpot]
        : [targetSelectSpot],
    };
  }

  if (isType(action, analysisExecuteActions.setSelectedSpotItems.done)) {
    return {
      ...state,
      selectedSpotItems: action.payload.params,
    };
  }

  if (isType(action, analysisExecuteActions.removeSelectedSpotItem.done)) {
    return {
      ...state,
      selectedSpotItems: state.selectedSpotItems.filter(
        (x) => x.id !== action.payload.params
      ),
    };
  }

  if (isType(action, analysisExecuteActions.setDate.done)) {
    if (action.payload.params.type === "startDate") {
      return {
        ...state,
        date: {
          ...state.date,
          startDate: action.payload.params.date,
        },
      };
    } else {
      return {
        ...state,
        date: {
          ...state.date,
          endDate: action.payload.params.date,
        },
      };
    }
  }

  if (isType(action, analysisExecuteActions.setStep.done)) {
    return {
      ...state,
      step: action.payload.params,
    };
  }

  if (isType(action, analysisExecuteActions.analysisReset)) {
    return {
      ...state,
      selectedSpotItems: [],
    };
  }

  if (isType(action, analysisExecuteActions.setGetDate)) {
    return {
      ...state,
      getDateNum: action.payload,
    };
  }

  if (isType(action, analysisExecuteActions.analysisResetForLinkage)) {
    return initialState;
  }

  if (isType(action, analysisExecuteActions.setMapbound)) {
    return {
      ...state,
      mapBound: action.payload,
    };
  }
  if (isType(action, analysisExecuteActions.setDeleteSpotId)) {
    return {
      ...state,
      deleteSpotID: action.payload,
    };
  }
  return state;
}
