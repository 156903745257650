import React, { useCallback, useState } from "react";
import { CreateSpotType, RoadMapMockData } from "../../../constants";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-icon.svg";
import { MoeCheckbox } from "../MoeCheckbox";
import { useDispatch } from "react-redux";
import { roadAction } from "../../../ducks/roads";

type Props = {
  index: number;
  roadItems: any;
  type?: string;
};
export const RoadItemSelection: React.FC<Props> = (props) => {
  const [selectedItem, setSelectedItem] = useState<any[]>([]);
  const dispatch = useDispatch();
  const handleSelectItem = useCallback(
    (item: any) => {
      const isExist = selectedItem.find((tar) => tar.id === item.id);
      if (!isExist) {
        dispatch(
          roadAction.selectRoadLine.started({
            ...item,
            id: item.id + props.index,
          })
        );

        setSelectedItem([...selectedItem, item]);
      } else {
        setSelectedItem([...selectedItem.filter((tar) => tar.id !== item.id)]);
      }
    },
    [selectedItem]
  );

  const handleClick = () => {
    if (props.type === CreateSpotType.POLYGON) {
      dispatch(roadAction.activateSecondDraw.started(true));
    }
    if (props.type === CreateSpotType.RECTANGLE) {
      const comp = document.getElementById("rect-road-map");

      comp?.addEventListener("click", () => {
        comp.classList.add("index-2");
      });
    }
  };

  const handleDelete = () => {
    dispatch(roadAction.activateSecondDraw.started(false));
  };

  return (
    <div className="mt-[28px]">
      <div>可視化する道路を選択</div>
      <div className="w-full h-[100px] border border-[#B5B5B5] overflow-y-scroll rounded-sm mt-[15px]">
        {props.roadItems.map((item: any, index: number) => {
          return (
            <div
              className="h-[40px] ml-[15px] text-[13px] flex items-center"
              key={item.id}
            >
              <MoeCheckbox
                isSelected={selectedItem.find((tar) => item.id === tar.id)}
                onClick={() => handleSelectItem(item)}
              />
              <div className="ml-[10px]">{item.name}</div>
            </div>
          );
        })}
      </div>
      {props.index < 1 && (
        <div className="flex justify-between items-center py-[10px] mt-[20px]">
          <button
            onClick={handleClick}
            className="flex items-center border py-[10px] px-[17px] bg-[#E8F0FE] border-[#4769B3] rounded "
          >
            <PlusIcon />
            <div className="text-[#4769B3] text-[12px] pl-[6px]">
              範囲を追加
            </div>
          </button>
          <button className="text-[11px] text-[#4769B3]" onClick={handleDelete}>
            クリア
          </button>
        </div>
      )}
    </div>
  );
};
