import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { LoginState } from "./types";
import { LoginAction } from "./actions";

const initialState: LoginState = {
  tenantId: 0,
  userId: 0,
  authority: 0,
  refreshToken: '',
};

export function LoginReducer(
  state = initialState,
  action: Action
): LoginState {
  if (isType(action, LoginAction.login.done)) {
    return {
      ...state,
      tenantId: action.payload.result.iam.tenant_id,
      userId: action.payload.result.iam.user_id,
      authority: action.payload.result.iam.authority,
      refreshToken: action.payload.result.refresh_token,
    };
  }
  if (isType(action, LoginAction.setUserInformation)) {
    return {
      ...state,
      tenantId: action.payload.tenant_id,
      userId: action.payload.user_id,
      authority: action.payload.authority
    }
  }
  return state;
}
