// rule
export const INCLUDE_LOWERCASE = /[a-z]+/;
export const INCLUDE_UPPERCASE = /[A-Z]+/;
export const INCLUDE_NUMBER = /[0-9]+/;
export const PASSWORD_SPECIAL = /^[a-zA-Z0-9 !#%*,-.\/;<=>?@^_|]+$/;

// message
export const LOGIN_MESSAGE = {
  TENANT_NAME: "テナント名は1文字以上入力してください",
  TENANT_ID: 'テナントIDは1文字以上入力してください',
  EMAIL: "Eメールを入力してください",
  PASSWORD:
    "パスワードは大文字、小文字、数字、記号の組合せの8桁以上64桁以下で入力して下さい。\n記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。",
  PASSWORD_INPUT: "パスワードを入力してください",
};

export const UserManagementMessage = {
  EMAIL: "Eメールを入力してください",
  PASSWORD:
    "パスワードは大文字、小文字、数字、記号の組合せの8桁以上64桁以下で入力して下さい。 記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。",
  PASSWORD_INPUT: "パスワードを入力してください",
  NAME: "アカウント名は1文字以上入力してください",
};
