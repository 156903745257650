import React from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { PageRoutes } from "../../../constants/Routes";
import { LOGIN_MESSAGE } from "../../../constants/schema";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import Input from "../../atoms/Input";
import Form from "../../organisms/Form";
import { useLogin } from "./hooks";

export const Login: React.FC<{}> = () => {
  const { onSubmitHandler } = useLogin();

  return (
    <div className="grid place-items-center w-full">
      <Form
        schema={z.object({
          tenantName: z.string().min(1, { message: LOGIN_MESSAGE.TENANT_ID }),
          emailAddress: z.string().email({ message: LOGIN_MESSAGE.EMAIL }),
          password: z
            .string()
            .min(1, { message: LOGIN_MESSAGE.PASSWORD_INPUT }),
        })}
        className="flex flex-col w-full max-w-100 gap-y-14"
        onSubmit={onSubmitHandler}
      >
        <span className="text-3xl text-gray-dark text-center">ログイン</span>
        <div className="flex flex-col gap-y-7.5">
          <Input
              name="tenantName"
              type="number"
              placeholder="テナントID"
              className="w-full"
              onKeyDown={
              (event) => {
                  return event.key === 'e' && event.preventDefault();
              }}
          />
          <Input
            name="emailAddress"
            placeholder="Eメール"
            className="w-full"
          />
          <Input
            name="password"
            type="password"
            placeholder="パスワード"
            maxLength={64}
            className="w-full"
          />
          <Link
            to={PageRoutes.FORGOT_PASSWORD}
            className="mt-[15px] mx-auto hover:underline text-primary text-sm"
          >
            パスワードをお忘れの方
          </Link>
        </div>
        <CustomButton type="submit" text="ログイン" />
      </Form>
    </div>
  );
};
