import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { analysisExecuteActions } from "../../../ducks/analysis_execute";
import { assetAction } from "../../../ducks/asset";
import { AnalysisItemCard } from "../../molecules/AnalysisItemCard";
import { useAnalysisExecute } from "../../pages/analysis_execution/hooks";

export const SelectAnalysis = () => {
  const dispatch = useDispatch();
  const { analysisItem, handleSelect, selectedAnalysisItem } =
    useAnalysisExecute();

  useEffect(() => {
    dispatch(assetAction.fetchSpotList.started({ forceRefetch: true }));
    dispatch(assetAction.fetchService.started({}));
    dispatch(analysisExecuteActions.setStep.started(1));
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-y-5 overflow-hidden">
      <span className="text-sm">
        分析する項目を選択し「次へ」を押下してください
      </span>
      {analysisItem.length > 0 ? (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(280px,1fr))] gap-4 overflow-y-auto">
          {analysisItem.map((item) => {
            return (
              <AnalysisItemCard
                isSelected={selectedAnalysisItem?.id === item.id}
                onClick={handleSelect}
                item={item}
                key={item.id}
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center text-lg text-[#666666] font-bold h-full">
          分析項目がありません。
        </div>
      )}
    </div>
  );
};
