//@ts-nocheck
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { MapStyle, drawingControlStyle } from "../../../constants";
import { assetSelectors } from "../../../ducks/asset/selector";
import { RoadResponseBody } from "../../../ducks/roads";
type Props = {
  callback?(value: any): void;
  selectedRoad?: RoadResponseBody[];
  secondDraw?: boolean;
};

const TOKEN = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

let draw = new MapboxDraw({
  userProperties: true,
  displayControlsDefault: false,
  styles: drawingControlStyle,
});
export const PolygonRoadMap: React.FC<Props> = (props) => {
  const [features, setFeatures] = useState({});
  const mapContainer = useRef(null) as any;
  const map = useRef(null) as any;
  const [polygonSource, setPolygonSource] = useState([]);
  const [roadSource, setRoadSource] = useState([]);
  const defaultLocation = useSelector(assetSelectors.getDefaultLocation);

  const [lng, setLng] = useState(defaultLocation.longitude);
  const [lat, setLat] = useState(defaultLocation.latitude);
  const [zoom, setZoom] = useState(13);

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };

      for (const f of e.features) {
        newFeatures[f.id] = f;
      }

      return newFeatures;
    });
    props.callback((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: MapStyle,
        center: [lng, lat],
        zoom: zoom,
      })
        .addControl(
          new MapboxGeocoder({
            accessToken: TOKEN,
            mapboxgl: mapboxgl,
          })
        )
        .addControl(new mapboxgl.NavigationControl(), "bottom-right")
        .addControl(
          new mapboxgl.GeolocateControl({ maxWidth: 300 }),
          "bottom-right"
        );

      map.current.addControl(draw);
      map.current.on("draw.create", onUpdate);
      // map.on("draw.update", props.onUpdate);
      map.current.on("draw.delete", onDelete);
      draw.changeMode("draw_polygon");
      let flag = false;
      map.current.on("click", function (event) {
        if (!flag) {
          document
            .getElementById("remove-rect")
            .addEventListener("click", () => {
              props.callback();
              flag = false;
              draw.deleteAll();
              map.current.removeControl(draw);
              draw = new MapboxDraw({
                userProperties: true,
                displayControlsDefault: false,
                styles: drawingControlStyle,
              });

              map.current.addControl(draw);

              draw.changeMode("draw_polygon");
            });

          flag = true;
        }
      });
    }, 100);
  }, []);

  const polygonFeature = useMemo(() => {
    return Object.keys(features).map((key) => features[key]);
  }, [features]);

  useEffect(() => {
    const initTarget = [];
    polygonFeature.forEach((feature) => {
      const target = {
        id: feature.id,
        geoJson: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: feature.geometry.coordinates,
          },
        },
      };
      initTarget.push(target);
    });
    setPolygonSource(initTarget);
  }, [features]);

  useEffect(() => {
    if (props.secondDraw && polygonFeature.length < 2) {
      draw.changeMode("draw_polygon");
    }
    if (polygonFeature.length > 0 && polygonFeature.length === 2) {
      draw.setFeatureProperty(polygonFeature[1].id, "valid", true);
      draw.add(draw.get(polygonFeature[1].id));
    }
    if (!props.secondDraw && polygonFeature.length === 2) {
      draw.delete(polygonFeature[1].id);
      setFeatures((currFeatures) => {
        const newData = { ...currFeatures };
        if (newData[polygonFeature[1].id]) {
          delete newData[polygonFeature[1].id];
        }
        return newData;
      });
    }
  }, [props.secondDraw, polygonFeature]);

  useEffect(() => {
    if (props.selectedRoad) {
      props.selectedRoad.forEach((item) => {
        const target = {
          id: item.id,
          geoJson: {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: item.area,
            },
          },
        };
        setRoadSource([...roadSource, target]);
      });
    }
  }, [props.selectedRoad]);

  const handleDelete = () => {
    document
      .getElementsByClassName(
        "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
      )[0]
      .click();
  };

  useEffect(() => {
    setTimeout(() => {
      if (map.current) {
        if (props.selectedRoad && props.selectedRoad.length > 0) {
          props.selectedRoad.forEach((item: RoadResponseBody) => {
            if (!map.current.getSource(`source-roads-${item.id}`)) {
              map.current
                .addSource(`source-roads-${item.id}`, {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "LineString",
                      coordinates: item.area,
                    },
                  },
                })
                .addLayer({
                  id: `layer-road-${item.id}`,
                  source: `source-roads-${item.id}`,
                  type: "line",
                  paint: {
                    "line-color": "#1967D2", // blue color fill
                    "line-width": 5,
                  },
                });
            }
          });
        }
      }
    }, 100);
  }, [props.selectedRoad]);

  return (
    <>
      <div className="flex flex-1 h-full  w-full   ">
        <div ref={mapContainer} className="w-full h-full">
          <button
            id="remove-rect"
            className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
            onClick={handleDelete}
          >
            リセット
          </button>
        </div>
      </div>
    </>
  );
};
