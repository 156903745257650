//@ts-nocheck
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import { DrawStyles } from "mapbox-gl-draw-rectangle-restrict-area";
import { useSelector } from "react-redux";
import { MapStyle } from "../../../constants";
import { assetSelectors } from "../../../ducks/asset/selector";
import { RoadResponseBody } from "../../../ducks/roads";

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

type Props = {
  callback?(value: any): void;
  selectedRoad?: RoadResponseBody[];
  rectIndex?: number;
};

//initial draw controller that can support rectangle drawing
let draw = new MapboxDraw({
  userProperties: true,
  displayControlsDefault: false,
  styles: DrawStyles,
  modes: Object.assign(MapboxDraw.modes, {
    draw_rectangle: DrawRectangle,
  }),
});
export const RectangleRoadMap = (props: Props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const defaultLocation = useSelector(assetSelectors.getDefaultLocation);

  const [lng, setLng] = useState(defaultLocation.longitude);
  const [lat, setLat] = useState(defaultLocation.latitude);
  const [zoom, setZoom] = useState(13);

  const [features, setFeatures] = useState({});
  const [rectSource, setRectSource] = useState([]);
  const handleUpdate = useCallback((e: any) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
    props.callback((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (map.current) {
        if (props.selectedRoad && props.selectedRoad.length > 0) {
          props.selectedRoad.forEach((item: RoadResponseBody) => {
            if (!map.current.getSource(`source-roads-${item.id}`)) {
              map.current
                .addSource(`source-roads-${item.id}`, {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "LineString",
                      coordinates: item.area,
                    },
                  },
                })
                .addLayer({
                  id: `layer-road-${item.id}`,
                  source: `source-roads-${item.id}`,
                  type: "line",
                  paint: {
                    "line-color": "#1967D2", // blue color fill
                    "line-width": 5,
                  },
                });
            }
          });
        }
      }
    }, 100);
  }, [props.selectedRoad]);
  const rectFeature = useMemo(() => {
    return Object.keys(features).map((key) => features[key]);
  }, [features]);

  useEffect(() => {
    if (rectFeature.length > 0 && rectFeature.length < 2) {
      draw.changeMode("draw_rectangle");
    }
  }, [rectFeature]);

  useEffect(() => {
    const initTarget = [];
    rectFeature.forEach((feature) => {
      const target = {
        id: feature.id,
        geoJson: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: feature.geometry.coordinates,
          },
        },
      };
      initTarget.push(target);
    });
    setRectSource(initTarget);
  }, [features]);
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  }, []);

  useEffect(() => {
    if (map.current) {
      rectSource.map((item, index) => {
        if (!map.current.getSource(`source-rect-${item.id}`)) {
          map.current.addSource(`source-rect-${item.id}`, {
            type: "geojson",
            data: item.geoJson,
          });
          map.current.addLayer({
            id: `layer-rect-fill-${item.id}`,
            source: `source-rect-${item.id}`,
            type: "fill",
            paint: {
              "fill-color": index === 0 ? "#1967D2" : "#000", // blue color fill
              "fill-opacity": 0.3,
            },
          });
          map.current.addLayer({
            id: `layer-rect-line-${item.id}`,
            source: `source-rect-${item.id}`,
            type: "line",
            paint: {
              "line-color": index === 0 ? "#1967D2" : "#000", // blue color fill
              "line-width": 2,
            },
          });
        }
      });
    }
  }, [rectSource]);

  useEffect(() => {
    //Initialize Mapbox from default libary
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MapStyle,
      center: [lng, lat],
      zoom: zoom,
    })
      .addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        })
      )
      .addControl(new mapboxgl.NavigationControl(), "bottom-right")
      .addControl(
        new mapboxgl.GeolocateControl({ maxWidth: 300 }),
        "bottom-right"
      );

    map.current.addControl(draw);

    //without changeMode, the draw controller will not be able to draw a rectangle
    draw.changeMode("draw_rectangle");
    let flag = false;

    map.current.on("draw.create", function (feature) {
      handleUpdate(feature);
    });
    map.current.on("draw.update", function (feature) {
      handleUpdate(feature);
    });
    map.current.on("click", function (event) {
      if (!flag) {
        document.getElementById("remove-rect").addEventListener("click", () => {
          props.callback();
          flag = false;
          draw.deleteAll();
          map.current.removeControl(draw);
          draw = new MapboxDraw({
            userProperties: true,
            displayControlsDefault: false,
            styles: DrawStyles,
            modes: Object.assign(MapboxDraw.modes, {
              draw_rectangle: DrawRectangle,
            }),
          });

          map.current.addControl(draw);

          draw.changeMode("draw_rectangle");
        });

        flag = true;
      }
    });

    if (map.current) {
      //@ts-ignore
      map.current.on("move", () => {
        if (map.current) {
          //@ts-ignore
          setLng(map.current.getCenter().lng.toFixed(4));
          //@ts-ignore
          setLat(map.current.getCenter().lat.toFixed(4));
          //@ts-ignore
          setZoom(map.current.getZoom().toFixed(2));
        }
      });
    }
  }, []);

  return (
    <div className="flex flex-1 h-full  w-full   ">
      <div id="rect-road-map" ref={mapContainer} className="w-full h-full">
        <button
          id="remove-rect"
          className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
          onClick={() => {}}
        >
          リセット
        </button>
      </div>
    </div>
  );
};
