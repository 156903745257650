import { SpotStatusEnum } from "../../core/api";
import { getStatus } from "../../utils/spotstatus";
import { mergeClassNames } from "../../utils/style";

type Props = {
  status: SpotStatusEnum | undefined;
};

export const SpotStatusLabel: React.FC<Props> = ({ status }: Props) => {
  if (!status) {
    return null;
  }

  return (
    <span
      className={mergeClassNames(
        `text-[11px] py-0.5 px-2.5 rounded-full text-white`,
        status === SpotStatusEnum.Processing && "bg-[#FE9500]",
        status === SpotStatusEnum.Success && "bg-[#5FB955]",
        status === SpotStatusEnum.Approved && "bg-sky-500",
        status === SpotStatusEnum.Failure && "bg-[#CCCCCC]"
      )}
    >
      {getStatus(status).name}
    </span>
  );
};
