import RequiredBadge from "./RequiredBadge";

type Props = {
  text?: string;
  required?: boolean;
};

export default function Label({ text, required }: Props) {
  if (!text) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-2.5 mb-2.5">
      <span className="text-sm">{text}</span>
      {required && <RequiredBadge />}
    </div>
  );
}
