import React, { useCallback, useMemo } from "react";
import { MoeCheckbox } from "../../molecules/MoeCheckbox";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as ConvenienceStoreIcon } from "../../../assets/icons/convenience-store.svg";
import { ReactComponent as MedicalIcon } from "../../../assets/icons/medical.svg";
import { ReactComponent as CommercialComplexIcon } from "../../../assets/icons/commercial-complex.svg";
import { ReactComponent as CulturalActivityIcon } from "../../../assets/icons/cultural-activity.svg";
import { ReactComponent as DepartmentStoreIcon } from "../../../assets/icons/departmentstore.svg";
import { ReactComponent as FamilyRestaurantIcon } from "../../../assets/icons/family-restaurant.svg";
import { ReactComponent as MedicalFacilityIcon } from "../../../assets/icons/medical.svg";
import { ReactComponent as SupermarketIcon } from "../../../assets/icons/supermarket.svg";

import { FacilityMasterItemsType } from "../../../constants";
type Props = {
  items: any;
  callback: (value: any) => void;
  selectedFilterType: string[];
};

export const FacilityMasterFilter: React.FC<Props> = (props) => {
  const keys = Object.values(props.items) as string[];

  const _renderIcon = (key: string) => {
    switch (key) {
      case FacilityMasterItemsType.HOTEL:
        return <HotelIcon />;
      case FacilityMasterItemsType.CONVENIENCE_STORE:
        return <ConvenienceStoreIcon />;
      case FacilityMasterItemsType.MEDICAL_FACILITY:
        return <MedicalIcon />;
      case FacilityMasterItemsType.COMMERCIAL_COMPLEX:
        return <CommercialComplexIcon />;
      case FacilityMasterItemsType.CULTURAL_FACILITY:
        return <CulturalActivityIcon />;
      case FacilityMasterItemsType.DEPARTMENT_STORES:
        return <DepartmentStoreIcon />;
      case FacilityMasterItemsType.FAMILTY_RESTAURANTS:
        return <FamilyRestaurantIcon />;
      case FacilityMasterItemsType.SUPERMARKET:
        return <SupermarketIcon />;

      default:
        return null;
    }
  };

  const handleSelectItem = (key: string) => {
    props.callback(key);
  };

  const checkSelection = useCallback(
    (key: string) => {
      if (props.selectedFilterType.length > 0) {
        return props.selectedFilterType.includes(key);
      }
      return false;
    },
    [props.selectedFilterType]
  );
  return (
    <div className="mt-[27px]">
      <div className="text-[13px]">訪問マスタ</div>
      <div className="h-[340px] overflow-y-auto mt-[15px] w-[320px] border border-[#B5B5B5] rounded-sm">
        {keys.map((key, index) => {
          return (
            <div
              key={index}
              className="min-h-[40px] pl-[15px] border-b border-[#EFEFEF] flex items-center"
            >
              <MoeCheckbox
                isSelected={checkSelection(key)}
                onClick={() => handleSelectItem(key)}
              />
              <div className="text-[13px] ml-[10px] w-[168px]">{key}</div>
              <div className="flex-auto justify-end flex float-right mr-[9px]">
                {_renderIcon(key)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
