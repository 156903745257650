import React from "react";
import { ReactComponent as CrossSVGIcon } from "../../assets/icons/cross.svg";

export const CrossIcon: React.FC = () => {
	return (
		<div className="flex justify-center">
			<CrossSVGIcon />
		</div>
	);
};
