import { IconProps } from "../types";

export default function IconMigratoryUnderstandingAnalysis({
    className,
}: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className ?? "w-6 h-6 text-primary"}
        >
            <path
                fill="currentColor"
                d="M18.02 7.307c-.139.007-.277.02-.414.042a7.175 7.175 0 0 0-3.138-2.627 2.9 2.9 0 0 0-5.79 0 7.155 7.155 0 0 0-3.6 3.434 3.083 3.083 0 1 0 0 6.165 7.14 7.14 0 0 0 2.857 3.062c0 .076-.023.147-.023.223a3.662 3.662 0 0 0 7.323 0c0-.082-.018-.158-.024-.238a7.148 7.148 0 0 0 2.379-2.23c.142.022.285.037.428.043a3.935 3.935 0 0 0 0-7.87m-6.446-3.8a1.365 1.365 0 1 1-1.382 1.363 1.375 1.375 0 0 1 1.381-1.365M3.53 11.238a1.6 1.6 0 1 1 1.6 1.577 1.588 1.588 0 0 1-1.6-1.577Zm8.042 8.474a2.107 2.107 0 1 1 2.132-2.107 2.123 2.123 0 0 1-2.132 2.107Zm3.195-3.839a3.65 3.65 0 0 0-6.39 0 5.626 5.626 0 0 1-1.778-1.925 3.042 3.042 0 0 0 0-5.412A5.63 5.63 0 0 1 9.02 6.231a2.9 2.9 0 0 0 5.1 0c.764.38 1.432.926 1.954 1.6a3.875 3.875 0 0 0 0 6.828c-.368.474-.81.887-1.309 1.222m3.255-2.215a2.425 2.425 0 1 1 2.452-2.428 2.442 2.442 0 0 1-2.452 2.428Z"
            />
        </svg>
    );
}
