import React, { useCallback } from "react";
import { CheckboxActive } from "../atoms/CheckboxActive";
import { CheckboxInactive } from "../atoms/CheckboxInactive";

type Props = {
  dashed?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
};

export const MoeCheckbox: React.FC<Props> = (props) => {
  const { dashed, onClick, isSelected } = props;
  const handleClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [isSelected, dashed, onClick]);

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {isSelected && <CheckboxActive size="normal" />}
      {!dashed && !isSelected && <CheckboxInactive size="normal" />}
    </div>
  );
};
