import { useDispatch, useSelector } from "react-redux";
import { analysisExecuteSelectors } from "../../../ducks/analysis_execute/selector";
import { Date } from "../../../ducks/analysis_execute";
import { useCallback, useMemo } from "react";
import { linkageAction } from "../../../ducks/linkage";
import dayjs from "dayjs";
import { Linkage, LinkageMutateResponse, SpotMenu } from "../../../core/api";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/Routes";

export const LinkageStatus = {
  draft: "下書き",
  before: "連携前",
  active: "連携中",
  end: "連携終了",
};

type ParseLinkageStatusRequest = Pick<
  Linkage,
  "is_draft" | "start_at" | "end_at"
>;
export const parseLinkageStatus = (request: ParseLinkageStatusRequest) => {
  const { is_draft, start_at, end_at } = request;
  let status = LinkageStatus.before;
  if (is_draft || (!start_at && !end_at)) {
    status = LinkageStatus.draft;
  } else if (end_at && dayjs().isSameOrAfter(dayjs(end_at))) {
    status = LinkageStatus.end;
  } else if (start_at && dayjs().isSameOrAfter(dayjs(start_at))) {
    status = LinkageStatus.active;
  }
  return status;
};

export const findLinkageDataType = ({
  analysisTypes,
  dataTypeId,
}: {
  analysisTypes: SpotMenu[];
  dataTypeId: number;
}) => {
  return analysisTypes.find((i) => {
    return i.id === dataTypeId;
  });
};

export const parseLinkageTerm = ({
  term,
  analysisTypeId,
  analysisTypes,
}: {
  term: number;
  analysisTypeId: number;
  analysisTypes: SpotMenu[];
}) => {
  const analysisType = analysisTypes.find((a) => {
    return a.id === analysisTypeId;
  });

  if (!analysisType) {
    return "";
  }

  const termForDisplaySuffix = analysisType?.type === "actual" ? "過去" : "";
  return `${termForDisplaySuffix}${term}${
    termForDisplaySuffix ? "日前まで" : "日先まで"
  }`;
};

export const useLinkage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedSpots = useSelector(
    analysisExecuteSelectors.getSelectedAnalyzedSpotItems
  );
  const analysisType = useSelector(analysisExecuteSelectors.getSelectedItem);
  const selectedTerm = useSelector(analysisExecuteSelectors.getGetDate);
  const dates: Date = useSelector(analysisExecuteSelectors.getDate);

  const isValidSpotIds = useCallback(
    (ids: (number | undefined)[]): ids is number[] => {
      return 0 < ids.length && ids.every((i) => typeof i === "number");
    },
    []
  );

  const isValidSpotMenuId = useCallback(
    (id: number | undefined): id is number => {
      return typeof id === "number" && 0 < id;
    },
    []
  );

  const areaSettingType = useMemo(() => {
    // TODO: POI の場合のラベル分岐
    return `${
      0 < selectedSpots.length
        ? `地図上でエリアを設定 (スポット数: ${selectedSpots.length})`
        : ""
    }`;
  }, [selectedSpots.length]);

  const spot_ids = useMemo(() => {
    return selectedSpots.map((s) => {
      return s.id;
    });
  }, [selectedSpots]);

  const registerLinkage = useCallback(
    ({ is_draft = false }: { is_draft?: boolean } = {}) => {
      if (
        !isValidSpotIds(spot_ids) ||
        !isValidSpotMenuId(analysisType.id) ||
        !selectedTerm
      ) {
        return;
      }

      dispatch(
        linkageAction.registerLinkage.started({
          body: {
            name: "",
            is_draft,
            spot_ids,
            data_type: analysisType.id,
            term: selectedTerm,
            start_at: dayjs(dates.startDate).startOf("day").toISOString(),
            end_at:
              dates.endDate !== ""
                ? dayjs(dates.endDate).endOf("day").toISOString()
                : undefined,
          },
          onSuccess: (response: LinkageMutateResponse) => {
            navigate(
              `${PageRoutes.ADD_LINKAGE_COMPLETE}/${response.linkage_id}`,
              { replace: true }
            );
          },
        })
      );
    },
    [
      analysisType.id,
      dates.endDate,
      dates.startDate,
      dispatch,
      isValidSpotIds,
      isValidSpotMenuId,
      navigate,
      selectedTerm,
      spot_ids,
    ]
  );

  return {
    areaSettingType,
    isValidSpotIds,
    isValidSpotMenuId,
    spot_ids,
    registerLinkage,
  };
};
