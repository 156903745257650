import axios, { AxiosResponse } from "axios";
import { AppConfig } from "../../AppConfig";
import { NETWORK_ERROR } from "../../constants";
import { PageRoutes } from "../../constants/Routes";
import { appActions } from "../../ducks/app";
import { store } from "../../store";
import { MoeEventEmitter } from "../event";
import { LocalStorageManager } from "../storage/LocalStoragetManager";
import { ErrorMessageManager } from "./ErrorMessageManager";

const mapBoxAPIInstance = axios.create({
  baseURL: process.env.REACT_APP__MAPBOX_API,
  timeout: 1000,
  headers: { "X-Custom-Header": "foobar" },
});
mapBoxAPIInstance.interceptors.response.use(
  (config) => requestSuccess(config),
  (config) => requestFailure(config)
);

const requestSuccess = (config: AxiosResponse<any, any>) => {
  // ここでAPIの共通処理を描く
  return config;
};

const requestFailure = (config: any) => {
  // ここでAPIのエラーの共通処理を描く
  if (config && config.code === NETWORK_ERROR) {
    const errorCode = "0000-0000";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else if (config && config?.response?.status === 503) {
    const errorCode = "9999-9999";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else if (
    (config && config?.response?.status === 401) ||
    (config && config?.response?.status === 403)
  ) {
    //token refresh attempting to request
    let refreshToken = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["refreshToken"]
    );
    try {
      if (refreshToken) {
        appActions.showLoading();
        store.dispatch(
          appActions.refreshToken.started({
            refreshToken: refreshToken,
            onSuccess: (response: any) => {
              appActions.hideLoading();
              LocalStorageManager.Instance.saveObject(
                AppConfig.Instance.LocalStorageKey["accessToken"],
                response.data.access_token
              );
              axios({
                method: config.config.method,
                url: config.config.url,
                data: config.config.data,
                headers: {
                  ...config.config.headers,
                  Authorization: `Bearer ${LocalStorageManager.Instance.getObject(
                    AppConfig.Instance.LocalStorageKey["accessToken"]
                  )}`,
                },
              }).then((res) => {
                if (res.status === 200 || res.status === 204) {
                  window.location.reload();
                }
              });
            },
          })
        );
      } else {
        // 認証エラー
        const errorCode = "0002";
        const errorObj: { title: string; message: string } =
          ErrorMessageManager.Instance.getErrorMessage(errorCode);
        store.dispatch(
          appActions.showModal.started({
            title: errorObj.title,
            modalType: "alert",
            detailText: errorObj.message,
            errorStatusCode: errorCode,
            closeButtonLabel: "閉じる",
            handleClose: () => {
              store.dispatch(appActions.closeModal());
              MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
            },
          })
        );
        return Promise.reject({ error: config, errorHandled: true });
      }
    } catch (e) {
      // 認証エラー
      const errorCode = "0002";
      const errorObj: { title: string; message: string } =
        ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: errorObj.title,
          modalType: "alert",
          detailText: errorObj.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
            MoeEventEmitter.Instance.emit("navigate", PageRoutes.LOGIN);
          },
        })
      );
      return Promise.reject({ error: config, errorHandled: true });
    }
  } else if (config && config?.response?.status === 404) {
    // 認証エラー
    const errorCode = "9999-9998";
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => {
          store.dispatch(appActions.closeModal());
        },
      })
    );
    return Promise.reject({ error: config, errorHandled: true });
  } else {
    return Promise.reject({ error: config, errorHandled: false });
  }
};

export { mapBoxAPIInstance };
