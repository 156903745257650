import { OutlineIconProps } from "../types";

export default function IconCheckboxDash({
  strokeWidth,
  className,
}: OutlineIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 11 2"
      className={className ?? "h-0.5 text-white"}
    >
      <path
        stroke="currentColor"
        strokeWidth={strokeWidth ?? 2}
        d="M0 1h10.5"
      />
    </svg>
  );
}
