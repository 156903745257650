import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selectApp = (state: StoreState) => state.analysisExcute;

export const analysisExecuteSelectors = {
  getSelectedItem: createSelector(selectApp, (app) => app.selectedItem),
  getSelectedAnalyzedSpotItems: createSelector(
    selectApp,
    (app) => app.selectedSpotItems
  ),
  getStep: createSelector(selectApp, (app) => app.step),
  getDate: createSelector(selectApp, (app) => app.date),
  getGetDate: createSelector(selectApp, (app) => app.getDateNum),
  getMapBound: createSelector(selectApp, (app) => app.mapBound),
  getDeleteSpotId: createSelector(selectApp, (app) => app.deleteSpotID),
};
