import React from "react";
import { ReactComponent as CheckboxDisable } from "../../assets/icons/disable-checkbox.svg";

export const DisableCheckbox: React.FC = () => {
  return (
    <div className="block mr-[1px] pb-[1px] w-[26px]">
      <div className="flex justify-center">
        <CheckboxDisable />
      </div>
    </div>
  );
};
