import actionCreatorFactory from "typescript-fsa";
import { Spot, SpotMenu } from "../../core/api";

const analysisExecuteCreator = actionCreatorFactory("@@AnalysisExecute");

export const analysisExecuteActions = {
  addSelectedItem: analysisExecuteCreator.async<SpotMenu, {}>("ADD_ANALYSIS_SELECTED_ITEM"),
  removeSelectedItem: analysisExecuteCreator.async<number, {}>("REMOVE_ANALYSIS_SELECTED_ITEM"),
  selectSpot: analysisExecuteCreator.async<Spot & { multiple: boolean }, {}>("SELECT_SPOT"),
  addSelectedSpotItem: analysisExecuteCreator.async<Spot, {}>("ADD_ANALYZE_SPOT_ITEM"),
  setSelectedSpotItems: analysisExecuteCreator.async<Spot[], {}>("SET_ANALYZE_SPOT_ITEMS"),
  removeSelectedSpotItem: analysisExecuteCreator.async<number, {}>("REMOVE_ANALYZE_SPOT_ITEM"),
  setDate: analysisExecuteCreator.async<{ date: string; type: string }, {}>("SET_DATE"),
  analysisReset: analysisExecuteCreator("@RESET_ANALYSIS_SELECTION"),
  setStep: analysisExecuteCreator.async<number, {}>("SET_STEP"),
  setGetDate: analysisExecuteCreator<number>("SET_GET_DATE"),
  analysisResetForLinkage: analysisExecuteCreator("@RESET_ANALYSIS_SELECTION_FOR_LINKAGE"),
  setMapbound: analysisExecuteCreator("@SET_MAPBOUND"),
  setDeleteSpotId: analysisExecuteCreator<Spot>("SET_DELETE_SPOT_ID"),
};
