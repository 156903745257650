import { ComponentProps, useEffect, useState } from "react";
import { UseFormRegisterReturn, useFormContext } from "react-hook-form";
import IconEye from "../../icons/IconEye";
import { mergeClassNames } from "../../utils/style";

type Props = Omit<ComponentProps<"input">, keyof UseFormRegisterReturn> & {
  name: string;
  label?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
};

export default function Input({ name, className, type, ...props }: Props) {
  const { register, formState } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const message = formState.errors[name];
    if (message) {
      setError(
        message
          ? typeof message.message === "string"
            ? message.message
            : ""
          : ""
      );
    } else {
      setError("");
    }
  }, [formState]);

  return (
    <div className="relative">
      {(props.label || props.required) && (
        <div className="flex items-center mb-[11px]">
          {props.label && <div className="text-[14px] ">{props.label}</div>}
          {props.required && (
            <div className="bg-[#D19C9A] ml-[10px] text-white rounded text-[10px] w-[32px] h-[14px] text-center">
              必須
            </div>
          )}
        </div>
      )}

      <div className="border border-gray-border overflow-hidden rounded bg-gray-background relative focus-within:border-gray-light">
        <input
          {...register(name)}
          type={showPassword ? "text" : type}
          className={mergeClassNames(
            "placeholder:text-gray-light w-full py-4.5 pl-4.5 inset-0 focus:outline-none text-sm bg-transparent",
            type === "password" ? "pr-14" : "pr-4.5",
            className
          )}
          {...props}
        />
        {type === "password" && (
          <button
            type="button"
            className="focus:outline-none absolute top-1/2 -translate-y-1/2 right-4.5"
            onClick={() => {
              setShowPassword((previousValue) => !previousValue);
            }}
          >
            <IconEye open={showPassword} className="h-5.5 w-5.5" />
          </button>
        )}
      </div>
      {error && <p className="text-[12px] text-[#ff0000]">{error}</p>}
    </div>
  );
}
