import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as DownSelecionIcon } from "../../assets/icons/down-selection.svg";
import { CreateSpotType } from "../../constants";
import { SelectOptionType } from "../../core/@types";

type Props = {
  options?: SelectOptionType[];
  onChange: (value: string) => void;
  mode?: string;
  itemsEnable?: any;
};

export const CustomSelections: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const handleOpenSelection = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const handleChange = (value: string) => {
    setCurrentName(value);
    setIsOpen(false);
    props.onChange(value);
  };

  const optionToDisplay = useMemo(() => {
    return props.options?.filter((item) => props.itemsEnable[item.type]) ?? [];
  }, []);

  useEffect(() => {
    if (optionToDisplay?.length === 1) {
      handleChange(optionToDisplay[0].name);
      setDisabled(true);
    }
  }, [optionToDisplay]);

  return (
    <div className="relative">
      <div
        onClick={handleOpenSelection}
        className={`w-[320px] border-gray-light ${
          disabled ? "" : "cursor-pointer"
        } rounded-lg h-[48px] justify-between border flex items-center pl-[16px]`}
      >
        <div className="text-[14px]">
          {currentName !== "" ? currentName : "追加方法を選択してください"}
        </div>
        {!disabled && <DownSelecionIcon className="mr-[20px]" />}
      </div>

      {props.mode !== CreateSpotType.ROAD && (
        <div className="w-[320px] mt-[14px] bg-[#F4F4F4] rounded-[4px] text-[#334A7C] text-[13px] py-[10px] px-[13px]">
          {props.mode === CreateSpotType.FACILITY_MASTER
            ? "施設マスタを選択後、地図上に表示するピンから分析したい施設を選択してください"
            : "地図上をクリックし、分析したいスポットを選択してください。"}
        </div>
      )}

      {isOpen && !disabled && (
        <div className="mt-[14px] top-12 z-10 bg-white absolute w-[320px] shadow-md rounded py-[10px]">
          {optionToDisplay?.map((option) => {
            return (
              <div
                onClick={() => handleChange(option.name)}
                key={option.id}
                className="text-[14px] cursor-pointer h-[40px] pl-[20px] flex items-center hover:bg-primary-light"
              >
                {option.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
