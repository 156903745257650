import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { PasswordReset1, PasswordReset2 } from "../../core/api";
import { Iam, LoginResponse, SubmitType } from "./types";

const actionCreator = actionCreatorFactory("@@Login");

export const LoginAction = {
  login: actionCreator.async<SubmitType, LoginResponse>("LOGIN"),
  setUserInformation: actionCreator<Iam>("SET_USER_INFORMATION"),
  forgetPassword: actionCreator.async<
    { body: PasswordReset1 } & CommonAsyncType,
    {}
  >("FORGOT_PASSWORD"),
  resetPassword: actionCreator.async<
    { body: PasswordReset2 } & CommonAsyncType,
    {}
  >("RESET_PASSWORD"),
};
