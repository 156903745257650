import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as SuccessIcon } from "../../assets/icons/tick.svg";
import { ReactComponent as FailIcon } from "../../assets/icons/fail.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closebtn.svg";
import { useDispatch } from "react-redux";
import { appActions } from "../../ducks/app";
type Props = {
  type: "success" | "failed";
  text: string;
  visible: boolean;
};
export const NotificationStatus: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(props.visible);
  const dispatch = useDispatch();
  let bgColor = useMemo(() => {
    return props.type === "success"
      ? "bg-[#5FB955]"
      : props.type === "failed"
      ? "bg-[#AA3B13]"
      : "";
  }, [props.type]);

  const handleClose = () => {
    setIsVisible(false);
    dispatch(appActions.resetNotification());
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 3000);
  }, [props.visible]);

  if (!props.visible) {
    return null;
  }
  return (
    <div
      className={`${bgColor} z-[121] fade-in  min-w-[312px] fixed right-[20px] rounded top-[20px] h-[64px] pl-[13px] py-[25px]`}
    >
      <div className="relative flex items-center w-full justify-between">
        <div className="flex ">
          {props.type === "success" ? (
            <SuccessIcon />
          ) : props.type === "failed" ? (
            <FailIcon />
          ) : null}
          <div className="text-[14px] font-bold text-white ml-[10px]">
            {props.text}
          </div>
        </div>

        <CloseIcon
          onClick={handleClose}
          className="absolute -top-[20px] right-[6px] cursor-pointer"
        />
      </div>
    </div>
  );
};
