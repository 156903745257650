import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { POIState } from "./types";
import { poiAction } from "./actions";

const initialState: POIState = {};

export function POIReducer(state = initialState, action: Action): POIState {
  if (isType(action, poiAction.fetchAllPOIs.done)) {
    return {
      ...state,
      poi: action.payload.result,
    };
  }
  return state;
}
