import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selectApp = (state: StoreState) => state.app;

export const appSelectors = {
  getLoading: createSelector(selectApp, (app) => app.isLoading),
  getModalObject: createSelector(selectApp, (app) => ({
    isOpen: app.showModal,
    modalItem: app.modalObject,
  })),
  getNotification: createSelector(selectApp, (app) => app.notificationState),
  showNotification: createSelector(selectApp, (app) => app.showNotification),
  getTitle: createSelector(selectApp, (app) => app.title),
  getUrl: createSelector(selectApp, (app) => app.url),
  getDisplayItems: createSelector(selectApp, (app) => app.displayItems),
  getDefaultLocation: createSelector(selectApp, (app) => app.defaultLocation),
  getState: createSelector(selectApp, (app) => app.state),
  getUserId: createSelector(selectApp, (app) => app.userId),
};
