import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/Routes";
import { Spot, SpotMenu } from "../../../core/api";
import { Date, analysisExecuteActions } from "../../../ducks/analysis_execute";
import { analysisExecuteSelectors } from "../../../ducks/analysis_execute/selector";
import { appActions } from "../../../ducks/app";
import { assetSelectors } from "../../../ducks/asset/selector";
import { isTrue } from "../../../utils/array";

export const useAnalysisExecute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const displayList = useSelector(assetSelectors.getDisplayItems);
  const stepList = [
    displayList.analysis_item_selection,
    displayList.spot_selection,
    displayList.period_selection,
  ];
  const analysis = 1;
  const spotSel = stepList[0] === true ? 2 : 1;
  const periodSel =
    stepList[0] && stepList[1]
      ? 3
      : !stepList[0] && stepList[1]
      ? 2
      : stepList[0] && !stepList[1]
      ? 2
      : 1;

  const step = useSelector(analysisExecuteSelectors.getStep);
  const analysisItem = useSelector(assetSelectors.getAnalysisTypes);
  const selectedAnalysisSpotItems = useSelector(
    analysisExecuteSelectors.getSelectedAnalyzedSpotItems
  );

  const date: Date = useSelector(analysisExecuteSelectors.getDate);
  const getDate = useSelector(analysisExecuteSelectors.getGetDate);

  useEffect(() => {
    if (step === 2 && selectedAnalysisItem.name === "") {
      navigate(PageRoutes.EXECUTE_SPOT_ANALYSIS);
      dispatch(analysisExecuteActions.analysisReset());
    }
  }, [step]);

  const selectedAnalysisItem = useSelector(
    analysisExecuteSelectors.getSelectedItem
  );
  const jumpUrl = useSelector(assetSelectors.getUrl);

  const handleSelect = useCallback((item: SpotMenu) => {
    return dispatch(analysisExecuteActions.addSelectedItem.started(item));
  }, []);

  const handleNextStep = () => {
    const selectedSpotIDs = selectedAnalysisSpotItems
      .map((x) => x.id)
      .filter(isTrue);

    let target = step + 1;
    if (step === analysis && stepList[0]) {
      if (stepList[1]) {
        navigate(PageRoutes.CHOOSE_SPOT_ANALYSIS);
        dispatch(analysisExecuteActions.setStep.started(target));
      } else if (stepList[2]) {
        navigate(PageRoutes.SELECT_SPOT_ANALYSIS_TYPE);
        dispatch(analysisExecuteActions.setStep.started(target));
      } else {
        dispatch(appActions.showLoading());
        const url = `${jumpUrl}?type=${selectedAnalysisItem.id}&spot=${[
          selectedSpotIDs,
        ]}&from=${date.startDate}&to=${date.endDate}`;
        window.location.replace(url);
      }
    } else if (step === spotSel && stepList[1]) {
      if (stepList[2]) {
        navigate(PageRoutes.SELECT_SPOT_ANALYSIS_TYPE);
        dispatch(analysisExecuteActions.setStep.started(target));
      } else {
        dispatch(appActions.showLoading());
        const url = `${jumpUrl}?type=${selectedAnalysisItem.id}&spot=${[
          selectedSpotIDs,
        ]}`;
        window.location.replace(url);
      }
    } else if (step === periodSel && stepList[2]) {
      dispatch(appActions.showLoading());
      const url = `${jumpUrl}?type=${selectedAnalysisItem.id}&spot=${[
        selectedSpotIDs,
      ]}&from=${date.startDate}&to=${date.endDate}&get_date=${getDate}`;
      window.location.replace(url);
    }
  };
  const handleBackStep = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      dispatch(analysisExecuteActions.setStep.started(step - 1));
      navigate(-1);
    }
  };

  const handleSelectAllSpotItem = useCallback(
    (item: Spot[]) => {
      if (selectedAnalysisSpotItems.length > 0) {
        item.forEach((target) => {
          if (target.id) {
            dispatch(
              analysisExecuteActions.removeSelectedSpotItem.started(target.id)
            );
          }
        });
      } else {
        item.forEach((target) => {
          dispatch(analysisExecuteActions.addSelectedSpotItem.started(target));
        });
      }
    },
    [selectedAnalysisSpotItems]
  );

  const handleSelectSpotItem = useCallback(
    (item: Spot) => {
      const isExist = selectedAnalysisSpotItems.find(
        (target) => item.id === target.id
      );
      if (!isExist) {
        dispatch(analysisExecuteActions.addSelectedSpotItem.started(item));
      } else {
        if (item.id) {
          dispatch(
            analysisExecuteActions.removeSelectedSpotItem.started(item.id)
          );
        }
      }
    },
    [selectedAnalysisSpotItems]
  );

  const isSaveButtonDisabled = useMemo(() => {
    if (step === 1) return selectedAnalysisItem.id === 0;
    if (step === 2)
      return (
        !!selectedAnalysisItem.min &&
        selectedAnalysisSpotItems.length < selectedAnalysisItem.min
      );
    if (step === 3)
      return (
        date.startDate === "" || dayjs(date.endDate) < dayjs(date.startDate)
      );
    return false;
  }, [selectedAnalysisItem, selectedAnalysisSpotItems, step, date]);

  return {
    analysisItem,
    handleNextStep,
    handleSelect,
    handleBackStep,
    selectedAnalysisItem,
    handleSelectSpotItem,
    handleSelectAllSpotItem,
    isSaveButtonDisabled,
    selectedAnalysisSpotItems,
    getDate,
  };
};
