import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { AppConfig } from "../../AppConfig";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { appActions } from "./actions";
import { AppState } from "./types";

const initialState: AppState = {
  isLoading: false,
  showModal: false,
  modalObject: {
    title: "",
    modalType: "alert",
    detailText: "",
    errorStatusCode: "",
    closeButtonLabel: "",
    nextButtonLabel: "",
  },
  menus: [],
  users: [],
  showNotification: false,
  title: "",
  url: "",
  displayItems: {
    spotCreate: false,
    analysisItemSelection: false,
    spotSelection: false,
    periodSelection: false,
    spot_select_always: false,
    dataSetName: false,
    spotSreateMethods: {
      poi: false,
      rectangle: false,
      circle: false,
      polygon: false,
    },
  },

  //fallback value
  defaultLocation: {
    latitude: 35.6587619,
    longitude: 139.7012342,
  },
  userId:
    LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["userId"]
    ) ?? "",
  state: {},
};

export function AppReducer(state = initialState, action: Action): AppState {
  if (isType(action, appActions.showLoading)) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (isType(action, appActions.hideLoading)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (isType(action, appActions.showModal.done)) {
    return {
      ...state,
      modalObject: action.payload.params,
      showModal: true,
    };
  }

  if (isType(action, appActions.closeModal)) {
    return {
      ...state,
      showModal: false,
      modalObject: {
        title: "",
        modalType: "alert",
        detailText: "",
        errorStatusCode: "",
        closeButtonLabel: "",
        nextButtonLabel: "",
      },
    };
  }

  if (isType(action, appActions.displayNotification.done)) {
    return {
      ...state,
      notificationState: {
        type: action.payload.params.type,
        text: action.payload.params.text,
      },
    };
  }
  if (isType(action, appActions.resetNotification)) {
    return {
      ...state,
      notificationState: undefined,
      showNotification: false,
    };
  }
  if (isType(action, appActions.showNotification)) {
    return {
      ...state,
      showNotification: true,
    };
  }

  if (isType(action, appActions.logout.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, appActions.fetchStateJson.done)) {
    return {
      ...state,
      state: action.payload.result,
    };
  }
  return state;
}
