import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { LOGIN_MESSAGE } from "../../constants/schema";
import { appActions } from "../../ducks/app";
import { LoginAction } from "../../ducks/login";
import { CustomButton } from "../atoms/CustomButton/CustomButton";
import Input from "../atoms/Input";
import Form from "../organisms/Form";
import { PageRoutes } from '../../constants/Routes';

export const ResetPassword: React.FC<{}> = () => {
  const { secret_key } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
      if (!secret_key) {
        navigate(PageRoutes.LOGIN);
    }
  }, []);

  return (
    <div className="grid place-items-center w-full">
      <Form
        schema={z
          .object({
            password: z
              .string()
              .regex(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,64}$/,
                {
                  message: LOGIN_MESSAGE.PASSWORD,
                }
              ),
            confirmPassword: z
              .string()
              .regex(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,64}$/,
                {
                  message: LOGIN_MESSAGE.PASSWORD,
                }
              ),
          })
          .refine(
            ({ password, confirmPassword }) => password === confirmPassword
          )}
        className="flex flex-col w-full max-w-100 gap-y-14"
        onSubmit={async ({ password }) => {
          dispatch(appActions.showLoading());
          dispatch(
            LoginAction.resetPassword.started({
              body: {
                secret_key: secret_key,
                password: password,
              },
              onSuccess: () => {
                dispatch(appActions.hideLoading());
                dispatch(
                  appActions.showModal.started({
                    title: "パスワードを更新しました。",
                    showXIcon: true,
                    closeButtonLabel: "ログインする",
                    handleClose: () => {
                      dispatch(appActions.closeModal());
                      navigate(PageRoutes.LOGIN);
                    },
                  })
                );
              },
            })
          );
        }}
      >
        <span className="text-3xl text-gray-dark text-center">
          パスワード再設定
        </span>
        <div className="flex flex-col">
          <div className="mb-[30px]">
              <Input
                name="password"
                type="password"
                placeholder="パスワード"
                className="w-full "
              />
          </div>
          <div className="mb-[30px]">
              <Input
                name="confirmPassword"
                type="password"
                placeholder="パスワード (確認)"
                className="w-full"
              />
          </div>
        </div>
        <CustomButton type="submit" text="送信" />
      </Form>
    </div>
  );
};
