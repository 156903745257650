import { useDispatch, useSelector } from "react-redux";
import {
  linkageAction,
  LinkageFilterRequest,
  linkageFilterRequest,
} from "../../../ducks/linkage";
import { linkageSelectors } from "../../../ducks/linkage/selector";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { LinkageTable } from "../../organisms/LinkageTable";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import { assetAction } from "../../../ducks/asset";
import { useLocation, useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/Routes";
import { analysisExecuteActions } from "../../../ducks/analysis_execute";
import { Linkage } from "../../../core/api";
import Form from "../../organisms/Form";
import Input from "../../atoms/Input";
import Select from "../../atoms/Select";
import { LinkageStatus, parseLinkageTerm } from "./hooks";
import { useAnalysisExecute } from "../analysis_execution/hooks";
import { assetSelectors } from '../../../ducks/asset/selector';

dayjs.extend(isSameOrAfter);

export const LinkageHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<number | undefined>();

  const linkages = useSelector(linkageSelectors.getLinkages);
  const filteredLinkages = useSelector(linkageSelectors.getFilteredLinkage);

  const updateSelected = useCallback(
    ({ id }: { id: number | undefined }) => {
      const sel = selected === id ? undefined : id;
      setSelected(sel);
    },
    [selected]
  );

  const edit = useCallback(() => {
    if (selected) {
      navigate(`${PageRoutes.EDIT_LINKAGE}/${selected}`);
    }
  }, [navigate, selected]);

  const copy = useCallback(() => {
    if (selected) {
      dispatch(
        linkageAction.getLinkage.started({
          id: selected,
          onSuccess: (res: Linkage) => {
            dispatch(analysisExecuteActions.analysisResetForLinkage());
            dispatch(
              analysisExecuteActions.setSelectedSpotItems.started(
                res.data ?? []
              )
            );
            navigate(PageRoutes.EXECUTE_SPOT_ANALYSIS);
          },
        })
      );
    }
  }, [dispatch, navigate, selected]);

  const buttonStyle = useCallback(
    ({ primary }: { primary: boolean }) => {
      return `${
        selected
          ? `text-primary border-primary ${primary ? "text-white" : "bg-white"}`
          : "text-[#C0C7D7] bg-white border border-[#C0C7D7] cursor-not-allowed"
      } text-[14px] h-[36px] border p-0 px-[1.5rem] flex items-center justify-center`;
    },
    [selected]
  );

  useEffect(() => {
    dispatch(linkageAction.getLinkages.started({}));
    dispatch(assetAction.fetchService.started({}));
    // 初回表示時のみ linkage, service 取得
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const sideMenus = useSelector(assetSelectors.getMenus);
  const title = useMemo(() => {
    return sideMenus.filter((item) => item.path === location.pathname);
  }, []);

  const LinkageFilter = () => {
    const [show, setShow] = useState(false);

    const filter = useSelector(linkageSelectors.getFilter);
    const { analysisItem } = useAnalysisExecute();
    const execFilter = useCallback(
      (form: LinkageFilterRequest) => {
        dispatch(
          linkageAction.filterLinkages.started({
            request: form,
            analysisTypes: analysisItem,
          })
        );
      },
      [analysisItem]
    );

    const statusValues = useMemo(() => {
      return Object.values({ none: "", ...LinkageStatus });
    }, []);

    const dataTypeValues = useMemo(() => {
      return analysisItem
        .map((a, idx) => {
          return idx === 0 ? ["", a.name] : a.name;
        })
        .flat();
    }, [analysisItem]);

    const termValues = useMemo(() => {
      const terms = linkages
        .map((l) => {
          return parseLinkageTerm({
            term: l.term,
            analysisTypeId: l.data_type,
            analysisTypes: analysisItem,
          });
        })
        .sort((a, b) => {
          const isPastA = a.startsWith("過去");
          const isPastB = b.startsWith("過去");

          const extractNumber = (str: string) => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0], 10) : 0;
          };

          const numA = extractNumber(a);
          const numB = extractNumber(b);

          if (isPastA && isPastB) {
            return numB - numA;
          } else if (!isPastA && !isPastB) {
            return numA - numB;
          } else {
            return isPastA ? -1 : 1;
          }
        });

      return Array.from(new Set(["", ...terms]));
    }, [analysisItem]);

    const fromToValues = useCallback(
      ({ type }: { type: "start_at" | "end_at" }) => {
        const years = linkages.map((l) => {
          return dayjs(l[type]).format("YYYY");
        });
        return Array.from(new Set(["", ...years]));
      },
      []
    );

    const months = useMemo(() => {
      const mo = [...Array(12)].map((_, i) => i + 1);
      return [
        "",
        ...mo.map((m) => {
          return `${m}`;
        }),
      ];
    }, []);

    const toggleShow = useCallback(() => {
      setShow(!show);
    }, [show]);

    return (
      <>
        <CustomButton
          type="button"
          className="text-[14px] h-[36px] border p-0 px-[1.5rem] flex items-center justify-center bg-white text-primary border-primary"
          text={show ? "キャンセル" : "フィルター"}
          onClick={() => toggleShow()}
        />
        <div
          className={`flex gap-2 absolute left-0 top-[36px] bg-white p-8 min-w-[1100px] rounded-lg shadow-lg border border-gray-100 ${
            !show && "opacity-0 pointer-events-none"
          }`}
        >
          <Form
            schema={linkageFilterRequest}
            onSubmit={(form) => execFilter(form)}
            defaultValues={filter}
          >
            <div className="flex gap-4">
              <div className="flex items-center gap-2 text-sm">
                <p className="w-[5.5rem]">連携データ名</p>
                <div className="w-[10rem]">
                  <Input name="name" className="w-full bg-white" />
                </div>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <p>連携ステータス</p>
                <div className="w-[10rem]">
                  <Select
                    name="status"
                    options={statusValues}
                    enableScrollIntoView={false}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <p>データ種別</p>
                <div className="w-[10rem]">
                  <Select
                    placeholder="&nbsp;"
                    name="dataType"
                    options={dataTypeValues}
                    enableScrollIntoView={false}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <p>期間</p>
                <div className="w-[10rem]">
                  <Select
                    placeholder="&nbsp;"
                    name="term"
                    options={termValues}
                    enableScrollIntoView={false}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-[1rem] justify-between">
              <div className="flex items-center gap-2 text-sm">
                <p className="w-[5.5rem]">連携期間</p>
                <div className="flex gap-2 items-center">
                  <div className="w-[10rem]">
                    <Select
                      placeholder="&nbsp;"
                      name="fromYear"
                      options={fromToValues({ type: "start_at" })}
                      enableScrollIntoView={false}
                    />
                  </div>
                  <div>年</div>
                  <Select
                    placeholder="&nbsp;"
                    name="fromMonth"
                    options={months}
                    enableScrollIntoView={false}
                  />
                  <div>月</div>
                  <div className="px-2"> ー </div>
                  <div className="w-[10rem]">
                    <Select
                      placeholder="&nbsp;"
                      name="toYear"
                      options={fromToValues({ type: "end_at" })}
                      enableScrollIntoView={false}
                    />
                  </div>
                  <div>年</div>
                  <Select
                    placeholder="&nbsp;"
                    name="toMonth"
                    options={months}
                    enableScrollIntoView={false}
                  />
                  <div>月</div>
                </div>
              </div>
              <CustomButton
                type={"submit"}
                text="適用"
                className="text-[14px] px-[2rem]"
              />
            </div>
          </Form>
        </div>
      </>
    );
  };

  return (
    <div className="h-full flex flex-col">
      <div className="ml-[1px] py-[18px] bg-white leading-4 text-primary font-bold flex items-center pl-7.5">
        <p className="mr-[40px] text-[16px]">{title && title[0] ? title[0].name : ''}</p>
      </div>
      <div className="mx-5 flex my-4 flex-col bg-white rounded px-7.5 py-6">
        <div className="rounded-[40px]  flex items-center ">
          <div className="ml-[5px] text-[16px] font-bold text-primary">
            {title && title[0] ? title[0].name : ''}
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center mb-6">
            <div className="flex gap-x-2.5 relative">
              <LinkageFilter />
              <CustomButton
                type="button"
                className={buttonStyle({ primary: true })}
                text="コピーして新規作成"
                onClick={() => copy()}
              />
              <CustomButton
                type="button"
                className={buttonStyle({ primary: false })}
                text="編集"
                onClick={() => edit()}
              />
            </div>
          </div>
          <LinkageTable
            linkages={filteredLinkages ?? linkages}
            needsCheckBox={true}
            emitSelected={updateSelected}
          />
        </div>
      </div>
    </div>
  );
};
