import { ReactNode, useState } from "react";
import { mergeClassNames } from "../../utils/style";
import Checkbox from "../atoms/Checkbox";

type Props<T> = {
  uniqueIdentifier: keyof T;
  headers: { title: string; getValue: (item: T) => ReactNode }[];
  items: T[];
  disabled?: boolean;
  onChange?: (items: T[]) => void;
};

export default function Table<T>({
  uniqueIdentifier,
  headers,
  items,
  disabled,
  onChange,
}: Props<T>) {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  if (!items.length) {
    return (
      <div className="w-full h-full grid place-content-center border border-[#B5B5B5]">
        No Data.
      </div>
    );
  }

  return (
    <div className="h-full overflow-y-auto border border-[#B5B5B5]">
      <table className="table-fixed w-full border-b border-gray-border">
        <thead>
          <tr className="divide-x h-12 divide-white sticky top-0 text-[11px]">
            <th
              align="left"
              className="px-5 w-16 text-white font-semibold bg-gray-light"
            >
              <Checkbox
                disabled={disabled}
                selected={
                  selectedItems.length > 0 &&
                  (selectedItems.length === items.length || "some")
                }
                onClick={() => {
                  const isSelected =
                    selectedItems.length > 0 &&
                    selectedItems.length === items.length;

                  const updatedSelectedItems = isSelected ? [] : items;
                  setSelectedItems(updatedSelectedItems);
                  onChange?.(updatedSelectedItems);
                }}
              />
            </th>
            {headers.map((header, headerIndex) => {
              return (
                <th
                  key={headerIndex}
                  align="left"
                  className={mergeClassNames(
                    "px-5 text-white font-semibold bg-gray-light",
                    headerIndex === 0 && "w-16"
                  )}
                >
                  {header.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-border">
          {items.map((item, itemIndex) => {
            const isSelected = selectedItems.find(
              (seletedItem) =>
                seletedItem[uniqueIdentifier] === item[uniqueIdentifier]
            );

            return (
              <tr
                key={itemIndex}
                className="divide-x h-14 divide-gray-border text-[13px]"
              >
                <td className="px-5">
                  <Checkbox
                    disabled={disabled}
                    selected={!!isSelected}
                    onClick={() => {
                      const isSelected = selectedItems.find(
                        (previousSelectedItem) =>
                          previousSelectedItem[uniqueIdentifier] ===
                          item[uniqueIdentifier]
                      );
                      const updatedSelectedItems = isSelected
                        ? selectedItems.filter(
                            (previousSelectedItem) =>
                              previousSelectedItem[uniqueIdentifier] !==
                              item[uniqueIdentifier]
                          )
                        : [...selectedItems, item];
                      setSelectedItems(updatedSelectedItems);
                      onChange?.(updatedSelectedItems);
                    }}
                  />
                </td>
                {headers.map((header, headerIndex) => {
                  return (
                    <td key={headerIndex} className="px-5 break-all">
                      {header.getValue(item)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
