import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { poiConditionAction } from "../../../ducks/poi_conditions/actions";
import { poiConditionSelectors } from "../../../ducks/poi_conditions/selector";
import { PrefCitiesWithCode } from "../../../constants/prefCity";
import { PoiSelectorAllChecker } from "./AllChecker";
import { PoiSelectorGroup } from "./Group";
import { PoiSelectorSearchResults } from "./SearchResults";

const schema = z.object({
  filterChain: z.string().nullish(),
  filterCity: z.string().nullish(),
  name: z.string().nullish(),
  chain: z.array(z.string()),
  city: z.array(z.string()),
  conditions: z.number().gt(0, { message: "検索条件が指定されていません" }),
});
export type PoiFilterSchema = z.infer<typeof schema>;

const zero2undef = (arg: string[]) => {
  return arg.length > 0 ? arg : undefined;
};

export const PoiSelectorBody = ({
  closeButton,
  closeSelector,
}: {
  closeButton: ReactNode;
  closeSelector?: () => void;
}) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PoiFilterSchema>({
    resolver: zodResolver(schema),
    defaultValues: { chain: [], city: [] },
  });

  const conditions = useSelector(poiConditionSelectors.getConditions);
  const poiByConditions = useSelector(poiConditionSelectors.getPoiByConditions);

  useEffect(() => {
    if (conditions.length === 0) {
      dispatch(poiConditionAction.getPoiConditions.started({}));
    }
    // 初回表示時のみ poiConditions 取得
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fChain, fCity, selectChains, selectCity, inputName] = watch([
    "filterChain",
    "filterCity",
    "chain",
    "city",
    "name",
  ]);

  const [shouldValidateConditions, setShouldValidateConditions] =
    useState<boolean>(false);

  // 検索処理
  const filter = useCallback(() => {
    setShouldValidateConditions(true);
    handleSubmit(async (input: PoiFilterSchema) => {
      dispatch(
        poiConditionAction.getPoiByConditions.started({
          body: {
            place_name: input.name ?? undefined,
            chain_names: zero2undef(input.chain),
            cities: zero2undef(input.city),
          },
        })
      );
    })();
  }, [dispatch, handleSubmit]);

  const checkAllChain = useCallback(() => {
    const a =
      conditions
        ?.map((i) => i.chain_name || [])
        .flat()
        .filter((i) => i.includes(fChain ?? "")) ?? [];
    setValue("chain", [...getValues("chain"), ...a]);
  }, [conditions, setValue, fChain, getValues]);

  const resetAllChain = useCallback(() => {
    setValue("chain", []);
  }, [setValue]);

  const selectedChain = useMemo(() => {
    return fChain
      ? conditions?.map((row) => {
          return {
            ...row,
            chain_name: row.chain_name?.filter((c) => c.includes(fChain)) ?? [],
          };
        }) ?? []
      : conditions ?? [];
  }, [fChain, conditions]);

  const selectedPrefCities = useMemo(() => {
    return fCity
      ? PrefCitiesWithCode?.map((pref) => {
          return {
            ...pref,
            cities: pref.cities.filter((c) =>
              `${pref.pref}${c.name}`.includes(fCity)
            ),
          };
        })
      : PrefCitiesWithCode;
  }, [fCity]);

  const checkAllCity = useCallback(() => {
    const cities = selectedPrefCities.map((i) => i.cities).flat();

    setValue("city", [...getValues("city"), ...cities.map((c) => c.name)]);
  }, [setValue, selectedPrefCities, getValues]);

  const resetAllCity = useCallback(() => {
    setValue("city", []);
  }, [setValue]);

  useEffect(() => {
    setValue(
      "conditions",
      selectChains.length + selectCity.length + (inputName?.length ?? 0),
      {
        shouldValidate: shouldValidateConditions,
      }
    );
  }, [selectChains, selectCity, inputName, setValue, shouldValidateConditions]);

  return (
    <div className="flex flex-col h-full">
      <div className="bg-white p-[24px] pt-[16px] rounded-lg h-[60%] flex flex-col">
        <div className="flex items-center mb-3">
          <h4 className="font-bold flex-1">施設マスタより選択</h4>
          <div className="flex justify-end gap-2">{closeButton}</div>
        </div>
        <div className="flex-auto flex flex-col">
          <div className="flex flex-auto gap-4">
            <div className="border rounded flex flex-col flex-auto w-1/2">
              <div className="p-4 border-b">
                <p className="font-bold text-sm">チェーン名を指定</p>
                <div className="mt-2.5">
                  <input
                    type="text"
                    placeholder="チェーン名でフィルタ"
                    className="w-full rounded border border-gray-border focus-within:border-gray-light placeholder:text-gray-light p-2 inset-0 focus:outline-none text-sm bg-transparent"
                    {...register("filterChain")}
                  />
                </div>
                <div className="flex px-0.5 pt-4">
                  <PoiSelectorAllChecker
                    set={checkAllChain}
                    reset={resetAllChain}
                    maxLength={
                      conditions?.reduce<number>(
                        (prev, gen) => prev + (gen.chain_name?.length ?? 0),
                        0
                      ) ?? 0
                    }
                    checkedLength={selectChains.length}
                  />
                </div>
              </div>
              <div className="flex-auto overflow-y-auto relative">
                <div className="absolute top-0 left-0 w-full">
                  {selectedChain.map((genre) => {
                    return (
                      <PoiSelectorGroup
                        title={genre.genre_name ?? ""}
                        key={genre.genre_name}
                        filterText={fChain}
                        rows={genre.chain_name ?? []}
                        selectedRows={selectChains}
                        setValue={setValue}
                        setValueKey={"chain"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="border rounded flex flex-col flex-auto w-1/2">
              <div className="p-4 border-b">
                <p className="font-bold text-sm">都道府県・市区町村を指定</p>
                <div className="mt-2.5">
                  <input
                    type="text"
                    placeholder="都道府県 市区町村名でフィルタ"
                    className="w-full rounded border border-gray-border focus-within:border-gray-light placeholder:text-gray-light p-2 inset-0 focus:outline-none text-sm bg-transparent"
                    {...register("filterCity")}
                  />
                </div>
                <div className="flex px-0.5 pt-4">
                  <PoiSelectorAllChecker
                    set={checkAllCity}
                    reset={resetAllCity}
                    maxLength={PrefCitiesWithCode.reduce<number>(
                      (prev, pref) => prev + pref.cities.length,
                      0
                    )}
                    checkedLength={selectCity.length}
                  />
                </div>
              </div>
              <div className="flex-auto overflow-y-auto relative">
                <div className="absolute top-0 left-0 w-full">
                  {selectedPrefCities.map((pref) => (
                    <PoiSelectorGroup
                      title={pref.pref}
                      prefix={pref.pref}
                      key={pref.pref}
                      filterText={fCity}
                      rows={pref.cities.map((i) => i.name) ?? []}
                      selectedRows={selectCity}
                      setValue={setValue}
                      setValueKey={"city"}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-4">
            <div className="flex flex-col w-1/2 p-4">
              <div className="flex items-center gap-4">
                <p className="font-bold text-sm">
                  施設名を指定{" "}
                  <span className="text-xs font-normal text-gray-400">
                    (部分一致で検索)
                  </span>
                </p>
                <div className="flex flex-auto">
                  <input
                    type="text"
                    placeholder=""
                    className="w-full rounded border border-gray-border focus-within:border-gray-light placeholder:text-gray-light p-2 inset-0 focus:outline-none text-sm bg-transparent"
                    {...register("name")}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 flex justify-end items-center gap-4">
              {errors.conditions && (
                <span className="text-red-600">
                  {errors.conditions?.message}
                </span>
              )}
              <button
                type="button"
                onClick={filter}
                className="px-10 py-3 disabled:bg-gray-border whitespace-nowrap bg-primary text-white disabled:cursor-not-allowed rounded"
              >
                検索
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[40%] flex flex-col">
        <div className="bg-white p-[24px] pt-[16px] rounded-lg h-full mt-3 flex flex-col">
          <PoiSelectorSearchResults poiByConditions={poiByConditions} closeSelector={closeSelector} />
        </div>
      </div>
    </div>
  );
};
