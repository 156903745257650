import { EmptyObject } from "@reduxjs/toolkit";
import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { LogoutRequest, ModalDataItem, RefreshTokenRequest } from "./types";

const actionCreator = actionCreatorFactory("@@App");

export const appActions = {
  showLoading: actionCreator("SHOW_LOADING"),
  hideLoading: actionCreator("HIDE_LOADING"),
  showModal: actionCreator.async<ModalDataItem, {}>("SHOW_MODAL"),
  closeModal: actionCreator("CLOSE_MODAL"),
  fetchMessageJson: actionCreator.async<EmptyObject, EmptyObject>(
    "FETCH_MESSAGE_JSON"
  ),
  displayNotification: actionCreator.async<
    { type: "success" | "failed"; text: string },
    {}
  >("DISPLAY_NOTIFICATION"),
  resetNotification: actionCreator("RESET_NOTIFICATION"),
  showNotification: actionCreator("SHOW_NOTIFICATION"),
  logout: actionCreator.async<LogoutRequest, unknown>("LOGOUT"),
  resetPassword: actionCreator.async<EmptyObject, EmptyObject>(
    "RESET_PASSWORD"
  ),
  refreshToken: actionCreator.async<RefreshTokenRequest, EmptyObject>(
    "REFRESH_TOKEN"
  ),
  fetchStateJson: actionCreator.async<EmptyObject, {}>("FETCH_STATE_JSON"),
  fetchGeoJson: actionCreator.async<
    { name: string; access_token: string } & CommonAsyncType,
    {}
  >("FETCH_GEO_JSON"),
};
