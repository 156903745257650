import React from "react";
import { useSideNavigation } from "../../hooks/useNavigation";
import { IconComponent } from "../../icons/types";
import { mergeClassNames } from "../../utils/style";

type Props = {
  shrink?: boolean;
  title: string;
  path: string;
  icon: IconComponent | null;
  current?: boolean;
};

export const SideNavItem: React.FC<Props> = ({
  shrink,
  title,
  path,
  icon: Icon,
  current,
}) => {
  const { handleNavigate } = useSideNavigation();

  const crnt = current ?? false;

  return (
    <button
      type="button"
      onClick={() => {
        handleNavigate(path);
      }}
      className={mergeClassNames(
        "flex items-center gap-x-2.5 py-3 px-5 my-1 mx-2",
        crnt
          ? "font-bold text-primary bg-primary-light rounded overflow-hidden"
          : "font-normal"
      )}
    >
      {Icon && (
        <Icon
          className={mergeClassNames(
            "w-6 h-6",
            crnt ? "text-primary" : "text-black"
          )}
        />
      )}
      {!shrink && <span className="text-sm">{title}</span>}
    </button>
  );
};
