import { mergeClassNames } from "../utils/style";
import { IconProps } from "./types";

type Props = IconProps & {
  direction?: "left" | "right";
};

export default function IconDoubleChevron({ className, direction }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 9 9"
      className={mergeClassNames(
        className ?? "h-2",
        direction === "right" && "rotate-180"
      )}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.5.5-4 4 4 4m-4-8-4 4 4 4"
      />
    </svg>
  );
}
