import { call, put } from "typed-redux-saga";
import { receptionApi } from "../../core/http/openAPIClient";
import { takeEveryFsa } from "../operations";
import { receptionAction } from "./actions";

export function* receptionSaga() {
  yield takeEveryFsa(
    receptionAction.fetchReceptionById.started,
    function* (actions) {
      const { onComplete, onSuccess, onError } = actions.payload;
      const response: any = yield* call(async (): Promise<unknown> => {
        return await receptionApi
          .result(actions.payload.id)
          .catch((error) => error);
      });

      if (response && response.error && onError) {
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }

      yield* put(
        receptionAction.fetchReceptionById.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
}
