import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Spot } from "../../core/api";
import { CirclePosition, FacilityMasterItems, SpotListItem } from "./types";

const actionCreator = actionCreatorFactory("@@Spot");

export const spotAction = {
  addPolygonPosition: actionCreator.async<number[], {}>("ADD_POLYGON_POSITION"),
  resetPolygonPosition: actionCreator("RESET_POLYGON_POSITION"),
  addRectanglePosition: actionCreator.async<number[], {}>(
    "ADD_RECTANGLE_POSITION"
  ),
  resetRectanglePosition: actionCreator("RESET_RECTANGLE_POSITION"),
  addCirclePosition: actionCreator.async<CirclePosition, {}>(
    "ADD_CIRCLE_POSITION"
  ),
  resetCirclePosition: actionCreator("RESET_CIRCLE_POSITION"),

  //Usage: W-007 Add checkbox item operation
  addSelectedFacilityMasterFilterType: actionCreator.async<string, {}>(
    "ADD_SELECTED_FACILITY_MASTER_FILTER_TYPE"
  ),
  //Usage: W-007 Remove checkbox item operation
  removeSelectedFacilityMasterFilterType: actionCreator.async<string, {}>(
    "REMOVE_SELECTED_FACILITY_MASTER_TYPE"
  ),

  //Usage: W-007 Get all item from facilty master
  setFacilityMasterItems: actionCreator.async<FacilityMasterItems[], {}>(
    "SET_FACILITY_MASTER_ITEMS"
  ),

  fetchSpotByID: actionCreator.async<
    { id: number } & CommonAsyncType,
    SpotListItem
  >("FETCH_SPOT_BY_ID"),

  analyzeSpot: actionCreator.async<
    { body: Spot } & CommonAsyncType,
    { reception_id: string }
  >("ANALYZE_SPOT"),

  deleteSpot: actionCreator.async<{ id: number } & CommonAsyncType, {}>(
    "DELETE_SPOT"
  ),

  modifySpot: actionCreator.async<{ body: Spot } & CommonAsyncType, {}>(
    "MODIFY_SPOT"
  ),

  registerSpot: actionCreator.async<{ body: Spot } & CommonAsyncType, {}>(
    "REGISTER_SPOT"
  ),
};
