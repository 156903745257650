import { IconProps } from "../types";

export default function IconHistory({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path d="M10.782 18.786a.75.75 0 0 1-.75.75h-4.29a2.178 2.178 0 0 1-2.175-2.175V5.194a2.178 2.178 0 0 1 2.175-2.18h11.776a2.178 2.178 0 0 1 2.175 2.18v4.56a.75.75 0 0 1-1.5 0v-4.56a.676.676 0 0 0-.675-.675H5.742a.676.676 0 0 0-.675.675v12.167a.676.676 0 0 0 .675.675h4.29a.75.75 0 0 1 .75.75m5.4-11.036a.75.75 0 0 0-.75-.75H7.824a.75.75 0 0 0 0 1.5h7.612a.75.75 0 0 0 .75-.75m-3.626 3.526a.75.75 0 0 0-.75-.75H7.824a.75.75 0 0 0 0 1.5h3.987a.75.75 0 0 0 .75-.75M7.824 14.05a.75.75 0 1 0 0 1.5h1.457a.75.75 0 1 0 0-1.5Zm13.287-.308a4.828 4.828 0 0 0-8.83 2.317h-.547a.375.375 0 0 0-.265.641l1.35 1.347a.375.375 0 0 0 .529 0l1.35-1.347a.375.375 0 0 0-.265-.641h-.656a3.335 3.335 0 1 1 1.372 3.088.75.75 0 1 0-.873 1.219 4.837 4.837 0 0 0 6.835-6.624m-4.015.114a.75.75 0 0 0-.75.749v1.828a.75.75 0 0 0 .75.751h1.282a.75.75 0 0 0 0-1.5h-.532v-1.077a.749.749 0 0 0-.749-.751" />
    </svg>
  );
}
