import { OutlineIconProps } from "../types";

export default function IconSameDayActivityAnalysis({
    className,
}: OutlineIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className ?? "w-6 h-6 text-primary"}
        >
            <path
                fill="currentColor"
                d="M16.878 12.499a3.848 3.848 0 0 0-2.321.791l-3-2.927c.35-.593.534-1.269.537-1.957a3.915 3.915 0 0 0-.046-.476l4.084-1.489a2.657 2.657 0 0 0 2.173 1.173 2.755 2.755 0 0 0 2.7-2.806 2.7 2.7 0 1 0-5.39 0c0 .046.011.09.013.135L11.54 6.427a3.624 3.624 0 0 0-3.114-1.831 3.742 3.742 0 0 0-3.661 3.81 3.742 3.742 0 0 0 3.661 3.808 3.535 3.535 0 0 0 2.086-.686l3 2.922a4.228 4.228 0 0 0-.617 2.193c0 .031.008.059.008.09l-3.993.908a3.109 3.109 0 0 0-2.783-1.806 3.254 3.254 0 0 0 0 6.5 3.184 3.184 0 0 0 3.111-3.141l3.984-.906a3.987 3.987 0 0 0 3.653 2.5 4.148 4.148 0 0 0 0-8.29m1.43-8.909a1.215 1.215 0 1 1-1.167 1.215 1.192 1.192 0 0 1 1.166-1.214M6.124 20.749a1.662 1.662 0 1 1 1.6-1.661 1.63 1.63 0 0 1-1.6 1.661Zm.17-12.342a2.134 2.134 0 1 1 2.132 2.219 2.18 2.18 0 0 1-2.132-2.22m10.584 10.792a2.556 2.556 0 1 1 2.454-2.554 2.508 2.508 0 0 1-2.454 2.554Z"
            />
        </svg>
    );
}
