import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import {
  CreateSportOptionItems,
  CreateSpotType,
  FacilityMasterItemsType,
  RoadOptionSpotItems,
} from "../../constants";

import { useDispatch } from "react-redux";
import { Spot, SpotAreaTypeEnum } from "../../core/api";
import { analysisExecuteActions } from "../../ducks/analysis_execute";
import { appActions } from "../../ducks/app";
import { assetAction } from "../../ducks/asset";
import { FacilityMasterItems } from "../../ducks/spot/types";
import { useCreateSpot } from "../../hooks/useCreateSpot";
import { CustomButton } from "../atoms/CustomButton/CustomButton";
import { CustomSelections } from "../molecules/CustomSelections";
import { FacilityMasterMap } from "./FacilityMasterMap";
import { FacilityMasterFilter } from "./FacilityMasterMap/FacilityMasterFilter";
import { CircleMoeMap } from "./MoeMap/CircleMoeMap";
import MoeMap from "./MoeMap/MoeMap";
import { RectangleMoeMap } from "./MoeMap/RectangleMoeMap";
import { CircleRoadMap } from "./RoadMap/CircleRoadMap";
import { PolygonRoadMap } from "./RoadMap/PolygonRoadMap";
import { RectangleRoadMap } from "./RoadMap/RectangleRoadMap";
import { RoadItemSelection } from "./RoadMap/RoadItemSelection";

type Props = {
  isOpen: boolean;
  handleNext?: () => void;
  handleClose?: () => void;
  openPoiSelector?: () => void;
  spots: Spot[];
};

export const CreateSpotModal: React.FC<Props> = (props) => {
  const spots = props.spots;
  const [currentMode, setCurrentMode] = useState("");
  const [currentRoadMode, setCurrentRoadMode] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [roadMapData, setRoadMapData] = useState<any>();
  const [openSelection, setOpenSelection] = useState(false);
  const [rectStep, setRectStep] = useState(1);
  const dispatch = useDispatch();
  // const spots = useSelector(assetSelectors.getSpots);

  const {
    addPolygonPosition,
    addCirclePosition,
    addRectanglePosition,
    resetCirclePosition,
    resetPolygonPosition,
    resetRectanglePosition,
    setFacilityMasterItems,
    handleSelectFacilityMasterType,
    facilityMasterFilterTypes,
    targetFacilityMasterItem,
    handleAddRoadMapSelection,
    roadMapSelectionItem,
    resetRoadMapSelection,
    selectedRoadLine,
    activateSecondDraw,
    displayItems,
  } = useCreateSpot();

  const [isInvalidPolygon, setIsInvalidPolygon] = useState<boolean>(false);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",

      zIndex: 20,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 120,
    },
  };
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const handleClickNext = () => {
    if (isInvalidPolygon) {
      dispatch(
        appActions.showModal.started({
          title: "辺どうしが交差したポリゴンは作成できません",
          closeButtonLabel: "閉じる",
          handleClose: () => {
            dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      if (props.handleNext) {
        props.handleNext();
      }
    }
  };
  const handleClickClose = () => {
    if (props.handleClose) {
      resetCirclePosition();
      resetPolygonPosition();
      resetRectanglePosition();
      props.handleClose();
    }
  };

  useEffect(() => {
    if (selectedRoadLine && selectedRoadLine?.length > 0) {
      setDisableSubmitButton(false);
    }
  }, [selectedRoadLine]);

  const handleChangeMode = (value: string) => {
    setRectStep(0);
    setDisableSubmitButton(true);
    setCurrentMode(value);
    setCurrentRoadMode("");
    resetRoadMapSelection();
    resetCirclePosition();
    resetPolygonPosition();
    resetRectanglePosition();
    if (value === CreateSpotType.FACILITY_MASTER) {
      if (props.openPoiSelector) {
        handleClickClose();
        props.openPoiSelector();
        setCurrentMode("");
      }
      //TODO: to be replace when api get facilit master is ready
      // setFacilityMasterItems(FacilityMasterItemsMockItem);
    }
  };
  const handleChangeRoadMode = (value: string) => {
    setRectStep(0);

    setDisableSubmitButton(true);
    setCurrentMode(CreateSpotType.ROAD);
    resetRoadMapSelection();
    setCurrentRoadMode(value);
  };
  const handleCallback = (value: any) => {
    //For Polygon value
    if (value) {
      setDisableSubmitButton(false);
      const data = value();

      addPolygonPosition(data[0]);
    } else {
      setDisableSubmitButton(true);
    }
  };

  const handleRectCallback = (value: any) => {
    //For Rectangle value
    if (value) {
      setDisableSubmitButton(false);
      const data = value();
      addRectanglePosition(data[0]);
    }
  };

  const handleCircleCallback = (circleCord: number[], circleRadius: number) => {
    if (circleRadius) {
      addCirclePosition({
        lat: circleCord[1],
        lng: circleCord[0],
        radius: circleRadius,
      });
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  };
  useEffect(() => {
    dispatch(assetAction.fetchAsset.started({ forceRefetch: true }));
  }, [props.isOpen]);

  const handleRoadMapCallback = (roadMap: any) => {
    if (typeof roadMap === "function") {
      const value = roadMap();

      setRoadMapData(value);
    }
  };
  const handleFacilityMasterCallback = (value: FacilityMasterItems | null) => {
    if (value) {
      const spot: Spot & { isNew: boolean } = {
        dataset_name: "",
        spot_name: "",
        area: {
          type: SpotAreaTypeEnum.Poi,
          data: [
            {
              latitude: value.cordorinate[1],
              longitude: value.cordorinate[0],
              radius: 10,
            },
          ],
        },
        isNew: true,
      };
      dispatch(analysisExecuteActions.setSelectedSpotItems.started([spot]));
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  };

  const addRoadMapSelection = useCallback(() => {
    handleAddRoadMapSelection(roadMapData);

    setOpenSelection(true);
    setRoadMapData(undefined);
    setRectStep(2);
  }, []);

  const handleRoadCircleCallback = (
    circleCord: number[],
    circleRadius: number
  ) => {
    addCirclePosition({
      lat: circleCord[1],
      lng: circleCord[0],
      radius: circleRadius,
    });
    setRoadMapData({
      lat: circleCord[1],
      lng: circleCord[0],
      radius: circleRadius,
    });
  };

  const handleRectRoadCallback = (roadMap: any) => {
    setRoadMapData(roadMap);
  };
  return (
    <Modal
      isOpen={props.isOpen}
      style={modalStyle}
      shouldReturnFocusAfterClose={true}
      portalClassName="alert-modal"
    >
      <div className="w-[1156px] h-[700px] bg-white rounded-lg pt-[25px] flex flex-col">
        <div className="flex-auto flex flex-col h-[548px] px-[40px]">
          <div className="flex justify-between items-center">
            <div className="font-bold">分析スポットの新規追加</div>
            <div
              onClick={handleClickClose}
              className="w-[32px] h-[32px] flex justify-end items-center cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex flex-1  mt-[15px]">
            <div className="overflow-y-scroll h-[548px]">
              <div>
                <CustomSelections
                  onChange={handleChangeMode}
                  options={CreateSportOptionItems}
                  mode={currentMode}
                  itemsEnable={displayItems?.spot_sreate_methods}
                />
                {currentMode === CreateSpotType.ROAD && (
                  <div className="mt-[16px]">
                    <CustomSelections
                      onChange={handleChangeRoadMode}
                      options={RoadOptionSpotItems}
                    />
                  </div>
                )}
                {roadMapSelectionItem && roadMapSelectionItem.length > 0 && (
                  <div>
                    {roadMapSelectionItem.map((item, index) => {
                      return (
                        <RoadItemSelection
                          type={currentRoadMode}
                          roadItems={item[0]}
                          key={index}
                          index={index}
                        />
                      );
                    })}
                  </div>
                )}
                {currentMode === CreateSpotType.ROAD &&
                  roadMapSelectionItem &&
                  roadMapSelectionItem.length < 2 && (
                    <div className="mt-[16px] flex justify-center ">
                      <CustomButton
                        className="w-[200px] h-[40px] font-medium text-[14px] px-0 py-0"
                        text="選択した範囲で道路を表示"
                        type={"button"}
                        disabled={!roadMapData}
                        onClick={addRoadMapSelection}
                      />
                    </div>
                  )}
              </div>
              {/* Filter Checkbox for W-007 */}
              {currentMode === CreateSpotType.FACILITY_MASTER && (
                <FacilityMasterFilter
                  selectedFilterType={facilityMasterFilterTypes}
                  items={FacilityMasterItemsType}
                  callback={handleSelectFacilityMasterType}
                />
              )}
            </div>

            <div className="flex-auto ml-[36px] h-[548px]">
              {currentMode === CreateSpotType.FACILITY_MASTER && (
                <FacilityMasterMap
                  callback={handleFacilityMasterCallback}
                  targetPinnedItem={targetFacilityMasterItem}
                />
              )}
              {currentMode === CreateSpotType.POLYGON && (
                <MoeMap
                  spots={spots}
                  callback={handleCallback}
                  setIsInvalidPolygon={setIsInvalidPolygon}
                />
              )}
              {currentMode === CreateSpotType.RECTANGLE && (
                <RectangleMoeMap callback={handleRectCallback} />
              )}
              {currentMode === CreateSpotType.CIRCLE && (
                <CircleMoeMap callback={handleCircleCallback} />
              )}
              {currentRoadMode === CreateSpotType.POLYGON && (
                <PolygonRoadMap
                  callback={handleRoadMapCallback}
                  selectedRoad={selectedRoadLine}
                  secondDraw={activateSecondDraw}
                />
              )}
              {currentRoadMode === CreateSpotType.RECTANGLE && (
                <RectangleRoadMap
                  selectedRoad={selectedRoadLine}
                  callback={handleRectRoadCallback}
                  rectIndex={rectStep}
                />
              )}
              {currentRoadMode === CreateSpotType.CIRCLE && (
                <CircleRoadMap
                  selectedRoad={selectedRoadLine}
                  callback={handleRoadCircleCallback}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-[88px] flex justify-center items-center">
          <CustomButton
            text="キャンセル"
            className="w-[200px] h-[40px] text-black bg-white border border-gray-light rounded items-center flex justify-center "
            onClick={handleClickClose}
          />
          <CustomButton
            disabled={disableSubmitButton}
            text="スポット設定"
            className="w-[200px] font-medium h-[40px] ml-[16px] text-white   rounded items-center flex justify-center "
            onClick={handleClickNext}
          />
        </div>
      </div>
    </Modal>
  );
};
