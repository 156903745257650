import React, { useCallback, useEffect, useState } from "react";
import { PoiByConditions, SpotAreaTypeEnum } from "../../../core/api";
import { spotAction } from "../../../ducks/spot";
import { appActions } from "../../../ducks/app";
import { useDispatch } from "react-redux";
import { poiConditionSelectors } from "../../../ducks/poi_conditions/selector";
import { PoiSelectorAllChecker } from "./AllChecker";
import { assetAction } from "../../../ducks/asset";

export const PoiSelectorSearchResults = ({
  poiByConditions,
  closeSelector,
}: {
  poiByConditions: PoiByConditions[];
  closeSelector?: () => void;
}) => {
  const dispatch = useDispatch();

  const [dividedSpots, setDividedSpots] = useState<(typeof poiByConditions)[]>(
    []
  );
  const [dividedSpotsIdx, setDividedSpotsIdx] = useState(0);
  const [selectedSpotIds, setSelectedSpotIds] = useState<string[]>([]);

  const listPerPage = 1000;

  const checkAll = useCallback(() => {
    setSelectedSpotIds(
      poiByConditions?.map((s) => {
        return s.place_id;
      }) ?? []
    );
  }, [poiByConditions]);

  const resetAll = useCallback(() => {
    setSelectedSpotIds([]);
  }, []);

  const mutateSelectedSpotIds = useCallback(
    ({
      event,
      poiByConditions,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      poiByConditions: PoiByConditions;
    }) => {
      if (event.target.checked && 3 <= selectedSpotIds.length) {
        return;
      }

      setSelectedSpotIds(
        event.target.checked
          ? [...selectedSpotIds, poiByConditions.place_id]
          : selectedSpotIds.filter((sid) => {
              return sid !== poiByConditions.place_id;
            })
      );
    },
    [selectedSpotIds]
  );

  const register = useCallback(() => {
    const spots = poiByConditions.filter((p) =>
      selectedSpotIds.includes(p.place_id)
    );

    dispatch(appActions.showLoading());

    const promises = spots.map((p) => {
      const spot = {
        spot_name: p.place_name,
        dataset_name: "",
        area: {
          type: SpotAreaTypeEnum.Poi,
          data: [
            {
              longitude: p.pl_lon,
              latitude: p.pl_lat,
              radius: p.radius,
            },
          ],
        },
      };

      return new Promise((resolve, reject) => {
        dispatch(
          spotAction.registerSpot.started({
            body: spot,
            onError: () => {
              reject();
            },
            onSuccess: () => {
              resolve("");
            },
          })
        );
      });
    });

    Promise.all(promises)
      .then(() => {
        dispatch(assetAction.fetchSpotList.started({ forceRefetch: true }));
        dispatch(appActions.showNotification());
        dispatch(
          appActions.displayNotification.started({
            type: "success",
            text: "スポットを保存しました",
          })
        );
        if (closeSelector) {
          closeSelector()
        }
      })
      .catch(() => {
        dispatch(appActions.showNotification());
        dispatch(
          appActions.displayNotification.started({
            type: "failed",
            text: "スポットを保存出来ませんでした",
          })
        );
      })
      .finally(() => {
        dispatch(appActions.hideLoading());
      });
  }, [dispatch, poiByConditions, selectedSpotIds]);

  useEffect(() => {
    if (poiByConditions) {
      setDividedSpots(
        poiByConditions?.reduce<(typeof poiByConditions)[]>(
          (prev, curr, idx) => {
            return idx % listPerPage
              ? prev
              : [...prev, poiByConditions.slice(idx, idx + listPerPage)];
          },
          []
        )
      );
    }
  }, [poiByConditions]);

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-4">
          <h4 className="font-bold flex-1">検索結果</h4>
          <p>{poiByConditions?.length}件</p>
          {/*<div className="flex items-center gap-4">*/}
          {/*  {poiByConditions && poiByConditions.length > 0 && (*/}
          {/*    <>*/}
          {/*      <div className="flex px-0.5 gap-5">*/}
          {/*        <PoiSelectorAllChecker*/}
          {/*          set={checkAll}*/}
          {/*          reset={resetAll}*/}
          {/*          maxLength={poiByConditions.length}*/}
          {/*          checkedLength={selectedSpotIds.length}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
        <button
          type="button"
          className="px-10 py-3 disabled:bg-gray-border whitespace-nowrap bg-primary text-white disabled:cursor-not-allowed rounded"
          disabled={selectedSpotIds.length === 0}
          onClick={() => register()}
        >
          スポット設定
        </button>
      </div>
      <div className="border rounded bg-gray-50 flex flex-col flex-auto">
        <div className="grid grid-cols-[40px_1fr_2fr_2fr] border-b py-1.5 px-4">
          <div />
          <div className="text-xs text-gray-400">チェーン名</div>
          <div className="text-xs text-gray-400">施設名</div>
          <div className="text-xs text-gray-400">住所</div>
        </div>
        <div className="flex-auto overflow-y-auto relative text-sm">
          <div className="absolute top-0 left-0 w-full pt-3">
            {dividedSpots[dividedSpotsIdx]?.map((s, idx) => (
              <label className="cursor-pointer" key={idx}>
                <div className="grid grid-cols-[40px_1fr_2fr_2fr] py-1 px-4 hover:bg-gray-200">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4.5 w-4.5 accent-primary"
                      value={s.place_id}
                      checked={selectedSpotIds.includes(s.place_id)}
                      onChange={(event) =>
                        mutateSelectedSpotIds({ event, poiByConditions: s })
                      }
                    />
                  </div>
                  <div>{s.chain_name}</div>
                  <div>{s.place_name}</div>
                  <div>{s.address}</div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="flex justify-between items-center p-2 border-t">
          <button
            type="button"
            onClick={() => setDividedSpotsIdx(dividedSpotsIdx - 1)}
            disabled={dividedSpotsIdx <= 0}
            className={`text-xs rounded bg-gray-200 p-2 ${
              dividedSpotsIdx <= 0 && "opacity-30"
            }`}
          >
            PREV
          </button>
          {0 < dividedSpots.length && (
            <div className="text-xs">
              {dividedSpotsIdx + 1} / {dividedSpots.length}
            </div>
          )}
          <button
            type="button"
            onClick={() => setDividedSpotsIdx(dividedSpotsIdx + 1)}
            disabled={dividedSpots.length <= dividedSpotsIdx + 1}
            className={`text-xs rounded bg-gray-200 p-2 ${
              dividedSpots.length <= dividedSpotsIdx + 1 && "opacity-30"
            }`}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
};
