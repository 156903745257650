import { call, put } from "typed-redux-saga";
import { AppConfig } from "../../AppConfig";
import { MAX_USERS_PER_PAGE } from "../../constants/pagination";
import { ONE_DAY_IN_MILLISECONDS } from "../../constants/time";
import { APIResponse, LocalAsset } from "../../core/@types/api";
import { Asset, AssetSchemaEnum, Service, Spot, Tenant, User } from "../../core/api";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { assetsApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { store } from "../../store";
import { appActions } from "../app";
import { takeEveryFsa } from "../operations";
import { assetAction } from "./actions";

export function* assetSaga() {
  // Retrieve asset from local storage. If the data is empty or expired, fetch from API.
  yield takeEveryFsa(assetAction.fetchAsset.started, function* (actions) {
    const response: APIResponse<Asset> = yield* call(async () => {
      const key = AppConfig.Instance.LocalStorageKey["assets"];
      const localAsset = LocalStorageManager.Instance.getObject<LocalAsset>(key);
      const now = new Date().getTime();
      const expiredAt = Math.min(
        ...Object.values(localAsset ?? {}).map(({ expiredAt }) => new Date(expiredAt).getTime())
      );

      if (!actions.payload.forceRefetch && localAsset && expiredAt > now) {
        return {
          data: Object.entries(localAsset).reduce((previousValue, [k, v]) => {
            return {
              ...previousValue,
              [k]: v.value,
            };
          }, {}),
        };
      } else {
        const res: APIResponse<Asset> = await assetsApi.asset().catch((err) => err);
        if (res?.data) {
          LocalStorageManager.Instance.saveObject(
            key,
            Object.entries(res.data).reduce((previousValue, [k, v]) => {
              return {
                ...previousValue,
                [k]: {
                  value: v,
                  expiredAt: now + ONE_DAY_IN_MILLISECONDS,
                },
              };
            }, {})
          );
        }
        return res;
      }
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      assetAction.fetchAsset.done({
        params: {},
        result: response?.data ?? {},
      })
    );
  });

  // Retrieve service from local storage. If the data is empty or expired, fetch from API.
  yield takeEveryFsa(assetAction.fetchService.started, function* (actions) {
    const response: APIResponse<Service> = yield* call(async () => {
      const key = AppConfig.Instance.LocalStorageKey["assets"];
      const localAsset = LocalStorageManager.Instance.getObject<LocalAsset>(key);
      const now = new Date().getTime();
      const expiredAt = localAsset?.services?.expiredAt ? new Date(localAsset.services.expiredAt).getTime() : 0;

      if (localAsset?.services?.value && expiredAt > now) {
        return { data: localAsset.services.value[0] };
      } else {
        const res: APIResponse<Asset> = await assetsApi.asset(AssetSchemaEnum.Services).catch((err) => err);
        if (res?.data) {
          LocalStorageManager.Instance.saveObject(key, {
            ...(localAsset ?? {}),
            services: {
              value: res.data.services ?? [],
              expiredAt: now + ONE_DAY_IN_MILLISECONDS,
            },
          });
        }
        return { ...res, data: res?.data?.services?.[0] };
      }
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      assetAction.fetchService.done({
        params: {},
        result: response?.data ?? {},
      })
    );
  });

  // Retrieve all spots from local storage. If the data is empty or expired, fetch from API.
  yield takeEveryFsa(assetAction.fetchSpotList.started, function* (actions) {
    const response: APIResponse<Spot[]> = yield* call(async () => {
      const key = AppConfig.Instance.LocalStorageKey["assets"];
      const localAsset = LocalStorageManager.Instance.getObject<LocalAsset>(key);
      const now = new Date().getTime();
      const expiredAt = localAsset?.spots?.expiredAt ? new Date(localAsset.spots.expiredAt).getTime() : 0;

      if (!actions.payload.forceRefetch && localAsset?.spots?.value && expiredAt > now) {
        return { data: localAsset.spots.value };
      } else {
        const res: APIResponse<Asset> = await assetsApi.asset(AssetSchemaEnum.Spots).catch((err) => err);
        if (res?.data) {
          LocalStorageManager.Instance.saveObject(key, {
            ...(localAsset ?? {}),
            spots: {
              value: res.data.spots ?? [],
              expiredAt: now + ONE_DAY_IN_MILLISECONDS,
            },
          });
        }
        return { ...res, data: res?.data?.spots ?? [] };
      }
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response?.error?.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      assetAction.fetchSpotList.done({
        params: {},
        result: response.data ?? [],
      })
    );
  });

  // Retrieve all users from local storage. If the data is empty or expired, fetch from API.
  yield takeEveryFsa(assetAction.fetchUserList.started, function* (actions) {
    const response: APIResponse<User[]> = yield* call(async () => {
      const key = AppConfig.Instance.LocalStorageKey["assets"];
      const localAsset = LocalStorageManager.Instance.getObject<LocalAsset>(key);
      const now = new Date().getTime();
      const expiredAt = localAsset?.users?.expiredAt ? new Date(localAsset.users.expiredAt).getTime() : 0;

      if (!actions.payload.forceRefetch && localAsset?.users?.value && expiredAt > now) {
        return { data: localAsset.users.value };
      } else {
        const res: APIResponse<Asset> = await assetsApi.asset(AssetSchemaEnum.Users).catch((err) => err);
        if (res?.data) {
          LocalStorageManager.Instance.saveObject(key, {
            ...(localAsset ?? {}),
            users: {
              value: res.data?.users ?? [],
              expiredAt: now + ONE_DAY_IN_MILLISECONDS,
            },
          });
        }
        return { ...res, data: res?.data?.users ?? [] };
      }
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    const users = response?.data ?? [];
    const numberOfPages = Math.max(Math.ceil(users.length / MAX_USERS_PER_PAGE), 1);

    const { page } = actions.payload;
    const pageNumber = Math.max(Math.min(page ?? 1, numberOfPages), 1);
    const startFrom = (pageNumber - 1) * MAX_USERS_PER_PAGE;

    yield* put(
      assetAction.fetchUserList.done({
        params: {},
        result: {
          value: page ? users.slice(startFrom, startFrom + MAX_USERS_PER_PAGE) : users,
          currentPage: pageNumber,
          totalPages: numberOfPages,
        },
      })
    );
  });

  // Retrieve all tenants from local storage. If the data is empty or expired, fetch from API.
  yield takeEveryFsa(assetAction.fetchTenantList.started, function* (actions) {
    const response: APIResponse<Tenant[]> = yield* call(async () => {
      const key = AppConfig.Instance.LocalStorageKey["assets"];
      const localAsset = LocalStorageManager.Instance.getObject<LocalAsset>(key);
      const now = new Date().getTime();
      const expiredAt = localAsset?.tenants?.expiredAt ? new Date(localAsset.tenants.expiredAt).getTime() : 0;

      if (!actions.payload.forceRefetch && localAsset?.tenants?.value && expiredAt > now) {
        return { data: localAsset.tenants.value };
      } else {
        const res: APIResponse<Asset> = await assetsApi.asset(AssetSchemaEnum.Tenants).catch((err) => err);
        if (res?.data) {
          LocalStorageManager.Instance.saveObject(key, {
            ...(localAsset ?? {}),
            tenants: {
              value: res.data.tenants ?? [],
              expiredAt: now + ONE_DAY_IN_MILLISECONDS,
            },
          });
        }
        return { ...res, data: res?.data?.tenants ?? [] };
      }
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response?.error?.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      assetAction.fetchTenantList.done({
        params: {},
        result: response.data ?? [],
      })
    );

    actions.payload.onSuccess?.(response);
  });

  // Retrieve current user based on the id saved in local storage.
  yield takeEveryFsa(assetAction.fetchLoggedInUser.started, function* (actions) {
    const response: APIResponse<User[]> = yield* call(async () => {
      const key = AppConfig.Instance.LocalStorageKey["assets"];
      const localAsset = LocalStorageManager.Instance.getObject<LocalAsset>(key);
      const now = new Date().getTime();
      const expiredAt = localAsset?.users?.expiredAt ? new Date(localAsset.users.expiredAt).getTime() : 0;

      if (localAsset?.users?.value && expiredAt > now) {
        return { data: localAsset.users.value };
      } else {
        const res: APIResponse<Asset> = await assetsApi.asset(AssetSchemaEnum.Users).catch((err) => err);
        if (res?.data) {
          LocalStorageManager.Instance.saveObject(key, {
            ...(localAsset ?? {}),
            users: {
              value: res.data?.users ?? [],
              expiredAt: now + ONE_DAY_IN_MILLISECONDS,
            },
          });
        }
        return { ...res, data: res?.data?.users ?? [] };
      }
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    } else {
      actions.payload.onSuccess?.(response.data);
    }

    actions.payload.onComplete?.();

    const users = response?.data ?? [];
    const userId = Number(LocalStorageManager.Instance.getObject(AppConfig.Instance.LocalStorageKey["userId"]));

    yield* put(
      assetAction.fetchLoggedInUser.done({
        params: {},
        result: users.find((user) => user.id === userId) ?? null,
      })
    );
  });
}
