import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { spotAction } from "./actions";
import { SpotDrawState } from "./types";

const initialState: SpotDrawState = {
  polygonPositions: [],
  rectanglePositions: [],
  facilityMasterItems: [],
  facilityMasterFilterTypes: [],
  targetFacilityMasterItem: [],
  circlePositions: {
    lat: 0,
    lng: 0,
    radius: 0,
  },
};

export function SpotReducer(
  state = initialState,
  action: Action
): SpotDrawState {
  if (isType(action, spotAction.addPolygonPosition.done)) {
    const target = action.payload.params.map(([longitude, latitude]: any) => ({
      longitude,
      latitude,
    }));

    return {
      ...state,
      polygonPositions: target,
    };
  }
  if (isType(action, spotAction.addRectanglePosition.done)) {
    const target = action.payload.params.map(([longitude, latitude]: any) => ({
      longitude,
      latitude,
    }));

    return {
      ...state,
      rectanglePositions: target,
    };
  }
  if (isType(action, spotAction.addCirclePosition.done)) {
    return {
      ...state,
      circlePositions: action.payload.params,
    };
  }
  if (isType(action, spotAction.resetPolygonPosition)) {
    return {
      ...state,
      polygonPositions: [],
    };
  }
  if (isType(action, spotAction.resetRectanglePosition)) {
    return {
      ...state,
      rectanglePositions: [],
    };
  }
  if (isType(action, spotAction.resetCirclePosition)) {
    return {
      ...state,
      circlePositions: {
        lat: 0,
        lng: 0,
        radius: 0,
      },
    };
  }
  if (isType(action, spotAction.setFacilityMasterItems.done)) {
    return {
      ...state,
      facilityMasterItems: action.payload.params,
    };
  }
  if (isType(action, spotAction.addSelectedFacilityMasterFilterType.done)) {
    const target = [...state.facilityMasterFilterTypes, action.payload.params];
    return {
      ...state,
      facilityMasterFilterTypes: target,

      //filtering from total master facility items
      targetFacilityMasterItem: [
        ...state.facilityMasterItems.filter((item) =>
          target.includes(item.facility_type)
        ),
      ],
    };
  }
  if (isType(action, spotAction.removeSelectedFacilityMasterFilterType.done)) {
    return {
      ...state,
      facilityMasterFilterTypes: [
        ...state.facilityMasterFilterTypes.filter(
          (item) => item !== action.payload.params
        ),
      ],
      targetFacilityMasterItem: [
        ...state.targetFacilityMasterItem.filter(
          (item) => item.facility_type !== action.payload.params
        ),
      ],
    };
  }
  return state;
}
