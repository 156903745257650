import React from "react";
import { SpotMenu } from "../../core/api";
import { mergeClassNames } from "../../utils/style";

type Props = {
  item: SpotMenu;
  onClick: (item: SpotMenu) => void;
  isSelected: boolean;
};

export const AnalysisItemCard: React.FC<Props> = (props) => {
  const { item, onClick, isSelected } = props;

  const handleOnClick = () => {
    if (onClick) {
      onClick(item);
    }
  };
  return (
    <div
      onClick={handleOnClick}
      className={mergeClassNames(
        "cursor-pointer break-all border gap-y-7 px-7.5 pt-9 pb-7.5 rounded-lg flex flex-col items-center",
        isSelected
          ? "bg-[#E8F0FE] border-primary"
          : "bg-white border-gray-light"
      )}
    >
      <div className="flex flex-col items-center gap-y-4">
        <div
          className={mergeClassNames(
            "flex flex-col gap-y-4 p-4.5 rounded-full",
            isSelected ? "bg-white" : "bg-primary-light"
          )}
        >
          <img src={item.image_url} />
        </div>
        <span
          className={mergeClassNames("font-bold", isSelected && "text-primary")}
        >
          {item.name}
        </span>
      </div>
      <div className="text-[13px]">{item.description}</div>
      {/*<div className="flex flex-col text-[13px]">
        <span>ユースケース例：</span>
        <span>{item.usecase_description}</span>
      </div>*/}
    </div>
  );
};
