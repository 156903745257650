//@ts-nocheck
import { useEffect, useRef, useState } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxCircle from "mapbox-gl-circle";
import { useSelector } from "react-redux";
import { MapStyle } from "../../../constants";
import { assetSelectors } from "../../../ducks/asset/selector";
import { RoadResponseBody } from "../../../ducks/roads";
mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

type Props = {
  callback?(circleCord?: number[], circleRadius?: number): void;
  selectedRoad?: RoadResponseBody[];
};

export const CircleRoadMap = (props: Props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const defaultLocation = useSelector(assetSelectors.getDefaultLocation);

  const [lng, setLng] = useState(defaultLocation.longitude);
  const [lat, setLat] = useState(defaultLocation.latitude);
  const [zoom, setZoom] = useState(13);

  const [circleData, setCircleData] = useState();
  const [circleCord, setCircleCord] = useState([]);
  const [circleRadius, setCircleRadius] = useState();

  useEffect(() => {
    if (props.callback) {
      props.callback(circleCord, circleRadius);
    }
  }, [circleCord, circleRadius]);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MapStyle,
      center: [lng, lat],
      zoom: zoom,
    })
      .addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        })
      )
      .addControl(new mapboxgl.NavigationControl(), "bottom-right")
      .addControl(
        new mapboxgl.GeolocateControl({ maxWidth: 300 }),
        "bottom-right"
      );
    let flag = 0;

    map.current.on("click", function (event) {
      if (flag < 2) {
        setCircleCord([event.lngLat.lng, event.lngLat.lat]);
        setCircleRadius(250);
        var newEditable = new MapboxCircle(event.lngLat, 250, {
          editable: true,
          minRadius: 50,
          radius: 100,
          fillColor: flag === 0 ? "#1967D2" : "#000",
          strokeColor: flag === 0 ? "#1967D2" : "#000",
          strokeWeight: 2,
          fillOpacity: 0.5,
          debugEl: document.getElementById("debug"),
        })
          .on("centerchanged", function (event) {
            setCircleCord(event._currentCenterLngLat);
          })
          .on("radiuschanged", function (event) {
            setCircleRadius(event._currentRadius);
          })
          .addTo(map.current);
        setCircleData(newEditable);

        document
          .getElementById("remove-circle")
          .addEventListener("click", () => {
            props.callback();
            flag = false;
          });

        flag += 1;
      }
    });

    if (map.current) {
      //@ts-ignore
      map.current.on("move", () => {
        if (map.current) {
          //@ts-ignore
          setLng(map.current.getCenter().lng.toFixed(4));
          //@ts-ignore
          setLat(map.current.getCenter().lat.toFixed(4));
          //@ts-ignore
          setZoom(map.current.getZoom().toFixed(2));
        }
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (map.current) {
        if (props.selectedRoad && props.selectedRoad.length > 0) {
          props.selectedRoad.forEach((item: RoadResponseBody) => {
            map.current
              .addSource(`source-roads-${item.id}`, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: item.area,
                  },
                },
              })
              .addLayer({
                id: `layer-road-${item.id}`,
                source: `source-roads-${item.id}`,
                type: "line",
                paint: {
                  "line-color": "#1967D2", // blue color fill
                  "line-width": 5,
                },
              });
          });
        }
      }
    }, 100);
  }, [props.selectedRoad]);

  return (
    <div className="flex flex-1 h-full  w-full   ">
      <div ref={mapContainer} className="w-full h-full">
        <button
          id="remove-circle"
          className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
          onClick={() => {
            if (typeof circleData === "object") circleData.remove();
          }}
        >
          リセット
        </button>
      </div>
    </div>
  );
};
