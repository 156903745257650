import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppConfig } from "../../AppConfig";
import { PageRoutes } from "../../constants/Routes";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { LoginAction } from "../../ducks/login";

type Props = {
  requiredLogin?: boolean;
  requiredLogout?: boolean;
  children?: ReactNode;
};

export default function AuthTemplate({
  requiredLogin,
  requiredLogout,
  children,
}: Props) {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);

  useEffect(() => {
    const accessToken = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["accessToken"]
    );
    setIsLoggedIn(Boolean(accessToken));
    const tenantId =
      LocalStorageManager.Instance.getObject<string>(
        AppConfig.Instance.LocalStorageKey["tenantId"]
      ) ?? 0;
    const userId =
      LocalStorageManager.Instance.getObject<string>(
        AppConfig.Instance.LocalStorageKey["userId"]
      ) ?? 0;
    const authority =
      LocalStorageManager.Instance.getObject<string>(
        AppConfig.Instance.LocalStorageKey["authority"]
      ) ?? 0;
    dispatch(
      LoginAction.setUserInformation({
        user_id: Number(userId),
        tenant_id: Number(tenantId),
        authority: Number(authority),
      })
    );
  }, []);

  if (isLoggedIn === null) {
    return null;
  }

  if (requiredLogin && !isLoggedIn) {
    return <Navigate replace to={PageRoutes.LOGIN} />;
  }

  if (requiredLogout && isLoggedIn) {
    return <Navigate replace to={PageRoutes.CREATE_SPOT} />;
  }

  if (!children) {
    return <Outlet />;
  }

  return <>{children}</>;
}
