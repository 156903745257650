import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode } from "react";
import {
  DeepPartial,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { ZodSchema, output } from "zod";

type Props<ValidationSchema extends ZodSchema> = {
  schema?: ValidationSchema;
  defaultValues?: DeepPartial<Record<string, unknown>>;
  className?: string;
  onSubmit: SubmitHandler<output<ValidationSchema>>;
  children: ReactNode;
};

export default function Form<ValidationSchema extends ZodSchema>({
  schema,
  defaultValues,
  className,
  onSubmit,

  children,
}: Props<ValidationSchema>) {
  const methods = useForm({
    mode: "all",
    defaultValues,
    ...(schema ? { resolver: zodResolver(schema) } : {}),
  });

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}
