import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../constants/Routes";
import { analysisExecuteActions } from "../ducks/analysis_execute";
import { assetSelectors } from "../ducks/asset/selector";

export const useSideNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const displayList = useSelector(assetSelectors.getDisplayItems);
  const stepList = [
    displayList.analysis_item_selection,
    displayList.spot_selection,
    displayList.period_selection,
  ];

  const handleNavigate = (path: string) => {
    if (path === PageRoutes.EXECUTE_SPOT_ANALYSIS) {
      if (stepList[0]) {
        dispatch(analysisExecuteActions.analysisReset());
        navigate(path);
      } else if (stepList[1]) {
        navigate(PageRoutes.CHOOSE_SPOT_ANALYSIS);
      } else if (stepList[2]) {
        navigate(PageRoutes.SELECT_SPOT_ANALYSIS_TYPE);
      }
    } else {
      navigate(path);
    }
  };

  return { handleNavigate };
};
