import React, { useCallback } from "react";
import { CheckboxActive } from "../atoms/CheckboxActive";
import { CheckboxDash } from "../atoms/CheckboxDash";
import { CheckboxInactive } from "../atoms/CheckboxInactive";
import { DisableCheckbox } from "../atoms/DisableCheckbox";

type Props = {
  dashed?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  isMax?: boolean;
  onClick?: () => void;
};

export const AnlyzeSpotCheckbox: React.FC<Props> = ({
  disabled,
  dashed,
  isSelected,
  onClick,
  isMax,
}) => {
  const handleClick = useCallback(() => {
    if (onClick && !isMax) {
      onClick();
    } else if (isSelected && onClick) {
      onClick();
    }
  }, [isSelected, dashed, onClick, isMax]);

  if (disabled) {
    return (
      <div className=" cursor-not-allowed">
        <DisableCheckbox />
      </div>
    );
  }

  return (
    <div
      className={`${
        isMax && !isSelected ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={handleClick}
    >
      {dashed && <CheckboxDash />}
      {isSelected && <CheckboxActive size="normal" />}
      {!dashed && !isSelected && (
        <CheckboxInactive size="normal" isMax={isMax} />
      )}
    </div>
  );
};
