import { IconProps } from "../types";

export default function IconTenantManagement({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path d="M22.016 16.937v-.357a1.107 1.107 0 0 0-.021-.18l.576-.411a.45.45 0 0 0 .13-.587l-.377-.665a.45.45 0 0 0-.579-.187l-.615.281a1.415 1.415 0 0 0-.306-.214 2.7 2.7 0 0 0-.34-.155l-.107-.678a.45.45 0 0 0-.445-.38h-.775a.449.449 0 0 0-.444.38l-.108.686a1.727 1.727 0 0 0-.336.151 2.305 2.305 0 0 0-.31.21l-.613-.282a.449.449 0 0 0-.578.187l-.382.666a.45.45 0 0 0 .126.585l.583.426a.944.944 0 0 0-.021.172v.344a.952.952 0 0 0 .021.172l-.583.426a.45.45 0 0 0-.126.585l.38.669a.449.449 0 0 0 .578.187l.615-.281a2.305 2.305 0 0 0 .31.21 2.271 2.271 0 0 0 .336.159l.107.678a.45.45 0 0 0 .445.38h.775a.451.451 0 0 0 .445-.38l.107-.678a1.411 1.411 0 0 0 .34-.155 2.791 2.791 0 0 0 .306-.214l.615.281a.45.45 0 0 0 .579-.187l.377-.667a.449.449 0 0 0-.128-.587l-.58-.418a1.1 1.1 0 0 0 .021-.176m-1.7.6a1.094 1.094 0 1 1 .319-.772 1.051 1.051 0 0 1-.319.772m-6.438-4.845h-3.75a.75.75 0 0 0-.75.75v4.875H5.207v-8.065h13.588v1.315a.75.75 0 0 0 1.5 0v-1.315h.979a.751.751 0 0 0 .709-1l-1.37-3.956a.749.749 0 0 0-.708-.5H4.155a.75.75 0 0 0-.705.493L2.011 9.242a.75.75 0 0 0 .7 1.006h.991v8.815a.75.75 0 0 0 .75.75h9.424a.75.75 0 0 0 .75-.75v-5.625a.75.75 0 0 0-.75-.75m-9.2-6.4h14.69l.851 2.456H3.787Zm6.2 7.9h2.25v4.125h-2.25Z" />
    </svg>
  );
}
