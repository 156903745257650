import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { ReceptionState } from "./types";
import { receptionAction } from "./actions";

const initialState: ReceptionState = {};

export function ReceptionReducer(
  state = initialState,
  action: Action
): ReceptionState {
  if (isType(action, receptionAction.fetchReceptionById.done)) {
    return {
      ...state,
      data: action.payload.result,
    };
  }
  return state;
}
