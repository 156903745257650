import { z, ZodTypeAny } from "zod";
import { SelectOptionType } from "../core/@types";
import { BaseTableCols } from "../ducks/analysis_execute";

export const MapStyle = "mapbox://styles/mapbox/streets-v12";

export const NETWORK_ERROR = "ERR_NETWORK";

export const defaultLngLat = [139.7049, 35.662];
export const CreateSportOptionItems: SelectOptionType[] = [
  {
    id: 1,
    name: "施設マスタより選択",
    type: "poi",
  },
  {
    id: 2,
    name: "地図上でスポットを定義（多角形）",
    type: "polygon",
  },
  {
    id: 3,
    name: "地図上でスポットを定義（円）",
    type: "circle",
  },
  {
    id: 4,
    name: "地図上でスポットを定義（長方形）",
    type: "rectangle",
  },
  {
    id: 5,
    name: "地図上でスポットを定義（道路）",
    type: "road",
  },
];

export const RoadOptionSpotItems: SelectOptionType[] = [
  {
    id: 1,
    name: "地図上でスポットを定義（多角形）",
    type: "polygon",
  },
  {
    id: 2,
    name: "地図上でスポットを定義（円）",
    type: "circle",
  },
  {
    id: 3,
    name: "地図上でスポットを定義（長方形）",
    type: "rectangle",
  },
];

export enum RoadSpotType {
  POLYGON = "地図上でスポットを定義（多角形）",
  CIRCLE = "地図上でスポットを定義（円）",
  RECTANGLE = "地図上でスポットを定義（長方形）",
}

export enum CreateSpotType {
  FACILITY_MASTER = "施設マスタより選択",
  POLYGON = "地図上でスポットを定義（多角形）",
  CIRCLE = "地図上でスポットを定義（円）",
  RECTANGLE = "地図上でスポットを定義（長方形）",
  ROAD = "地図上でスポットを定義（道路）",
}

export const RoadMapMockData = [
  {
    id: 1,
    name: "永代通り",
    area: [
      [139.69868733847352, 35.664715693227805],
      [139.69679136803774, 35.66442404980006],
    ],
  },
  {
    id: 2,
    name: "新宿",
    area: [
      [139.69679136803774, 35.66442404980006],
      [139.70789136803774, 35.66442404980006],
    ],
  },
  {
    id: 3,
    name: "新宿",
    area: [139.56544, 35.652],
  },
  {
    id: 4,
    name: "新宿",
    area: [139.56544, 35.652],
  },
  {
    id: 5,
    name: "新宿",
    area: [139.56544, 35.652],
  },
];
//Add New Facility Master Type
export enum FacilityMasterItemsType {
  HOTEL = "ホテル",
  CONVENIENCE_STORE = "コンビニエンスストア",
  MEDICAL_FACILITY = "医療施設",
  CULTURAL_FACILITY = "文化施設",
  COMMERCIAL_COMPLEX = "複合商業施設",
  FAMILTY_RESTAURANTS = "ファミリーレストラン",
  DEPARTMENT_STORES = "百貨店",
  SUPERMARKET = "スーパーマーケット",
  SALON = "SALON",
}

export const TABLE_COLUMN_DEFINITIONS: BaseTableCols[] = [
  {
    key: "checkbox",
    dataKey: "checkbox",
    width: 6,
    classes: "",
    rowClasses: "",
    title: "",
  },
  {
    key: "id",
    dataKey: "id",
    title: "ID",
    width: 4,
    classes: "justify-start border-r",
    rowClasses: "justify-center border-r border-gray-transparent",
  },
  {
    key: "spot_name",
    title: "スポット名",
    dataKey: "spot_name",
    classes: "justify-start border-r",
    rowClasses: "justify-start px-[20px] border-r border-gray-transparent",
    width: 40,
  },
  {
    key: "data_generation_status",
    dataKey: "data_generation_status",
    classes: "justify-start border-r",
    rowClasses: "justify-start px-[20px] border-r border-gray-transparent",
    title: "データ生成ステータス",
    width: 25,
  },
  {
    key: "registration_date",
    dataKey: "registration_date",
    classes: "justify-start",
    rowClasses: "justify-start px-[20px] ",
    title: "登録日時",
    width: 25,
  },
];

export const drawControlStyleBlack = [
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",

      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",

      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-point-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-opacity": 1,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-point-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-point-stroke-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-static",
    type: "circle",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
];
export const drawingControlStyle = [
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],

      "fill-outline-color": "#1967D2",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],
      "fill-outline-color": "#1967D2",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#1967D2",
    },
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],

      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],

      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],

      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#1967D2",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-point-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-opacity": 1,
      "circle-color": "#1967D2",
    },
  },
  {
    id: "gl-draw-point-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#1967D2",
    },
  },
  {
    id: "gl-draw-point-stroke-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": "#1967D2",
    },
  },
  {
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],
      "fill-outline-color": "#1967D2",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["boolean", ["get", "user_valid"], false],
        "#000",
        "#1967D2",
      ],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-static",
    type: "circle",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#1967D2",
    },
  },
];

export const numericString = (schema: ZodTypeAny) =>
  z.preprocess((a) => {
    if (typeof a === "string") {
      return parseInt(a, 10);
    } else if (typeof a === "number") {
      return a;
    } else {
      return undefined;
    }
  }, schema);

export const TABLE_TENANT_COLUMN_DEFINITIONS: BaseTableCols[] = [
  {
    key: "checkbox",
    dataKey: "checkbox",
    width: 5,
    classes: "",
    rowClasses: "",
    title: "",
  },
  {
    key: "id",
    dataKey: "id",
    title: "ID",
    width: 5,
    classes: "justify-start border-r",
    rowClasses: "justify-center border-r border-gray-transparent",
  },
  {
    key: "tenant_name",
    title: "テナント名",
    dataKey: "tenant_name",
    classes: "justify-start border-r",
    rowClasses: "justify-start px-[20px] border-r border-gray-transparent",
    width: 30,
  },
  {
    key: "target_area",
    dataKey: "target_area",
    classes: "justify-start border-r",
    rowClasses: "justify-start px-[20px] border-r border-gray-transparent",
    title: "対象エリア",
    width: 20,
  },
  {
    key: "usage_start_date",
    dataKey: "usage_start_date",
    classes: "justify-start border-r",
    rowClasses: "justify-start px-[20px] border-r border-gray-transparent",
    title: "データ利用開始日",
    width: 20,
  },
  {
    key: "registration_date",
    dataKey: "registration_date",
    classes: "justify-start ",
    rowClasses: "justify-start px-[20px]",
    title: "登録日時",
    width: 20,
  },
];
