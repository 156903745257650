//@ts-nocheck
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { useDispatch, useSelector } from "react-redux";
import { MapStyle, drawingControlStyle } from "../../../constants";
import { BLUE } from "../../../constants/color";
import { analysisExecuteActions } from "../../../ducks/analysis_execute";
import { analysisExecuteSelectors } from "../../../ducks/analysis_execute/selector";
import { assetSelectors } from "../../../ducks/asset/selector";
import { isTrue } from "../../../utils/array";
import { checkSelfIntersection } from "../../../utils/polygons";
type Props = {
  callback?(value: any): void;
  spots: Spot[];
  setIsInvalidPolygon: (flg: boolean) => void;
};
const TOKEN = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;
let draw = new MapboxDraw({
  userProperties: true,
  displayControlsDefault: false,
  styles: drawingControlStyle,
  controls: {
    polygon: true,
    trash: true,
  },
});

const ZOOM = 13;
const LAYER_OPACITY = 0.15;

export default function MoeMap(props: Props) {
  const dispatch = useDispatch();
  const [features, setFeatures] = useState({});
  const spots = props.spots;
  const mapContainer = useRef(null) as any;
  const map = useRef(null) as any;
  const defaultLocation = useSelector(assetSelectors.getDefaultLocation);
  const mapBound = useSelector(analysisExecuteSelectors.getMapBound);
  const deleteSpotID = useSelector(analysisExecuteSelectors.getDeleteSpotId);
  const onUpdate = useCallback((e: any) => {
    const coordinates = e.features[0].geometry.coordinates[0];
    if (checkSelfIntersection(coordinates)) {
      props.setIsInvalidPolygon(true);
    } else {
      props.setIsInvalidPolygon(false);
    }
    setFeatures((currFeatures) => {
      let newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures = f;
      }
      return newFeatures.geometry.coordinates;
    });

    props.callback((currFeatures) => {
      let newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures = f;
      }
      return newFeatures.geometry.coordinates;
    });
  }, []);
  const polygonFeature = useMemo(() => {
    return Object.keys(features).map((key) => features[key]);
  }, [features]);
  const handleDelete = () => {
    document
      .getElementsByClassName(
        "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
      )[0]
      .click();
    const editComp = document.getElementsByClassName(
      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
    );
    if (!editComp[0].classList.contains("active")) {
      editComp[0].click();
    }
  };

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
    props.callback();
  }, []);

  // useEffect(() => {
  //   dispatch(assetAction.fetchAsset.started({ forceRefetch: true }));
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: MapStyle,
        center: [defaultLocation.longitude, defaultLocation.latitude],
        zoom: ZOOM,
      })
        .addControl(
          new MapboxGeocoder({
            accessToken: TOKEN,
            mapboxgl: mapboxgl,
            placeholder: "地図を検索",
          })
        )
        .addControl(new mapboxgl.NavigationControl(), "bottom-right")
        .addControl(
          new mapboxgl.GeolocateControl({ maxWidth: 300 }),
          "bottom-right"
        )
        .addControl(
          new MapboxLanguage({
            defaultLanguage: "ja",
          })
        );

      map.current.addControl(draw);
      map.current.on("draw.create", onUpdate);
      map.current.on("draw.update", onUpdate);
      map.current.on("draw.delete", onDelete);
      map.current.on(MapboxDraw.constants.events.MODE_CHANGE, () => {
        if (
          MapboxDraw.constants.modes.SIMPLE_SELECT &&
          !draw.getAll().features.length
        ) {
          draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
          props.callback();
        }
      });
      draw.changeMode("draw_polygon");
      let flag = false;
      map.current.on("click", function (event) {
        if (!flag) {
          document
            .getElementById("remove-rect")
            .addEventListener("click", () => {
              props.callback();
              flag = false;
              draw.deleteAll();
              map.current.removeControl(draw);
              draw = new MapboxDraw({
                userProperties: true,
                displayControlsDefault: false,
                styles: drawingControlStyle,
                controls: {
                  polygon: true,
                  trash: true,
                },
              });

              map.current.addControl(draw);

              draw.changeMode("draw_polygon");
              const trashBtn = document.getElementsByClassName(
                "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
              )[0];
              const editComp = document.getElementsByClassName(
                "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
              )[0];

              trashBtn.style.display = "none";
              editComp.style.display = "none";
            });

          flag = true;
        }
      });
    }, 100);
  }, []);
  useEffect(() => {
    document.addEventListener("keyup", (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (polygonFeature.length === 0) {
          props.callback();
          draw.deleteAll();
          if (map.current) {
            map.current.removeControl(draw);
            draw = new MapboxDraw({
              userProperties: true,
              displayControlsDefault: false,
              styles: drawingControlStyle,
              controls: {
                polygon: true,
                trash: true,
              },
            });

            map.current.addControl(draw);

            draw.changeMode("draw_polygon");
          }
          const trashBtn = document.getElementsByClassName(
            "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
          )[0];
          const editComp = document.getElementsByClassName(
            "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
          )[0];

          trashBtn.style.display = "none";
          editComp.style.display = "none";
        }
      }
      if (event.key === "Backspace") {
        if (polygonFeature.length === 0) {
          props.callback();
          draw.deleteAll();
          if (map.current) {
            map.current.removeControl(draw);
            draw = new MapboxDraw({
              userProperties: true,
              displayControlsDefault: false,
              styles: drawingControlStyle,
              controls: {
                polygon: true,
                trash: true,
              },
            });

            map.current.addControl(draw);

            draw.changeMode("draw_polygon");
          }
          const trashBtn = document.getElementsByClassName(
            "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
          )[0];
          const editComp = document.getElementsByClassName(
            "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
          )[0];
          trashBtn.style.display = "none";
          editComp.style.display = "none";
        }
      }
    });
  }, [features]);

  useEffect(() => {
    setTimeout(() => {
      const trashBtn = document.getElementsByClassName(
        "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
      )[0];
      const editComp = document.getElementsByClassName(
        "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
      )[0];

      trashBtn.style.display = "none";
      editComp.style.display = "none";
    }, 100);

    return () => {
      map.current = null;
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      spots
        .filter((spot) => spot.id !== deleteSpotID?.id)
        .forEach((spot) => {
          const { id, area, spot_name } = spot;
          const isCircle = area?.type === "circle";
          const isPoi = area?.type === "poi";

          const sourceId = `source-spots-${id}-${spot_name}`;
          const fillLayerId = `layer-spots-${id}-${spot_name}`;
          const lineLayerId = `line-spots-${id}-${spot_name}`;
          const circleLayerId = `circle-spots-${spot.id}-${spot.spot_name}`;
          const innerCircleLayerId = `inner-circle-spots-${id}-${spot_name}`;
          const coordinates =
            area?.data
              ?.map(({ longitude, latitude }) => {
                if (!longitude || !latitude) return null;
                return [longitude, latitude] as [number, number];
              })
              .filter<[number, number]>(isTrue) ?? [];
          if (map.current) {
            // When all map resources are loaded, draw 2 layers on top - "fillLayerId" and "lineLayerId"
            const draw = () => {
              if (!map.current?.getSource(sourceId)) {
                if (isCircle || isPoi) {
                  map.current?.addSource(sourceId, {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: { type: "Point", coordinates: coordinates[0] },
                      properties: {},
                    },
                  });
                } else {
                  map.current?.addSource(sourceId, {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: { type: "Polygon", coordinates: [coordinates] },
                      properties: {},
                    },
                  });
                }
              }

              if (isCircle) {
                if (!map.current?.getLayer(fillLayerId)) {
                  map.current?.addLayer({
                    id: fillLayerId,
                    type: "circle",
                    source: sourceId,
                    paint: {
                      "circle-radius": area.data?.[0]?.radius ?? 0,
                      "circle-color": BLUE,
                      "circle-opacity": LAYER_OPACITY,
                      "circle-stroke-width": 2,
                      "circle-stroke-color": BLUE,
                    },
                  });
                }
              } else if (isPoi) {
                if (!map.current?.getLayer(fillLayerId)) {
                  map.current?.addLayer({
                    id: fillLayerId,
                    type: "symbol",
                    source: sourceId,
                    layout: {
                      "icon-image": "common-active-marker",
                      "icon-size": 0.25,
                    },
                  });
                }
              } else {
                if (!map.current?.getLayer(fillLayerId)) {
                  map.current?.addLayer({
                    id: fillLayerId,
                    source: sourceId,
                    type: "fill",
                    paint: {
                      "fill-color": BLUE,
                      "fill-opacity": LAYER_OPACITY,
                    },
                  });
                }
                if (!map.current?.getLayer(lineLayerId)) {
                  map.current?.addLayer({
                    id: lineLayerId,
                    type: "line",
                    source: sourceId,
                    paint: { "line-color": BLUE, "line-width": 2 },
                  });
                }
                if (!map.current?.getLayer(circleLayerId)) {
                  map.current?.addLayer({
                    id: circleLayerId,
                    type: "circle",
                    source: sourceId,
                    paint: { "circle-radius": 4, "circle-color": BLUE },
                  });
                }
                if (!map.current?.getLayer(innerCircleLayerId)) {
                  map.current?.addLayer({
                    id: innerCircleLayerId,
                    type: "circle",
                    source: sourceId,
                    paint: { "circle-radius": 2, "circle-color": "#fff" },
                  });
                }
              }
            };
            map.current.on("drag", () => {
              if (map.current) {
                const bounds = map.current.getBounds();
                dispatch(analysisExecuteActions.setMapbound(bounds));
              }
            });
            map.current.on("wheel", () => {
              if (map.current) {
                const bounds = map.current.getBounds();
                dispatch(analysisExecuteActions.setMapbound(bounds));
              }
            });
            if (map.current.isStyleLoaded()) {
              draw();
            } else {
              map.current.on("load", () => {
                draw();
              });
            }
          }
        });
      if (Object.keys(mapBound).length > 0) {
        map.current.fitBounds?.(mapBound, {
          duration: 0.25,
        });
      } else {
        if (spots.length > 0) {
          let allLatitudes: number[] = [];
          let allLongitude: number[] = [];
          spots.forEach((spot) => {
            //if (spot.status === 'success') {
            if (spot.area?.data?.length === 0) return;
            const laltLng = spot.area?.data;
            const latitudeArray = laltLng
              ?.map((x) => x.latitude)
              ?.filter((lat) => lat !== undefined) as number[];
            const longitudeArray = laltLng
              ?.map((x) => x.longitude)
              ?.filter((lon) => lon !== undefined) as number[];

            allLatitudes.push(...latitudeArray);
            allLongitude.push(...longitudeArray);
          });
          const minLat: number = Math.min(...allLatitudes);
          const minLon: number = Math.min(...allLongitude);
          const maxLat: number = Math.max(...allLatitudes);
          const maxLon: number = Math.max(...allLongitude);
          const marginVal = 0.01;
          const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(minLon - marginVal, minLat - marginVal),
            new mapboxgl.LngLat(maxLon + marginVal, maxLat + marginVal)
          );
          map.current.fitBounds?.(bounds, {
            duration: 0.25,
          });
        }
      }
    }, 100);
  }, [spots, deleteSpotID]);
  return (
    <>
      <div className="flex flex-1 h-full  w-full   ">
        <div ref={mapContainer} className="w-full h-full">
          <button
            id="remove-rect"
            className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
            onClick={handleDelete}
          >
            リセット
          </button>
        </div>
      </div>
    </>
  );
}

MoeMap.defaultProps = {
  callback: (value: any) => {},
};
