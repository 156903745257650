import Modal from "react-modal";
import { PoiSelectorBody } from "./Body";
import { ReactNode } from "react";

export const PoiSelector = ({
  isOpen,
  closeButton,
  closeSelector,
}: {
  isOpen: boolean;
  closeButton: ReactNode;
  closeSelector?: () => void;
}) => {
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "97%",
      height: "99%",
      zIndex: 20,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 120,
    },
  };

  return (
    <Modal isOpen={isOpen} style={modalStyle}>
      <PoiSelectorBody closeButton={closeButton} closeSelector={closeSelector} />
    </Modal>
  );
};
