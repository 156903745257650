import React, { createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../../../ducks/app";

const MessageContext = createContext(null);
type Props = {
  children: React.ReactNode;
};

export const MessageProvider = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appActions.fetchMessageJson.started({}));
    dispatch(appActions.fetchStateJson.started({}));
  }, []);
  return (
    <MessageContext.Provider value={null}>
      {props.children}
    </MessageContext.Provider>
  );
};
