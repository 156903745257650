import React from "react";
import { CustomButton } from "../atoms/CustomButton/CustomButton";

import IconX from "../../icons/IconX";

type Props = {
  isOpen: boolean;
  title: string;
  showXIcon?: boolean;
  detailText?: string;
  errorStatusCode?: string;
  closeButtonLabel?: string;
  nextButtonLabel?: string;
  handleNext?: () => void;
  handleClose?: () => void;
};

export const TenantDeleteModal: React.FC<Props> = ({
  isOpen,
  title,
  showXIcon,
  detailText,
  errorStatusCode,
  closeButtonLabel,
  nextButtonLabel,
  handleClose,
  handleNext,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-40 bg-black/25 grid place-content-center">
      <div className="w-[577px] relative py-16 rounded-xl bg-white px-[55px] flex items-center flex-col gap-y-11">
        {showXIcon && (
          <div
            className="absolute cursor-pointer p-2.5 right-0 top-0"
            onClick={handleClose}
          >
            <IconX />
          </div>
        )}

        <div className="flex flex-col gap-y-6">
          <div className="text-base whitespace-pre-line text-center font-bold">
            {title}
          </div>
          {(detailText || errorStatusCode) && (
            <div className="flex flex-col gap-y-5">
              {(detailText || errorStatusCode) && (
                <div className="w-[324px] border-b border-dotted" />
              )}
              {detailText && (
                <div className="whitespace-pre-wrap text-center text-[14px] text-[#666666]">
                  {detailText}
                </div>
              )}
              {errorStatusCode && (
                <div className="text-xs text-[#AAAAAA] text-center">
                  {errorStatusCode}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex gap-x-3.75">
          {closeButtonLabel && (
            <CustomButton
              onClick={handleClose}
              text={closeButtonLabel}
              className="w-[226px] bg-white border-gray-light border text-black"
            />
          )}
          {nextButtonLabel && (
            <CustomButton
              onClick={handleNext}
              text={nextButtonLabel}
              className="w-[226px] text-white"
            />
          )}
        </div>
      </div>
    </div>
  );
};
