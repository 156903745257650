import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { tenantAction } from "./actions";
import { TenantState } from "./types";

const initialState: TenantState = {
  selectedID: [],
  tenantList: [],
  numLoad: 20,
  offSet: 0,
  page: 1,
  maxPage: 1,
};

export function TenantReducer(
  state = initialState,
  action: Action
): TenantState {
  if (isType(action, tenantAction.selectTenant.done)) {
    let target = [];
    const isExist = state.selectedID.includes(action.payload.params.id);
    if (isExist) {
      target = state.selectedID.filter((id) => id !== action.payload.params.id);
    } else {
      target = [...state.selectedID, action.payload.params.id];
    }

    return {
      ...state,
      selectedID: target,
    };
  }

  if (isType(action, tenantAction.setSelectedTenants.done)) {
    return {
      ...state,
      selectedID: action.payload.params.ids,
    };
  }

  if (isType(action, tenantAction.resetSelectedTenant)) {
    return {
      ...state,
      selectedID: [],
    };
  }
  if (isType(action, tenantAction.fetchTenantList.done)) {
    return {
      ...state,
      tenantList: action.payload.result,
      maxPage:
        action.payload.result.length > 0
          ? action.payload.result.length / state.numLoad
          : 1,
    };
  }
  if (isType(action, tenantAction.fetchNextTenant)) {
    return {
      ...state,
      offSet: state.offSet + state.numLoad,
      page: state.page + 1,
    };
  }
  if (isType(action, tenantAction.fetchPrevTenant)) {
    return {
      ...state,
      offSet: state.offSet > state.numLoad ? state.offSet - state.numLoad : 0,
      page: state.page - 1,
    };
  }
  if (isType(action, tenantAction.fetchMostPreviousTenant)) {
    return {
      ...state,
      offSet: 0,
      page: 1,
    };
  }
  if (isType(action, tenantAction.fetchLatestTenant.done)) {
    return {
      ...state,
      offSet: action.payload.params.num - state.numLoad,
      page: Math.ceil(action.payload.params.num / state.numLoad),
    };
  }
  if (isType(action, tenantAction.setMaxPage.done)) {
    return {
      ...state,
      maxPage: action.payload.params.num,
    };
  }

  return state;
}
