import React from "react";
import "./DatePicker.css";

type Props = {
  text: string;
};

export const AnalysicAreaText: React.FC<Props> = (props) => {
  return (
    <div className="bg-[#E8F0FE] min-w-[145px] mr-[25px] h-[28px] rounded-[40px]  border border-[#E8F0FE] flex items-center justify-center text-primary font-bold text-[13px]">
      {props.text}
    </div>
  );
};
