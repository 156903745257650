// @ts-nocheck
// @ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
// @ts-expect-error
import MapboxCircle from "mapbox-gl-circle";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MapStyle } from "../../../constants";
import { assetSelectors } from "../../../ducks/asset/selector";

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

type Props = {
  callback?(circleCord?: number[], circleRadius?: number): void;
};

export const CircleMoeMap = (props: Props) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const defaultLocation = useSelector(assetSelectors.getDefaultLocation);

  const [lng, setLng] = useState(defaultLocation.longitude);
  const [lat, setLat] = useState(defaultLocation.latitude);
  const [zoom, setZoom] = useState(13);

  const [circleData, setCircleData] = useState();
  const [circleCord, setCircleCord] = useState([]);
  const [circleRadius, setCircleRadius] = useState();

  useEffect(() => {
    if (props.callback) {
      props.callback(circleCord, circleRadius);
    }
  }, [circleCord, circleRadius]);

  var editableCircleOpts = {
    editable: true,
    minRadius: 50,
    radius: 100,
    fillColor: "#1967D2",
    strokeColor: "#1967D2",
    strokeWeight: 2,
    fillOpacity: 0.5,
    debugEl: document.getElementById("debug"),
  };

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MapStyle,
      center: [lng, lat],
      zoom: zoom,
    })
      .addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: "地図を検索",
        })
      )
      .addControl(new mapboxgl.NavigationControl(), "bottom-right")
      .addControl(
        new mapboxgl.GeolocateControl({ maxWidth: 300 }),
        "bottom-right"
      )
      .addControl(
        new MapboxLanguage({
          defaultLanguage: "ja",
        })
      );
    let flag = false;

    map.current?.on("click", function ({ lngLat }: any) {
      if (!flag) {
        setCircleCord([lngLat.lng, lngLat.lat]);
        setCircleRadius(250);
        var newEditable = new MapboxCircle(lngLat, 250, editableCircleOpts)
          .on("centerchanged", function (event) {
            setCircleCord(event._currentCenterLngLat);
          })
          .on("radiuschanged", function (event) {
            setCircleRadius(event._currentRadius);
          })
          .addTo(map.current);
        setCircleData(newEditable);

        document
          .getElementById("remove-circle")
          .addEventListener("click", () => {
            props.callback();
            flag = false;
          });

        flag = true;
      }
    });

    if (map.current) {
      //@ts-ignore
      map.current.on("move", () => {
        if (map.current) {
          //@ts-ignore
          setLng(map.current.getCenter().lng.toFixed(4));
          //@ts-ignore
          setLat(map.current.getCenter().lat.toFixed(4));
          //@ts-ignore
          setZoom(map.current.getZoom().toFixed(2));
        }
      });
    }
  }, []);

  return (
    <div className="flex flex-1 h-full  w-full   ">
      <div ref={mapContainer} className="w-full h-full">
        <button
          id="remove-circle"
          className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
          onClick={() => {
            if (typeof circleData === "object") circleData.remove();
          }}
        >
          リセット
        </button>
      </div>
    </div>
  );
};

CircleMoeMap.defaultProps = {
  callback: (circleCord?: number[], circleRadius?: number) => {},
};
