import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selectApp = (state: StoreState) => state.road;

export const roadSelectors = {
  getRoad: createSelector(selectApp, (state) => state.roads),
  getRoadMapOptionSelection: createSelector(
    selectApp,
    (state) => state.roadMapSelection
  ),
  getSelectedRoadLine: createSelector(
    selectApp,
    (state) => state.selectedRoadLine
  ),
  getActivateSecondDraw: createSelector(
    selectApp,
    (state) => state.activateSecondDraw
  ),
};
