//@ts-nocheck
import { useCallback, useEffect, useRef, useState } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import DrawRectangle from "mapbox-gl-draw-rectangle-restrict-area";
import { useSelector } from "react-redux";
import { MapStyle, drawingControlStyle } from "../../../constants";
import { assetSelectors } from "../../../ducks/asset/selector";

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

type Props = {
  callback?(value: any): void;
};

export const RectangleMoeMap = (props: Props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const defaultLocation = useSelector(assetSelectors.getDefaultLocation);

  const [lng, setLng] = useState(defaultLocation.longitude);
  const [lat, setLat] = useState(defaultLocation.latitude);
  const [zoom, setZoom] = useState(13);

  const [features, setFeatures] = useState({});

  const handleUpdate = useCallback((e: any) => {
    setFeatures((currFeatures) => {
      let newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures = f;
      }
      return newFeatures.geometry.coordinates;
    });
    props.callback((currFeatures) => {
      let newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures = f;
      }
      return newFeatures.geometry.coordinates;
    });
  }, []);

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  }, []);

  useEffect(() => {
    //Initialize Mapbox from default libary
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MapStyle,
      center: [lng, lat],
      zoom: zoom,
    })
      .addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: "地図を検索",
        })
      )
      .addControl(new mapboxgl.NavigationControl(), "bottom-right")
      .addControl(
        new mapboxgl.GeolocateControl({ maxWidth: 300 }),
        "bottom-right"
      );

    //initial draw controller that can support rectangle drawing
    let draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      styles: drawingControlStyle,
      modes: Object.assign(MapboxDraw.modes, {
        draw_rectangle: DrawRectangle,
      }),
    });

    map.current.addControl(draw).addControl(
      new MapboxLanguage({
        defaultLanguage: "ja",
      })
    );

    //without changeMode, the draw controller will not be able to draw a rectangle
    draw.changeMode("draw_rectangle");
    let flag = false;

    map.current.on("draw.create", function (feature) {
      handleUpdate(feature);
    });
    map.current.on("draw.update", function (feature) {
      handleUpdate(feature);
    });
    map.current.on("click", function (event) {
      if (!flag) {
        document.getElementById("remove-rect").addEventListener("click", () => {
          props.callback();
          flag = false;
          draw.deleteAll();
          map.current.removeControl(draw);
          draw = new MapboxDraw({
            userProperties: true,
            displayControlsDefault: false,
            styles: drawingControlStyle,
            modes: Object.assign(MapboxDraw.modes, {
              draw_rectangle: DrawRectangle,
            }),
          });

          map.current.addControl(draw);

          draw.changeMode("draw_rectangle");
        });

        flag = true;
      }
    });

    if (map.current) {
      //@ts-ignore
      map.current.on("move", () => {
        if (map.current) {
          //@ts-ignore
          setLng(map.current.getCenter().lng.toFixed(4));
          //@ts-ignore
          setLat(map.current.getCenter().lat.toFixed(4));
          //@ts-ignore
          setZoom(map.current.getZoom().toFixed(2));
        }
      });
    }
  }, []);

  return (
    <div className="flex flex-1 h-full  w-full   ">
      <div ref={mapContainer} className="w-full h-full">
        <button
          id="remove-rect"
          className="absolute bg-primary top-[58px] rounded py-0 h-[32px] shadow-md flex items-center justify-center  right-[10px] z-30 w-[80px] text-white px-0"
          onClick={() => {}}
        >
          リセット
        </button>
      </div>
    </div>
  );
};

RectangleMoeMap.defaultProps = {
  callback: () => {},
};
