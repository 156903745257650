import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConfig } from "../../AppConfig";
import { PageRoutes } from "../../constants/Routes";
import { deleteLoginLocalStorageData } from "../../constants/utils";
import { LocalStorageManager } from "../../core/storage/LocalStoragetManager";
import { appActions } from "../../ducks/app";
import { assetAction } from "../../ducks/asset";
import { assetSelectors } from "../../ducks/asset/selector";
import IconDefaultUserProfileImage from "../../icons/IconDefaultUserProfileImage";
import IconLogout from "../../icons/IconLogout";
import { mergeClassNames } from "../../utils/style";

type Props = {
  empty?: boolean;
};

export const Header = ({ empty }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const title = useSelector(assetSelectors.getTitle);
  const loggedInUser = useSelector(assetSelectors.getLoggedInUser);
  const accessToken = LocalStorageManager.Instance.getObject(
    AppConfig.Instance.LocalStorageKey["accessToken"]
  );
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      dispatch(assetAction.fetchLoggedInUser.started({}));
      dispatch(assetAction.fetchUserList.started({}));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (loggedInUser) {
      const tenantId = Number(
        LocalStorageManager.Instance.getObject(
          AppConfig.Instance.LocalStorageKey["tenantId"]
        )
      );

      const isAdmin = loggedInUser?.tenants?.find(
        (tenant: any) => tenant.tenant_id === tenantId
      );
      if (isAdmin?.authorization === 1 && !loggedInUser.wheel) {
        switch (location.pathname) {
          case PageRoutes.USER_MANAGEMENT:
          case PageRoutes.TENANT_MANAGEMENT:
            window.location.replace("/");
        }
      }
    }
  }, [loggedInUser]);

  useEffect(() => {
    document.title = title && accessToken ? title : "ダッシュボード";
  }, [title, accessToken]);

  const logout = async () => {
    dispatch(
      appActions.logout.started({
        onSuccess: () => {
          deleteLoginLocalStorageData();
          navigate(PageRoutes.LOGIN);
          dispatch(appActions.closeModal());
        },
      })
    );
  };

  return (
    <div
      className={mergeClassNames(
        "py-2 px-6 flex items-center text-white bg-primary justify-between",
        empty && "h-10"
      )}
    >
      {!empty && (
        <>
          <span>{title}</span>
          <div className="flex items-center gap-x-7 justify-end">
            <div className="flex gap-x-1.5 items-center">
              <IconDefaultUserProfileImage />
              {loggedInUser?.name && (
                <span className="text-sm">{loggedInUser.name}</span>
              )}
            </div>
            <button
              type="button"
              className="flex items-center gap-x-1.5"
              onClick={() => {
                dispatch(
                  appActions.showModal.started({
                    title: "ログアウトしますか",
                    closeButtonLabel: "キャンセル",
                    handleClose: () => {
                      dispatch(appActions.closeModal());
                    },
                    nextButtonLabel: "ログアウト",
                    handleNext: logout,
                  })
                );
              }}
            >
              <IconLogout />
              <span className="text-xs">ログアウト</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
