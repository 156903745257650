import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { UserManagementMessage } from "../../../constants/schema";
import { UserType } from "../../../constants/user";
import { User } from "../../../core/api";
import { adminAction } from "../../../ducks/admin";
import { appActions } from "../../../ducks/app";
import { assetAction } from "../../../ducks/asset";
import { assetSelectors } from "../../../ducks/asset/selector";
import IconX from "../../../icons/IconX";
import { isTrue } from "../../../utils/array";
import { formatDate } from "../../../utils/date";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import Input from "../../atoms/Input";
import Pagination from "../../molecules/Pagination";
import Table from "../../molecules/Table";
import Form from "../../organisms/Form";
import { MultipleTenantSelection } from "./MultipleTenantSelection";

export default function PageUserManagement() {
  const dispatch = useDispatch();
  const location = useLocation();

  const tenants = useSelector(assetSelectors.getTenants);
  const sideMenus = useSelector(assetSelectors.getMenus);
  const users = useSelector(assetSelectors.getUsers);
  const loggedInUser = useSelector(assetSelectors.getLoggedInUser);
  const isAdmin = useSelector(assetSelectors.isAdminUser);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [showUserModal, setShowUserModal] = useState<"create" | "edit" | false>(
    false
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const tenantOptions = (loggedInUser?.tenants ?? [])
    .map((tenant) => {
      const loggedInTenant = tenants.find((t) => t.id === tenant.tenant_id);
      return { ...tenant, ...loggedInTenant };
    })
    .filter(isTrue);

  const selectedTenant = tenantOptions.filter((t) =>
    selectedUsers[0]?.tenants?.find((x) => t.tenant_id === x.tenant_id)
  )[0];

  const title = useMemo(() => {
    return sideMenus.filter((item) => item.path === location.pathname);
  }, [sideMenus, location.pathname]);

  useEffect(() => {
    dispatch(assetAction.fetchUserList.started({ page: 1 }));
    dispatch(assetAction.fetchTenantList.started({}));
  }, [dispatch]);

  return (
    <div className="flex flex-col h-full">
      <div className="bg-white ml-[1px]">
        <p className="ml-[30px] py-[20px] text-[#334A7C] text-[16px] leading-4 font-bold">
          {title?.[0]?.name ?? ""}
        </p>
      </div>
      <div className="px-5 py-4 flex flex-col h-full overflow-hidden">
        <div className="flex flex-col h-full bg-white rounded p-5 gap-y-5">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-2.5">
              <button
                type="button"
                disabled={!loggedInUser?.wheel}
                className="rounded text-sm w-[96px] h-9 border disabled:opacity-30 border-primary bg-primary text-white disabled:text-[#C0C7D7] disabled:bg-white disabled:border disabled:border-[#C0C7D7] disabled:cursor-not-allowed"
                onClick={() => {
                  setShowUserModal("create");
                }}
              >
                新規追加
              </button>
              <button
                type="button"
                disabled={selectedUsers.length !== 1 || !loggedInUser?.wheel}
                className="rounded text-sm w-[72px] h-9 bg-white border flex items-center justify-center text-primary border-primary disabled:text-[#C0C7D7] disabled:border disabled:border-[#C0C7D7] disabled:cursor-not-allowed"
                onClick={() => {
                  setShowUserModal("edit");
                }}
              >
                編集
              </button>
              <button
                type="button"
                disabled={!selectedUsers.length || !loggedInUser?.wheel}
                className="rounded text-sm w-[72px] h-9 bg-white border flex items-center justify-center text-primary border-primary disabled:text-[#C0C7D7] disabled:border disabled:border-[#C0C7D7] disabled:cursor-not-allowed"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                削除
              </button>
            </div>
            <Pagination
              currentPage={users.currentPage}
              totalPages={users.totalPages}
              onFirst={() => {
                dispatch(
                  assetAction.fetchUserList.started({
                    page: 1,
                  })
                );
              }}
              onPrevious={() => {
                dispatch(
                  assetAction.fetchUserList.started({
                    page: users.currentPage - 1,
                  })
                );
              }}
              onNext={() => {
                dispatch(
                  assetAction.fetchUserList.started({
                    page: users.currentPage + 1,
                  })
                );
              }}
              onLast={() => {
                dispatch(
                  assetAction.fetchUserList.started({
                    page: users.totalPages,
                  })
                );
              }}
            />
          </div>
          <Table
            uniqueIdentifier="id"
            disabled={!loggedInUser?.wheel}
            onChange={(users) => {
              setSelectedUsers(users);
            }}
            items={users.value.map((user) => ({
              ...user,
              tenants: (user.tenants ?? [])
                .map((userTenant) => {
                  const loggedInTenant = tenants.find(
                    (tenant) => tenant.id === userTenant.tenant_id
                  );
                  if (!loggedInTenant) return null;
                  return { ...userTenant, ...loggedInTenant };
                })
                .filter(isTrue),
            }))}
            headers={[
              { title: "ID", getValue: (user) => user.id },
              { title: "ユーザー名", getValue: (user) => user.name },
              { title: "Eメール", getValue: (user) => user.email },
              {
                title: "テナントID",
                getValue: (user) => {
                  return user.tenants
                    .map((userTenant) => userTenant.id)
                    .join(", ");
                },
              },
              {
                title: "テナント名",
                getValue: (user) => {
                  return user.tenants
                    .map((userTenant) => userTenant.name)
                    .join(", ");
                },
              },
              {
                title: "ユーザータイプ",
                getValue: (user) => {
                  return user.tenants
                    .map((userTenant) =>
                      user.wheel
                        ? "全体管理者"
                        : Object.values(UserType)[
                            Number(userTenant.authorization)
                          ]
                    )
                    .join(", ");
                },
              },
              {
                title: "登録日時",
                getValue: (user) =>
                  user.create_date ? formatDate(user.create_date) : "",
              },
            ]}
          />
        </div>
        {showDeleteModal &&
          createPortal(
            <div className="fixed inset-0 z-20 bg-black/25 grid place-content-center">
              <div className="w-[577px] relative py-16 rounded-xl bg-white px-[55px] flex items-center flex-col gap-y-11">
                <div
                  className="absolute cursor-pointer p-2.5 right-0 top-0"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                >
                  <IconX />
                </div>
                <div className="flex flex-col gap-y-6">
                  <div className="text-base whitespace-pre-line text-center font-bold">
                    {"ユーザーを削除しても\nよろしいですか？"}
                  </div>
                </div>

                <div className="flex gap-x-3.75">
                  <CustomButton
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                    text={"キャンセル"}
                    className="w-[226px] bg-white border-gray-light border text-black"
                  />

                  <CustomButton
                    onClick={() => {
                      selectedUsers.forEach((user) => {
                        if (user.id) {
                          dispatch(
                            adminAction.deleteUser.started({
                              id: user.id,
                              onComplete: () => {
                                dispatch(appActions.showNotification());
                              },
                              onSuccess: () => {
                                dispatch(appActions.closeModal());
                                setShowDeleteModal(false);
                                setSelectedUsers([]);
                                dispatch(
                                  assetAction.fetchUserList.started({
                                    forceRefetch: true,
                                    page: users.currentPage,
                                  })
                                );
                                dispatch(
                                  appActions.displayNotification.started({
                                    type: "success",
                                    text: "アカウント情報を削除しました",
                                  })
                                );
                              },
                            })
                          );
                        }
                      });
                    }}
                    text={"削除"}
                    className="w-[226px] text-white"
                  />
                </div>
              </div>
            </div>,
            document.body
          )}
        {showUserModal &&
          createPortal(
            <div className="fixed inset-0 mx-auto py-15 bg-black/30 grid place-items-center">
              <div className="w-full h-full max-w-4xl px-12 overflow-hidden">
                <Form
                  className="h-full flex flex-col bg-white rounded-lg px-4 py-3"
                  defaultValues={
                    showUserModal === "edit"
                      ? {
                          name: selectedUsers[0]?.name,
                          email: selectedUsers[0]?.email,
                          tenant: selectedTenant,
                        }
                      : {
                          tenants: new Array(0),
                        }
                  }
                  schema={z.object({
                    name: z
                      .string()
                      .min(1, { message: UserManagementMessage.NAME }),
                    email: z
                      .string()
                      .email({ message: UserManagementMessage.EMAIL }),
                    password: z
                      .string()
                      .regex(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,64}$/,
                        {
                          message: UserManagementMessage.PASSWORD,
                        }
                      ),
                    tenants: z
                      .array(
                        z.object({
                          id: z.number(),
                          uuid: z.string(),
                          userType: z.string().min(1),
                        })
                      )
                      .min(1),
                  })}
                  onSubmit={(input: any) => {
                    const selectedUser = selectedUsers.find((user) => user.email === input.email);
                    const newUser = {
                      name: input.name,
                      email: input.email,
                      password: input.password,
                      wheel: selectedUser?.wheel ? selectedUser?.wheel : false,
                      tenants: input.tenants.map((item: any) => {
                        return {
                          tenant_id: item.id,
                          authorization: Object.values(UserType).indexOf(
                            item.userType
                          ),
                        };
                      }),
                    };

                    if (showUserModal === "create") {
                      dispatch(
                        adminAction.createUser.started({
                          user: newUser,
                          onComplete: () => {
                            dispatch(appActions.showNotification());
                          },
                          onSuccess: () => {
                            dispatch(
                              assetAction.fetchUserList.started({
                                forceRefetch: true,
                                page: users.totalPages,
                              })
                            );
                            setShowUserModal(false);
                            dispatch(
                              appActions.displayNotification.started({
                                type: "success",
                                text: "新しいアカウントが作成されました",
                              })
                            );
                          },
                        })
                      );
                    } else if (showUserModal === "edit") {
                      const userId = selectedUsers[0]?.id;

                      if (userId) {
                        dispatch(
                          adminAction.editUser.started({
                            user: { id: userId, ...newUser },
                            onComplete: () => {
                              dispatch(appActions.showNotification());
                            },
                            onSuccess: () => {
                              setSelectedUsers([]);
                              setShowUserModal(false);

                              dispatch(
                                assetAction.fetchUserList.started({
                                  forceRefetch: true,
                                  page: users.currentPage,
                                })
                              );
                              dispatch(
                                appActions.displayNotification.started({
                                  type: "success",
                                  text: "アカウント情報が更新されました",
                                })
                              );
                            },
                          })
                        );
                      }
                    }
                  }}
                >
                  <div className="flex justify-between pb-11">
                    <span className="pl-3.5 pt-4.5 font-semibold">
                      {showUserModal === "create" && "アカウントの新規作成"}
                      {showUserModal === "edit" && "アカウント情報の編集"}
                    </span>
                    <button
                      type="button"
                      className="p-2.5"
                      onClick={() => {
                        setShowUserModal(false);
                      }}
                    >
                      <IconX />
                    </button>
                  </div>
                  <div className="flex-1 overflow-y-auto px-16 flex flex-col">
                    <div className="h-full flex flex-col gap-y-8">
                      <Input
                        required
                        name="name"
                        label="ユーザー名"
                        placeholder="アカウント名を入力してください"
                      />
                      <Input
                        required
                        name="email"
                        label="Eメール"
                        placeholder="Eメールを入力してください"
                      />
                      <Input
                        required
                        name="password"
                        type="password"
                        label="パスワード"
                        placeholder={"パスワードを入力してください"}
                      />
                      <MultipleTenantSelection
                        isEdit={showUserModal === "edit"}
                        tenantsList={tenants}
                        selectedUser={selectedUsers[0] ?? []}
                      />
                    </div>
                  </div>
                  <div className="flex gap-x-4 justify-center pb-5 pt-12">
                    <button
                      type="button"
                      className="w-50 border border-gray-light rounded py-2.5 focus:outline-none"
                      onClick={() => {
                        setShowUserModal(false);
                      }}
                    >
                      キャンセル
                    </button>
                    <CustomButton
                      type="submit"
                      text="保存"
                      className="w-50 border border-primary bg-primary text-white disabled:border-gray-border disabled:bg-gray-border rounded py-2.5 focus:outline-none"
                    ></CustomButton>
                  </div>
                </Form>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
}
