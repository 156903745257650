import actionCreatorFactory from "typescript-fsa";
import { EmptyObject } from "@reduxjs/toolkit";
import { CommonAsyncType } from "../../core/@types";
import { ReceptionResponseBody } from "./types";

const actionCreator = actionCreatorFactory("@@Reception");

export const receptionAction = {
  fetchReceptionById: actionCreator.async<
    { id: string } & CommonAsyncType,
    ReceptionResponseBody
  >("FETCH_RECEPTION_BY_ID"),
};
