export const getStatus = (value: string) => {
  const name: { [key: string]: string } = {
    approved: "生成中",
    processing: "生成中",
    success: "生成済",
    failure: "失敗",
  };
  const color: { [key: string]: string } = {
    approved: "bg-sky-500",
    processing: "bg-[#FE9500]",
    success: "bg-[#5FB955]",
    failure: "bg-[#CCCCCC]",
  };
  return { name: name[value], color: color[value] };
};
