import { call, put } from "typed-redux-saga";
import { APIResponse } from "../../core/@types/api";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { userApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { store } from "../../store";
import { appActions } from "../app";
import { takeEveryFsa } from "../operations";
import { adminAction } from "./actions";

export function* adminSaga() {
  yield takeEveryFsa(adminAction.createUser.started, function* (actions) {
    yield* put(appActions.showLoading());

    const response: APIResponse = yield* call(async () => {
      return await userApi.userregist(actions.payload.user).catch((err) => err);
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
      yield* put(appActions.hideLoading());
      return;
    }
    if (response) {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      adminAction.createUser.done({
        params: actions.payload,
        result: {},
      })
    );

    yield* put(appActions.hideLoading());
  });

  yield takeEveryFsa(adminAction.editUser.started, function* (actions) {
    yield* put(appActions.showLoading());

    const response: APIResponse = yield* call(async () => {
      return await userApi.usermodify(actions.payload.user).catch((err) => err);
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    }
    if (response) {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      adminAction.editUser.done({
        params: actions.payload,
        result: {},
      })
    );

    yield* put(appActions.hideLoading());
  });

  yield takeEveryFsa(adminAction.deleteUser.started, function* (actions) {
    yield* put(appActions.showLoading());

    const response: APIResponse = yield* call(async () => {
      return await userApi.userdelete(actions.payload.id).catch((err) => err);
    });

    if (response?.error && !response?.errorHandled) {
      Logger.getInstance().error(response.error.toString());
      actions.payload.onError?.(response.error);

      const errorCode = response.error.response?.data.error_code;
      const error = ErrorMessageManager.Instance.getErrorMessage(errorCode);
      store.dispatch(
        appActions.showModal.started({
          title: error.title,
          modalType: "alert",
          detailText: error.message,
          errorStatusCode: errorCode,
          closeButtonLabel: "閉じる",
          handleClose: () => {
            store.dispatch(appActions.closeModal());
          },
        })
      );
    }
    if (response) {
      actions.payload.onSuccess?.();
    }

    actions.payload.onComplete?.();

    yield* put(
      adminAction.deleteUser.done({
        params: actions.payload,
        result: {},
      })
    );

    yield* put(appActions.hideLoading());
  });
}
