import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { UserModifyRequestBody, UserRegistRequestBody } from "../../core/api";

const actionCreator = actionCreatorFactory("@@Admin");

export const adminAction = {
  createUser: actionCreator.async<CommonAsyncType & { user: UserRegistRequestBody }, {}>("CREATE_USER"),
  editUser: actionCreator.async<CommonAsyncType & { user: UserModifyRequestBody }, {}>("EDIT_USER"),
  deleteUser: actionCreator.async<CommonAsyncType & { id: number }, {}>("DELETE_USER"),
};
