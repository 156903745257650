import { takeEveryFsa } from "../operations";
import { call, put } from "typed-redux-saga";
import { linkageAction } from "./actions";
import { linkageApi, assetsApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { appActions } from "../app";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { store } from "../../store";

export function* linkageSaga() {
  yield takeEveryFsa(linkageAction.getLinkages.started, function* (actions) {
    try {
      const response = yield* call(async (): Promise<any> => {
        return await assetsApi.asset("linkages");
      });

      yield* put(
        linkageAction.getLinkages.done({
          params: actions.payload,
          result: response.data?.linkages ?? [],
        })
      );
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(linkageAction.getLinkage.started, function* (actions) {
    try {
      const { onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await assetsApi.asset("linkages", actions.payload.id);
      });

      yield* put(
        linkageAction.getLinkage.done({
          params: actions.payload,
          result: response.data?.linkages[0] ?? undefined,
        })
      );

      if (onSuccess) {
        onSuccess(response.data.linkages[0]);
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(linkageAction.registerLinkage.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await linkageApi.linkageregist(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());

        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } = ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      if (response && response.status === 200) {
        yield* put(
          linkageAction.registerLinkage.done({
            params: actions.payload,
            result: response.data,
          })
        );
      }

      if (onSuccess) {
        onSuccess(response.data);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(linkageAction.modifyLinkage.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await linkageApi.linkagemodify(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error && onError) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        linkageAction.modifyLinkage.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(linkageAction.filterLinkages.started, function* (actions) {
    yield* put(
      linkageAction.filterLinkages.done({
        params: actions.payload,
        result: null,
      })
    );
  });
}
