import { takeEveryFsa } from "../operations";
import { call, put } from "typed-redux-saga";
import { poiConditionsApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { poiConditionAction } from "./actions";
import { appActions } from "../app";

export function* poiConditionSaga() {
  yield takeEveryFsa(poiConditionAction.getPoiConditions.started, function* (actions) {
    yield* put(appActions.showLoading());
    try {
      const response = yield* call(async (): Promise<any> => {
        return await poiConditionsApi.poiconditions();
      });

      yield* put(
        poiConditionAction.getPoiConditions.done({
          params: actions.payload,
          result: response.data?.conditions ?? [],
        })
      );
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    } finally {
      yield* put(appActions.hideLoading());
    }
  });

  yield takeEveryFsa(poiConditionAction.getPoiByConditions.started, function* (actions) {
    yield* put(appActions.showLoading());

    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await poiConditionsApi.poibyconditions(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error && onError) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        poiConditionAction.getPoiByConditions.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    } finally {
      yield* put(appActions.hideLoading());
    }
  });
}
