import { OutlineIconProps } from "../types";

export default function IconTransportationAnalysis({
    className,
}: OutlineIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className ?? "w-6 h-6 text-primary"}
        >
            <path
                fill="currentColor"
                d="M16.041 14.601v-1.575l-2.241 2.25 2.241 2.25v-1.575H21v-1.35h-4.959Zm2.718 3.825H13.8v1.35h4.959v1.575L21 19.101l-2.241-2.25v1.575ZM9.75 5.601a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Zm-3.375 3.06L3.9 21.351h1.89l1.575-7.2 1.935 1.8v5.4h1.8v-6.8l-1.845-1.84.54-2.7a6.28 6.28 0 0 0 4.9 2.34v-1.8a4.613 4.613 0 0 1-3.915-2.2l-.85-1.445a1.755 1.755 0 0 0-1.53-.855c-.232.001-.46.047-.675.135L3 8.121v4.23h1.8V9.336l1.575-.675Z"
            />
        </svg>
    );
}
