import { Linkage } from "../../core/api";
import { z } from "zod";

export interface LinkageState {
  linkages: Linkage[];
  linkage?: Linkage;
  filteredLinkages?: Linkage[];
  filter?: LinkageFilterRequest;
}

export const linkageFilterRequest = z.object({
  name: z.string().optional(),
  status: z.string().optional(),
  dataType: z.string().optional(),
  term: z.string().optional(),
  fromYear: z.string().optional(),
  fromMonth: z.string().optional(),
  toYear: z.string().optional(),
  toMonth: z.string().optional(),
});
export type LinkageFilterRequest = z.infer<typeof linkageFilterRequest>;
