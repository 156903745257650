import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { UserType } from "../../../constants/user";
import { User } from "../../../core/api";
import { assetSelectors } from "../../../ducks/asset/selector";
import { CrossIcon } from "../../atoms/CrossIcon";
import { SelectComponent } from "./SelectComponent";
type Props = {
  tenantsList: any;
  isEdit: boolean;
  selectedUser?: User;
};
export interface ITenantSelectionComponentInteface {
  uuid: string;
}
export const MultipleTenantSelection: React.FC<Props> = ({
  tenantsList,
  isEdit,
  selectedUser,
}) => {
  const [selectionList, setSelectionList] = useState<
    ITenantSelectionComponentInteface[]
  >([]);
  const { resetField, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (isEdit) {
      if (
        selectedUser &&
        selectedUser.tenants &&
        selectedUser.tenants.length > 0
      ) {
        let temp: any = [];
        selectedUser.tenants.map((tenant) => {
          const id = v4();
          setValue(
            `tenant_${id}`,
            tenantsList.find((t: any) => t.id === tenant.tenant_id)
          );
          setValue(
            `userType_${id}`,
            Object.values(UserType)[tenant.authorization ?? 0]
          );
          temp.push({
            uuid: id,
            id: tenant.tenant_id,
            userType: Object.values(UserType)[tenant.authorization ?? 0],
          });
        });
        setValue("tenants", temp);
        setSelectionList(temp);
      }
    } else {
      const id = v4();
      setSelectionList(selectionList.concat({ uuid: id }));
      const tenants = getValues("tenants");
      setValue("tenants", [...tenants, { uuid: id }]);
    }
  }, []);

  const addToList = () => {
    const id = v4();
    const tenants = getValues("tenants");

    setValue("tenants", [...tenants, { uuid: id }]);
    setSelectionList(selectionList.concat({ uuid: id }));
  };
  const removeFromList = (id: string) => {
    const tenants = getValues("tenants");
    resetField(`tenant_${id}`);
    resetField(`userType_${id}`);
    setSelectionList(selectionList.filter((item) => item.uuid !== id));
    setValue(
      "tenants",
      tenants.filter((item: any) => item.uuid !== id)
    );
  };

  const selectedTenants = getValues("tenants");

  const enable = selectedTenants?.length < tenantsList?.length;
  const isAdmin = useSelector(assetSelectors.isAdminUser);
  return (
    <div className="relative  flex flex-col">
      <div className="w-full relative space-y-4">
        {selectionList.map((item, index) => {
          return (
            <SelectComponent
              selectedTenants={selectedTenants}
              showMinusIcon={index !== 0}
              tenantList={tenantsList}
              removeFromList={removeFromList}
              id={item.uuid}
              key={item.uuid}
            />
          );
        })}
      </div>
      <div
        onClick={addToList}
        className={
          enable && isAdmin
            ? "cursor-pointer hover:opacity-80 mt-[24px] flex items-center"
            : "cursor-pointer hover:opacity-80 mt-[24px] flex items-center opacity-25 pointer-events-none"
        }
      >
        <CrossIcon />
        <span className="text-[#1967D2] text-[14px] ml-[4px]">
          テナントの追加
        </span>
      </div>
    </div>
  );
};
