import React, { useCallback, useEffect, useState } from "react";
import { Controller, Validate, useFormContext } from "react-hook-form";
import { ReactComponent as DownSelecionIcon } from "../../assets/icons/down-selection.svg";
type Props = {
  options: any;
  onChange: (value: string) => void;
  isCity?: boolean;
  disabled?: boolean;
  name: string;
  rules?: {
    required?: boolean;
    validate?: Validate<string, string>;
  };
  defaltValue?: string;
  defaultId?: string;
};

export const TenantSelection: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setValue } = useFormContext();
  const [currentName, setCurrentName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const handleOpenSelection = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const handleChange = (value: string) => {
    setCurrentName(value);
    if (props.name === "city") {
      setValue("state", "", { shouldValidate: true });
    }
    setValue(props.name, value, { shouldValidate: true });
    setIsOpen(false);
    props.onChange(value);
  };

  useEffect(() => {
    setCurrentName("");
    if (props.defaltValue) {
      setCurrentName(props.defaltValue);
    }
    if (props.defaultId) {
      setValue(props.name, props.defaultId, { shouldValidate: true });
    }
    if (props.options.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [props.options, props.defaltValue]);

  return (
    <div className="relative">
      <Controller
        name={props.name}
        defaultValue={""}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <div>
              <div
                onClick={handleOpenSelection}
                className={`w-[315px] border-[#999999] ${
                  disabled
                    ? "cursor-not-allowed opacity-50 bg-[#c4c4c4]"
                    : "cursor-pointer"
                } rounded h-[56px] justify-between border flex items-center pl-[16px]`}
              >
                <div className="text-[14px]">
                  {currentName !== ""
                    ? currentName
                    : props.isCity
                    ? "市区町村"
                    : "都道府県"}
                </div>
                <DownSelecionIcon className="mr-[20px]" />
              </div>

              {isOpen && !disabled && !props.isCity && (
                <div className="mt-[14px] top-12 z-10 bg-white absolute w-[320px] h-[300px] overflow-auto shadow-md rounded py-[10px]">
                  {Object.keys(props.options).map((option) => {
                    return (
                      <div
                        onClick={() => {
                          handleChange(option);
                          field.onChange(option);
                        }}
                        key={option}
                        className="text-[14px] cursor-pointer h-[40px] pl-[20px] flex items-center hover:bg-primary-light"
                      >
                        {option}
                      </div>
                    );
                  })}
                </div>
              )}
              {isOpen && !disabled && props.isCity && (
                <div className="mt-[14px] top-12 z-10 bg-white absolute w-[320px] h-[300px] overflow-auto shadow-md rounded py-[10px]">
                  {props.options.map((option: any) => {
                    return (
                      <div
                        onClick={() => {
                          handleChange(option.name);
                          field.onChange(option.id);
                        }}
                        key={option.id}
                        className="text-[14px] cursor-pointer h-[40px] pl-[20px] flex items-center hover:bg-primary-light"
                      >
                        {option.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
