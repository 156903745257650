import { put } from "typed-redux-saga";
import { Logger } from "../../core/logger";
import { takeEveryFsa } from "../operations";
import { analysisExecuteActions } from "./actions";

export function* analysisExcuteSaga() {
  yield takeEveryFsa(
    analysisExecuteActions.addSelectedItem.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.addSelectedItem.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );
  yield takeEveryFsa(
    analysisExecuteActions.removeSelectedItem.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.removeSelectedItem.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );
  yield takeEveryFsa(
    analysisExecuteActions.addSelectedSpotItem.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.addSelectedSpotItem.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(
    analysisExecuteActions.selectSpot.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.selectSpot.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(
    analysisExecuteActions.setSelectedSpotItems.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.setSelectedSpotItems.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(
    analysisExecuteActions.setDate.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.setDate.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(
    analysisExecuteActions.removeSelectedSpotItem.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.removeSelectedSpotItem.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(
    analysisExecuteActions.setStep.started,
    function* (actions) {
      try {
        yield* put(
          analysisExecuteActions.setStep.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );
}
