import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { ReactComponent as MinusIcon } from "../../../assets/minus-select.svg";
import { UserType } from "../../../constants/user";
import { assetSelectors } from "../../../ducks/asset/selector";
import Select from "../../atoms/Select";

type Props = {
  id: string;

  removeFromList: (id: string) => void;

  tenantList: any;

  showMinusIcon: boolean;

  selectedTenants: any[];
};
export const SelectComponent: React.FC<Props> = ({
  id,
  removeFromList,
  tenantList,
  selectedTenants,
  showMinusIcon,
}) => {
  const { setValue, getValues } = useFormContext();
  const isAdmin = useSelector(assetSelectors.isAdminUser);
  const handleChangeTenant = (value: any) => {
    const tenant = getValues(`tenants`);
    let has = false;
    if (tenant) {
      const res = tenant.map((item: any) => {
        if (item.uuid === id) {
          has = true;
          return { ...value, ...item, uuid: id };
        }

        return item;
      });
      if (has) {
        setValue(`tenants`, res, { shouldValidate: true });
      } else
        setValue(`tenants`, [...res, { ...value, uuid: id }], {
          shouldValidate: true,
        });
    } else {
      setValue(`tenants`, [{ ...value, uuid: id }], { shouldValidate: true });
    }
  };
  const handleChangeUserType = (value: any) => {
    const tenant = getValues(`tenants`);
    let has = false;
    if (tenant) {
      const res = tenant.map((item: any) => {
        if (item.uuid === id) {
          has = true;

          return { ...item, userType: value, uuid: id };
        }

        return item;
      });
      if (has) {
        setValue(`tenants`, res, { shouldValidate: true });
      } else
        setValue(`tenants`, [...res, { userType: value, uuid: id }], {
          shouldValidate: true,
        });
    } else {
      setValue(`tenants`, [{ name: value, uuid: id }], {
        shouldValidate: true,
      });
    }
  };

  const unSelectedTenants = tenantList.filter((t: any) => {
    return !selectedTenants.find((s) => {
      return s.id === t.id;
    });
  });

  return (
    <div id={id} className="flex w-full space-x-[22px] items-center">
      <div className="w-[273px] h-full">
        <Select
          required
          label="テナント"
          name={`tenant_${id}`}
          options={unSelectedTenants}
          displayKey="name"
          onChange={handleChangeTenant}
          placeholder="テナントを選択してください"
        />
      </div>
      <div className="w-[273px] h-full">
        <Select
          disabled={!isAdmin}
          required
          label="ユーザータイプ"
          name={`userType_${id}`}
          options={Object.values(UserType)}
          placeholder="ユーザータイプを選択してください"
          onChange={handleChangeUserType}
        />
      </div>
      <div className="mt-6">
        {showMinusIcon && isAdmin && (
          <MinusIcon
            className="cursor-pointer"
            onClick={() => {
              removeFromList(id);
            }}
          />
        )}
      </div>
    </div>
  );
};
