import { call, put } from "typed-redux-saga";
import { ErrorMessageManager } from "../../core/http/ErrorMessageManager";
import { spotApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { store } from "../../store";
import { appActions } from "../app";
import { takeEveryFsa } from "../operations";
import { spotAction } from "./actions";

export function* spotSaga() {
  yield takeEveryFsa(
    spotAction.addPolygonPosition.started,
    function* (actions) {
      try {
        yield* put(
          spotAction.addPolygonPosition.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );
  yield takeEveryFsa(
    spotAction.addRectanglePosition.started,
    function* (actions) {
      try {
        yield* put(
          spotAction.addRectanglePosition.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );
  yield takeEveryFsa(spotAction.addCirclePosition.started, function* (actions) {
    try {
      yield* put(
        spotAction.addCirclePosition.done({
          params: actions.payload,
          result: {},
        })
      );
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(
    spotAction.setFacilityMasterItems.started,
    function* (actions) {
      try {
        yield* put(
          spotAction.setFacilityMasterItems.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(
    spotAction.addSelectedFacilityMasterFilterType.started,
    function* (actions) {
      try {
        yield* put(
          spotAction.addSelectedFacilityMasterFilterType.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );
  yield takeEveryFsa(
    spotAction.removeSelectedFacilityMasterFilterType.started,
    function* (actions) {
      try {
        yield* put(
          spotAction.removeSelectedFacilityMasterFilterType.done({
            params: actions.payload,
            result: {},
          })
        );
      } catch (err) {
        Logger.getInstance().error(`${err}`);
      }
    }
  );

  yield takeEveryFsa(spotAction.fetchSpotByID.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await spotApi.spotinfo(actions.payload.id).catch((err) => {
          return err;
        });
      });
      if (response && response.error && onError) {
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        spotAction.fetchSpotByID.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(spotAction.analyzeSpot.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await spotApi.spotanalyze(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error && onError) {
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        spotAction.analyzeSpot.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess(response);
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(spotAction.deleteSpot.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await spotApi.spotdelete(actions.payload.id).catch((err) => {
          return err;
        });
      });
      if (response && response.error && onError) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        spotAction.deleteSpot.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(spotAction.registerSpot.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await spotApi.spotregist(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error) {
        yield* put(appActions.hideLoading());

        if (!response.errorHandled && onError) {
          onError(response.error);
        } else if (!response.errorHandled && !onError) {
          yield* put(appActions.hideLoading());
          const errorCode = response?.error?.response?.data.error_code;
          const errorObj: { title: string; message: string } =
            ErrorMessageManager.Instance.getErrorMessage(errorCode);
          store.dispatch(
            appActions.showModal.started({
              title: errorObj.title,
              modalType: "alert",
              detailText: errorObj.message,
              errorStatusCode: errorCode,
              closeButtonLabel: "閉じる",
              handleClose: () => {
                store.dispatch(appActions.closeModal());
              },
            })
          );
        }
        return;
      }
      if (response && response.status === 200) {
        yield* put(
          spotAction.registerSpot.done({
            params: actions.payload,
            result: response.data,
          })
        );
      }

      if (onSuccess) {
        onSuccess(response);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });

  yield takeEveryFsa(spotAction.modifySpot.started, function* (actions) {
    try {
      const { onComplete, onError, onSuccess } = actions.payload;
      const response = yield* call(async (): Promise<any> => {
        return await spotApi.spotmodify(actions.payload.body).catch((err) => {
          return err;
        });
      });
      if (response && response.error && onError) {
        yield* put(appActions.hideLoading());
        if (!response.errorHandled) {
          onError(response.error);
        }
        return;
      }
      yield* put(
        spotAction.modifySpot.done({
          params: actions.payload,
          result: response.data,
        })
      );
      if (onSuccess) {
        onSuccess();
      }
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      Logger.getInstance().error(`${err}`);
    }
  });
}
