import React from "react";

type Props = {
  icon: JSX.Element | null;
  title: string;
};
export const AnalysisSpotDisplay: React.FC<Props> = (props) => {
  const { icon, title } = props;
  return (
    <div className="rounded-[40px]  flex items-center ">
      {icon}
      <div className="ml-[5px] text-[16px] font-bold text-primary">{title}</div>
    </div>
  );
};
