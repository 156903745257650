export const PageRoutes = {
  INDEX: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:secret_key",
  RESET_PASSWORD_DEFAULT: "/reset-password",
  HOME: "/home",
  CREATE_SPOT: "/create_spot",
  SELECT_SPOT_ANALYSIS: "/select_analysis",
  EXECUTE_SPOT_ANALYSIS: "/analysis_execute",
  CHOOSE_SPOT_ANALYSIS: "/analysis_execute/choose_spot_analysis",
  SELECT_SPOT_ANALYSIS_TYPE: "/analysis_execute/select_spot_analysis_type",
  TENANT_MANAGEMENT: "/tenant_manager",
  USER_MANAGEMENT: "/user_management", // W-027
  LINKAGE_HISTORY: "/linkage",
  ADD_LINKAGE_CONFIRM: "/linkage/add/confirm",
  ADD_LINKAGE_COMPLETE: "/linkage/add/complete",
  EDIT_LINKAGE: "/linkage/edit",
};
