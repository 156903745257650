import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Linkage, LinkageCreateRequest, LinkageModifyRequest, LinkageMutateResponse, SpotMenu } from "../../core/api";
import { LinkageFilterRequest } from "./types";

const actionCreator = actionCreatorFactory("@@Linkage");

export const linkageAction = {
  getLinkages: actionCreator.async<{}, Linkage[]>("GET_LINKAGES"),
  getLinkage: actionCreator.async<{ id: number } & CommonAsyncType, Linkage>("GET_LINKAGE"),
  registerLinkage: actionCreator.async<{ body: LinkageCreateRequest } & CommonAsyncType, LinkageMutateResponse>("REGISTER_LINKAGE"),
  modifyLinkage: actionCreator.async<{ body: LinkageModifyRequest } & CommonAsyncType, LinkageMutateResponse>("MODIFY_LINKAGE"),
  filterLinkages: actionCreator.async<{ request: LinkageFilterRequest; analysisTypes: SpotMenu[] }, null>("FILTER_LINKAGES"),
};
