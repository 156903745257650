import { IconProps } from "./types";

export default function IconDefaultUserProfileImage({
  className = "w-6 h-6",
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path fill="#B0B9CC" d="M24 12A12 12 0 1 1 12 0a12 12 0 0 1 12 12" />
      <path
        fill="#fff"
        d="M12 6a3 3 0 1 0 3 3 3 3 0 0 0-3-3m0 7.5c-2 0-6 1.005-6 3V18h12v-1.5c0-1.995-4-3-6-3"
      />
    </svg>
  );
}
