import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selector = (state: StoreState) => state.asset;

export const assetSelectors = {
  getTitle: createSelector(selector, (app) => app.title),
  getUrl: createSelector(selector, (app) => app.url),
  getDisplayItems: createSelector(selector, (app) => app.displayItems),
  getDefaultLocation: createSelector(selector, (app) => app.defaultLocation),
  getMenus: createSelector(selector, (asset) => asset.menus),
  getAnalysisTypes: createSelector(selector, (asset) => asset.analysisTypes),
  getSpots: createSelector(selector, (asset) => asset.spots),
  getUsers: createSelector(selector, (asset) => asset.users),
  getTenants: createSelector(selector, (asset) => asset.tenants),
  getLoggedInUser: createSelector(selector, (asset) => asset.loggedInUser),
  isAdminUser: createSelector(selector, (asset) => asset.isAdmin),
};
