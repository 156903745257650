import IconMigratoryUnderstandingAnalysis from "../icons/AnalysisType/IconMigratoryUnderstandingAnalysis";
import IconNumberOfVisitorsAnalysis from "../icons/AnalysisType/IconNumberOfVisitorsAnalysis";
import IconPlaceOfResidenceAnalysis from "../icons/AnalysisType/IconPlaceOfResidenceAnalysis";
import IconSameDayActivityAnalysis from "../icons/AnalysisType/IconSameDayActivityAnalysis";
import IconTransportationAnalysis from "../icons/AnalysisType/IconTransportationAnalysis";

export const getAnalysisIcon = (analysis_id: number | undefined) => {
  switch (analysis_id) {
    case 1:
      return IconNumberOfVisitorsAnalysis;
    case 2:
      return IconPlaceOfResidenceAnalysis;
    case 3:
      return IconTransportationAnalysis;
    case 4:
      return IconMigratoryUnderstandingAnalysis;
    case 5:
      return IconSameDayActivityAnalysis;
    default:
      return null;
  }
};
