import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/Routes";
import { ErrorMessageManager } from "../../../core/http/ErrorMessageManager";
import { appActions } from "../../../ducks/app";
import { assetAction } from "../../../ducks/asset";
import { LoginAction, SubmitType } from "../../../ducks/login";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ログインボタン
  const onSubmitHandler = async ({
    tenantName,
    emailAddress,
    password,
  }: SubmitType) => {
    dispatch(assetAction.reset());
    dispatch(
      LoginAction.login.started({
        tenantName,
        emailAddress,
        password,
        onError: (e: any) => {
          showModal(e);
        },
        onSuccess: (s: any) => {
          dispatch(
            assetAction.fetchAsset.started({
              forceRefetch: true,
              onSuccess: () => {
                navigate(PageRoutes.CREATE_SPOT, { replace: true });
              },
            })
          );
        },
        onComplete: (c: any) => {
          console.log("complete:", c);
        },
      })
    );
  };

  const showModal = (e: any) => {
    const error = e.response.data;
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(error.error_code);
    dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: error.error_code,
        nextButtonLabel: "",
        closeButtonLabel: "閉じる",
        handleNext: () => {
          dispatch(appActions.closeModal());
        },
        handleClose: () => {
          dispatch(appActions.closeModal());
        },
      })
    );
  };

  return { onSubmitHandler };
};
